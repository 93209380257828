import { useState } from 'react';

import container from 'atoms/spacing/containers';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Heading from 'quarks/Heading';
import Image from 'quarks/Image';
import Text from 'quarks/Text';

import Section from 'molecules/Section/Section';

import TabbedFeature from 'components/VerticalSwitcher/TabbedFeature';
import type { VerticalSwitcherProps } from 'components/VerticalSwitcher/VerticalSwitcher.config';

import renderAsset from 'utils/assetGenerator';
import colorizeText from 'utils/colorizeText';
import { doodleGenerator } from 'utils/doodleGenerator';
import { getBackgroundIsDark, optimizeBuilderImage } from 'utils/functions';
import RichTextParser from 'utils/richTextParser';

import type { FC } from 'react';

const VerticalSwitcher: FC<VerticalSwitcherProps> = ({
  assetType,
  background,
  coloredEyebrow,
  eyebrow,
  features,
  heading,
  imageCaption,
  subheading,
  doodle,
  switcherImage,
  lottieFile,
  ariaLabel,
  sectionId,
}) => {
  const [openedFeature, setOpenFeature] = useState<number>(0);

  const backgroundIsDark = getBackgroundIsDark(background);
  const doodleImage = doodle && doodleGenerator(doodle);

  const imageList = features?.map(feature => feature?.featuredImage);
  const activeFeature = imageList && imageList[openedFeature];

  const textColor = backgroundIsDark ? 'common-white' : 'gray-900';
  const styledEyebrow = eyebrow && coloredEyebrow ? colorizeText(eyebrow, backgroundIsDark) : eyebrow;

  const optimizedSwitcherImage = switcherImage && optimizeBuilderImage(switcherImage);
  const optimizedVerticalFeaturedImage =
    activeFeature?.featuredImage && optimizeBuilderImage(activeFeature.featuredImage);

  const verticalFeaturedImage = assetType === 'featureAssets' && activeFeature && (
    <Image
      image={optimizedVerticalFeaturedImage}
      alt={activeFeature.imageCaption || ''}
      alignSelf="center"
      objectFit="contain"
      width="100%"
      borderRadius="8px"
      transition="align-self 0.150s ease"
    />
  );

  return (
    <Section __typename="ComponentVerticalSwitcher" background={background} id={sectionId}>
      <Flex
        gap="48px"
        flexDirection="column"
        md={{ gap: '64px' }}
        xl={{ flexDirection: 'row', gap: '32px', flex: '0 0 50%' }}
      >
        <Flex
          flexDirection="column"
          flexWrap="nowrap"
          gap="24px"
          textColor={textColor}
          width="100%"
          maxWidth={container.md}
          xl={{ maxWidth: container.sm }}
        >
          <Flex flexDirection="column" gap="24px">
            {eyebrow && <Text textStyle="eyebrow">{styledEyebrow}</Text>}
            {heading && (
              <Heading
                as="h2"
                textStyle="xs"
                fontWeight="semiBold"
                md={{ textStyle: 'sm', fontWeight: 'semiBold' }}
                xl={{ textStyle: 'md', fontWeight: 'semiBold' }}
              >
                {heading}
              </Heading>
            )}
            {subheading && (
              <Container
                textColor={backgroundIsDark ? 'gray-50' : 'gray-700'}
                fontSize="textLg"
                md={{ fontSize: 'textXl' }}
              >
                {RichTextParser(subheading)}
              </Container>
            )}
          </Flex>
          <Flex flexDirection="column" gap="16px" xl={{ height: 'auto' }}>
            {features &&
              features.map((feature, index) => (
                <TabbedFeature
                  isActive={index === openedFeature}
                  icon={feature.icon}
                  backgroundIsDark={backgroundIsDark}
                  key={`${feature?.title}-${index}` || index}
                  title={feature.title || ''}
                  description={feature.description || ''}
                  onClick={() => setOpenFeature(index)}
                />
              ))}
          </Flex>
        </Flex>
        <Container xl={{ maxWidth: container.sm }}>
          <Container position="relative">
            {verticalFeaturedImage
              ? verticalFeaturedImage
              : renderAsset(assetType, optimizedSwitcherImage, imageCaption, lottieFile, ariaLabel)}
            {doodleImage && (
              <Image
                width="283px"
                left="195px"
                top="-30px"
                position="absolute"
                zIndex={-10}
                image={doodleImage}
                alt={doodle}
                md={{ width: '496px', left: '330px', top: '-50px' }}
              />
            )}
          </Container>
        </Container>
      </Flex>
    </Section>
  );
};

VerticalSwitcher.defaultProps = {
  coloredEyebrow: false,
  eyebrow: 'eyebrow',
  background: 'Gray-100',
  heading: 'Vertical Switcher',
  subheading: '<p>Insert Subheading Here</p>',
};

export default VerticalSwitcher;
