import { forwardRef } from 'react';

import type { GetColorDefinition } from 'atoms/colors/colors';

import Container from 'quarks/Container';
import type { BasicProps } from 'quarks/interpolations/basic';

import type { FC, KeyboardEventHandler, MouseEventHandler } from 'react';

interface DotsProps extends BasicProps {
  /**
   * The flag for an active dot.
   */
  isActive?: boolean;
  /**
   * Color definition of the dot.
   */
  color?: GetColorDefinition;
  /**
   * Callback function fired on Keyboard `onKeyDown` event.
   */
  onKeyDown?: KeyboardEventHandler<HTMLDivElement>;
  /**
   * Callback function fired on Mouse `onClick` event.
   */
  onClick?: MouseEventHandler<HTMLDivElement>;
}

// eslint-disable-next-line react/prop-types
const Dots: FC<DotsProps> = forwardRef(({ color, onKeyDown, onClick, ...props }, ref) => (
  <Container
    cursor="pointer"
    width="12px"
    height="12px"
    borderRadius="50%"
    transition="width .2s"
    backgroundColor={color}
    onKeyDown={onKeyDown}
    onClick={onClick}
    {...ref}
    {...props}
  />
));

export default Dots;
