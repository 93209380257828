import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Heading from 'quarks/Heading';
import Image from 'quarks/Image';
import Text from 'quarks/Text';

import Section from 'molecules/Section/Section';

import type { ComponentProfitMarginCalculatorProps } from 'components/ProfitMarginCalculator/ProfitMarginCalcDataAndTypes';
import ProfitMarginCalculator from 'components/ProfitMarginCalculator/ProfitMarginCalculator';

import colorizeText from 'utils/colorizeText';
import doodleGenerator from 'utils/doodleGenerator';
import type { DoodleKey } from 'utils/doodleGenerator';
import RichTextParser from 'utils/richTextParser';

import type { FC } from 'react';

const ComponentProfitMarginCalculator: FC<ComponentProfitMarginCalculatorProps> = ({
  eyebrow,
  heading,
  headingType,
  subheading,
  sectionId,
  calculatorEyebrow,
  calculatorTitle,
  calculatorDescription,
  totalRevenue,
  costOfGoodsSold,
  totalOperatingCosts,
  calculatorCaption,
}) => {
  const doodleProps = {
    position: 'absolute',
    alt: 'doodle',
    zIndex: '-1',
  } as const;

  return (
    <Section __typename="Component Profit Margin Calculator" id={sectionId} contained>
      <Flex flexDirection="column" alignItems="center" justifyContent="center" gap="60px">
        <Flex flexDirection="column" gap="20px" md={{ gap: '32px' }} position="relative">
          <Image
            {...doodleProps}
            display="none"
            height="500px"
            width="438px"
            bottom="-140px"
            left="-304px"
            image={doodleGenerator('skillet') as DoodleKey}
            transform="rotate(13deg)"
            xl={{
              display: 'block',
            }}
          />
          {(eyebrow || heading || subheading) && (
            <Flex flexDirection="column" gap="18px" alignItems="center">
              {eyebrow && <Text textStyle="eyebrow">{colorizeText(eyebrow, false)}</Text>}
              {heading && (
                <Heading
                  as={headingType || 'h2'}
                  textStyle="sm"
                  fontWeight="semiBold"
                  md={{ textStyle: 'md', fontWeight: 'semiBold' }}
                  xl={{ textStyle: 'md', fontWeight: 'semiBold' }}
                >
                  {heading}
                </Heading>
              )}
              {subheading && (
                <Container textColor="gray-700" fontSize="textLg" md={{ fontSize: 'textXl' }}>
                  {RichTextParser(subheading)}
                </Container>
              )}
            </Flex>
          )}
        </Flex>
        <Container
          position="relative"
          marginTop={24}
          md={{
            marginTop: 48,
          }}
          xl={{
            marginTop: 0,
          }}
        >
          <Image
            {...doodleProps}
            height="68px"
            width="320px"
            top="-68px"
            right="-170px"
            image={doodleGenerator('tripleknotswirl') as DoodleKey}
            transform="rotate(345deg)"
            md={{
              height: '100px',
              width: '478px',
              top: '-110px',
              right: '-230px',
            }}
            xl={{
              top: '-44px',
              right: '-385px',
            }}
          />
          <ProfitMarginCalculator
            eyebrow={calculatorEyebrow}
            title={calculatorTitle}
            description={calculatorDescription}
            totalRevenueField={totalRevenue}
            costOfGoodsSoldField={costOfGoodsSold}
            totalOperatingCostsField={totalOperatingCosts}
            calculatorCaption={calculatorCaption}
          />
        </Container>
      </Flex>
    </Section>
  );
};

export default ComponentProfitMarginCalculator;
