import { breakpoints } from 'atoms/breakpoints/breakpoints';

import Container from 'quarks/Container';

import Button, { handleButtonTracking } from 'molecules/Button/Button';

import type { SwitcherItemProps } from 'components/HorizontalSwitcher/HorizontalSwitcher';

import type { FC } from 'react';

interface HorizontalSwitcherTabsProps {
  switcherItem: SwitcherItemProps;
  isCurrentTab: boolean;
  index: number;
  setCurrentSwitcherItem: (arg0: number) => void;
}

const HorizontalSwitcherTabs: FC<HorizontalSwitcherTabsProps> = ({
  switcherItem,
  isCurrentTab,
  setCurrentSwitcherItem,
  index,
}) => {
  const { itemTitle } = switcherItem;

  const handleClick = (idx: number, title: string) => {
    setCurrentSwitcherItem(idx);

    const trackingScheme = {
      label: title,
      location: 'HorizontalSwitcherTab',
    };

    handleButtonTracking(trackingScheme);
  };

  return (
    <Container
      key={itemTitle}
      as="li"
      css={`
        & + li {
          margin-left: 22px;
          @media screen and (min-width: ${breakpoints.lg}) {
            margin-left: 30px;
          }
        }
      `}
    >
      <Button
        variant="text"
        textColor="gray-900"
        fontSize="textLg"
        backgroundColor="common-transparent"
        paddingX={4}
        boxShadow="none"
        fontWeight={isCurrentTab ? 'bold' : 'regular'}
        onClick={() => handleClick(index, itemTitle)}
        borderRadius="0"
        borderBottom={isCurrentTab ? '2px solid' : 'unset'}
        borderColor="primary-500"
        width="max-content"
        md={{ paddingX: 10 }}
        hover={{
          textColor: 'primary-500',
          borderBottom: '2px solid',
        }}
        focusVisible={{
          boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba(43, 57, 230, 0.3)',
        }}
      >
        {itemTitle}
      </Button>
    </Container>
  );
};

export default HorizontalSwitcherTabs;
