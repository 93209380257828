import Flex from 'quarks/Flex';
import Image from 'quarks/Image';
import Text from 'quarks/Text';
import type { FlexProps } from 'quarks/interpolations/flex';

import { optimizeBuilderImage } from 'utils/functions';

import type { Builder_EntityQuoteData } from 'graphqlTypes';
import type { FC } from 'react';

interface NoImageQuoteProps extends FlexProps, Omit<Builder_EntityQuoteData, 'role' | 'image'> {
  backgroundIsDark: boolean;
}

const NoImageQuote: FC<NoImageQuoteProps> = ({
  backgroundIsDark,
  quote,
  author,
  authorHeadshot,
  company,
  ...props
}) => {
  const optimizedImage = authorHeadshot && optimizeBuilderImage(authorHeadshot);

  return (
    <Flex
      width="100%"
      alignSelf="center"
      maxWidth="370px"
      borderRadius="12px"
      backgroundImage="lineupGradient-darkMode"
      {...props}
      css={{ padding: '2px' }}
    >
      <Flex
        flexDirection="column"
        alignItems="flex-start"
        gap="32px"
        borderRadius="12px"
        backgroundColor={backgroundIsDark ? 'gray-900' : 'common-white'}
        paddingAll={24}
        lg={{ paddingAll: 32 }}
      >
        <Text textStyle="xl" fontWeight="medium" textColor={backgroundIsDark ? 'common-white' : 'gray-700'}>
          {quote}
        </Text>
        <Flex gap="12px">
          {optimizedImage && (
            <Image
              image={optimizedImage}
              alt={`${author} Quote Image`}
              height="48px"
              width="48px"
              objectFit="cover"
              borderRadius="50%"
            />
          )}
          {author && (
            <Flex flexDirection="column">
              <Text textStyle="lg" textColor={backgroundIsDark ? 'common-white' : 'gray-900'}>
                {author}
              </Text>
              {company && (
                <Text textStyle="md" textColor={backgroundIsDark ? 'gray-100' : 'gray-700'}>
                  {company}
                </Text>
              )}
            </Flex>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default NoImageQuote;
