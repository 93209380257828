// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import formConfigIds from '/public/static/formConfigIds.json';

import { iconValues } from 'atoms/Icons/Icon.types';

import FormConfig from 'components/HsForm/HsForm.config';

import SectionBuilderConfig from 'molecules/Section/Section.config';

import { ButtonBuilderConfig } from 'components/ComponentButton/buttonTypeConfig';

import { doodleField } from 'utils/doodleGenerator';
import type { Writeable } from 'utils/typeUtils';

type removeReadOnlyModifier = Writeable<typeof iconValues>;

const SwitchbackBuilderConfig = {
  name: 'Switchback',
  inputs: [
    { name: 'internalName', type: 'text' },
    { name: 'isFormSwitchback', type: 'boolean', helperText: 'Toggle on to render a Form.' },
    {
      name: 'assetSide',
      type: 'text',
      enum: ['Left', 'Right'],
      showIf: (options: { get: (arg0: string) => boolean }) => options.get('isFormSwitchback') === false,
    },
    {
      name: 'featureType',
      type: 'text',
      enum: ['Feature List', 'Stats'],
      helperText: 'For the Form Switchback, please select Feature List.',
    },
    { name: 'background', type: 'text', enum: ['White', 'Gray-100', 'Gray-900'] },
    { name: 'coloredEyebrow', type: 'boolean', helperText: 'Set to true if you want your heading colorized' },
    { name: 'eyebrow', type: 'text' },
    { name: 'heading', type: 'text' },
    { name: 'headingType', type: 'text', enum: ['h2', 'h3', 'h4', 'h5', 'h6'] },
    { name: 'subheading', type: 'richText' },
    {
      name: 'buttonGroup',
      type: 'list',
      subFields: [{ name: 'button', type: 'reference', model: 'component-button' }],
      helperText: 'For the Switchback Component, the Figma Design System has both buttons at xLarge. ',
    },
    {
      name: 'buttons',
      type: 'list',
      subFields: ButtonBuilderConfig.inputs,
      helperText: 'For the Switchback Component, the Figma Design System has both buttons at xLarge. ',
    },
    {
      name: 'hsForm',
      type: 'object',
      subFields: FormConfig.inputs,
      defaultValue: {
        variant: 'Basic',
        formId: formConfigIds[0],
      },
      showIf: (options: { get: (arg0: string) => boolean }) => options.get('isFormSwitchback') === true,
    },
    { name: 'assetType', type: 'text', enum: ['Image', 'Animation'], defaultValue: 'Image' },
    {
      name: 'switchbackImage',
      type: 'file',
      allowedFileTypes: ['jpeg', 'png', 'jpg', 'svg'],
      showIf: (options: { get: (arg0: string) => string }) => options.get('assetType') === 'Image',
    },
    {
      name: 'imageCaption',
      type: 'text',
      helperText: 'Caption for an Image',
      showIf: (options: { get: (arg0: string) => string }) => options.get('assetType') === 'Image',
    },
    {
      name: 'lottieFile',
      type: 'file',
      allowedFileTypes: ['lottie', 'json'],
      helperText: 'Only accepts .json files',
      showIf: (options: { get: (arg0: string) => string }) => options.get('assetType') === 'Animation',
    },
    {
      name: 'ariaLabel',
      type: 'text',
      helperText: 'Aria Label for the Lottie',
      showIf: (options: { get: (arg0: string) => string }) => options.get('assetType') === 'Animation',
    },
    ...doodleField,
    { name: 'featureListHeading', type: 'text', defaultValue: 'Feature list heading' },
    {
      name: 'features',
      type: 'list',
      subFields: [
        {
          name: 'title',
          type: 'text',
        },
        {
          name: 'link',
          type: 'url',
          helperText: 'Include a link to convert the title to a button.',
        },
        {
          name: 'description',
          type: 'text',
        },
        {
          name: 'icon',
          type: 'text',
          enum: iconValues as removeReadOnlyModifier,
        },
      ],
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onChange: options => {
        if (options.get('features').length > 3) {
          options.set('features', options.get('features').slice(0, 3));
          alert('Only three items allowed.');
        }
      },
    },
    {
      name: 'stats',
      type: 'list',
      subFields: [
        {
          name: 'stat',
          type: 'text',
        },
        {
          name: 'caption',
          type: 'text',
        },
      ],
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onChange: options => {
        if (options.get('stats').length > 5) {
          options.set('stats', options.get('features').slice(0, 5));
          alert('Only five items allowed.');
        }
      },
    },
    ...SectionBuilderConfig.inputs,
  ],
};

export default SwitchbackBuilderConfig;
