import { BuilderStoreContext, withChildren } from '@builder.io/react';
import { useMediaQuery } from '@mui/material';
import HorizontalRule from 'builder/HorizontalRule';
import { useContext, useEffect, useMemo, useState } from 'react';

import { media } from 'atoms/breakpoints/breakpoints';
import container from 'atoms/spacing/containers';

import Flex from 'quarks/Flex';

import Section from 'molecules/Section/Section';

import AuthorCard from 'components/ContentEditor/AuthorCard';
import PlaybookContent from 'components/PlaybookContent/PlaybookContent';
import type { FeaturesProps } from 'components/Sidebar/PostFeatures';
import type { postTypes } from 'components/Sidebar/SideBar';
import SideBar from 'components/Sidebar/SideBar';
import type { SideBarConversionPanelProps } from 'components/Sidebar/SideBarConversionPanel';

import { ActiveContentProvider } from 'contexts/ContentEditorProvider';

import type { Builder_Author } from 'graphqlTypes';
import type { FC, ReactElement } from 'react';

interface BuilderState {
  state: { template: postTypes; legalPages?: string };
  context: { builderContent: { data: { postAuthor: { value?: Builder_Author } } } };
}

interface ContentEditorProps {
  children?: ReactElement[];
  postFeatures?: FeaturesProps[];
  sideBarConversionPanel?: SideBarConversionPanelProps;
  builderState: BuilderState;
  sectionId?: string;
}

const ContentEditor: FC<ContentEditorProps> = ({
  children,
  postFeatures,
  sideBarConversionPanel,
  builderState: {
    state,
    state: { template },
    context: {
      builderContent: {
        data: { postAuthor },
      },
    },
  },
  sectionId,
}) => {
  const [desktopPlaybook, setDesktopPlaybook] = useState(true);
  const builderState = useContext(BuilderStoreContext);
  const includePlaybook = builderState?.context?.includePlaybookSection;
  const playbookData = builderState?.context?.playbookContent;
  const isDesktop = useMediaQuery(media.lg);

  useEffect(() => {
    isDesktop ? setDesktopPlaybook(true) : setDesktopPlaybook(false);
  }, [isDesktop]);

  const getContentEditor = useMemo(
    () => (
      <ActiveContentProvider>
        <Section id={sectionId} playbook={includePlaybook}>
          <Flex
            flexDirection="column"
            justifyContent="space-between"
            gap="40px"
            lg={{ flexDirection: 'row', gap: '36px' }}
          >
            {!desktopPlaybook && includePlaybook && <PlaybookContent content={playbookData} />}
            <SideBar
              variation={template}
              state={state}
              content={children || []}
              postFeatures={postFeatures}
              conversionPanel={sideBarConversionPanel}
            />
            <Flex
              flexDirection="column"
              gap="40px"
              width="100%"
              maxWidth={container.md}
              css={{ scrollPaddingTop: '120px' }}
            >
              {desktopPlaybook && includePlaybook && <PlaybookContent content={playbookData} />}
              {children}
              {postAuthor?.value?.name && postAuthor?.value?.data && (
                <>
                  <HorizontalRule />
                  <AuthorCard authorName={postAuthor?.value?.name} author={postAuthor.value.data} />
                </>
              )}
            </Flex>
          </Flex>
        </Section>
      </ActiveContentProvider>
    ),
    [children],
  );

  return getContentEditor;
};

export default withChildren(ContentEditor);
