import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Image from 'quarks/Image';
import Link from 'quarks/Link';
import Text from 'quarks/Text';

import Badge from 'molecules/Badge/Badge';

import type { PartnerCardData } from 'components/PartnerList/partnerListTypesAndData';

import { optimizeBuilderImage } from 'utils/functions';

import type { FC } from 'react';

const PartnerCard: FC<PartnerCardData> = ({ name, brandLogo, description, isPopular, serviceType, link }) => {
  const optimizedImage = brandLogo && optimizeBuilderImage(brandLogo);

  const serviceTypeBadges =
    serviceType &&
    serviceType?.map(service => (
      <Badge key={service} size="sm" backgroundColor="primary-50">
        <Text textStyle="xs" textColor="primary-700">
          {service}
        </Text>
      </Badge>
    ));

  return (
    <Link
      href={link || ''}
      cursor={link ? 'pointer' : 'default'}
      trackingSchema={{
        label: name,
        location: 'Partner card in Partner Listing,',
      }}
    >
      <Container
        backgroundColor="common-white"
        border="1px solid"
        borderColor="primary-50"
        borderRadius="8px"
        paddingAll={24}
        boxShadow="md"
        transition="box-shadow 150ms ease"
        hover={{ boxShadow: link ? 'xl' : 'lg' }}
        position="relative"
        height="100%"
      >
        {isPopular && (
          <Image image="/images/popular-badge.svg" alt="popular badge" position="absolute" top="-12px" right="-9px" />
        )}
        <Flex flexDirection="column" gap="40px">
          {brandLogo && (
            <Image
              image={optimizedImage}
              width="auto"
              height="40px"
              maxWidth="fit-content"
              objectFit="contain"
              alt={`${name} logo`}
              marginRight="auto"
            />
          )}
          <Flex flexDirection="column" gap="8px">
            <Flex gap="8px">{serviceTypeBadges}</Flex>
            <Text textStyle="xxl" fontWeight="semiBold" textColor="gray-900">
              {name}
            </Text>
            {description && (
              <Text
                textColor="gray-700"
                textStyle="md"
                css={`
                  display: -webkit-box;
                  -webkit-line-clamp: 3;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                `}
              >
                {description}
              </Text>
            )}
          </Flex>
        </Flex>
      </Container>
    </Link>
  );
};

export default PartnerCard;
