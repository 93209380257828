// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import formConfigIds from '/public/static/formConfigIds.json';

import { iconValues } from 'atoms/Icons/Icon.types';

import FormConfig from 'components/HsForm/HsForm.config';

import type { Writeable } from 'utils/typeUtils';
import { listOfDoodles } from 'utils/doodleGenerator';

import SectionBuilderConfig from 'molecules/Section/Section.config';

type removeReadOnlyModifier = Writeable<typeof iconValues>;

const HeroFormConfig = {
  name: 'ComponentHeroForm',
  inputs: [
    { name: 'eyebrow', type: 'text', defaultValue: 'Eyebrow' },
    { name: 'heading', type: 'text', defaultValue: 'Title goes here' },
    { name: 'subheading', type: 'richText', defaultValue: '<p>Subheading goes here</p>' },
    { name: 'featureListHeading', type: 'text', defaultValue: 'Feature list heading' },
    {
      name: 'featureList',
      type: 'list',
      subFields: [
        { name: 'copy', type: 'text' },
        { name: 'icon', type: 'text', enum: iconValues as removeReadOnlyModifier },
        { name: 'id', type: 'text', required: true, advanced: true },
      ],
      defaultValue: [
        {
          icon: 'rocket-01',
          copy: 'This is placeholder text. Should be kept relatively short.',
        },
        {
          icon: 'rocket-01',
          copy: 'This is placeholder text. Should be kept relatively short.',
        },
        {
          icon: 'rocket-01',
          copy: 'This is placeholder text. Should be kept relatively short.',
        },
      ],
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onChange: options => {
        if (options.get('featureList').length > 5) {
          options.set('featureList', options.get('featureList').slice(0, 5));
          alert('Maximum of five features are allowed.');
        }
      },
    },

    {
      name: 'hsForm',
      type: 'object',
      subFields: FormConfig.inputs,
      defaultValue: {
        variant: 'Basic',
        formId: formConfigIds[0],
      },
    },
    {
      name: 'leftDoodle',
      type: 'text',
      enum: listOfDoodles,
      defaultValue: 'tripleknotswirl',
      helperText: 'The Hero Form was designed with the "tripleknotswirl" doodle in mind on the left.',
    },
    {
      name: 'rightDoodle',
      type: 'text',
      enum: listOfDoodles,
      defaultValue: 'plate',
      helperText: 'The Hero Form was designed with the "plate" doodle in mind on the right.',
    },
    ...SectionBuilderConfig.inputs,
  ],
};

export default HeroFormConfig;
