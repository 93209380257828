import { ChevronDown } from 'react-feather';

import colors from 'atoms/colors/colors';

import Flex from 'quarks/Flex';
import Text from 'quarks/Text';

import type { FC } from 'react';

interface SeeAllFeaturesComponentProps {
  backgroundIsDark?: boolean;
  isCollapsed?: boolean;
  seeAllFeaturesText?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onCollapse: (e: any) => void;
}

const SeeAllFeaturesComponent: FC<SeeAllFeaturesComponentProps> = ({
  backgroundIsDark,
  isCollapsed,
  seeAllFeaturesText,
  onCollapse,
}) => (
  <Flex
    justifyContent="center"
    alignItems="center"
    gap="12px"
    cursor="pointer"
    css={`
      &&& {
        > svg {
          transition: transform 0.3s ease;
          transform: ${!isCollapsed ? 'rotate(-180deg)' : undefined};
        }
      }
      user-select: none;
    `}
    onClick={onCollapse}
    onKeyDown={onCollapse}
  >
    <Text
      fontWeight="semiBold"
      fontSize="textLg"
      fontFamily="textFont"
      textColor={backgroundIsDark ? 'primary-25' : 'primary-700'}
    >
      {seeAllFeaturesText}
    </Text>
    <ChevronDown color={backgroundIsDark ? colors.primary['25'] : colors.primary['700']} />
  </Flex>
);

export default SeeAllFeaturesComponent;
