import { useContext, useEffect, useState } from 'react';

import color from 'atoms/colors/colors';
import shadow from 'atoms/shadows/shadows';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Text from 'quarks/Text';

import TextField from 'molecules/TextField/TextField';
import Wrapper from 'molecules/Wrapper/Wrapper';

import type { ReportCardTypes } from 'components/Cards/ReportCard/reportCardConfigTypes';
import type { ForecastCardTypes, RevenueCardTypes } from 'components/Cards/RevenueCard/revenueCardConfigTypes';
import ComponentButton from 'components/ComponentButton/ComponentButton';
import {
  calculatorObj,
  days,
  dollarIcon,
  errorMessage,
  forecastObj,
  formatCurrency,
  getForecastData,
  mobileObj,
  percentIcon,
  weeks,
} from 'components/ForecastCalculator/CalculatorDataAndTypes';
import type {
  CalculatorData,
  ForecastData,
  HandleInputChange,
  ToolTipFields,
} from 'components/ForecastCalculator/CalculatorDataAndTypes';
import CalculatorHeader from 'components/ForecastCalculator/CalculatorHeader';
import CalculatorResults from 'components/ForecastCalculator/CalculatorResults';
import CalculatorRowDesktop from 'components/ForecastCalculator/CalculatorRowDesktop';
import CalculatorRowMobile from 'components/ForecastCalculator/CalculatorRowMobile';
import type { CalculatorResultsTypes } from 'components/ForecastCalculator/componentCalculatorTypesConfig';

import { objectKeys } from 'utils/typeUtils';

import { ModalContext } from 'contexts/ModalProvider';

import type { FC } from 'react';

interface ForecastCalculatorProps {
  hourlyField?: ToolTipFields;
  targetLaborCostField?: ToolTipFields;
  resultsCopy?: CalculatorResultsTypes;
  revenueCard?: RevenueCardTypes;
  forecastCard?: ForecastCardTypes;
  reportCard?: ReportCardTypes;
}

const ForecastCalculator: FC<ForecastCalculatorProps> = ({
  hourlyField,
  revenueCard,
  forecastCard,
  reportCard,
  resultsCopy,
  targetLaborCostField,
}) => {
  const [calculatorData, setCalculatorData] = useState<CalculatorData>(calculatorObj);
  const [resultsData, setResultsData] = useState<CalculatorData>(calculatorObj);
  const [forecastData, setForecastData] = useState<ForecastData>(forecastObj);
  const [formData, setFormData] = useState<string[]>([]);
  const [hourlyRate, setHourlyRate] = useState('');
  const [laborCost, setLaborCost] = useState('');
  const [mobileData, setMobileData] = useState<CalculatorData>(mobileObj);
  const [calculatePressed, setCalculatePressed] = useState(false);
  const [errors, setErrors] = useState(false);

  const { modalState } = useContext(ModalContext);

  const weekTotal = (week: string) => {
    let total = 0;
    objectKeys(resultsData).map(day => {
      if (typeof resultsData[day][week] === 'string') {
        if (resultsData[day][week] === '') {
          return (total = total + 0);
        }
        // TO DO: fix type error
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return (total = total + parseFloat(resultsData[day][week]));
      }

      return null;
    });

    return formatCurrency(total);
  };

  const handleForecast = () => {
    setResultsData(calculatorData);
    weeks.map(week =>
      setForecastData(prevState => {
        const currentWeeklyTotals = {
          weeklyTotals: {
            ...prevState.weeklyTotals,
            [week]: weekTotal(week),
          },
        };

        return {
          ...prevState,
          ...currentWeeklyTotals,
          ...getForecastData(currentWeeklyTotals.weeklyTotals, laborCost, hourlyRate),
        };
      }),
    );
  };

  useEffect(() => {
    const inputData = weeks.map(week => Object.values(mobileData[week]));

    const weeklyTotals = weeks.map(week => forecastData.weeklyTotals[week]);
    // TO DO: fix type error
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const dailyForecasts = days.map(day => getForecastData(resultsData[day], laborCost, hourlyRate));

    const dailySalesForecast = dailyForecasts.map(forecast => forecast.sales);
    const dailyLaborCostForecast = dailyForecasts.map(forecast => forecast.laborCost);
    const dailyLaborHoursForecast = dailyForecasts.map(forecast => forecast.laborHours);

    setFormData([
      laborCost,
      hourlyRate,
      ...inputData.join(',').split(','),
      ...weeklyTotals,
      ...dailySalesForecast,
      ...dailyLaborCostForecast,
      ...dailyLaborHoursForecast,
      forecastData.sales,
      forecastData.laborCost,
      forecastData.laborHours,
    ]);
  }, [resultsData, forecastData]);

  const calculatorFormWithData = { ...forecastCard?.hsForm, hiddenFieldsManualData: formData };

  const fillHiddenFields = () => {
    const hiddenFields = [
      ...document.querySelectorAll('form input[groupname=calculator_fields], input[type=hidden]'),
    ] as HTMLInputElement[];

    hiddenFields.map((field, index) => (field.value = formData[index].toString()));
  };

  useEffect(() => {
    if (!document) {
      return;
    }
    if (modalState.open) {
      setTimeout(() => {
        fillHiddenFields();
      }, 100);
    }
  }, [modalState]);

  const handleErrors = () => {
    const blankCalcFields = objectKeys(calculatorData).some(day =>
      objectKeys(calculatorData[day]).some(week => calculatorData[day][week] === ''),
    );

    const blankGeneralFields = hourlyRate === '' || laborCost === '';

    if (blankCalcFields || blankGeneralFields) {
      return setErrors(true);
    }

    return setErrors(false);
  };

  const handleCalculate = () => {
    handleForecast();
    setCalculatePressed(true);
  };

  const handleMobileData = () => {
    const weekData = weeks.reduce(
      (weekObj, week) => ({
        ...weekObj,
        [week]: days.reduce((dayObj, day) => ({ ...dayObj, [day]: calculatorData[day][week] }), {}),
      }),
      {},
    );
    setMobileData(weekData);
  };

  useEffect(() => {
    handleMobileData();
  }, []);

  useEffect(() => {
    handleMobileData();
    handleErrors();
  }, [calculatorData, laborCost, hourlyRate]);

  useEffect(() => {
    handleForecast();
  }, [resultsData]);

  const handleInputChange: HandleInputChange = (day, week, e) => {
    const checkValue = (value: string) => {
      if (value === '') {
        setErrors(true);

        return '';
      }

      return parseFloat(value);
    };

    if (e.nativeEvent.data === '-') {
      return null;
    }

    setCalculatorData(prevState => ({
      ...prevState,
      [day]: {
        ...prevState[day],
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        [week]: checkValue(e.target.value.replace('-', '')),
      },
    }));
  };

  const formatInput: HandleInputChange = (day, week, e) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (e.target.value === '') {
      return;
    }
    setCalculatorData(prevState => ({
      ...prevState,
      [day]: {
        ...prevState[day],
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        [week]: parseFloat(e.target.value.replace('-', '')).toFixed(2),
      },
    }));
  };

  const handleReset = () => {
    setCalculatorData(calculatorObj);
    setResultsData(calculatorObj);
    setHourlyRate('');
    setLaborCost('');
    setCalculatePressed(false);
    setErrors(false);
  };

  return (
    <Container paddingTop={16} paddingBottom={24}>
      <Wrapper>
        <Flex
          gap="16px"
          flexDirection="column"
          marginBottom={24}
          md={{
            flexDirection: 'row',
            justifyContent: 'center',
            gap: '30px',
          }}
        >
          <TextField
            label="Avg. Hourly Rate"
            labelSize="textMd"
            type="number"
            placeholder="15"
            startIcon={dollarIcon}
            value={hourlyRate}
            onChange={e => setHourlyRate(e.target.value.replace('-', ''))}
            tooltip={hourlyField?.toolTipTitle}
            tooltipDescription={hourlyField?.toolTipDescription}
            error={calculatePressed && hourlyRate === ''}
            errorMessage={errorMessage}
            md={{
              width: '270px',
            }}
            css={`
              label {
                color: ${color.gray[900]};
                font-weight: 500;
              }
            `}
          />
          <TextField
            label="Target Labor Cost %"
            labelSize="textMd"
            type="number"
            placeholder="20"
            value={laborCost}
            endIcon={percentIcon}
            onChange={e => setLaborCost(e.target.value.replace('-', ''))}
            tooltip={targetLaborCostField?.toolTipTitle}
            tooltipDescription={targetLaborCostField?.toolTipDescription}
            error={calculatePressed && laborCost === ''}
            errorMessage={errorMessage}
            md={{
              width: '270px',
            }}
            css={`
              label {
                color: ${color.gray[900]};
                font-weight: 500;
              }
            `}
          />
        </Flex>
      </Wrapper>
      <Wrapper display="none" md={{ display: 'block' }} css="contain: unset;">
        <Container
          md={{
            borderRadius: '8px',
            boxShadow: shadow.lg,
            border: `1px solid ${color.primary[100]}`,
          }}
        >
          <CalculatorHeader rowTitle="Day" rowContent={weeks} />
          {days.map(day => (
            <CalculatorRowDesktop
              key={day}
              rowTitle={day}
              rowContent={calculatorData[day]}
              calculatorData={calculatorData}
              handleInputChange={handleInputChange}
              formatInput={formatInput}
              calculatePressed={calculatePressed}
            />
          ))}
        </Container>
      </Wrapper>
      <Container md={{ display: 'none' }}>
        {weeks.map(week => (
          <CalculatorRowMobile
            key={week}
            rowTitle={week}
            rowContent={mobileData[week]}
            mobileData={mobileData}
            handleInputChange={handleInputChange}
            formatInput={formatInput}
            calculatorData={calculatorData}
            calculatePressed={calculatePressed}
          />
        ))}
      </Container>
      <Wrapper>
        {errors && (
          <Flex justifyContent="center" marginTop={32}>
            <Text textColor="gray-700" textStyle="md" textAlign="center">
              Please fill out all calculator fields to see your results!
            </Text>
          </Flex>
        )}

        <Flex
          gap="16px"
          marginY={32}
          flexDirection="column"
          sm={{
            flexDirection: 'row',
            gap: '30px',
            justifyContent: 'center',
          }}
        >
          <ComponentButton
            buttonType="callToAction"
            width="100%"
            md={{
              width: 'auto',
              minWidth: '170px',
            }}
            button={{
              value: {
                data: {
                  label: 'Calculate',
                  size: 'large',
                  hierarchy: 'Primary Button',
                  disabled: errors,
                },
              },
            }}
            onClick={handleCalculate}
            xl={{ height: '52px' }}
            trackingSchema={{
              hierarchy: '1 of 2',
              location: 'ForecastCalculator',
            }}
          />
          <ComponentButton
            buttonType="callToAction"
            width="100%"
            md={{
              width: 'auto',
              minWidth: '170px',
            }}
            button={{
              value: {
                data: {
                  label: 'Reset',
                  size: 'large',
                  hierarchy: 'Secondary Color',
                },
              },
            }}
            onClick={handleReset}
            xl={{ height: '52px' }}
            trackingSchema={{
              hierarchy: '2 of 2',
              location: 'ForecastCalculator',
            }}
          />
        </Flex>

        <Flex flexDirection="column" width="100%" id="results">
          <CalculatorResults
            resultsCopy={resultsCopy}
            revenueCard={revenueCard}
            forecastCard={forecastCard}
            reportCard={reportCard}
            calculatorFormWithData={calculatorFormWithData}
            weeks={weeks}
            forecastData={forecastData}
            calculatePressed={calculatePressed}
          />
        </Flex>
      </Wrapper>
    </Container>
  );
};

export default ForecastCalculator;
