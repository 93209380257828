import { Script } from 'gatsby';

import type { FC, ReactNode } from 'react';

interface GeneralHeadProps {
  children: ReactNode | ReactNode[];
}

const GeneralHead: FC<GeneralHeadProps> = ({ children }) => (
  <>
    {/* <Script
      type="text/javascript"
      src="https://cdn.cookielaw.org/consent/bd249cee-22ea-44be-be32-2f3bfd31ea0a/OtAutoBlock.js"
    />
    <Script
      type="text/javascript"
      src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
      data-domain-script="bd249cee-22ea-44be-be32-2f3bfd31ea0a"
    /> */}
    <Script type="text/javascript">{'function OptanonWrapper() { } '}</Script>
    <Script type="text/javascript" id="hs-script-loader" async defer src="//js-na1.hs-scripts.com/20075163.js"></Script>
    {children}
  </>
);

export default GeneralHead;
