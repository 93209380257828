import color from 'atoms/colors/colors';
import shadow from 'atoms/shadows/shadows';
import { padding } from 'atoms/spacing/spacing';
import { fontSize, fontWeight } from 'atoms/typography/typography';

declare module '@mui/material/Button' {
  interface ButtonPropsSizeOverrides {
    xlarge: true;
  }
}

const themeOverrides = {
  root: {
    minWidth: 0,
    textTransform: 'none',
    fontWeight: fontWeight.bold,
    borderRadius: '8px',
    fontSize: fontSize.textMd,
    paddingLeft: padding[20],
    paddingRight: padding[20],
    boxShadow: shadow.xs,
  },
};

const sizeVariants = [
  {
    props: { size: 'small' },
    style: {
      lineHeight: '24px',
      paddingTop: padding[8],
      paddingBottom: padding[8],
      paddingLeft: padding[8],
      paddingRight: padding[8],
    },
  },
  {
    props: { size: 'medium' },
    style: {
      lineHeight: '24px',
      paddingTop: padding[8],
      paddingBottom: padding[8],
    },
  },
  {
    props: { size: 'large' },
    style: {
      fontSize: fontSize.textLg,
      lineHeight: '28px',
      paddingTop: padding[10],
      paddingBottom: padding[10],
    },
  },
  {
    props: { size: 'xlarge' },
    style: {
      fontSize: fontSize.textLg,
      lineHeight: '28px',
      paddingTop: padding[12],
      paddingBottom: padding[12],
    },
  },
  {
    props: { size: '2xlarge' },
    style: {
      fontSize: fontSize.textXl,
      lineHeight: '30px',
      paddingTop: padding[12],
      paddingBottom: padding[12],
    },
  },
];

export const lightButton = {
  MuiButton: {
    styleOverrides: themeOverrides,
    variants: [
      ...sizeVariants,
      {
        props: { variant: 'contained' },
        style: {
          backgroundColor: color.primary[600],
        },
      },
      {
        props: { variant: 'outlined' },
        style: {
          color: color.common.black,
          borderColor: color.gray[300],
        },
      },
      {
        props: { variant: 'text' },
        style: {
          color: color.primary[600],
        },
      },
      {
        props: { color: 'secondary', variant: 'contained' },
        style: {
          backgroundColor: color.common.white,
          color: color.primary[600],
          '&:hover': {
            backgroundColor: color.primary[100],
          },
          '&:active': {
            backgroundColor: color.primary[100],
          },
          '&[disabled]': {
            backgroundColor: color.common.white,
            opacity: 0.3,
          },
        },
      },
    ],
  },
};

export const darkButton = {
  MuiButton: {
    styleOverrides: themeOverrides,
    variants: [
      ...sizeVariants,
      {
        props: { variant: 'contained' },
        style: {
          color: color.common.black,
          backgroundColor: color.gray[25],
        },
      },
      {
        props: { variant: 'outlined' },
        style: {
          color: color.common.white,
          borderColor: color.gray[300],
        },
      },
      {
        props: { variant: 'text' },
        style: {
          color: color.gray[25],
        },
      },
    ],
  },
};
