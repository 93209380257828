import SectionBuilderConfig from 'molecules/Section/Section.config';

import { ButtonBuilderConfig } from 'components/ComponentButton/buttonTypeConfig';

const AccordionBuilderConfig = {
  name: 'Accordion',
  inputs: [
    { name: 'background', type: 'text', enum: ['White', 'Gray-100', 'Primary-600', 'Convex'] },
    { name: 'alignment', type: 'text', enum: ['Centered', 'Offset'] },
    { name: 'eyebrow', type: 'text' },
    { name: 'heading', type: 'text' },
    { name: 'headingType', type: 'text', enum: ['h2', 'h3', 'h4', 'h5', 'h6'] },
    { name: 'itemHeadingType', type: 'text', enum: ['h2', 'h3', 'h4', 'h5', 'h6'] },
    { name: 'subheading', type: 'richText' },
    { name: 'enableDoodle', type: 'boolean' },
    {
      name: 'buttonGroup',
      type: 'list',
      subFields: ButtonBuilderConfig.inputs,
    },
    {
      name: 'items',
      type: 'list',
      subFields: [{ name: 'item', type: 'reference', model: 'accordion-item' }],
    },
    ...SectionBuilderConfig.inputs,
  ],
};

export default AccordionBuilderConfig;
