import { forwardRef, useContext } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import Icon from 'atoms/Icons/Icon';
import rotate from 'atoms/animations/rotate';
import container from 'atoms/spacing/containers';

import Flex from 'quarks/Flex';
import Heading from 'quarks/Heading';
import Image from 'quarks/Image';
import Text from 'quarks/Text';

import ComponentButton from 'components/ComponentButton/ComponentButton';
import successImageFile from 'components/HsForm/assets/confetti.svg';
import type { FormProps } from 'components/HsForm/types';

import onKeyDown from 'utils/onKeyDown';

import { ModalContext } from 'contexts/ModalProvider';

import type { FC } from 'react';

const BasicForm: FC<FormProps> = forwardRef(
  ({
    heading,
    headingType = 'h2',
    subHeading,
    formFields,
    submitButtonText,
    postSubmitMessage,
    successImage,
    isSubmitting,
    isSubmitSuccessful,
    handleFormSubmit,
    onClose,
    loading,
    captchaRef,
    isValid,
  }) => {
    const { modalState } = useContext(ModalContext);

    return (
      <Flex
        as="form"
        position="relative"
        flexWrap="wrap"
        alignItems="flex-start"
        gap="32px"
        width="100%"
        maxWidth={container.md}
        backgroundColor="common-white"
        paddingAll={32}
        paddingTop={modalState.open && 40}
        marginX="auto"
        borderRadius="20px"
        boxShadow="xxl"
        onSubmit={handleFormSubmit}
      >
        {modalState.open && (
          <Icon
            position="absolute"
            id="x-close"
            size={24}
            top="16px"
            right="20px"
            cursor="pointer"
            aria-label="Close Modal Dialog"
            iconColor="gray-600"
            tabIndex={0}
            onClick={onClose && (() => onClose())}
            onKeyDown={onClose && ((e: React.KeyboardEvent<HTMLElement>) => onKeyDown(e, () => onClose()))}
          />
        )}
        {!isSubmitSuccessful ? (
          <>
            <Flex flexDirection="column" gap="4px" alignItems="center" flex="1 0 100%">
              {heading && (
                <Heading
                  as={headingType || 'h2'}
                  fontSize="displayXs"
                  lineHeight="displayXs"
                  textAlign="center"
                  textColor="common-black"
                  sm={{ textStyle: 'xs' }}
                >
                  {heading}
                </Heading>
              )}
              {subHeading && (
                <Text textStyle="md" textAlign="center" textColor="gray-700" sm={{ textStyle: 'lg' }}>
                  {subHeading}
                </Text>
              )}
            </Flex>
            {loading ? (
              <Flex width="100%" flexDirection="column" alignItems="center">
                <Text textStyle="eyebrow" textAlign="center" textColor="primary-600">
                  Loading... please wait
                </Text>
              </Flex>
            ) : (
              <>
                {formFields && (
                  <>
                    {formFields}
                    <ReCAPTCHA ref={captchaRef} size="invisible" sitekey={`${process.env.GATSBY_RECAPTCHA_SITE_KEY}`} />
                    <ComponentButton
                      type="submit"
                      buttonType="submit"
                      button={{
                        value: {
                          data: {
                            label: isSubmitting ? '...Submitting' : submitButtonText || 'Submit',
                            size: 'xLarge',
                            hierarchy: 'primaryButton',
                            startIcon: isSubmitting ? 'refresh-cw-01' : '',
                            disabled: !isValid,
                          },
                        },
                      }}
                      width="100%"
                      css={rotate}
                      trackingSchema={{
                        hierarchy: '1 of 1',
                        location: `Form: ${heading}`,
                      }}
                    />
                  </>
                )}
              </>
            )}
          </>
        ) : (
          <Flex width="100%" flexDirection="column" gap="24px" alignItems="center">
            <Flex flexDirection="column" gap="16px" alignItems="center">
              <Heading textStyle="xs" textAlign="center" textColor="common-black">
                Success
              </Heading>
              <Text textStyle="lg" textAlign="center" textColor="gray-700">
                {postSubmitMessage || 'Thanks for your interest in Lineup.ai! A representative will reach out shortly.'}
              </Text>
            </Flex>
            {successImage && (
              <Image
                image={successImageFile}
                alt="Image depicting a state of success"
                objectPosition="center"
                borderRadius="9px"
                maxWidth="510px"
                marginX="auto"
                width="100%"
              />
            )}
          </Flex>
        )}
      </Flex>
    );
  },
);

export default BasicForm;
