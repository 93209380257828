import { font } from 'atoms/typography/typography';

import Flex from 'quarks/Flex';
import Text from 'quarks/Text';

import ComponentButton from 'components/ComponentButton/ComponentButton';
import type { PlanCardsProps } from 'components/PricingTable/PricingTableTypes';

import { getBackground, getBackgroundIsDark } from 'utils/functions';

import type { FC } from 'react';

const PlanCards: FC<PlanCardsProps> = ({ isSticky, width, background, ctaButton, plan }) => {
  const { backgroundColor } = getBackground('plan-card', background, width);
  const backgroundIsDark = getBackgroundIsDark(background);
  const textColor = backgroundIsDark ? 'common-white' : 'gray-900';
  const planDetails = plan?.value?.data;

  return (
    <Flex
      backgroundColor={backgroundColor}
      textColor={textColor}
      flexDirection="column"
      width="225px"
      xl={{ width: '292px' }}
    >
      <Flex
        flexDirection="column"
        justifyContent="center"
        paddingX={24}
        paddingY={isSticky ? 16 : 32}
        gap={isSticky ? '16px' : '24px'}
        backgroundColor={backgroundColor ?? 'common-white'}
        height={isSticky ? '160px' : '248px'}
        transition="height 0.3s, padding 0.3s, gap 0.3s ease"
      >
        <Flex flexDirection="column" alignItems="center" gap="8px">
          {planDetails?.planTitle && (
            <Text textStyle="eyebrow" textColor={textColor}>
              {planDetails.planTitle}
            </Text>
          )}
          <Text
            textColor={textColor}
            transition="font 0.3s ease"
            css={{
              font: isSticky ? font.text.lg.font() : font.display.md.font(),
              fontWeight: 'bold',
            }}
          >
            ${planDetails?.isAnnual ?? planDetails?.priceMonthly}
            <Text
              css={{
                font: isSticky ? font.text.lg.font() : font.display.xs.font(),
                fontWeight: isSticky ? 'bold' : 'semiBold',
              }}
            >
              /mo
            </Text>
          </Text>
          {!isSticky && (
            <Text textStyle="md" textColor={backgroundIsDark ? 'gray-100' : 'gray-700'}>
              Billed {planDetails?.isAnnual ? 'annually' : 'monthly'}
            </Text>
          )}
        </Flex>
        {ctaButton?.value?.data && <ComponentButton width="100%" {...ctaButton.value.data} />}
      </Flex>
    </Flex>
  );
};

export default PlanCards;
