import { useMediaQuery } from '@mui/material';
import { useCallback, useMemo } from 'react';

import Icon from 'atoms/Icons/Icon';
import { media } from 'atoms/breakpoints/breakpoints';
import shadow from 'atoms/shadows/shadows';

import Flex from 'quarks/Flex';
import Text from 'quarks/Text';
import type { BasicProps } from 'quarks/interpolations/basic';

import Button, { handleButtonTracking } from 'molecules/Button/Button';
import getPaginationSpread from 'molecules/Pagination/utils/getPaginationSpread';

import type { Dispatch, FC, SetStateAction } from 'react';

interface NumberedPaginationProps extends BasicProps {
  activePage: number;
  pageCount: number;
  setActivePage: Dispatch<SetStateAction<number>>;
}

const buttonProps = {
  borderRadius: '8px',
  fontSize: 'textLg',
  lineHeight: 'textLg',
  fontWeight: 'regular',
  borderColor: 'gray-400',
  paddingAll: 8,
  hover: {
    backgroundColor: 'primary-300',
    textColor: 'common-white',
  },
} as const;

const NumberedPagination: FC<NumberedPaginationProps> = ({ activePage, pageCount, setActivePage }) => {
  const isTabletOrLarger = useMediaQuery(media.md);
  const handlePrevClick = useCallback(() => setActivePage(activePage - 1), [setActivePage, activePage]);
  const handleNextClick = useCallback(() => setActivePage(activePage + 1), [setActivePage, activePage]);
  const pageArray = [...Array(pageCount + 1).keys()];
  pageArray.shift();
  const pagination = pageCount > 10 ? getPaginationSpread(pageArray, activePage) : pageArray;

  const handlePageClick = (val: number) => {
    const trackingScheme = {
      label: val?.toString(),
      location: 'Pagination',
    };

    handleButtonTracking(trackingScheme);

    setActivePage(val);
  };

  const handleButtonClick = (title: string) => {
    if (title === 'Previous') {
      handlePrevClick();
    }
    if (title === 'Next') {
      handleNextClick();
    }

    const trackingScheme = {
      label: title,
      location: 'Pagination',
    };

    handleButtonTracking(trackingScheme);
  };

  const numberedButtons = useMemo(
    () => (
      <Flex gap="2px" alignItems="center">
        {isTabletOrLarger ? (
          pagination.map((val, idx) =>
            typeof val === 'number' ? (
              <Button
                // eslint-disable-next-line react/no-array-index-key
                key={idx}
                height="40px"
                width="40px"
                borderRadius="6px"
                backgroundColor={activePage === val ? 'primary-50' : 'common-transparent'}
                fontSize="textSm"
                lineHeight="textSm"
                textColor={activePage === val ? 'primary-900' : 'gray-700'}
                fontWeight="regular"
                boxShadow="none"
                onClick={() => handlePageClick(val)}
                hover={{
                  backgroundColor: 'primary-300',
                  textColor: 'common-white',
                }}
                focusVisible={{
                  borderColor: 'primary-300',
                  boxShadow: 'focused',
                }}
              >
                {val}
              </Button>
            ) : (
              // eslint-disable-next-line react/no-array-index-key
              <Text key={idx} width="40px" height="40px" textAlign="center">
                {val}
              </Text>
            ),
          )
        ) : (
          <Text textStyle="sm" textColor="gray-700">{`Page ${activePage} of ${pageCount}`}</Text>
        )}
      </Flex>
    ),
    [activePage, pageCount, isTabletOrLarger],
  );

  const leftArrow = <Icon id="arrow-left" size={20} iconColor={activePage === 1 ? 'gray-500' : 'gray-700'} />;
  const rightArrow = <Icon id="arrow-right" size={20} iconColor={activePage === pageCount ? 'gray-500' : 'gray-700'} />;
  const responsiveProps = {
    boxShadow: isTabletOrLarger ? 'none' : shadow.sm,
    height: isTabletOrLarger ? '40px' : '36px',
    width: isTabletOrLarger ? '120px' : '36px',
    border: isTabletOrLarger ? undefined : '1px solid',
  } as const;

  return (
    <Flex justifyContent="space-between">
      <Button
        textColor={activePage === 1 ? 'gray-500' : 'gray-700'}
        disabled={activePage === 1}
        onClick={() => handleButtonClick('Previous')}
        startIcon={isTabletOrLarger && leftArrow}
        {...responsiveProps}
        {...buttonProps}
        focusVisible={{
          borderColor: activePage === 1 ? null : 'primary-300',
          boxShadow: activePage === 1 ? null : 'focused',
        }}
      >
        {isTabletOrLarger ? 'Previous' : leftArrow}
      </Button>
      {numberedButtons}
      <Button
        textColor={activePage === pageCount ? 'gray-500' : 'gray-700'}
        disabled={activePage === pageCount}
        onClick={() => handleButtonClick('Next')}
        endIcon={isTabletOrLarger && rightArrow}
        {...responsiveProps}
        {...buttonProps}
        focusVisible={{
          borderColor: activePage === pageCount ? null : 'primary-300',
          boxShadow: activePage === pageCount ? null : 'focused',
        }}
      >
        {isTabletOrLarger ? 'Next' : rightArrow}
      </Button>
    </Flex>
  );
};

export default NumberedPagination;
