import checkCircle from 'assets/svg/check-circle.svg';

import { media } from 'atoms/breakpoints/breakpoints';
import { fontSize, lineHeight } from 'atoms/typography/typography';

import Flex from 'quarks/Flex';
import Grid from 'quarks/Grid';
import Image from 'quarks/Image';
import type { Url } from 'quarks/Image';

import doodleGenerator from 'utils/doodleGenerator';
import RichTextParser from 'utils/richTextParser';

import type { FC } from 'react';

interface PlaybookContentProps {
  content?: string;
}

const PlaybookContent: FC<PlaybookContentProps> = ({ content }) => {
  const doodleProps = {
    opacity: '0.3',
    position: 'absolute',
    alt: 'doodle',
    zIndex: '-1',
  } as const;

  return (
    <Grid
      position="relative"
      gap="24px"
      gridTemplateColumns="1fr"
      backgroundColor="primary-600"
      paddingAll={24}
      borderRadius="12px"
      contain="content"
      textColor="common-white"
      fontSize="textLg"
      sm={{
        fontSize: 'textXl',
      }}
      md={{ paddingAll: 48 }}
      css={`
        margin-top: -120px;
        ${media.xl} {
          margin-top: -200px;
        }
        z-index: 2;
        display: block;
        p {
          margin-bottom: 16px;
        }
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          font-size: ${fontSize.displayXs};
          line-height: ${lineHeight.displayXs};
          margin-bottom: 12px;
          ${media.sm} {
            font-size: ${fontSize.displayMd};
            line-height: ${lineHeight.displayMd};
            margin-bottom: 20px;
          }
        }
        ul {
          list-style: none;
          padding-left: 40px;
          li {
            position: relative;
            &:before {
              content: '';
              width: 24px;
              height: 24px;
              position: absolute;
              background-image: url(${checkCircle});
              background-repeat: no-repeat;
              background-size: cover;
              background-position: center;
              left: -40px;
              top: 4px;
            }
          }
        }
      `}
    >
      <Image
        {...doodleProps}
        image={doodleGenerator('doubleknotthirdinverted') as Url}
        width="94%"
        left="-26%"
        top="0"
        transform="rotate(145deg)"
        xs={{
          top: '4%',
        }}
        sm={{
          top: '-12%',
        }}
      />
      <Image
        {...doodleProps}
        image={doodleGenerator('whisk') as Url}
        right="-20%"
        bottom="-6%"
        width="50%"
        transform="scale(1,-1) rotate(87deg)"
        visibility="hidden"
        sm={{
          visibility: 'visible',
        }}
      />
      {content && (
        <Flex flexDirection="row" alignItems="center" flexWrap="wrap" gap="4px 8px">
          {RichTextParser(content)}
        </Flex>
      )}
    </Grid>
  );
};

export default PlaybookContent;
