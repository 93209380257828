import Icon from 'atoms/Icons/Icon';

import Flex from 'quarks/Flex';

import type { PlanWrapperProps } from 'components/PricingTable/PricingTableTypes';

import { getBackground, getBackgroundIsDark } from 'utils/functions';

import type { FC } from 'react';

const PlanWrapper: FC<PlanWrapperProps> = ({ width, background, plan }) => {
  const { backgroundColor } = getBackground('plan-card', background, width);
  const backgroundIsDark = getBackgroundIsDark(background);
  const featureList = plan?.value?.data?.featureChecklist;

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      backgroundColor={backgroundColor}
      paddingTop={80}
      width="225px"
      xl={{ width: '292px' }}
    >
      <Flex flexDirection="column" width="100%">
        {featureList?.map(({ title, checkList }) => (
          <Flex key={title} flexDirection="column">
            <Flex borderBottom="1px solid" borderColor={backgroundIsDark ? 'primary-400' : 'gray-300'} height="70px" />
            {checkList?.map(({ id, checkItem }) => (
              <Flex
                key={id}
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                borderBottom="1px solid"
                borderColor={backgroundIsDark ? 'primary-400' : 'gray-300'}
                height="60px"
              >
                {checkItem && <Icon id={backgroundIsDark ? 'check-white' : 'check-blue-100'} size={20} />}
              </Flex>
            ))}
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};

export default PlanWrapper;
