import CheckBoxTwoTone from '@mui/icons-material/CheckBoxTwoTone';
import IndeterminateCheckBoxTwoTone from '@mui/icons-material/IndeterminateCheckBoxTwoTone';
import MUICheckbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import styled from 'styled-components';

import color from 'atoms/colors/colors';

import Text from 'quarks/Text';
import type { BasicProps } from 'quarks/interpolations/basic';
import { basic } from 'quarks/interpolations/basic';
import { allCSSWithPseudos } from 'quarks/styleProps/all';

import type { FC, SyntheticEvent } from 'react';

const StyledFormControl = styled(FormControlLabel).withConfig({
  shouldForwardProp: prop => !Object.keys(allCSSWithPseudos).includes(prop),
})`
  && {
    ${basic}
  }
`;

const sx = {
  '.MuiFormControlLabel-labelPlacementEnd': {
    marginLeft: '0 !important',
  },
  '.MuiFormControlLabel-label': {
    width: '100%',
  },
  '.MuiCheckbox-root': {
    alignSelf: 'center !important',
  },
  '&:hover .MuiCheckbox-root': {
    backgroundColor: color.primary[50],
    color: color.primary[600],
    borderRadius: '4px',
  },
  '.MuiRadio-root': {
    alignSelf: 'center',
    marginTop: '0px',
  },
  '&:hover .MuiRadio-root': {
    backgroundColor: color.primary[50],
    color: color.primary[600],
  },
  '.Mui-checked ~ .MuiFormControlLabel-label .helper-text': {
    color: color.primary[500],
  },
  '.Mui-checked ~ .MuiFormControlLabel-label .content-text span': {
    color: color.primary[500],
  },
  '.Mui-checked ~ .MuiFormControlLabel-label .header-text': {
    color: color.primary[800],
  },
};

interface CheckboxProps extends BasicProps {
  /**
   * Text label of the Checkbox.
   */
  label?: string;
  /**
   * If `true`, the component is disabled.
   */
  disabled?: boolean;
  /**
   * displays error message under the label (replaces helper text)
   */
  errorMessage?: string;
  /**
   * If `true`, the component is places in the error state
   */
  error?: boolean;
  /**
   * Determines the size of the `Checkbox`.
   */
  size?: 'small' | 'large';
  /**
   * Specifies the type of `Checkbox`. `form` is to be used for form fields, and `filter` is to be used within a filtering component.
   */
  checkType?: 'form' | 'filter';
  /**
   * If `true`, the checkbox is checked.
   */
  checked?: boolean;
  /**
   * If `true`, the component is required.
   */
  required?: boolean;
  /**
   * If `true`, the component appears indeterminate. This does not set the native input element to indeterminate due to inconsistent behavior across browsers. However, we set a `data-indeterminate` attribute on the `input`.
   */
  indeterminate?: boolean;
  /**
   * displays text under the label to assist the user.
   */
  helperText?: string;
  /**
   * Callback fired when the state is changed.
   * **Signature:**
   * `function(event: React.SyntheticEvent, checked: boolean) => void`
   * event: The event source of the callback. You can pull out the new value by accessing `event.target.value` (string). You can pull out the new checked state by accessing `event.target.checked` (boolean).
   */
  onChange?: (event: SyntheticEvent<Element, Event>, checked: boolean) => void;
  /**
   * Callback fired when the key is pressed.
   * **Signature:**
   * `function(event: React.SyntheticEvent, checked: boolean) => void`
   * event: The event source of the callback. You can pull out the new value by accessing `event.target.value` (string). You can pull out the new checked state by accessing `event.target.checked` (boolean).
   */
  onKeyDown?: (e: React.KeyboardEvent<HTMLElement>) => void;
  /**
   * The id of the `input` element.
   */
  id?: string;
  /**
   * The value of the component. The DOM API casts this to a string.
   */
  value?: string | boolean;
}

const Checkbox: FC<CheckboxProps> = ({
  label,
  size,
  helperText,
  indeterminate,
  disabled,
  required,
  checkType,
  id,
  value,
  checked,
  error,
  errorMessage,
  ...props
}) => {
  const labelComponent =
    checkType === 'filter' ? (
      <>
        <Text fontSize="textMd" fontWeight="regular" textColor="gray-800">
          {label}
        </Text>
      </>
    ) : (
      <>
        <Text
          fontSize={size === 'small' ? 'textSm' : 'textSm'}
          fontWeight="regular"
          marginLeft={size === 'small' ? 8 : 12}
          fontFamily="textFont"
        >
          {label}
        </Text>
        {helperText && (
          <Text
            display="block"
            textStyle={size === 'small' ? 'sm' : 'md'}
            marginLeft={size === 'small' ? 8 : 12}
            fontWeight="semiBold"
            textColor={error ? 'error-400' : 'gray-700'}
          >
            {error ? errorMessage : helperText}
          </Text>
        )}
      </>
    );

  return (
    <StyledFormControl
      control={
        <MUICheckbox
          size={size}
          indeterminate={indeterminate}
          required={required}
          checkedIcon={<CheckBoxTwoTone />}
          indeterminateIcon={<IndeterminateCheckBoxTwoTone />}
        />
      }
      disabled={disabled}
      checked={checked}
      id={id}
      value={value}
      label={labelComponent}
      sx={label === 'filter' ? sx : undefined}
      {...props}
    />
  );
};

Checkbox.defaultProps = {
  label: '',
  size: 'small',
};

export default Checkbox;
