import color from 'atoms/colors/colors';

import Flex from 'quarks/Flex';

import type { FC } from 'react';

interface GhostCardProps {
  position: 'top' | 'bottom';
}

const GhostCard: FC<GhostCardProps> = ({ position }) => {
  switch (position) {
    case 'top':
      return (
        <Flex
          width="150px"
          height="20px"
          marginX={12}
          borderBottomLeftRadius="12px"
          borderBottomRightRadius="12px"
          boxShadow="md"
          marginBottom={8}
          css={{
            background: `linear-gradient(transparent, ${color.gray[50]})`,
          }}
        />
      );
    case 'bottom':
      return (
        <Flex
          width="150px"
          height="20px"
          marginX={12}
          marginTop={8}
          borderTopLeftRadius="12px"
          borderTopRightRadius="12px"
          boxShadow="md"
          css={{
            background: `linear-gradient(${color.gray[50]}, transparent)`,
          }}
        />
      );
  }
};

export default GhostCard;
