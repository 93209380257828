import { useMediaQuery } from '@mui/material';
import { useMemo } from 'react';

import Icon from 'atoms/Icons/Icon';
import { media } from 'atoms/breakpoints/breakpoints';
import color from 'atoms/colors/colors';
import type { GetColorDefinition } from 'atoms/colors/colors';
import container from 'atoms/spacing/containers';

import Flex from 'quarks/Flex';
import Heading from 'quarks/Heading';
import Image from 'quarks/Image';
import Link from 'quarks/Link';
import Text from 'quarks/Text';
import type { FlexProps } from 'quarks/interpolations/flex';
import type { HeadingTypes } from 'quarks/styleProps/heading';

import Badge from 'molecules/Badge/Badge';
import BadgeGroup from 'molecules/BadgeGroup/BadgeGroup';

import { AuthorBadgeFeature, AuthorSocialsFeature } from 'components/Cards/EntryCard/AuthorCardFeatures';

import { optimizeBuilderImage } from 'utils/functions';
import type { BlocksData } from 'utils/readingTime';
import readingTime from 'utils/readingTime';
import ReverseChildren from 'utils/reverseChildren';

import type {
  Builder_BlogPostCategoryData,
  Builder_CaseStudyCategoryData,
  Builder_EntityPersonData,
  Builder_PostData,
  Builder_ResourcesCategoryData,
} from 'graphqlTypes';
import type { FC } from 'react';

const toReadableDate = (date: Date) =>
  date.toLocaleString('en-US', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  });

export interface AuthorBadges {
  evidenceBased?: boolean;
  factChecked?: boolean;
  topContributor?: boolean;
}

export interface AuthorSocials {
  facebook?: string;
  twitter?: string;
  linkedin?: string;
}

export interface CardProps
  extends FlexProps,
    Pick<Builder_PostData, 'featuredImage' | 'publishDate' | 'description' | 'title' | 'featuredImageCaption' | 'url'> {
  /**
   * For usage if EntryCard is a Featured Card, references Entity Person
   */
  author?: { value: { data: Builder_EntityPersonData; id: string } };
  /**
   * For usage if EntryCard is an Author Card, supplies author badges
   */
  authorBadges?: AuthorBadges;
  /**
   * For usage if EntryCard is an Author Card, supplies author socials
   */
  authorSocials?: AuthorSocials;
  /**
   * For usage if EntryCard is an Author Card, supplies author's fun fact
   */
  funFact?: string | null;
  /**
   * For usage if EntryCard is an Author Card, supplies author's company
   */
  authorsCompany?: string | null;
  /**
   * For usage if EntryCard is an Author Card, supplies author's title
   */
  authorsTitle?: string | null;
  /**
   * Determines what EntryCard category the post falls under.
   */
  category?: {
    value: {
      data: Builder_BlogPostCategoryData | Builder_CaseStudyCategoryData | Builder_ResourcesCategoryData;
      id: string;
    };
    id: string;
  };
  /**
   * Blocks are calculated and generate estimated read time.
   */
  blocks?: BlocksData[];
  /**
   * Toggle Between Featured Card or Standard Card, toggling author will utilize author sub-variant of Featured Card
   */
  variant?: 'featured' | 'default' | 'author' | 'exploreAuthors';
  /**
   * List of Heading Types
   */
  headingType?: HeadingTypes;
}

const getBadge = (
  category?: Builder_BlogPostCategoryData | Builder_ResourcesCategoryData | Builder_CaseStudyCategoryData,
  blocks?: BlocksData[],
) => {
  switch (category?.typeName) {
    case 'blog':
      return (
        <BadgeGroup
          title={
            <Text textStyle="xs" textColor="primary-700">
              {`${readingTime(blocks)} min read`}
            </Text>
          }
          badgePosition="start"
          fontWeight="regular"
          backgroundColor="primary-50"
        >
          {category?.title && (
            <Badge size="md" backgroundColor="common-white">
              <Text textStyle="xs" textColor="primary-700" textTransform="uppercase">
                {category.title}
              </Text>
            </Badge>
          )}
        </BadgeGroup>
      );

    case 'resource':
      return (
        <>
          {category?.title ? (
            <Badge
              size="md"
              fontWeight="medium"
              textColor={(category?.badgeTextColor as GetColorDefinition) || 'primary-700'}
              backgroundColor={(category?.badgeColor as GetColorDefinition) || 'common-white'}
            >
              <Text textStyle="sm">{category.title}</Text>
            </Badge>
          ) : null}
        </>
      );

    case 'caseStudy':
      return (
        <>
          {category?.title && (
            <Badge size="md" backgroundColor="common-white">
              <Text textStyle="xs" textColor="primary-700" textTransform="uppercase">
                {category.title}
              </Text>
            </Badge>
          )}
        </>
      );
    default:
      return null;
  }
};

const EntryCard: FC<CardProps> = ({
  author,
  authorBadges,
  authorsTitle,
  authorsCompany,
  category,
  featuredImage,
  featuredImageCaption: alt,
  publishDate,
  title,
  description,
  funFact,
  url: slug,
  variant = 'default',
  blocks,
  headingType,
  authorSocials,
  ...props
}) => {
  const isTablet = useMediaQuery(media.sm);
  const { fullName, headshot } = author?.value?.data || {};
  const isFeature = variant === 'featured' || variant === 'author';
  const isAuthor = variant === 'author';
  const isExploreAuthor = variant === 'exploreAuthors';
  const categoryData = category?.value?.data;
  const optimizedImage = featuredImage && optimizeBuilderImage(featuredImage);
  const optimizedHeadshot = headshot && optimizeBuilderImage(headshot);

  const getAuthorBadge = useMemo(
    () => (
      <Badge size="md" fontWeight="medium" textColor="primary-700" backgroundColor="primary-50">
        <Text textStyle="sm">Author</Text>
      </Badge>
    ),
    [],
  );

  const ftImgWidth = useMemo(() => {
    switch (variant) {
      case 'author':
        return '500px';

      case 'featured':
        return '50%';

      default:
        return '100%';
    }
  }, []);

  const xlTitleSize = useMemo(() => {
    switch (variant) {
      case 'author':
        return 'lg';
      case 'featured':
        return 'md';
      default:
        return 'xs';
    }
  }, []);

  const getCardContent = () => (
    <Flex
      flexDirection="column"
      gap={isFeature ? '30px' : undefined}
      justifyContent="space-between"
      alignItems="center"
      width="100%"
      height="100%"
      md={{
        gap: isFeature ? '40px' : undefined,
      }}
      lg={{
        flexDirection: isFeature && !isAuthor ? 'row' : 'column',
      }}
      xl={{
        flexDirection: isAuthor && 'row',
        gap: isAuthor && '60px',
      }}
    >
      <ReverseChildren reversed={isFeature}>
        {optimizedImage && (
          <Image
            image={optimizedImage}
            alt={alt || `Featured Image for ${title}`}
            borderRadius={isFeature ? '16px' : '8px'}
            width="100%"
            height={isExploreAuthor ? '240px' : 'auto'}
            objectFit="cover"
            objectPosition="center"
            lg={{ maxWidth: isFeature && !isAuthor ? '50%' : '100%' }}
            xl={{ width: ftImgWidth, maxWidth: isAuthor && '500px' }}
          />
        )}
        <Flex
          paddingAll={!isFeature && 24}
          gap={!isAuthor && '24px'}
          flexDirection="column"
          justifyContent="space-between"
          height="100%"
        >
          <Flex flexDirection="column" gap={isAuthor ? '8px' : '24px'}>
            {isAuthor || isExploreAuthor ? getAuthorBadge : categoryData && getBadge(categoryData, blocks)}
            {title && (
              <Flex flexDirection="column" gap={isFeature ? '24px' : '8px'}>
                {(isTablet || isFeature) && !isAuthor ? (
                  <Link
                    href={slug || ''}
                    trackingSchema={{
                      label: title,
                      location: 'EntryCard',
                    }}
                  >
                    <Heading
                      as={headingType || isAuthor ? 'h1' : 'h2'}
                      textStyle="xs"
                      fontWeight={isAuthor ? 'bold' : 'semiBold'}
                      md={{ textStyle: isFeature ? 'md' : 'xs' }}
                      xl={{ textStyle: xlTitleSize }}
                      css={`
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                      `}
                      hover={{
                        textColor: 'primary-700',
                      }}
                    >
                      {title}
                    </Heading>
                  </Link>
                ) : (
                  <Text
                    as={headingType || 'h2'}
                    textStyle="xl"
                    fontWeight="semiBold"
                    css={`
                      display: -webkit-box;
                      -webkit-line-clamp: 2;
                      -webkit-box-orient: vertical;
                      overflow: hidden;
                    `}
                  >
                    {title}
                  </Text>
                )}
                {isAuthor && authorsCompany && (
                  <Text
                    textStyle="lg"
                    textColor="gray-900"
                    fontWeight="medium"
                    xl={{
                      textStyle: 'xl',
                      fontWeight: 'medium',
                    }}
                  >
                    {authorsTitle && `${authorsTitle} at `}
                    {authorsCompany}
                  </Text>
                )}
                {isAuthor && authorBadges && <AuthorBadgeFeature authorBadges={authorBadges} />}
                {description && (
                  <Text
                    textStyle={isFeature ? 'md' : 'sm'}
                    textColor="gray-700"
                    md={{ textStyle: isFeature ? 'xl' : 'md' }}
                    css={`
                      display: ${!isAuthor && '-webkit-box'};
                      -webkit-line-clamp: ${!isAuthor && 3};
                      -webkit-box-orient: ${!isAuthor && 'vertical'};
                      overflow: ${!isAuthor && 'hidden'};
                    `}
                  >
                    {description}
                  </Text>
                )}
                {isAuthor && funFact && (
                  <Text
                    textStyle="md"
                    textColor="gray-700"
                    fontStyle="italic"
                    md={{ textStyle: 'xl', fontStyle: 'italic' }}
                  >
                    {funFact}
                  </Text>
                )}
                {isAuthor && authorSocials && <AuthorSocialsFeature authorSocials={authorSocials} />}
              </Flex>
            )}
          </Flex>
          {isFeature ? (
            <Flex gap="12px" alignItems="center">
              {optimizedHeadshot && (
                <Image image={optimizedHeadshot} alt={`Headshot for ${fullName}`} borderRadius="50%" />
              )}
              <Flex flexDirection="column">
                {fullName && (
                  <Text textStyle="md" fontWeight="semiBold">
                    {fullName}
                  </Text>
                )}
                {publishDate && (
                  <Text textStyle="md" textColor="gray-700">
                    {toReadableDate(new Date(publishDate))}
                  </Text>
                )}
              </Flex>
            </Flex>
          ) : (
            <Link
              href={slug || ''}
              trackingSchema={{
                label: title,
                location: 'EntryCard',
              }}
            >
              <Flex
                gap="8px"
                alignItems="center"
                hover={{
                  textColor: 'primary-700',
                }}
                css={`
                  &:hover > div > svg {
                    stroke: ${color.primary[700]};
                  }
                `}
              >
                <Text textStyle="lg" fontWeight="semiBold">
                  {isExploreAuthor ? 'View Posts' : 'Learn More'}
                </Text>
                <Icon id="chevron-right" iconColor="gray-900" />
              </Flex>
            </Link>
          )}
        </Flex>
      </ReverseChildren>
    </Flex>
  );

  const cardProps = {
    boxShadow: 'lg',
    borderRadius: '16px',
    contain: 'content',
    transition: 'box-shadow 200ms ease-in-out',
    backgroundColor: isFeature ? 'gray-25' : 'common-white',
    paddingAll: isFeature && 24,
    maxWidth: isFeature ? container.lg : '370px',
    md: { paddingAll: isFeature && 40 },
    ...props,
  } as const;

  return isAuthor ? (
    <Flex {...cardProps}>{getCardContent()}</Flex>
  ) : (
    <Flex hover={{ boxShadow: 'xxxl' }} {...cardProps}>
      {getCardContent()}
    </Flex>
  );
};

export default EntryCard;
