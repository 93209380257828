import Icon from 'atoms/Icons/Icon';
import color from 'atoms/colors/colors';

import Flex from 'quarks/Flex';
import Text from 'quarks/Text';
import type { BasicProps } from 'quarks/interpolations/basic';

import ComponentButton from 'components/ComponentButton/ComponentButton';

import { unescapehtml } from 'utils/functions';

import type { FC } from 'react';

interface CareerListingProps extends BasicProps {
  department?: string;
  title?: string;
  caption?: string;
  location?: string;
  href?: string;
}

const CareerCard: FC<CareerListingProps> = ({ department, title, caption, location, href }) => (
  <Flex
    backgroundColor="gray-50"
    paddingX={32}
    paddingY={24}
    flexDirection="column"
    gap="24px"
    border="1px solid"
    borderColor="primary-50"
    borderRadius="16px"
    hover={{
      backgroundColor: 'primary-500',
      textColor: 'common-white',
    }}
    transition="background-color 300ms ease"
    maxWidth="328px"
    sm={{ maxWidth: '373px' }}
    css={`
      &:hover > * span,
      &:hover > * svg {
        color: ${color.common.white};
        stroke: ${color.common.white};
        fill: ${color.common.white};
      }
      &:hover > * .captions {
        color: ${color.gray[100]};
      }
    `}
  >
    <Flex gap="12px" flexDirection="column">
      <Flex gap="4px" flexDirection="column">
        {department && (
          <Text textStyle="sm" textColor="primary-500" fontWeight="medium">
            {department}
          </Text>
        )}
        {title && (
          <Text textStyle="lg" textColor="gray-900" fontWeight="medium" sm={{ textStyle: 'xl', fontWeight: 'medium' }}>
            {title}
          </Text>
        )}
      </Flex>
      {caption && (
        <Text
          textStyle="md"
          textColor="gray-700"
          textOverflow="ellipsis"
          className="captions"
          css={`
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
          `}
        >
          {unescapehtml(caption)}
        </Text>
      )}
    </Flex>
    {location && (
      <Flex gap="12px" sm={{ gap: '24px' }}>
        <Flex gap="8px">
          <Icon id="marker-pin-02" size={20} />
          <Text textColor="gray-700" textStyle="sm" className="captions">
            {location}
          </Text>
        </Flex>
      </Flex>
    )}
    {href && (
      // TODO: Build a function to create the button data model.
      <ComponentButton
        buttonType="callToAction"
        button={{ value: { data: { link: href, label: 'Apply Now', hierarchy: 'Secondary Color', size: 'large' } } }}
        width="100%"
        trackingSchema={{
          hierarchy: '1 of 1',
          location: 'CareerCard',
        }}
      />
    )}
  </Flex>
);

export default CareerCard;
