import type { ManualFormData, field } from 'components/HsForm/types';

const handleDefaultValue = (
  input: field,
  ind: number,
  normalFieldAmount: number,
  hiddenFieldsManualData?: ManualFormData,
) => {
  if (input.defaultValue) {
    return input.defaultValue;
  }
  if (input.hidden && hiddenFieldsManualData) {
    return hiddenFieldsManualData[ind - normalFieldAmount];
  }

  return '';
};

export default handleDefaultValue;
