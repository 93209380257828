import { useEffect, useState } from 'react';

import { media } from 'atoms/breakpoints/breakpoints';
import gradient from 'atoms/colors/gradients';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Heading from 'quarks/Heading';
import Image from 'quarks/Image';
import Text from 'quarks/Text';

import Section from 'molecules/Section/Section';

import EntryCard from 'components/Cards/EntryCard/EntryCard';

import colorizeText from 'utils/colorizeText';
import doodleGenerator from 'utils/doodleGenerator';
import useBlogAuthors from 'utils/hooks/useBlogAuthors';

import type { FC } from 'react';

interface ExploreAuthorsProps {
  currentAuthor: string;
}

const ExploreAuthors: FC<ExploreAuthorsProps> = ({ currentAuthor }) => {
  interface AuthorProps {
    name: string;

    data: {
      bio: string;
      headshot: string;
      url: string;
    };
  }
  const [moreAuthors, setMoreAuthors] = useState<AuthorProps[]>([]);

  const authorList = useBlogAuthors();

  const doodleProps = {
    opacity: '1',
    position: 'absolute',
    alt: 'doodle',
    zIndex: '-1',
  } as const;

  const leftDoodle = doodleGenerator('croissant');
  const rightDoodle = doodleGenerator('tripleknotswirl');

  useEffect(() => {
    authorList.map((author: AuthorProps) => {
      if (author.name !== currentAuthor) {
        return setMoreAuthors(prev => [...prev, author].slice(0, 3));
      } else {
        return;
      }
    });
  }, [authorList]);

  return (
    <Section
      __typename="ExploreAuthors"
      css={`
        &:after {
          content: '';
          display: block;
          z-index: -2;
          height: 50%;
          background: ${gradient.radialGradient.concave};
          position: absolute;
          top: -22%;
          left: 0;
          width: 100%;
          ${media.xl} {
            top: -10%;
          }
        }
      `}
    >
      <Container position="relative" display="none" md={{ display: 'block' }}>
        {leftDoodle && (
          <Image
            height="185px"
            width="390px"
            top="653px"
            left="-25%"
            image={leftDoodle}
            display="none"
            xl={{ display: 'block' }}
            {...doodleProps}
          />
        )}
        {rightDoodle && (
          <Image
            height="92px"
            width="455px"
            top="359px"
            right="-41%"
            transform="rotate(339deg)"
            image={rightDoodle}
            xl={{
              height: '140px',
              width: '688px',
              top: '309px',
              right: '-44%',
            }}
            {...doodleProps}
          />
        )}
      </Container>
      <Flex
        alignItems="center"
        flexDirection="column"
        gap="24px"
        md={{
          marginTop: 80,
        }}
      >
        <Text textStyle="eyebrow">{colorizeText('Lineup Authors', false)}</Text>
        <Heading
          as="h2"
          textStyle="xs"
          fontWeight="semiBold"
          textAlign="center"
          md={{
            textStyle: 'md',
          }}
          xl={{
            textStyle: 'lg',
          }}
          css={`
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
          `}
        >
          Explore more Lineup Authors
        </Heading>
        <Flex
          marginTop={10}
          gap="30px"
          flexDirection="column"
          flexWrap="wrap"
          maxWidth="100%"
          md={{
            marginTop: 40,
          }}
          xl={{
            flexWrap: 'nowrap',
            flexDirection: 'row',
          }}
          css={`
            ${media.sm} {
              display: grid;
              grid-template-columns: 1fr 1fr;
            }
            ${media.xl} {
              display: flex;
            }
          `}
        >
          {moreAuthors.map((author: AuthorProps) => (
            <EntryCard
              key={author.name}
              variant="exploreAuthors"
              url={author.data.url}
              title={author.name}
              description={author.data.bio}
              featuredImage={author.data.headshot}
              featuredImageCaption={`${author.name}'s headshot`}
            />
          ))}
        </Flex>
      </Flex>
    </Section>
  );
};

export default ExploreAuthors;
