import { iconValues } from 'atoms/Icons/Icon.types';

const ArticleOutlineConfig = {
  name: 'articleOutline',
  requiresParent: {
    message: 'Block must be placed inside the Content Editor',
    component: 'ContentEditor',
  },
  image: 'https://tabler-icons.io/static/tabler-icons/icons-png/album.png',
  inputs: [
    { name: 'heading', type: 'text', defaultValue: 'Report Outline' },
    { name: 'headingType', type: 'text', enum: ['h2', 'h3', 'h4', 'h5', 'h6'], defaultValue: 'h2' },
    {
      name: 'features',
      type: 'list',
      helperText: 'Adds up to three key features at the top of the sidebar',
      subFields: [
        { name: 'icon', type: 'text', enum: iconValues, defaultValue: 'rocket-01', required: true },
        { name: 'copy', text: 'text', defaultValue: 'Saved 1.1-1.3 million dollars in labor costs' },
        { name: 'id', type: 'text', required: true, advanced: true },
      ],
      defaultValue: [
        {
          icon: 'rocket-01',
          copy: 'This is placeholder text. Should be kept relatively short.',
          id: 'post-feature-0',
        },
        {
          icon: 'rocket-01',
          copy: 'This is placeholder text. Should be kept relatively short.',
          id: 'post-feature-1',
        },
      ],
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onChange: options => {
        if (options.get('features').length > 1) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          options.get('features').forEach(({ feature }, index: number) => {
            feature.set('id', `post-feature-${index}`);
          });
        }
        if (options.get('features').length > 3) {
          options.set('features', options.get('features').slice(0, 3));
          alert('Maximum of three features are allowed.');
        }
      },
    },
  ],
};
export default ArticleOutlineConfig;
