import SectionBuilderConfig from 'molecules/Section/Section.config';

const CareerListingBuilderConfig = {
  name: 'CareerListing',
  inputs: [
    { name: 'eyebrow', type: 'text', defaultValue: 'Job Openings' },
    { name: 'heading', type: 'text', defaultValue: 'Current job openings.' },
    ...SectionBuilderConfig.inputs,
  ],
};

export default CareerListingBuilderConfig;
