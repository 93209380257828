import SectionBuilderConfig from 'molecules/Section/Section.config';

const ProfitMarginCalculatorBuilderConfig = {
  name: 'Component Profit Margin Calculator',
  inputs: [
    { name: 'eyebrow', type: 'text', defaultValue: 'Eyebrow' },
    { name: 'heading', type: 'text', defaultValue: 'Profit Margin Calculator' },
    { name: 'headingType', type: 'text', enum: ['h2', 'h3', 'h4', 'h5', 'h6'] },
    { name: 'subheading', type: 'richText' },
    {
      name: 'calculatorEyebrow',
      type: 'text',
      defaultValue: 'Calculator',
      helperText: 'Set the eyebrow the calculator component.',
    },
    {
      name: 'calculatorTitle',
      type: 'text',
      defaultValue: 'Profit Margin Calculator',
      helperText: 'Set the title inside the calculator component.',
    },
    {
      name: 'calculatorDescription',
      type: 'longText',
      defaultValue: "Enter your information to get your restaurant's profit margin.",
      helperText: 'Set the description inside the calculator component.',
    },
    {
      name: 'totalRevenue',
      type: 'object',
      subFields: [
        { name: 'label', type: 'text' },
        { name: 'placeholder', type: 'number' },
        { name: 'toolTipTitle', type: 'text' },
        { name: 'toolTipDescription', type: 'text' },
      ],
      defaultValue: {
        label: 'Total Revenue',
        placeholder: 5000,
      },
    },
    {
      name: 'costOfGoodsSold',
      type: 'object',
      subFields: [
        { name: 'label', type: 'text' },
        { name: 'placeholder', type: 'number' },
        { name: 'toolTipTitle', type: 'text' },
        { name: 'toolTipDescription', type: 'text' },
      ],
      defaultValue: {
        label: 'Cost of Goods Sold',
        placeholder: 2100,
      },
    },
    {
      name: 'totalOperatingCosts',
      type: 'object',
      subFields: [
        { name: 'label', type: 'text' },
        { name: 'placeholder', type: 'number' },
        { name: 'toolTipTitle', type: 'text' },
        { name: 'toolTipDescription', type: 'text' },
      ],
      defaultValue: {
        label: 'Total Operating Costs',
        placeholder: 500,
      },
    },
    {
      name: 'calculatorCaption',
      type: 'richText',
      defaultValue: 'Enter your information to get restuarant profit margin with the Profit Margin Calculator.',
      helperText: 'Set the caption inside the calculator component.',
    },
    ...SectionBuilderConfig.inputs,
  ],
};

export default ProfitMarginCalculatorBuilderConfig;
