import { useState } from 'react';

import Flex from 'quarks/Flex';
import Heading from 'quarks/Heading';
import type { BasicProps } from 'quarks/interpolations/basic';
import type { HeadingTypes } from 'quarks/styleProps/heading';

import { handleAccordionItem } from 'components/Accordion/Accordion';
import AccordionItem from 'components/Accordion/AccordionItem';

import type { Builder_AccordionItem } from 'graphqlTypes';
import type { FC } from 'react';

export interface AccordionItemsProps extends BasicProps {
  label?: string;
  headingType?: HeadingTypes;
  itemHeadingType?: HeadingTypes;
  items?: { item: { value: Builder_AccordionItem; id: string } }[];
}

const AccordionItems: FC<AccordionItemsProps> = ({ headingType, itemHeadingType, label, items }) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  return (
    <Flex flexDirection="column" width="100%" gap="30px">
      {label && (
        <Heading as={headingType || 'h2'} textStyle="xs">
          {label}
        </Heading>
      )}
      <Flex flexDirection="column" width="100%" gap="16px">
        {items &&
          items?.length > 0 &&
          items?.map(
            ({ item }, index) =>
              item && (
                <AccordionItem
                  key={item.id}
                  onClick={() => handleAccordionItem(index, activeIndex, setActiveIndex)}
                  isActive={index === activeIndex}
                  label={item.value?.data?.label}
                  itemHeadingType={itemHeadingType}
                  content={item.value?.data?.content}
                  {...item.value?.data}
                />
              ),
          )}
      </Flex>
    </Flex>
  );
};

export default AccordionItems;
