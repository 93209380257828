import type { fontWeight } from 'atoms/typography/typography';

import type { BasicProps } from 'quarks/interpolations/basic';

import HeadingContent from 'molecules/HeadingContent/HeadingContent';
import Section from 'molecules/Section/Section';

import type { ButtonTypes } from 'components/ComponentButton/buttonTypeConfig';

import type { FC } from 'react';

export interface HeadingProps extends BasicProps {
  eyebrow?: string;
  coloredEyebrow?: boolean;
  heading?: string;
  subheading?: string;
  subheadingFontWeight?: keyof typeof fontWeight;
  leftAlign?: boolean;
  background?: string;
  buttonGroup?: ButtonTypes;
  collapseBottomPadding?: boolean;
  sectionId?: string;
}

const ComponentHeading: FC<HeadingProps> = ({ collapseBottomPadding, sectionId, ...props }) => (
  <Section
    __typename="ComponentHeading"
    background={props.background}
    id={sectionId}
    css={`
      & > div {
        padding-bottom: ${collapseBottomPadding && 0};
      }
    `}
  >
    <HeadingContent {...props} />
  </Section>
);

ComponentHeading.defaultProps = {
  coloredEyebrow: false,
  eyebrow: 'eyebrow',
  heading: 'Component Heading',
};

export default ComponentHeading;
