/* eslint-disable import/prefer-default-export */
import { useEffect, useState } from 'react';
import { useFirstMountState } from 'react-use';
import { isBrowser } from 'react-use/lib/misc/util';

import type { RefObject } from 'react';

interface WindowSize {
  width: number;
  height: number;
}

export const useWindowSize = (): WindowSize => {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: 0,
    height: 0,
  });
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};

export const useSticky = (ref: RefObject<HTMLElement>): boolean => {
  const [isSticky, setIsSticky] = useState<boolean>(false);
  const isFirstMount = useFirstMountState();

  const handleOnTop = () => {
    if (ref?.current) {
      const offsetTop = ref.current?.getBoundingClientRect()?.top;
      const distanceToTop = 89;

      setIsSticky(offsetTop < distanceToTop);
    }
  };

  useEffect(() => {
    if (isFirstMount) {
      handleOnTop();
    }
  }, []);

  useEffect(() => {
    if (ref?.current && isBrowser) {
      window.addEventListener('scroll', handleOnTop, { passive: true });

      return () => {
        window.removeEventListener('scroll', handleOnTop);
      };
    }
  }, []);

  return isSticky;
};
