import container from 'atoms/spacing/containers';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Heading from 'quarks/Heading';
import Text from 'quarks/Text';

import type { HeroPricingCopyProps } from 'components/HeroPricing/heroTypesAndData';

import colorizeText from 'utils/colorizeText';
import RichTextParser from 'utils/richTextParser';

import type { FC } from 'react';

const HeroPricingCopy: FC<HeroPricingCopyProps> = ({ heading, backgroundIsDark, subheading, eyebrow }) => (
  <Flex flexDirection="column" gap="24px" maxWidth={container.md}>
    {eyebrow && <Text textStyle="eyebrow">{colorizeText(eyebrow, backgroundIsDark)}</Text>}
    {heading && (
      <Heading
        as="h1"
        textColor={backgroundIsDark ? 'common-white' : 'gray-900'}
        textStyle="sm"
        fontWeight="semiBold"
        lg={{ textStyle: 'lg' }}
        xl={{ textStyle: 'xl' }}
      >
        {heading}
      </Heading>
    )}
    {subheading && (
      <Container
        textColor={backgroundIsDark ? 'gray-50' : 'gray-700'}
        fontSize="textLg"
        fontWeight="medium"
        md={{ fontSize: 'textXl' }}
      >
        {RichTextParser(subheading)}
      </Container>
    )}
  </Flex>
);

export default HeroPricingCopy;
