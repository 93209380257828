import SectionBuilderConfig from 'molecules/Section/Section.config';

import { doodleField } from 'utils/doodleGenerator';

const HeroPricingBuilderConfig = {
  name: 'ComponentHeroPricing',
  inputs: [
    { name: 'enablePlanSwitcher', type: 'boolean', defaultValue: false },
    { name: 'isAnnual', type: 'boolean', defaultValue: false },
    {
      name: 'background',
      type: 'text',
      enum: ['Gray-900', 'White', 'Gray-100', 'Concave', 'Hero Pricing'],
      defaultValue: 'Hero Pricing',
    },
    ...doodleField,
    { name: 'eyebrow', type: 'text', defaultValue: 'Pricing' },
    { name: 'discountText', type: 'text', defaultValue: 'Get 20% Discount' },
    { name: 'heading', type: 'text', defaultValue: 'This is placeholder text.' },
    { name: 'monthlySupplementText', type: 'text', defaultValue: 'Monthly Agreement' },
    { name: 'annualSupplementText', type: 'text', defaultValue: 'Annual Agreement' },
    {
      name: 'subheading',
      type: 'richText',
      defaultValue: '<p>This is placeholder text. Should be kept relatively short.</p>',
    },
    {
      name: 'plans',
      type: 'list',
      subFields: [
        { name: 'id', type: 'text', defaultValue: 'example-plan-id-01' },
        { name: 'plan', type: 'reference', model: 'component-pricing-card-deck' },
      ],
    },
    ...SectionBuilderConfig.inputs,
  ],
};

export default HeroPricingBuilderConfig;
