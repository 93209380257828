import { FormControl, InputBase } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import styled from 'styled-components';

import Icon from 'atoms/Icons/Icon';
import color from 'atoms/colors/colors';
import shadow from 'atoms/shadows/shadows';
import type { FontSizeDefinition } from 'atoms/typography/typography';

import Text from 'quarks/Text';
import type { BasicProps } from 'quarks/interpolations/basic';
import { basic } from 'quarks/interpolations/basic';
import { flex } from 'quarks/interpolations/flex';
import type { FlexProps } from 'quarks/interpolations/flex';

import type { TooltipProps } from 'molecules/Tooltip/Tooltip';
import Tooltip from 'molecules/Tooltip/Tooltip';

import type { ChangeEventHandler, FC, FocusEventHandler, MouseEventHandler, ReactElement } from 'react';

const StyledFormControl = styled(FormControl)`
  && {
    ${flex}
  }
`;

const StyledInput = styled(InputBase)<BasicProps>`
  && {
    ${basic}
    padding: 8px 12px;

    &.Mui-focused {
      border-color: ${color.primary[300]};
      box-shadow: ${shadow.focused};
    }

    & input {
      padding: 0;
    }

    & input::placeholder {
      padding: 0;
      color: ${color.gray[500]};
      transition: 'color 100ms ease-in-out';
    }

    &.Mui-error {
      border-color: ${color.error[300]};
    }
  }
`;

interface TextFieldProps extends FlexProps {
  /**
   * Defines the icon to be used at the start of the TextField.
   */
  startIcon?: ReactElement;
  /**
   * Defines the icon to be used at the end of the TextField.
   */
  endIcon?: ReactElement | null;
  /**
   * The label content.
   */
  label?: string;
  /**
   * The size of the label. Uses values from Typography only.
   */
  labelSize?: FontSizeDefinition;
  /**
   * Removes the label instance. Useful for single line forms.
   */
  disableLabel?: boolean;
  /**
   * 	The short hint displayed in the `TextField` before the user enters a value.
   */
  placeholder?: string;
  /**
   * Name attribute of the `TextField` element.
   */
  name?: string;
  /**
   * Defines a string of text located under the TextField to assist the user with the TextField functionality.
   */
  helperText?: string;
  /**
   * Callback fired when the endIcon is clicked.
   *  **Signature:**
   *  `function(event: object) => void`
   */
  onEndIconClick?: MouseEventHandler<HTMLDivElement> | undefined;
  /**
   * 	Callback fired when the value is changed.
   *  **Signature:**
   *  `function(event: object) => void`
   *  event: The event source of the callback. You can pull out the new value by accessing `event.target.value` (string).
   */
  onChange?: ChangeEventHandler<HTMLInputElement> | undefined;
  /**
   * 	Callback fired when focus exits the input.
   *  **Signature:**
   *  `function(event: object) => void`
   *  event: The event source of the callback. You can pull out the new value by accessing `event.target.value` (string).
   */
  onBlur?: FocusEventHandler<HTMLInputElement> | undefined;
  /**
   * Callback fired when the key is pressed.
   * **Signature:**
   * `function(event: React.SyntheticEvent, checked: boolean) => void`
   * event: The event source of the callback. You can pull out the new value by accessing `event.target.value` (string). You can pull out the new checked state by accessing `event.target.checked` (boolean).
   */
  onKeyDown?: (e: React.KeyboardEvent<HTMLElement>) => void;
  /**
   * Defines the type of text TextField or a number TextField
   */
  type?: 'password' | 'text' | 'email' | 'number' | 'tel';
  /**
   * 	If `true`, the component is disabled.
   */
  disabled?: boolean;
  /**
   * displays error message under the label (replaces helper text)
   */
  errorMessage?: string;
  /**
   * If `true`, the label is displayed in an error state.
   */
  error?: boolean;
  /**
   * If `true`, the label is displayed as required and the `TextField` element is required.
   */
  required?: boolean;
  /**
   * The id of the `TextField` element. Use this prop to make `label` and `helperText` accessible for screen readers.
   */
  id?: string;
  /**
   * The value of the `TextField` element, required for a controlled component.
   */
  value?: string | boolean | number;
  /**
   * String that displays the title of a tooltip on the `endIcon`.
   */
  tooltip?: TooltipProps['title'];
  /**
   * Tooltip placement in regards to the endIcon. Default `top`
   */
  tooltipPlacement?: TooltipProps['placement'];
  /**
   * Rendered below the title. Used when more than a single line or phrase is needed in the tooltip.
   */
  tooltipDescription?: TooltipProps['description'];
  /**
   * The type of the hubspot form text field type
   */
  hsType?: 'text' | 'email' | 'number';
}

const TextField: FC<TextFieldProps> = ({
  id,
  name,
  label,
  labelSize = 'textSm',
  startIcon,
  endIcon,
  onEndIconClick,
  error,
  errorMessage,
  helperText,
  required,
  disableLabel,
  disabled,
  placeholder,
  type,
  tooltip,
  tooltipDescription,
  tooltipPlacement,
  value,
  hsType,
  ...props
}) => {
  const isEmail = type === 'email' || hsType === 'email';
  startIcon ||= isEmail ? <Icon id="mail-01" size={16} iconColor="gray-500" /> : undefined;

  return (
    <StyledFormControl error={error} required={required} zIndex={0} gap="6px" {...props}>
      {label && !disableLabel && (
        <Text
          as="label"
          htmlFor={id || name}
          textStyle="sm"
          fontWeight="regular"
          textColor="gray-700"
          fontSize={labelSize}
          css={{
            '& + .MuiInputBase-root': {
              flexGrow: 0,
            },
          }}
        >
          {`${label}`}
          {required && (
            <Text textColor="bitterSweet-300" textStyle="xs" fontSize="textLg" lineHeight="zero">
              *
            </Text>
          )}
        </Text>
      )}
      <StyledInput
        id={id || name}
        startAdornment={startIcon && <InputAdornment position="start">{startIcon}</InputAdornment>}
        endAdornment={
          <>
            {(endIcon || error) && (
              <InputAdornment position="start" onClick={onEndIconClick}>
                {error ? <Icon id="alert-circle" iconColor="error-500" size={14} /> : endIcon}
              </InputAdornment>
            )}
            {tooltip && (
              <Tooltip title={tooltip} placement={tooltipPlacement} description={tooltipDescription}>
                <InputAdornment position="start" onClick={onEndIconClick} css="cursor: pointer;">
                  <Icon id="help-circle" iconColor="gray-400" size={16} />
                </InputAdornment>
              </Tooltip>
            )}
          </>
        }
        name={name}
        type={type || hsType}
        error={error}
        disabled={disabled}
        placeholder={placeholder}
        value={value}
        position="relative"
        flex="1 0 auto"
        fontSize="textMd"
        fontWeight="medium"
        textColor="gray-900"
        border="1px solid"
        borderColor="gray-400"
        borderRadius="8px"
        backgroundColor="common-white"
        transition="color 100ms ease-in-out, background-color 100ms ease-in-out"
        hover={{
          backgroundColor: 'primary-25',
        }}
      />
      {(error || helperText) && !disableLabel && (
        <Text textStyle="sm" fontWeight="semiBold" textColor={error ? 'error-500' : 'gray-500'}>
          {error ? errorMessage : helperText}
        </Text>
      )}
    </StyledFormControl>
  );
};

export default TextField;

TextField.defaultProps = {
  type: 'text',
  tooltipPlacement: 'top',
};
