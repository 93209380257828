import { useState } from 'react';

import container from 'atoms/spacing/containers';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Heading from 'quarks/Heading';
import Image from 'quarks/Image';
import Text from 'quarks/Text';
import type { BasicProps } from 'quarks/interpolations/basic';
import type { HeadingTypes } from 'quarks/styleProps/heading';

import Section from 'molecules/Section/Section';

import AccordionItem from 'components/Accordion/AccordionItem';
import ComponentButton from 'components/ComponentButton/ComponentButton';
import type { ButtonTypes } from 'components/ComponentButton/buttonTypeConfig';
import ButtonWrapper from 'components/ComponentButton/buttonWrapper';

import doodleGenerator from 'utils/doodleGenerator';
import { getBackgroundIsDark, getButtonHierarchy } from 'utils/functions';
import RichTextParser from 'utils/richTextParser';

import type { Builder_AccordionItem, Builder_ComponentButton } from 'graphqlTypes';
import type { Dispatch, FC, SetStateAction } from 'react';

type AccordionProps = BasicProps & {
  background: string;
  alignment: string;
  eyebrow: string;
  heading: string;
  headingType?: HeadingTypes;
  itemHeadingType?: HeadingTypes;
  subheading: string;
  enableDoodle?: boolean;
  buttonGroup: ButtonTypes;
  items: Array<{ item: { value: Builder_AccordionItem; id: string } }>;
  sectionId?: string;
};

export const handleAccordionItem = (
  accordionIndex: number,
  activeIndex: number | null,
  setActiveIndex: Dispatch<SetStateAction<number | null>>,
) => {
  setActiveIndex(activeIndex !== accordionIndex ? accordionIndex : null);
};

const Accordion: FC<AccordionProps> = ({
  background,
  alignment,
  enableDoodle,
  eyebrow,
  heading,
  headingType,
  itemHeadingType,
  subheading,
  buttonGroup,
  items,
  sectionId,
  ...props
}) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const doodle = enableDoodle && doodleGenerator('doodlegroup');
  const backgroundIsDark = getBackgroundIsDark(background);
  const isCentered = alignment === 'Centered';

  const typename = 'ComponentAccordion';

  return (
    <Section __typename={typename} background={background} id={sectionId}>
      <Container>
        <Flex
          width="100%"
          maxWidth={isCentered ? container.md : undefined}
          marginX={isCentered ? 'auto' : 0}
          justifyContent={isCentered ? undefined : 'space-between'}
          flexDirection="column"
          gap="40px"
          md={{
            gap: '48px',
          }}
          lg={{
            flexDirection: isCentered ? 'column' : 'row',
            gap: isCentered ? '64px' : '30px',
          }}
          {...props}
        >
          <Flex
            flexDirection="column"
            flexWrap="nowrap"
            textAlign={isCentered ? 'center' : 'left'}
            lg={{
              maxWidth: isCentered ? undefined : '470px',
              minWidth: isCentered ? undefined : '470px',
            }}
            alignItems="center"
            marginX="auto"
            textColor={backgroundIsDark ? 'common-white' : 'gray-900'}
            gap="32px"
          >
            <Flex flexDirection="column" gap="24px" alignItems={isCentered ? 'center' : undefined}>
              {eyebrow && (
                <Text
                  textStyle="eyebrow"
                  gradient={backgroundIsDark ? undefined : 'lineupGradient-lightMode'}
                  whiteSpace="nowrap"
                  width="min-content"
                >
                  {eyebrow}
                </Text>
              )}
              {heading && (
                <Heading
                  as={headingType || 'h2'}
                  textStyle="xs"
                  md={{ textStyle: 'md' }}
                  xl={{ textStyle: 'lg' }}
                  fontWeight="semiBold"
                >
                  {heading}
                </Heading>
              )}
              {subheading && (
                <Text
                  as="div"
                  display="flex"
                  flexDirection="column"
                  textColor={backgroundIsDark ? 'common-white' : 'gray-700'}
                  textStyle="lg"
                  gap="8px"
                  md={{ textStyle: 'xl' }}
                >
                  {RichTextParser(subheading)}
                </Text>
              )}
              {buttonGroup && (
                <ButtonWrapper paddingTop={8} justifyContent={isCentered ? 'center' : 'left'}>
                  {buttonGroup.map((button, index) => (
                    <ComponentButton
                      buttonType={button?.buttonType || 'callToAction'}
                      key={button.button?.value?.id || index}
                      trackingSchema={{
                        hierarchy: getButtonHierarchy(buttonGroup, button),
                        location: typename,
                      }}
                      {...(button as Builder_ComponentButton)}
                    />
                  ))}
                </ButtonWrapper>
              )}
            </Flex>
          </Flex>
          {items && (
            <Flex flexDirection="column" width="100%" gap="16px">
              {items.map(
                ({ item }, index) =>
                  item && (
                    <AccordionItem
                      key={item.id || index}
                      onClick={() => handleAccordionItem(index, activeIndex, setActiveIndex)}
                      isActive={index === activeIndex}
                      itemHeadingType={itemHeadingType}
                      {...item.value?.data}
                    />
                  ),
              )}
            </Flex>
          )}
        </Flex>
        {doodle && enableDoodle && (
          <Image
            opacity=".2"
            width="100%"
            left="0"
            position="absolute"
            zIndex={-10}
            image={doodle}
            alt="doodle"
            top="97%"
            md={{
              top: '92%',
            }}
            lg={{
              top: isCentered ? '72%' : '67%',
            }}
          />
        )}
      </Container>
    </Section>
  );
};

export default Accordion;
