import MuiRating from '@mui/material/Rating';
import styled from 'styled-components';

import type { BasicProps } from 'quarks/interpolations/basic';
import { basic } from 'quarks/interpolations/basic';

import type { AppTheme, CSSProps } from 'theme/getAppTheme';

import type { FC, SyntheticEvent } from 'react';
import type { CSSProp } from 'styled-components';

const StyledRating = styled(MuiRating)`
  ${basic}
`;

type RatingProps = BasicProps & {
  /**
   * Number 0-5 by .1 increments that determines the star count
   */
  value?: number;
  /**
   * If true, the stars are non-clickable
   */
  readOnly?: boolean;
  /**
   * `small` for small, `large` for large and nothing for medium
   */
  size?: 'small' | 'large';
  /**
   * if true the stars will be disabled
   */
  disabled?: boolean;
  /**
   * used to control component
   */
  onChange?: (event: SyntheticEvent<Element, Event>, value: number | null) => void;

  css?: CSSProp<AppTheme>;
};

const Rating: FC<RatingProps> = ({ ...props }) => (
  <StyledRating
    precision={0.1}
    css={`
      &.MuiRating-root {
        color: ${(muiProps: CSSProps) => muiProps.theme.palette.warning[400]};
      }
      ${props.css}
    `}
    {...props}
  />
);

export default Rating;

Rating.defaultProps = {};
