import '@dotlottie/player-component';

import { useEffect, useRef, useState } from 'react';

import Container from 'quarks/Container';
import Image from 'quarks/Image';

import type { Dispatch, FC, SetStateAction } from 'react';

interface LottieAnimationProps extends Omit<Partial<JSX.IntrinsicElements['dotlottie-player']>, 'children'> {
  lottieAnimation: string;
  placeholderImage?: string;
  setIsPlaying?: Dispatch<SetStateAction<boolean>>;
  isHomePage?: boolean | null;
}

const LottieAnimation: FC<LottieAnimationProps> = ({
  lottieAnimation,
  setIsPlaying,
  placeholderImage,
  isHomePage,
  ...props
}) => {
  const [showPlaceholder, setShowPlaceholder] = useState<boolean>(true);

  const dotLottieRef = useRef<JSX.IntrinsicElements['dotlottie-player']>(null);

  useEffect(() => {
    lottieAnimation && dotLottieRef?.current?.load(lottieAnimation);
  }, [lottieAnimation]);

  useEffect(() => {
    let playing: NodeJS.Timeout | undefined;
    let settingPlaceholder: NodeJS.Timeout | undefined;

    dotLottieRef?.current?.addEventListener('playing', () => {
      playing = setTimeout(() => {
        setIsPlaying && setIsPlaying(true);
      }, 600);

      settingPlaceholder = setTimeout(() => {
        setShowPlaceholder(false);
      }, 900);
    }),
      { once: true };

    return () => {
      dotLottieRef?.current?.removeEventListener('playing', () => {
        clearTimeout(playing);
        clearTimeout(settingPlaceholder);
      });
    };
  }, [dotLottieRef?.current]);

  if (!lottieAnimation) {
    return null;
  }

  return (
    <Container position="relative" width="100%">
      <Container position={isHomePage ? 'absolute' : 'relative'} top="0" width="100%">
        <dotlottie-player src={lottieAnimation} autoplay ref={dotLottieRef} {...props} />
      </Container>
      {placeholderImage && (
        <Image
          image={placeholderImage}
          alt="Lottie placeholder"
          maxWidth="100%"
          opacity={showPlaceholder ? 1 : 0}
          position="relative"
          title=""
        />
      )}
    </Container>
  );
};

export default LottieAnimation;
