import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import color from 'atoms/colors/colors';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Text from 'quarks/Text';
import type { FlexProps } from 'quarks/interpolations/flex';
import type { HeadingTypes } from 'quarks/styleProps/heading';

import richTextParser from 'utils/richTextParser';

import type { FC } from 'react';

type AccordionItemProps = FlexProps & {
  content?: string | null;
  label?: string | null;
  itemHeadingType?: HeadingTypes;
  isActive: boolean;
  onClick: () => void;
};

const AccordionItem: FC<AccordionItemProps> = ({ label, itemHeadingType, content, isActive, onClick, ...props }) => (
  <Flex
    backgroundColor="common-white"
    width="100%"
    flexDirection="column"
    paddingY={16}
    gap="16px"
    paddingAll={24}
    onClick={onClick}
    cursor="pointer"
    onKeyDown={e => (e.key === 'Enter' || e.key === ' ') && onClick()}
    tabIndex={0}
    border="1px solid"
    borderColor={isActive ? 'primary-400' : 'primary-100'}
    borderRadius="12px"
    boxShadow="md"
    hover={{
      boxShadow: 'lg',
    }}
    {...props}
  >
    <Flex gap="16px" justifyContent="space-between">
      {label && (
        <Text
          as={itemHeadingType || 'h3'}
          textColor="gray-900"
          fontWeight="semiBold"
          textStyle="lg"
          md={{ textStyle: 'xl', fontWeight: 'semiBold' }}
        >
          {label}
        </Text>
      )}
      <Flex alignItems="center" css="> svg { transition: transform 150ms; }">
        <ExpandMoreIcon
          sx={{ transform: isActive ? 'rotate(180deg)' : 'rotate(0deg)' }}
          width="24px"
          height="24px"
          css={{ path: { fill: color.primary[600] } }}
        />
      </Flex>
    </Flex>
    {content && (
      <Container display={isActive ? 'block' : 'none'}>
        <Text
          display="flex"
          flexDirection="column"
          gap="16px"
          textStyle="md"
          md={{ textStyle: 'lg' }}
          fontWeight="regular"
          textColor="gray-700"
          textAlign="left"
        >
          {richTextParser(content)}
        </Text>
      </Container>
    )}
  </Flex>
);

export default AccordionItem;
