import { ChevronDown } from 'react-feather';

import Icon from 'atoms/Icons/Icon';
import type { iconIds } from 'atoms/Icons/Icon.types';
import { media } from 'atoms/breakpoints/breakpoints';

import Flex from 'quarks/Flex';
import Text from 'quarks/Text';
import type { BasicProps } from 'quarks/interpolations/basic';

import type { FC } from 'react';

interface TabbedFeatureProps extends BasicProps {
  title: string;
  description: string;
  icon?: iconIds;
  backgroundIsDark: boolean;
  isActive: boolean;
  onClick: () => void;
}

const TabbedFeature: FC<TabbedFeatureProps> = ({ backgroundIsDark, onClick, title, description, icon, isActive }) => {
  const backgroundColor = backgroundIsDark ? 'gray-800' : 'gray-25';

  return (
    <Flex
      gap="16px"
      cursor={!isActive && 'pointer'}
      tabIndex={0}
      paddingAll={16}
      borderRadius="12px"
      backgroundColor={isActive ? backgroundColor : 'common-transparent'}
      transition="background-color 0.150s"
      onClick={onClick}
      boxShadow={isActive ? 'lg' : 'undefined'}
      onKeyDown={e => (e.key === 'Enter' || e.key === ' ') && onClick()}
      md={{ gap: '24px' }}
    >
      {icon && (
        <Flex height="fit-content">
          <Icon size={16} id={icon} hasGradientBorder isBackgroundDark={backgroundIsDark} mdSize={24} wrapped />
        </Flex>
      )}
      <Flex alignSelf="end" width="100%" flexDirection="column" gap="8px" md={{ gap: '12px' }}>
        <Flex justifyContent="space-between" css="> svg { transition: 150ms; }">
          {title && (
            <Text
              textStyle="lg"
              fontWeight="semiBold"
              textColor={backgroundIsDark ? 'common-white' : 'gray-900'}
              md={{ textStyle: 'xl', fontWeight: 'semiBold' }}
            >
              {title}
            </Text>
          )}
          <ChevronDown
            size={20}
            css={`
              transform: ${`rotate(${isActive ? '180deg' : '0deg'})`};
              align-self: center;
              ${media.md} {
                height: 24px;
                width: 24px;
              }
            `}
          />
        </Flex>
        {description && (
          <Text
            as="div"
            maxHeight={isActive ? 'fit-content' : '0px'}
            textStyle="md"
            textColor={backgroundIsDark ? 'gray-50' : 'gray-700'}
            overflowY="hidden"
            md={{ textStyle: 'lg' }}
            transition="max-height 0.150s ease"
          >
            {description}
          </Text>
        )}
      </Flex>
    </Flex>
  );
};

export default TabbedFeature;
