import Icon from 'atoms/Icons/Icon';

import type { BasicProps } from 'quarks/interpolations/basic';
import type { HeadingTypes } from 'quarks/styleProps/heading';

interface FieldProps {
  label?: string;
  placeholder?: string | number;
  toolTipTitle?: string;
  toolTipDescription?: string;
}

export interface ComponentProfitMarginCalculatorProps extends BasicProps, ProfitMarginCalculatorProps {
  eyebrow?: string;
  heading?: string;
  headingType?: HeadingTypes;
  sectionId?: string;
  subheading?: string;
  calculatorEyebrow?: string;
  calculatorTitle?: string;
  calculatorDescription?: string;
  totalRevenue?: FieldProps;
  costOfGoodsSold?: FieldProps;
  totalOperatingCosts?: FieldProps;
  calculatorCaption?: string;
}

export interface ProfitMarginCalculatorProps {
  eyebrow?: string;
  title?: string;
  description?: string;
  totalRevenueField?: FieldProps;
  costOfGoodsSoldField?: FieldProps;
  totalOperatingCostsField?: FieldProps;
  calculatorCaption?: string;
}

export interface planObj {
  name: string;
  value: string;
}

export interface ProfitMarginData {
  totalRevenue: string;
  costOfGoodsSold: string;
  totalOperatingCosts: string;
}

export interface ToolTipFields {
  toolTipTitle?: string;
  toolTipDescription?: string;
}

export type HandleInputChange = (e: {
  target?: {
    value: string;
    name: string;
  };
}) => void;

export type ErrorData = {
  [key: string]: string | undefined;
};

export const formatCurrency = (number: number) =>
  number.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

export const formatUSDCurrency = (number: number) =>
  number.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: 'currency',
    currency: 'USD',
  });

export const dollarIcon = <Icon id="currency-dollar" iconColor="gray-500" />;

export const percentIcon = <Icon id="percent-02" iconColor="gray-500" />;

export const calculatorObj = {};

export const profitMarginCalculatorObj = {
  totalRevenue: '',
  costOfGoodsSold: '',
  totalOperatingCosts: '',
};

/**
 * Formula: sales ratio = (A - B) / A
 * A = Total Revenue
 * B = Cost of Goods Sold
 * C = Total Expenses and Operating Costs
 */

export const calculateGrossProfitMargin = (profitMargin: ProfitMarginData) => {
  const { totalRevenue, costOfGoodsSold } = profitMargin;

  if (totalRevenue === '0') {
    return '0';
  }

  const totalRevenueValue =
    typeof totalRevenue === 'string' ? parseFloat(totalRevenue.replace(/,/g, '')) : Number(totalRevenue);
  const costOfGoodsSoldValue =
    typeof costOfGoodsSold === 'string' ? parseFloat(costOfGoodsSold.replace(/,/g, '')) : Number(costOfGoodsSold);

  const grossProfit = totalRevenueValue - costOfGoodsSoldValue;

  const grossProfitMargin = (grossProfit / totalRevenueValue) * 100;

  return grossProfitMargin.toFixed(2).replace(/[.,]00$/, '');
};

/**
 * Formula: sales ratio = (A - (B + C)) / A
 * A = Total Revenue
 * B = Cost of Goods Sold
 * C = Total Expenses and Operating Costs
 */

export const calculateNetProfitMargin = (profitMargin: ProfitMarginData) => {
  const { totalRevenue, costOfGoodsSold, totalOperatingCosts } = profitMargin;

  if (totalRevenue === '0') {
    return '0';
  }

  const totalRevenueValue =
    typeof totalRevenue === 'string' ? parseFloat(totalRevenue.replace(/,/g, '')) : Number(totalRevenue);
  const costOfGoodsSoldValue =
    typeof costOfGoodsSold === 'string' ? parseFloat(costOfGoodsSold.replace(/,/g, '')) : Number(costOfGoodsSold);
  const totalOperatingCostsValue =
    typeof totalOperatingCosts === 'string'
      ? parseFloat(totalOperatingCosts.replace(/,/g, ''))
      : Number(totalOperatingCosts);

  const totalCost = costOfGoodsSoldValue + totalOperatingCostsValue;
  const grossProfit = totalRevenueValue - totalCost;

  const netProfitMargin = (grossProfit / totalRevenueValue) * 100;

  return netProfitMargin.toFixed(2).replace(/[.,]00$/, '');
};

export const requiredErrorMessage = 'Field cannot be blank';
export const positiveValueErrorMessage = 'Field cannot be negative';
