import Flex from 'quarks/Flex';
import Image from 'quarks/Image';
import Link from 'quarks/Link';

import { optimizeBuilderImage } from 'utils/functions';

import type { Builder_Integration } from 'graphqlTypes';
import type { FC } from 'react';

interface CarouselCardProps {
  integration: {
    value: Builder_Integration;
  };
}

const CarouselCard: FC<CarouselCardProps> = ({ integration }) => {
  const int = integration?.value?.data;

  return (
    <Link href="/integrations/">
      <Flex
        backgroundColor="gray-50"
        paddingAll={8}
        marginX={8}
        marginY={4}
        borderRadius="12px"
        boxShadow="md"
        hover={{
          boxShadow: 'lg',
        }}
      >
        {int?.brandLogo && (
          <Image
            height="64px"
            image={optimizeBuilderImage(int?.brandLogo)}
            alt={int?.name || 'integration image logo'}
          />
        )}
      </Flex>
    </Link>
  );
};

export default CarouselCard;
