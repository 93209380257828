import Text from 'quarks/Text';

const colorizeText = (text: string, backgroundIsDark: boolean | { backgroundIsDark: boolean }) => {
  if (!text) {
    return;
  }

  return (
    <Text textStyle="eyebrow" gradient={backgroundIsDark ? 'lineupGradient-darkMode' : 'lineupGradient-lightMode'}>
      {text}
    </Text>
  );
};

export default colorizeText;
