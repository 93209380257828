import { BuilderComponent, builder } from '@builder.io/react';
import { graphql } from 'gatsby';
import { Suspense } from 'react';
import customComponentsRegistration from 'templates/componentGenerator';

import AuthorDetail from 'components/AuthorDetail/AuthorDetail';
import Footer from 'components/Footer/Footer';
import Header from 'components/Header/Header';
import AuthorDetailHero from 'components/Hero/HeroAuthorDetail/HeroAuthorDetail';
import Main from 'components/Main';
import SEO from 'components/seo';

import type { Query } from 'graphqlTypes';
import type { FC } from 'react';
import type { IHead } from 'templates/BuilderPage';

builder.init(process.env.GATSBY_BUILDER_KEY || '');

const AuthorTemplate = (query: { data: Query }) => {
  const {
    data: {
      allBuilderModels: { oneAuthor, oneGlobalBanner, oneComponentHeader: header, oneComponentFooter: footer },
    },
  } = query;

  const authorName = oneAuthor?.content?.name;
  const author = oneAuthor?.content;
  const { banner } = { ...oneAuthor?.data };
  const globalBanner = oneGlobalBanner?.data;

  const isCustomBanner = banner?.customBanner === true;
  const isGlobalBanner = globalBanner?.showBanner === true;
  const isHideBanner = banner?.hideBanner === true;

  customComponentsRegistration();

  return (
    <Suspense fallback>
      {header?.content && (
        <Header
          {...header.content.data}
          banner={(isCustomBanner && !isHideBanner && banner) || (isGlobalBanner && !isHideBanner && globalBanner)}
        />
      )}
      <Main>
        <AuthorDetailHero authorName={authorName} authorInfo={author} />
        <AuthorDetail postsPerPage={8} blogCardsHeadingType="h2" authorName={authorName} />
        <BuilderComponent
          model="author"
          data={{ template: 'author' }}
          content={author}
          options={{ includeRefs: true, cachebust: true }}
        />
      </Main>
      {footer?.content && <Footer isDark={true} content={footer.content.data} />}
    </Suspense>
  );
};

export default AuthorTemplate;

export const Head: FC<IHead> = ({
  data: {
    allBuilderModels: { oneAuthor },
  },
  location,
}) => (
  <>
    {oneAuthor && (
      <SEO
        title={oneAuthor?.data?.metaTitle || oneAuthor?.content?.name}
        description={oneAuthor?.data?.metaTitle || oneAuthor?.data?.metaDescription}
        image={oneAuthor?.data?.ogImage}
        location={location}
        indexable={!oneAuthor?.data?.noIndex || true}
      />
    )}
  </>
);

export const authorQuery = graphql`
  query getAuthors($path: String!) {
    allBuilderModels {
      oneAuthor(target: { urlPath: $path }, options: { includeRefs: true, cachebust: true }) {
        content
        data {
          headshot
          authorsTitle
          badges
          socials
          company
          bio
          funFact
          noIndex
          banner
          metaTitle
          metaDescription
          ogImage
        }
      }
      oneGlobalBanner(
        options: { includeRefs: true, cachebust: true }
        target: { id: "b241c59d043b4cc7b0f608356bea42ef" }
      ) {
        data {
          showBanner
          body
          button
          heading
          includeIcon
        }
      }
      oneComponentHeader(
        options: { includeRefs: true, cachebust: true }
        target: { id: "8fe1129198a9478c9cc7346a40a63805" }
      ) {
        content
      }
      oneComponentFooter(
        options: { includeRefs: true, cachebust: true }
        target: { id: "a7c491a16336403c94432e7b3ebb419b" }
      ) {
        content
      }
    }
  }
`;
