import { graphql, useStaticQuery } from 'gatsby';

const useBlogCategories = () => {
  const data = useStaticQuery(graphql`
    query AllBlogCategories {
      allBuilderModels {
        blogPostCategory {
          data {
            title
            slug
          }
          id
        }
      }
    }
  `);

  return data.allBuilderModels.blogPostCategory;
};

export default useBlogCategories;
