import { iconValues } from 'atoms/Icons/Icon.types';
import type { iconIds } from 'atoms/Icons/Icon.types';

import type { BasicProps } from 'quarks/interpolations/basic';

import SectionBuilderConfig from 'molecules/Section/Section.config';

import { doodleField } from 'utils/doodleGenerator';
import type { DoodleKey } from 'utils/doodleGenerator';
import type { Writeable } from 'utils/typeUtils';

type removeReadOnlyModifier = Writeable<typeof iconValues>;

const VerticalSwitcherBuilderConfig = {
  name: 'VerticalSwitcher',
  inputs: [
    { name: 'internalName', type: 'text' },
    { name: 'background', type: 'text', enum: ['White', 'Gray-900'] },
    { name: 'coloredEyebrow', type: 'boolean', helperText: 'Set to true if you want your heading colorized' },
    { name: 'eyebrow', type: 'text' },
    { name: 'heading', type: 'text' },
    { name: 'subheading', type: 'richText' },
    { name: 'assetType', type: 'text', enum: ['Image', 'Animation', 'featureAssets'], defaultValue: 'Image' },
    {
      name: 'switcherImage',
      type: 'file',
      allowedFileTypes: ['jpeg', 'png', 'jpg', 'svg'],
      showIf: (options: { get: (arg0: string) => string }) => options.get('assetType') === 'Image',
    },
    {
      name: 'imageCaption',
      type: 'text',
      helperText: 'Caption for an Image',
      showIf: (options: { get: (arg0: string) => string }) => options.get('assetType') === 'Image',
    },
    {
      name: 'lottieFile',
      type: 'file',
      allowedFileTypes: ['lottie', 'json'],
      helperText: 'Only accepts .json files',
      showIf: (options: { get: (arg0: string) => string }) => options.get('assetType') === 'Animation',
    },
    {
      name: 'ariaLabel',
      type: 'text',
      helperText: 'Aria Label for the Lottie',
      showIf: (options: { get: (arg0: string) => string }) => options.get('assetType') === 'Animation',
    },
    ...doodleField,
    {
      name: 'features',
      type: 'list',
      subFields: [
        {
          name: 'title',
          type: 'text',
        },
        {
          name: 'description',
          type: 'text',
        },
        {
          name: 'icon',
          type: 'text',
          enum: iconValues as removeReadOnlyModifier,
        },

        {
          name: 'featuredImage',
          type: 'object',
          subFields: [
            {
              name: 'featureAssetType',
              type: 'text',
              enum: ['Image'],
              defaultValue: 'Image',
              helperText: 'Make sure the value of assetType is set to featureAssets in order to utilize these fields.',
            },
            {
              name: 'featuredImage',
              type: 'file',
              allowedFileTypes: ['jpeg', 'png', 'jpg'],
              showIf: "options.get('featureAssetType') === 'Image'",
            },
            {
              name: 'imageCaption',
              type: 'text',
              helperText: 'Caption for an Image',
              showIf: "options.get('featureAssetType') === 'Image'",
            },
          ],
        },
      ],
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onChange: options => {
        if (options.get('features').length > 5) {
          options.set('features', options.get('features').slice(0, 5));
          alert('Only five items allowed.');
        }
      },
    },
    ...SectionBuilderConfig.inputs,
  ],
};

export interface VerticalSwitcherProps extends BasicProps {
  assetType?: 'Image' | 'Animation' | 'featureAssets';
  eyebrow?: string;
  coloredEyebrow?: boolean;
  heading?: string;
  doodle?: DoodleKey;
  subheading?: string;
  background?: string;
  switcherImage?: string;
  features?: Array<{
    featureAssetType?: 'Image';
    title?: string;
    description?: string;
    icon?: iconIds;
    featuredImage?: { featuredImage?: string; imageCaption?: string };
  }>;
  imageCaption?: string;
  lottieFile?: string;
  ariaLabel?: string;
  sectionId?: string;
}

export default VerticalSwitcherBuilderConfig;
