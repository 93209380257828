import { useMemo } from 'react';

import darkLogo from 'assets/logo/logo-dark.webp';
import lightLogo from 'assets/logo/logo-light.webp';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Image from 'quarks/Image';
import Link from 'quarks/Link';
import Paragraph from 'quarks/Paragraph';
import Text from 'quarks/Text';
import type { BasicProps } from 'quarks/interpolations/basic';

// import Form from 'components/HsForm/HsForm';

import { getLinkHierarchy } from 'utils/functions';

import type { Builder_ComponentFooterData, Builder_ComponentMenuData, Builder_MenuItemData, Maybe } from 'graphqlTypes';
import type { FC } from 'react';

interface FooterProps extends BasicProps {
  isDark?: Maybe<boolean>;
  content?: Maybe<Builder_ComponentFooterData>;
  isLandingPage?: boolean;
}

const Footer: FC<FooterProps> = ({ isDark, content, isLandingPage }) => {
  const background = isDark ? 'gray-900' : 'common-white';
  const textColor = isDark ? 'gray-200' : 'gray-800';

  const menuItemsData = useMemo(
    () =>
      content?.menuItems &&
      content.menuItems.map(
        (menu: { menuItem: { value: { data: Builder_ComponentMenuData } } }) => menu?.menuItem?.value?.data,
      ),
    [content?.menuItems],
  );

  const location = 'Footer';

  return (
    <Flex
      as="footer"
      width="100%"
      backgroundColor={background}
      textColor={textColor}
      paddingX={24}
      paddingY={48}
      paddingTop={isLandingPage && 64}
      md={{
        paddingY: 64,
        paddingX: isLandingPage && 112,
      }}
    >
      <Container
        width="100%"
        marginX="auto"
        lg={{
          maxWidth: isLandingPage ? '1124px' : '1170px',
        }}
      >
        <Flex flexDirection="column" gap={!isLandingPage && '32px'}>
          <Flex
            flexDirection="column"
            gap={isLandingPage ? '24px' : '32px'}
            alignItems="center"
            justifyContent="space-between"
            md={{ flexDirection: 'row', alignItems: 'flex-start' }}
          >
            <Flex flexDirection="column" gap={isLandingPage ? '16px' : '24px'}>
              <Image maxWidth="144px" image={isDark ? darkLogo : lightLogo} alt="Lineup.ai logo" />
              <Paragraph
                fontFamily="textFont"
                fontSize="textMd"
                fontWeight="semiBold"
                maxWidth="148px"
                textAlign="center"
                textColor={isDark ? 'gray-200' : 'gray-800'}
                md={{ textAlign: 'left' }}
              >
                {content?.address}
              </Paragraph>
            </Flex>
            {/* <Form
              isDark={isDark}
              subHeading={content?.newsletterText}
              formId="Subscribe Form | c9325cee-6d27-41b9-9b9d-731d8cf0cf0e"
              submitButtonText={undefined}
              variant="Single Field"
              minWidth="100%"
              md={{
                minWidth: 'calc(50% - 32px)',
              }}
              lg={{
                minWidth: '370px',
              }}
              trackingSchema={{
                location,
              }}
            /> */}
          </Flex>
          <Flex
            gap="32px"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="flex-start"
            textAlign="left"
            sm={{ flexDirection: 'row', flexWrap: 'wrap' }}
          >
            {useMemo(
              () =>
                !isLandingPage &&
                menuItemsData &&
                menuItemsData?.map((menu: Builder_ComponentMenuData, idx: number) => (
                  <Flex
                    key={menu?.menuTitle || `${menu?.internalName}-${Math.floor(idx)}`}
                    gap="16px"
                    flexDirection="column"
                    flex="0 100%"
                    sm={{ flex: '0 45%' }}
                    lg={{ flex: '0 20%' }}
                    xl={{ minWidth: '260px' }}
                  >
                    <Text
                      fontFamily="textFont"
                      fontSize="textLg"
                      fontWeight="bold"
                      textColor={isDark ? 'gray-200' : 'gray-800'}
                    >
                      {menu?.menuTitle}
                    </Text>
                    <Flex flexDirection="column" gap="8px">
                      {menu?.menuItems?.map((item: { menuLinks: Builder_MenuItemData }, id: number) => (
                        <Link
                          key={item?.menuLinks?.menuLabel || `${item?.menuLinks?.internalName}-${Math.floor(id)}`}
                          href={item?.menuLinks?.menuLink || '/'}
                          target={item?.menuLinks?.openInNewTab ? '_blank' : ''}
                          width="fit-content"
                          trackingSchema={{
                            label: item?.menuLinks?.menuLabel,
                            hierarchy: getLinkHierarchy(menu?.menuItems, id),
                            location,
                          }}
                        >
                          <Text
                            fontFamily="textFont"
                            fontSize="textLg"
                            fontWeight="regular"
                            textColor={isDark ? 'gray-200' : 'gray-700'}
                            hover={{
                              textColor: isDark ? 'primary-200' : 'primary-700',
                            }}
                          >
                            {item?.menuLinks?.menuLabel}
                          </Text>
                        </Link>
                      ))}
                    </Flex>
                  </Flex>
                )),
              [isDark, content?.menuItems],
            )}
          </Flex>

          <Flex
            justifyContent="space-between"
            flexDirection="column"
            gap="24px"
            marginTop={32}
            paddingTop={32}
            borderTop="1px solid"
            borderTopColor={isDark ? 'gray-600' : 'gray-400'}
            md={{
              marginTop: isLandingPage && 48,
            }}
          >
            <Flex gap="24px" justifyContent="center" sm={{ justifyContent: 'left' }}>
              {useMemo(
                () =>
                  content?.socialMediaLinks &&
                  content.socialMediaLinks.map(
                    (social: { internalName: string; link: string; icon: string }, idx: number) => (
                      <Link
                        key={`${social?.internalName}-${Math.floor(idx)}`}
                        href={social?.link || 'https://lineup.ai'}
                        target="_blank"
                        trackingSchema={{
                          label: social?.internalName,
                          hierarchy: getLinkHierarchy(content.socialMediaLinks, idx),
                          location,
                        }}
                        hover={{
                          opacity: 0.7,
                        }}
                      >
                        <Image image={social?.icon} alt="Lineup.ai socials" />
                      </Link>
                    ),
                  ),
                [content?.socialMediaLinks],
              )}
            </Flex>
            <Flex
              gap="8px"
              flexDirection="column"
              lg={{ flexDirection: 'row', alignItems: 'center', marginX: 0, justifyContent: 'space-between' }}
            >
              <Text
                fontFamily="textFont"
                fontSize="textMd"
                fontWeight="semiBold"
                textAlign="center"
                textColor={isDark ? 'gray-500' : 'gray-600'}
                sm={{ textAlign: 'left' }}
              >
                {content?.copyrightText}
              </Text>
              <Flex
                flexWrap="wrap"
                justifyContent="center"
                alignItems="center"
                columnGap="8px"
                sm={{ justifyContent: 'flex-start', columnGap: '12px' }}
              >
                {useMemo(
                  () =>
                    !isLandingPage &&
                    content?.footerLinks &&
                    content.footerLinks.map((link: { footerLink: Builder_MenuItemData }, idx: number) => (
                      <>
                        <Link
                          key={link?.footerLink?.menuLabel || `${link?.footerLink?.internalName}-${Math.floor(idx)}`}
                          href={link?.footerLink?.menuLink || 'https://lineup.ai'}
                          target={link?.footerLink?.openInNewTab ? '_blank' : ''}
                          trackingSchema={{
                            label: link?.footerLink?.menuLabel && link?.footerLink?.menuLabel,
                            hierarchy: getLinkHierarchy(content.footerLinks, idx),
                            location,
                          }}
                        >
                          <Text
                            fontFamily="textFont"
                            fontSize="textMd"
                            fontWeight="semiBold"
                            textColor={isDark ? 'gray-500' : 'gray-600'}
                            hover={{
                              textColor: isDark ? 'primary-200' : 'primary-700',
                            }}
                          >
                            {link?.footerLink?.menuLabel?.replace(/\|$/, '')}
                          </Text>
                        </Link>
                        <Flex
                          border={idx !== content?.footerLinks.length - 1 && '1px solid'}
                          borderColor={isDark ? 'gray-500' : 'gray-600'}
                          height="16px"
                        />
                      </>
                    )),
                  [isDark, content?.footerLinks],
                )}
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Container>
    </Flex>
  );
};

export default Footer;
