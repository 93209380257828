import { graphql, useStaticQuery } from 'gatsby';

const useSupportPosts = () => {
  const data = useStaticQuery(graphql`
    query AllSupports {
      allBuilderModels {
        support(options: { includeRefs: true, cachebust: true }, limit: 1000) {
          data {
            category
            featuredImage
            featuredImageCaption
            title
            url
            description
            blocks
          }
          id
        }
      }
    }
  `);

  return data.allBuilderModels.support;
};

export default useSupportPosts;
