import type { FlexProps } from 'quarks/interpolations/flex';
import type { HeadingTypes } from 'quarks/styleProps/heading';

import SectionBuilderConfig from 'molecules/Section/Section.config';

import type { Builder_BlogPostCategoryData, Builder_PostData } from 'graphqlTypes';

export interface BlogListingProps extends FlexProps {
  postsPerPage: number;
  featureCardHeadingType?: HeadingTypes;
  blogCardsHeadingType: HeadingTypes;
  sectionId?: string;
}

export interface BlogPost {
  data: Builder_PostData;
  id: string;
}

export interface BlogCategory {
  data: Builder_BlogPostCategoryData;
  id: string;
}

export const BlogListingConfig = {
  name: 'BlogListing',
  inputs: [
    { name: 'featureCardHeadingType', type: 'text', enum: ['h2', 'h3', 'h4', 'h5', 'h6'], defaultValue: 'h2' },
    { name: 'blogCardsHeadingType', type: 'text', enum: ['h2', 'h3', 'h4', 'h5', 'h6'], defaultValue: 'h2' },
    {
      name: 'postsPerPage',
      type: 'number',
      defaultValue: 8,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onChange: options => {
        if (!options.get('postsPerPage')) {
          return 8;
        }

        options.set('postsPerPage', Math.floor(options.get('postsPerPage')));
      },
    },
    ...SectionBuilderConfig.inputs,
  ],
};

export default BlogListingConfig;
