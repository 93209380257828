import { useMemo, useState } from 'react';

import Icon from 'atoms/Icons/Icon';
import type { iconIds } from 'atoms/Icons/Icon.types';
import container from 'atoms/spacing/containers';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Grid from 'quarks/Grid';
import Heading from 'quarks/Heading';
import Text from 'quarks/Text';
import type { BasicProps } from 'quarks/interpolations/basic';

import Overlay from 'components/ProgressBarSwitcher/Overlay';
import ProgressBarButton from 'components/ProgressBarSwitcher/ProgressBarButton';
import ProgressBarSlide from 'components/ProgressBarSwitcher/ProgressBarSlide';

import { getBackgroundIsDark } from 'utils/functions';
import onKeyDown from 'utils/onKeyDown';

import type { FC } from 'react';

export interface SlideProps {
  eyebrow?: string;
  heading?: string;
  subheading?: string;
  assetType?: 'Image' | 'Lottie';
  slideImage?: string;
  lottieFile?: string;
  animationLength?: number;
  id?: string;
}

interface ProgressBarSwitcherProps extends BasicProps {
  background?: 'White' | 'Gray-900';
  slides: SlideProps[];
}

const ProgressBarSwitcher: FC<ProgressBarSwitcherProps> = ({ background = 'White', slides }) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [isPlaying, setIsPlaying] = useState(true);
  const [isFinished, setIsFinished] = useState(false);

  const rowSize = {
    mobile: '264px',
    desktop: '370px',
  };

  const restart = () => {
    setActiveSlide(0);
    setIsPlaying(true);
    setIsFinished(false);
  };

  const pause = () => {
    setIsPlaying(false);
    setIsFinished(false);
  };

  const play = () => {
    setIsPlaying(true);
    setIsFinished(false);
  };

  const [icon, trigger] = useMemo(() => {
    if (isPlaying && !isFinished && activeSlide < slides.length) {
      return ['pause-circle', pause];
    } else if (!isPlaying && !isFinished && activeSlide < slides.length) {
      return ['play', play];
    } else {
      return ['refresh-ccw-01', restart];
    }
  }, [activeSlide, isPlaying]);

  const backgroundColor = background === 'Gray-900' ? 'gray-900' : 'common-white';
  const backgroundIsDark = getBackgroundIsDark(backgroundColor);

  return (
    <Flex
      flexDirection="column"
      overflowX="hidden"
      gap="56px"
      position="relative"
      paddingY={40}
      paddingX={24}
      backgroundColor={backgroundColor}
      sm={{
        paddingTop: 58,
      }}
      lg={{
        paddingY: 72,
      }}
      xl={{
        paddingY: 96,
      }}
    >
      <Overlay mobileSize={rowSize.mobile} desktopSize={rowSize.desktop} isBackgroundDark={backgroundIsDark} />
      <Flex
        flexDirection="column"
        maxWidth={container.sm}
        xs={{
          maxWidth: `${container.md}`,
        }}
        xl={{
          maxWidth: `${container.lg}`,
        }}
        width="100%"
        marginY={0}
        marginX="auto"
        gap="6px"
        position="relative"
      >
        <Container
          display="none"
          position="absolute"
          left={0}
          height="40px"
          width="40px"
          borderRadius="30px"
          border="1px solid"
          borderColor="gray-300"
          boxShadow="xs"
          as="button"
          tabIndex={0}
          backgroundColor="common-white"
          sm={{
            display: 'block',
            top: '-50px',
          }}
          lg={{
            top: '-60px',
          }}
          focusVisible={{
            boxShadow: 'focused',
          }}
        >
          <Icon
            id={icon as iconIds}
            width="100%"
            height="100%"
            justifyContent="center"
            alignItems="center"
            iconColor="primary-600"
            onClick={trigger}
            onKeyDown={e => onKeyDown(e, () => trigger())}
          />
        </Container>
        {slides &&
          slides.map(
            ({ eyebrow }, index) =>
              index === activeSlide && (
                <>
                  {eyebrow && (
                    <Text textStyle="eyebrow" textColor={backgroundIsDark ? 'common-white' : 'gray-700'}>
                      {eyebrow}
                    </Text>
                  )}
                </>
              ),
          )}
        <Grid gridTemplateColumns={`repeat(${slides.length}, 1fr)`} gap="12px">
          {slides &&
            slides.map(({ assetType, animationLength, eyebrow, id }, index) => (
              <ProgressBarButton
                key={id || index}
                assetType={assetType}
                animationLength={animationLength}
                eyebrow={eyebrow}
                index={index}
                activeSlide={activeSlide}
                setActiveSlide={setActiveSlide}
                isPlaying={isPlaying}
                setIsPlaying={setIsPlaying}
                setIsFinished={setIsFinished}
                length={slides.length}
              />
            ))}
        </Grid>
        {slides &&
          slides.map(
            ({ heading, subheading }, index) =>
              index === activeSlide && (
                <>
                  {heading && (
                    <Heading
                      gradient="lineupGradient-lightMode"
                      fontSize="displaySm"
                      sm={{ fontSize: 'displayMd' }}
                      width="fit-content"
                      marginAll={0}
                    >
                      {heading}
                    </Heading>
                  )}
                  {subheading && (
                    <Text
                      maxWidth={container.md}
                      textColor={backgroundIsDark ? 'common-white' : 'gray-700'}
                      textStyle="md"
                      sm={{
                        textStyle: 'lg',
                      }}
                      lg={{
                        textStyle: 'xl',
                      }}
                    >
                      {subheading}
                    </Text>
                  )}
                </>
              ),
          )}
      </Flex>
      <Container
        maxWidth={container.sm}
        height={rowSize.mobile}
        width="100%"
        marginY={0}
        marginX="auto"
        overflowX="visible"
        position="relative"
        zIndex={0}
        xs={{
          maxWidth: `${container.md}`,
        }}
        sm={{
          height: `${rowSize.desktop}`,
        }}
        xl={{
          maxWidth: `${container.lg}`,
        }}
      >
        {slides &&
          slides.map(({ lottieFile, slideImage, id }, index) => (
            <ProgressBarSlide
              key={id || index}
              index={index}
              lottieFile={lottieFile}
              slideImage={slideImage}
              rowSize={rowSize}
              activeSlide={activeSlide}
            />
          ))}
      </Container>
    </Flex>
  );
};

export default ProgressBarSwitcher;
