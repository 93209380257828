import { graphql, useStaticQuery } from 'gatsby';

const useBlogAuthors = () => {
  const data = useStaticQuery(graphql`
    query AllBlogAuthors {
      allBuilderModels {
        author {
          name
          data {
            bio
            headshot
            url
          }
        }
      }
    }
  `);
  if (data.allBuilderModels.author) {
    return data.allBuilderModels.author.sort((a: { name: string }, b: { name: string }) =>
      a.name.localeCompare(b.name),
    );
  } else {
    return null;
  }
};

export default useBlogAuthors;
