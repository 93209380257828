import SectionBuilderConfig from 'molecules/Section/Section.config';

import { ButtonBuilderConfig } from 'components/ComponentButton/buttonTypeConfig';

const integrationsCarouselBuilderDataField = {
  name: 'IntegrationsCarousel',
  inputs: [
    {
      name: 'inverseMovementDirection',
      type: 'boolean',
    },
    {
      name: 'alignment',
      type: 'text',
      enum: ['vertical', 'horizontal'],
      helpText: 'Indicate whether the desktop component should be stacked vertically or horizontally.',
      defaultValue: 'vertical',
    },
    {
      name: 'eyebrow',
      type: 'text',
      defaultValue: 'Integrations',
    },
    {
      name: 'heading',
      type: 'string',
    },
    {
      name: 'description',
      type: 'string',
    },
    {
      name: 'integrations',
      type: 'list',
      helperText:
        'Indicate the integrations to be displayed. The integrations will be split between the top and bottom rows. MINIMUM OF 8 REQUIRED.',
      subFields: [
        {
          name: 'integration',
          type: 'reference',
          model: 'integration',
        },
      ],
    },
    {
      name: 'buttons',
      type: 'list',
      subFields: ButtonBuilderConfig.inputs,
      helperText: 'For the CTA to render below description ',
    },
  ],
  ...SectionBuilderConfig.inputs,
};

export default integrationsCarouselBuilderDataField;
