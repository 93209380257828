import color from 'atoms/colors/colors';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Heading from 'quarks/Heading';
import Text from 'quarks/Text';

import TextField from 'molecules/TextField/TextField';
import Wrapper from 'molecules/Wrapper/Wrapper';

import { dollarIcon, errorMessage } from 'components/ForecastCalculator/CalculatorDataAndTypes';
import type { CalculatorData, HandleInputChange, RowData } from 'components/ForecastCalculator/CalculatorDataAndTypes';

import type { FC } from 'react';

interface CalculatorRowMobileProps {
  rowTitle: string;
  rowContent: Record<RowData[string], RowData | string | number>;
  handleInputChange: HandleInputChange;
  mobileData: CalculatorData;
  formatInput: HandleInputChange;
  calculatorData: CalculatorData;
  calculatePressed: boolean;
}

const CalculatorRowMobile: FC<CalculatorRowMobileProps> = ({
  rowTitle,
  rowContent,
  handleInputChange,
  mobileData,
  formatInput,
  calculatorData,
  calculatePressed,
}) => (
  <Flex flexDirection="column" backgroundColor="common-white">
    <Container backgroundColor="primary-50">
      <Wrapper paddingY={12}>
        <Heading as="h2" textStyle="xs" textColor="primary-700">
          {rowTitle}
        </Heading>
      </Wrapper>
    </Container>
    {rowContent &&
      Object.keys(rowContent).map(day => (
        <Flex
          key={day}
          css={`
            &:nth-of-type(even) {
              background: ${color.softWhite[50]};
            }
          `}
        >
          <Wrapper>
            <Flex flexDirection="row" justifyContent="space-between" paddingY={16}>
              <Text textStyle="lg">{day}</Text>
              <TextField
                startIcon={dollarIcon}
                type="number"
                value={mobileData[rowTitle][day] as string}
                placeholder="1000.00"
                onChange={e => handleInputChange(day, rowTitle, e)}
                onBlur={e => formatInput(day, rowTitle, e)}
                maxWidth="152px"
                marginLeft={24}
                error={calculatePressed && calculatorData[day][rowTitle] === ''}
                errorMessage={errorMessage}
              />
            </Flex>
          </Wrapper>
        </Flex>
      ))}
  </Flex>
);

export default CalculatorRowMobile;
