import type { BasicProps } from 'quarks/interpolations/basic';

import type { Builder_EntityPersonData } from 'graphqlTypes';

export interface TeamCardProps extends BasicProps {
  internalName?: string;
  quote?: { question: string; answer: string };
  backgroundIsDark?: boolean;
  person?: { value: { data: Builder_EntityPersonData } };
}

export const teamCardsBuilderDataField = [
  {
    name: 'teamCards',
    type: 'list',
    subFields: [
      {
        name: 'quote',
        type: 'object',
        subFields: [
          { name: 'internalName', type: 'text', helperText: 'Used as an ID, must be unique.', required: true },
          { name: 'question', type: 'text' },
          { name: 'answer', type: 'text' },
        ],
      },
      { name: 'person', type: 'reference', model: 'entity-person' },
    ],
    showIf: (options: { get: (arg0: string) => string }) => options.get('cardContentType') === 'Team Cards',
  },
];
