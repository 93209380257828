import styled from 'styled-components';

import type { BasicProps } from 'quarks/interpolations/basic';
import { basic } from 'quarks/interpolations/basic';

import type { ButtonTrackingSchema } from 'components/ComponentButton/buttonTypeConfig';

import parseUrl from 'utils/parseUrl';

import type { AnchorHTMLAttributes, FC, ReactNode } from 'react';

const StyledLink = styled.a`
  cursor: pointer;
  ${basic}
`;

interface LinkProps extends AnchorHTMLAttributes<HTMLAnchorElement>, BasicProps {
  /**
   *Takes a full url string. Internal links will be parsed as a `<Link>` and external links will be an `<a>` that safely opens in a new tab.
   */
  href?: string;
  /**
   * The string or element within the link.
   */
  children: ReactNode;
  trackingSchema?: ButtonTrackingSchema;
}

const Link: FC<LinkProps> = ({ children, href, trackingSchema, ...props }) => {
  const handleClick = () => {
    window.analytics.track(
      'Link Clicked',
      {
        label: trackingSchema?.label || 'Unnamed Link',
        location: trackingSchema?.location ? trackingSchema.location : 'Unnamed Component',
        url: window.location.href,
        hierarchy: trackingSchema?.hierarchy ? trackingSchema.hierarchy : 'Unknown Location',
      },
      {
        context: {
          traits: window.analytics.user().traits(),
        },
      },
    );
  };

  return (
    // @ts-expect-error If href is not truthy, styled link will return a div. In node_modules/typescript/lib/lib.dom.d.ts:6327:5, prop align for whatever reason is required.
    <StyledLink {...parseUrl(href)} onClick={handleClick} {...props}>
      {children}
    </StyledLink>
  );
};

export default Link;
