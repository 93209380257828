import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Grid from 'quarks/Grid';
import Heading from 'quarks/Heading';
import Image from 'quarks/Image';
import Paragraph from 'quarks/Paragraph';
import Text from 'quarks/Text';
import type { BasicProps } from 'quarks/interpolations/basic';

import Badge from 'molecules/Badge/Badge';
import Breadcrumbs from 'molecules/Breadcrumbs/Breadcrumbs';
import Section from 'molecules/Section/Section';

import doodleGenerator from 'utils/doodleGenerator';
import { optimizeBuilderImage } from 'utils/functions';

import type { Builder_GatedResourceData } from 'graphqlTypes';
import type { FC } from 'react';

type HeroResource = BasicProps & {
  resource?: { data: Builder_GatedResourceData };
  playbook?: boolean | null;
};

const ResourceHero: FC<HeroResource> = ({ resource, playbook }) => {
  const { category, title, featuredImage, featuredImageCaption, description } = { ...resource?.data };
  const pathname = 'resources/' + title;

  const leftDoodle = doodleGenerator('whisk');
  const rightDoodle = doodleGenerator('swirl');

  const doodleProps = {
    opacity: '1',
    position: 'absolute',
    alt: 'doodle',
    zIndex: '-1',
  } as const;

  return (
    <Section __typename="Resource-Hero" background="primary-25" playbookHero={playbook}>
      <Container position="relative" paddingTop={24}>
        <Container display="none" md={{ display: 'block' }}>
          {leftDoodle && (
            <Image
              height="545px"
              width="582px"
              transform="rotate(307deg)"
              bottom="-92px"
              left="-43%"
              image={leftDoodle}
              {...doodleProps}
              xl={{}}
            />
          )}
          {rightDoodle && (
            <Image
              height="400px"
              width="510px"
              bottom="60px"
              right="-30%"
              transform="rotate(166deg)"
              image={rightDoodle}
              {...doodleProps}
            />
          )}
        </Container>
        <Container
          paddingLeft={4}
          marginBottom={20}
          md={{ marginBottom: 32 }}
          xl={{ marginBottom: 58 }}
          position="relative"
        >
          <Breadcrumbs url={pathname} />
        </Container>
        <Grid
          gridTemplateColumns="1fr"
          rowGap="40px"
          md={{ rowGap: '64px' }}
          xl={{ gridTemplateColumns: 'repeat(2, 1fr)', columnGap: '30px' }}
        >
          <Flex flexDirection="column" gap="24px">
            {category && (
              <Badge size="sm" fontWeight="medium" textColor="irisBlue-900" backgroundColor="irisBlue-50">
                <Paragraph>{category?.value?.name}</Paragraph>
              </Badge>
            )}
            <Flex flexDirection="column" gap="24px">
              {title && (
                <Heading
                  as="h1"
                  textColor="gray-900"
                  fontWeight="semiBold"
                  textStyle="sm"
                  md={{ textStyle: 'lg', fontWeight: 'semiBold' }}
                >
                  {title}
                </Heading>
              )}
              {description && (
                <Text textColor="gray-700" textStyle="lg">
                  {description}
                </Text>
              )}
            </Flex>
          </Flex>
          <Flex>
            {featuredImage && (
              <Flex flex="1 0 50%">
                <Container marginX="auto" width="fit-content" maxWidth="100%">
                  <Image
                    image={optimizeBuilderImage(featuredImage)}
                    loading="eager"
                    alt={featuredImageCaption || 'Blog post Featured Image'}
                    objectPosition="center"
                    borderRadius="9px"
                    maxWidth="inherit"
                    sm={{ borderRadius: '16px' }}
                  />
                </Container>
              </Flex>
            )}
          </Flex>
        </Grid>
      </Container>
    </Section>
  );
};

export default ResourceHero;
