import { useState } from 'react';

import { media } from 'atoms/breakpoints/breakpoints';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Image from 'quarks/Image';

import Section from 'molecules/Section/Section';

import ComponentHeading from 'components/Heading/Heading';
import SolutionsCard from 'components/SolutionsCardGrid/SolutionsCard';
import type { SolutionsCardGridProps } from 'components/SolutionsCardGrid/SolutionsCardGridTypes';

import doodleGenerator from 'utils/doodleGenerator';

import type { FC } from 'react';

const SolutionsCardGrid: FC<SolutionsCardGridProps> = ({
  doodle,
  cards,
  background,
  sectionId,
  useHeading,
  headingContent,
}) => {
  const doodleImage = doodle && doodleGenerator(doodle);

  const [activeCard, setActiveCard] = useState(0);
  const [cardHeight, setCardHeight] = useState(0);
  const cardLength = cards?.length || 1;

  const handleCardHeight = (height: number) => {
    height > cardHeight && setCardHeight(height);
  };

  return (
    <Section __typename="ComponentSolutionsCardGrid" background={background} id={sectionId}>
      {doodleImage && (
        <Image
          display="none"
          position="absolute"
          alt="doodle-image-card-deck"
          image={doodleImage}
          width="470px"
          height="295px"
          transform="rotate(2deg)"
          top="0px"
          right="0px"
          overflowX="hidden"
          zIndex={-1}
          md={{ display: 'block' }}
          xl={{
            top: '0px',
            right: '0px',
          }}
        />
      )}
      <Flex flexDirection="column" position="relative">
        {useHeading && <ComponentHeading background={background} {...headingContent} />}
        <Container
          position="relative"
          boxShadow="xl"
          borderRadius="16px"
          css={`
            padding-bottom: ${54 * (cardLength - 1)}px;
            ${media.md} {
              padding-bottom: 0;
            }
          `}
        >
          {cards?.map((card, index) => (
            <SolutionsCard
              key={card.id}
              {...card}
              index={index}
              activeCard={activeCard}
              minHeight={`${Math.max(cardHeight, 370)}px`}
              width="100%"
              left="0"
              top={`${index * 54}px`}
              boxShadow="xl"
              isLast={index === cardLength - 1}
              sm={{
                minHeight: `${Math.max(cardHeight, 300)}px`,
              }}
              md={{
                width: `calc(100% - ${54 * (cards.length - 1)}px)`,
                left: `${index * 54}px`,
                top: 0,
                minHeight: `${Math.max(cardHeight, 370)}px`,
                height: '100%',
                boxShadow: 'unset',
              }}
              lg={{
                width: `calc(100% - ${74 * (cards.length - 1)}px)`,
                left: `${index * 74}px`,
              }}
              onClick={setActiveCard}
              handleHeight={handleCardHeight}
            />
          ))}
        </Container>
      </Flex>
    </Section>
  );
};

SolutionsCardGrid.defaultProps = {
  background: 'Gray-100',
  cards: [
    {
      id: 'card-1',
      title: 'Solutions Card',
      description: '<p>Insert Subheading Here</p>',
    },
  ],
};

export default SolutionsCardGrid;
