import type { BasicProps } from 'quarks/interpolations/basic';

import type { PricingCardDeckProps } from 'components/PricingCardDeck/PricingCardDeckProps';

import type { DoodleKey } from 'utils/doodleGenerator';

import type { ReactNode } from 'react';

export interface HeroPricingHeadProps {
  background?: string;
  children: ReactNode;
}

export interface HeroPricingCopyProps extends BasicProps {
  backgroundIsDark: boolean;
  enableCenteredCopy?: boolean;
  eyebrow?: string;
  heading?: string;
  subheading?: string;
}

export interface HeroPricingCardsProps {
  isAnnual?: boolean;
  isCollapsible?: boolean;
  plans?: { plan: { value: { data: PricingCardDeckProps }; id: string } }[];
}

export interface PlanSwitcherProps {
  isAnnual?: boolean;
  discountText?: string;
  monthlySupplementText?: string;
  annualSupplementText?: string;
  onChangePlan: () => void;
}

export interface HeroPricingProps extends HeroPricingCopyProps {
  enablePlanSwitcher?: boolean;
  isAnnual?: boolean;
  background?: string;
  discountText?: string;
  monthlySupplementText?: string;
  annualSupplementText?: string;
  doodle?: DoodleKey;
  heroImage?: string;
  plans?: { plan: { value: { data: PricingCardDeckProps }; id: string } }[];
  sectionId?: string;
}

export const heroDoodleSpecs = {
  standard: {
    centeredDoodle: {
      width: '187px',
      height: '190px',
      top: '56px',
      left: '-74px',
    },
  },
  small: {
    centeredDoodle: {
      width: '256px',
      height: '252px',
    },
  },
  medium: {
    centeredDoodle: {
      width: '330px',
      height: '230px',
      left: '-100px',
      top: '130px',
    },
  },
  xLarge: {
    centeredDoodle: {
      width: '497px',
      height: '347px',
      left: '-125px',
      top: '196px',
    },
  },
} as const;
