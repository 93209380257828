import { useMediaQuery } from '@mui/material';

import { media } from 'atoms/breakpoints/breakpoints';
import colors from 'atoms/colors/colors';

import Flex from 'quarks/Flex';
import Text from 'quarks/Text';

import Switch from 'molecules/Switch/Switch';

import type { PlanSwitcherProps } from 'components/HeroPricing/heroTypesAndData';

import type { FC } from 'react';

const PlanSwitcher: FC<PlanSwitcherProps> = ({
  isAnnual,
  discountText,
  monthlySupplementText,
  annualSupplementText,
  onChangePlan,
}) => {
  const isTablet = useMediaQuery(media.md);

  return (
    <Flex flexDirection="column" alignItems="center" gap="20px" md={{ flexDirection: 'row' }}>
      <Flex
        justifyContent="center"
        alignItems="center"
        gap="16px"
        marginTop={16}
        position="relative"
        css={`
          &&& {
            & .MuiFormControlLabel-root {
              margin-right: -12px;
            }
          }
        `}
      >
        <Text textColor={isAnnual ? 'gray-700' : 'gray-900'} textStyle="md" fontWeight="semiBold">
          {monthlySupplementText || 'Monthly Agreement'}
        </Text>
        <Switch
          offBackgroundColor={colors.primary['100']}
          checked={isAnnual}
          label=""
          onChange={onChangePlan}
          keyDown={onChangePlan}
        />
        <Text textColor={isAnnual ? 'gray-900' : 'gray-700'} textStyle="md" fontWeight="semiBold">
          {annualSupplementText || 'Annual Agreement'}
        </Text>
        {isTablet && (
          <Flex
            paddingY={4}
            paddingX={12}
            backgroundColor="primary-50"
            borderRadius="16px"
            position="absolute"
            right="0"
            transform="translateX(148px)"
          >
            <Text textStyle="sm" fontWeight="regular" textColor="primary-700">
              {discountText || 'Get 20% Discount'}
            </Text>
          </Flex>
        )}
      </Flex>
      {!isTablet && (
        <Flex paddingY={4} paddingX={12} backgroundColor="primary-50" borderRadius="16px" width="fit-content">
          <Text textStyle="sm" fontWeight="regular" textColor="primary-700">
            {`Get ${
              discountText ? discountText.split(' ').find(w => w.trim().includes('%')) : '20%'
            } discount with annual plan`}
          </Text>
        </Flex>
      )}
    </Flex>
  );
};

export default PlanSwitcher;
