/* eslint-disable react/no-array-index-key */

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Image from 'quarks/Image';
import Link from 'quarks/Link';
import Text from 'quarks/Text';
import type { BasicProps } from 'quarks/interpolations/basic';

import { optimizeBuilderImage } from 'utils/functions';

import type { FC } from 'react';

interface MobileSubMenuItemMediaProps extends BasicProps {
  title?: string;
  description?: string;
  image?: string;
  imageCaption?: string;
  link?: string;
}

const SubMenuItemMedia: FC<MobileSubMenuItemMediaProps> = ({
  title,
  description,
  image,
  imageCaption,
  link,
  ...props
}) => {
  const optimizedImage = image && optimizeBuilderImage(image);

  return (
    <Container as="li" hover={{ backgroundColor: 'gray-50', borderRadius: '5px' }} {...props}>
      <Link
        href={link || ''}
        trackingSchema={{
          label: title,
          location: 'Submenu in Header',
        }}
      >
        <Flex gap="12px" alignItems="center">
          {optimizedImage && (
            <Image
              image={optimizedImage}
              alt={imageCaption || 'image-caption'}
              width="144px"
              height="92px"
              borderRadius="8px"
              objectFit="cover"
            />
          )}
          <Flex flexDirection="column" gap="4px">
            <Text textColor="gray-900" textStyle="md" fontWeight="semiBold">
              {title}
            </Text>
            <Text
              textOverflow="ellipsis"
              display="none"
              textColor="gray-700"
              textStyle="md"
              css={`
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
              `}
              sm={{ display: 'block' }}
            >
              {description}
            </Text>
          </Flex>
        </Flex>
      </Link>
    </Container>
  );
};

export default SubMenuItemMedia;
