import { useLocation } from '@reach/router';
import { useEffect, useState } from 'react';

import { stringToKebabCase } from 'utils/functions';

import type { UIEvent } from 'react';

const copyTextToClipboard = async (text: string) => {
  try {
    await navigator.clipboard.writeText(text);
  } catch {
    try {
      // for older browsers like IE
      document.execCommand('copy', true, text);
    } catch {
      return 'Failed to copy';
    }
  }

  return 'Link copied!';
};

/**
 * A hook that allows you to copy the page location to the user's clipboard
 * @param hash Optional hash id string to link directly to a specific section on the page
 * @returns An object with:
 * - `handleCopy`: the function to pass into event handlers
 * - `pseudoStyle`: an object with quark styles to pass into before/after pseudo-element to display the copy confirmation message
 */
const useSharePage = (hash?: string) => {
  const { href } = useLocation();
  const [copiedMessage, setCopiedMessage] = useState('');
  const copiedText = hash ? `${href}#${stringToKebabCase(hash || '')}` : href;

  useEffect(() => {
    if (!copiedMessage) {
      return;
    }
    const copiedTimeout = setTimeout(() => {
      setCopiedMessage('');
    }, 3500);

    return () => clearTimeout(copiedTimeout);
  }, [copiedMessage]);

  const handleCopy = async (e: Event | UIEvent) => {
    e.stopPropagation();
    setCopiedMessage(await copyTextToClipboard(copiedText));
  };

  const pseudoStyle = {
    content: copiedMessage || '',
    position: 'absolute',
    fontSize: 'textXs',
    top: copiedMessage ? '110%' : '50%',
    backgroundColor: 'common-transparent',
    textColor: { dark: 'green-300', light: 'green-900' },
    paddingY: 4,
    borderRadius: '8px',
    width: 'max-content',
    opacity: copiedMessage ? 1 : 0,
    transition: 'opacity 200ms cubic-bezier(0.68, -0.55, 0.27, 1.55), top 200ms cubic-bezier(0.68, -0.55, 0.27, 1.55)',
  } as const;

  return { handleCopy, pseudoStyle };
};

export default useSharePage;
