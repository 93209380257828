import { useMediaQuery } from '@mui/material';
import { navigate } from 'gatsby';
import { useLocation } from 'react-use';

import { media } from 'atoms/breakpoints/breakpoints';

import Flex from 'quarks/Flex';
import Paragraph from 'quarks/Paragraph';

import Button, { handleButtonTracking } from 'molecules/Button/Button';
import Select from 'molecules/Select/Select';

import type { postTypes } from 'components/Sidebar/SideBar';

import type { FC } from 'react';

export interface LegalLink {
  id: string;
  url?: string;
  title?: string;
}

export interface LegalSidebarProps {
  state: { template: postTypes; legalPages?: string };
}

const LegalSideBar: FC<LegalSidebarProps> = ({ state }) => {
  const { origin, pathname } = useLocation();
  const isDesktop = useMediaQuery(media.lg);
  const linkTree: LegalLink[] = state.legalPages ? JSON.parse(state.legalPages) : [];

  const path = pathname?.slice(0, -1);
  const activePath = linkTree.find(link => link.url === path);

  const handleChange = (label: string) => {
    const href = linkTree.find(link => link?.url === label)?.url as string;
    navigate(href);
  };

  const handleClick = (title?: string) => {
    const trackingScheme = {
      label: title,
      location: 'Legal Sidebar',
    };

    handleButtonTracking(trackingScheme);
  };

  return (
    <Flex
      position="static"
      flexDirection="column"
      gap="20px"
      height="fit-content"
      flex="0 0 25%"
      lg={{ position: 'sticky', top: '110px' }}
    >
      {isDesktop ? (
        <>
          <Paragraph textStyle="lg" fontWeight="semiBold" textColor="gray-900">
            Legal Documents
          </Paragraph>
          <Flex flexDirection="column" gap="6px">
            {linkTree.map((link: LegalLink) => (
              <Button
                key={link.id}
                href={`${origin}${link.url}`}
                justifyContent="flex-start"
                width="100%"
                paddingY={10}
                paddingX={14}
                borderRadius="6px"
                backgroundColor={link.url === path ? 'primary-50' : 'common-transparent'}
                fontSize="textMd"
                lineHeight="textMd"
                textColor={link.url === path ? 'primary-900' : 'gray-700'}
                fontWeight="medium"
                boxShadow="none"
                onClick={() => handleClick(link.title)}
                hover={{
                  backgroundColor: 'primary-300',
                  textColor: 'common-white',
                }}
              >
                {link.title}
              </Button>
            ))}
          </Flex>
        </>
      ) : (
        <Select
          list={linkTree.map(link => link?.title)}
          defaultValue={activePath?.title || ''}
          onChange={e => handleChange(e.target.value)}
        />
      )}
    </Flex>
  );
};

export default LegalSideBar;
