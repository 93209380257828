/* eslint-disable no-useless-escape */
import { parse } from 'node-html-parser';

import Flex from 'quarks/Flex';
import Heading from 'quarks/Heading';
import Link from 'quarks/Link';
import Paragraph from 'quarks/Paragraph';
import Text from 'quarks/Text';

import type { Node } from 'node-html-parser';

interface ChildNodesTypes extends Node {
  tagName?: string;
  getAttribute?(arg0: string): string;
}

const textContentConversion = (grandChildElement: ChildNodesTypes, isDetailPage = false) => {
  const textStyling = grandChildElement.childNodes.map((childNode: ChildNodesTypes) => {
    const content = childNode.childNodes.length > 0 ? textContentConversion(childNode) : childNode.textContent;

    const inheritFontFamily = 'font-family: inherit;';

    switch (childNode.tagName) {
      case 'A': {
        const url = (childNode?.getAttribute && childNode?.getAttribute('href')) || '';
        const isValidUrl =
          url?.match(
            /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/,
          ) ||
          url?.match(/^\/[a-zA-Z0-9\-._~!$&'()*+,;=:@\/]+$/) ||
          url === '/';

        const urlGuard = isValidUrl ? url : '';

        return (
          <Link
            href={urlGuard || ''}
            display="inline-block"
            textDecoration={isDetailPage ? 'underline' : 'none'}
            textColor={isDetailPage ? 'gray-700' : 'primary-600'}
            css={{
              fontFamily: 'inherit',
            }}
            trackingSchema={{
              label: content.toString(),
              location: 'RichText',
            }}
          >
            {content}
          </Link>
        );
      }
      case 'U':
        return (
          <Text css={inheritFontFamily} textDecoration="underline">
            {content}
          </Text>
        );
      case 'S':
        return (
          <Text css={inheritFontFamily} textDecoration="line-through">
            {content}
          </Text>
        );
      case 'STRONG':
        return (
          <Text css={inheritFontFamily} fontWeight="bold">
            {content}
          </Text>
        );
      case 'EM':
        return (
          <Text css={inheritFontFamily} fontStyle="italic">
            {content}
          </Text>
        );
      case 'LI':
        return (
          <Paragraph as="li" textStyle="lg">
            {content}
          </Paragraph>
        );
      case 'BR':
        return <br />;
      default:
        return <Text css={inheritFontFamily}>{content}</Text>;
    }
  });

  return textStyling;
};

const conversionToQuarkElement = (childElement: ChildNodesTypes, isDetailPage = false) => {
  const styledElement = (childElement?.getAttribute && childElement?.getAttribute('style')) || '';
  const textContent = textContentConversion(childElement, isDetailPage);

  switch (childElement?.tagName) {
    case 'P':
      return <Paragraph css={styledElement}>{textContent}</Paragraph>;
    case 'H1':
      return (
        <Heading css={styledElement} as="h1">
          {textContent}
        </Heading>
      );
    case 'H2':
      return (
        <Heading css={styledElement} as="h2">
          {textContent}
        </Heading>
      );
    case 'H3':
      return (
        <Heading css={styledElement} as="h3">
          {textContent}
        </Heading>
      );
    case 'H4':
      return (
        <Heading css={styledElement} as="h4">
          {textContent}
        </Heading>
      );
    case 'H5':
      return (
        <Heading css={styledElement} as="h5">
          {textContent}
        </Heading>
      );
    case 'H6':
      return (
        <Heading css={styledElement} as="h6">
          {textContent}
        </Heading>
      );
    case 'OL':
      return (
        <Flex as="ol" gap="12px" flexDirection="column" fontFamily="textFont" paddingAll={0} paddingLeft={24}>
          {textContent}
        </Flex>
      );
    case 'UL':
      return (
        <Flex
          as="ul"
          gap="12px"
          flexDirection="column"
          fontFamily="textFont"
          paddingAll={0}
          paddingLeft={24}
          css={{
            '& > li::marker': {
              color: '#3B4EFF',
            },
          }}
        >
          {textContent}
        </Flex>
      );
    default:
      return null;
  }
};

/**
 * @param content string made up of HTML Elements ;
 * @param isDark if true, renders button as dark version
 * @returns JSX
 */
const RichTextParser = (content: string, isDetailPage = false) => {
  const domParser = parse(content);
  const childNodesFromParsedHTML = domParser.childNodes;
  const jsxElementList = childNodesFromParsedHTML.map((layerOneChildren: ChildNodesTypes) => {
    const jsxElement = conversionToQuarkElement(layerOneChildren, isDetailPage);

    return jsxElement;
  });

  // Double check that this did not break nuthin
  return <>{jsxElementList}</>;
};

export default RichTextParser;
