import { m } from 'framer-motion';
import { type FC, useEffect, useState } from 'react';

import Heading from 'quarks/Heading';
import Text from 'quarks/Text';

import type { HeroHomepageFrames } from 'components/Hero/HeroStandard/heroTypesAndData';

const HeadingFrames: FC<HeroHomepageFrames> = ({
  animationText,
  isLottiePlaying,
  staticTextPart1 = 'Simplifying complex ',
  staticTextPart2 = 'challenges for restaurants using ',
  staticTextPart3 = 'AI-powered',
}) => {
  const animationFrames: HeroHomepageFrames['animationText'] = {
    ...animationText,
    frame4: {
      text1: '',
      text2: 'Lineup.ai',
    },
  };

  const [currentText, setCurrentText] = useState(0);

  const firstFrame = currentText === 0;

  const lastFrame = currentText === 3;

  const loopAnimationText = () => {
    if (animationFrames) {
      const animationTextKeys = Object.keys(animationFrames);

      animationTextKeys.forEach((frame, index) => {
        setTimeout(() => {
          setCurrentText(index);
        }, index * 8000);

        if (lastFrame) {
          return;
        }
      });
    }
  };

  useEffect(() => {
    if (isLottiePlaying && currentText === 0) {
      setCurrentText(0);
      loopAnimationText();
    }
  }, [animationText, isLottiePlaying]);

  const textAnimations = {
    offscreen: {
      y: 50,
      opacity: 0,
      transition: {
        y: { stiffness: 1000 },
      },
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 1000, velocity: -100 },
      },
    },
    exit: {
      y: 50,
      opacity: 0,
      transition: {
        y: { stiffness: 1000 },
      },
    },
  };

  const textAnimationProps = {
    transition: {
      duration: 1000,
      delay: 8000,
    },
    initial: firstFrame ? 'onscreen' : 'offscreen',
    animate: 'onscreen',
    exit: 'exit',
    variants: textAnimations,
  };

  return (
    <Heading
      as="h1"
      textColor="common-white"
      textStyle="sm"
      fontWeight="semiBold"
      md={{
        textStyle: 'lg',
        fontWeight: 'semiBold',
      }}
      maxWidth={lastFrame && '900px'}
      marginX="auto"
    >
      {staticTextPart1}
      {animationFrames &&
        Object.keys(animationFrames).map((frame: string, index: number) =>
          index === currentText && currentText !== 3 ? (
            <m.span key={frame} {...textAnimationProps} css="display: inline-block;">
              <Text gradient="lineupGradient-textOne">{animationFrames[frame].text1}&nbsp;</Text>
            </m.span>
          ) : null,
        )}
      {staticTextPart2}
      <Text display={lastFrame && 'none'}>{staticTextPart3}</Text>
      &nbsp;
      <span css="display: inline-block;">
        <Text gradient="lineupGradient-textTwo">
          {animationFrames &&
            Object.keys(animationFrames).map((frame, index) =>
              index === currentText ? (
                <m.span key={frame} {...textAnimationProps} css="display: inline-block;">
                  <Text gradient="lineupGradient-textTwo">{animationFrames[frame].text2}</Text>
                </m.span>
              ) : null,
            )}
        </Text>

        <Text>.</Text>
      </span>
    </Heading>
  );
};

export default HeadingFrames;
