import Container from 'quarks/Container';

import type { FC } from 'react';

interface OverlayProps {
  mobileSize: string;
  desktopSize: string;
  isBackgroundDark?: boolean;
}

const Overlay: FC<OverlayProps> = ({ mobileSize, desktopSize, isBackgroundDark }) => (
  <Container
    position="absolute"
    right={0}
    bottom="96px"
    width={0}
    height={mobileSize}
    zIndex={1}
    backgroundImage={isBackgroundDark ? 'lineupGradient-darkTransparent' : 'lineupGradient-whiteTransparent'}
    sm={{
      width: '70px',
      height: `${desktopSize}`,
    }}
    lg={{
      width: '170px',
    }}
  />
);

export default Overlay;
