// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore

import SectionBuilderConfig from 'molecules/Section/Section.config';

import { ButtonBuilderConfig } from 'components/ComponentButton/buttonTypeConfig';
import HeadingBuilderConfig from 'components/Heading/Heading.config';

import { doodleField } from 'utils/doodleGenerator';

const SolutionsCardGridBuilderConfig = {
  name: 'Solutions Card Grid',
  inputs: [
    { name: 'internalName', type: 'text' },
    {
      name: 'background',
      type: 'text',
      enum: ['White', 'Gray-100', 'Gray-900'],
      defaultValue: 'White',
    },
    ...doodleField,
    { name: 'useHeading', type: 'boolean', helperText: 'Set to true to add Heading Component' },
    {
      name: 'headingContent',
      type: 'object',
      subFields: [
        ...HeadingBuilderConfig.inputs.filter(
          input =>
            input.name !== 'internalName' && input.name !== 'collapseBottomPadding' && input.name !== 'background',
        ),
      ],
      showIf: (options: { get: (arg0: string) => boolean }) => options.get('useHeading') === true,
    },
    {
      name: 'cards',
      type: 'list',
      subFields: [
        { name: 'title', type: 'text' },
        { name: 'description', type: 'richText' },
        {
          name: 'link',
          type: 'object',
          subFields: ButtonBuilderConfig.inputs,
          helperText: 'Card Link',
        },
        {
          name: 'cardImage',
          type: 'file',
          allowedFileTypes: ['jpeg', 'png', 'jpg', 'svg'],
        },
        {
          name: 'imagePosition',
          type: 'text',
          enum: ['left', 'center', 'right'],
          defaultValue: 'center',
          helperText: 'Visible position of the image on the card',
        },
        {
          name: 'imageCaption',
          type: 'text',
          helperText: 'Caption for an Image',
        },
        ...doodleField,
      ],
    },
    ...SectionBuilderConfig.inputs,
  ],
};

export default SolutionsCardGridBuilderConfig;
