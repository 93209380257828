import type { Dispatch, SetStateAction } from 'react';

export const filterFunc = (string: string, keys: Array<string>) => {
  let check = false;
  for (let i = 0; i < keys?.length; i++) {
    if (string?.toLowerCase().includes(keys[i]?.toLowerCase())) {
      check = true;
      break;
    }
  }

  return check;
};

export const handleCheck = (item: string, array: string[], setFunc: Dispatch<SetStateAction<string[]>>) => {
  const existingItemIndex = array.indexOf(item);
  // item does not exist
  if (existingItemIndex === -1) {
    setFunc([...array, item]);
  } else {
    // item does exist
    array.splice(existingItemIndex, 1);
    setFunc([...array]);
  }
};
