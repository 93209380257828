import { useMediaQuery } from '@mui/material';

import Icon from 'atoms/Icons/Icon';
import { media } from 'atoms/breakpoints/breakpoints';

import Flex from 'quarks/Flex';
import Text from 'quarks/Text';

import Tooltip from 'molecules/Tooltip/Tooltip';

import type { PricingCardFeatureItemsProps } from 'components/PricingCardDeck/PricingCardDeckProps';

import type { FC } from 'react';

const PricingCardFeatureItems: FC<PricingCardFeatureItemsProps> = ({
  backgroundIsDark,
  collapsible,
  isCollapsed,
  features,
}) => {
  const isDesktop = useMediaQuery(media.xl);

  return (
    <Flex
      flexDirection="column"
      gap="24px"
      position="relative"
      css={`
        ${collapsible && {
          display: isCollapsed ? 'none' : 'flex',
          '> div': {
            display: isCollapsed ? 'none' : 'flex',
          },
        }}
      `}
    >
      {features?.map(({ id, title, featureItems }) => (
        <Flex key={id} flexDirection="column" gap="24px">
          <Text fontWeight="bold" fontSize="textLg" fontFamily="textFont" textAlign="left">
            {title}
          </Text>
          <Flex flexDirection="column" gap="16px">
            {featureItems?.length &&
              // eslint-disable-next-line no-shadow
              featureItems?.map(({ id, feature, tooltipDescription }) => {
                const hasTooltip = tooltipDescription && isDesktop;

                return (
                  <Flex
                    key={id}
                    gap="8px"
                    alignItems="center"
                    css={`
                      &&& {
                        > svg {
                          min-width: 20px;
                        }
                      }
                    `}
                  >
                    {hasTooltip ? (
                      <Tooltip title="" arrow description={tooltipDescription} placement="top">
                        <div>
                          <Icon id={backgroundIsDark ? 'check-blue-50' : 'check-blue-100'} size={20} />
                        </div>
                      </Tooltip>
                    ) : (
                      <Icon id={backgroundIsDark ? 'check-blue-50' : 'check-blue-100'} size={20} />
                    )}
                    <Text
                      fontWeight="regular"
                      fontSize="textMd"
                      fontFamily="textFont"
                      textColor={backgroundIsDark ? 'gray-100' : 'gray-700'}
                      md={{ fontSize: 'textLg' }}
                      xl={{ fontSize: 'textMd' }}
                    >
                      {feature}
                    </Text>
                  </Flex>
                );
              })}
          </Flex>
        </Flex>
      ))}
    </Flex>
  );
};

export default PricingCardFeatureItems;
