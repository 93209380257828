import { useEffect } from 'react';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';

import type { FC } from 'react';

interface WistiaEmbedProps {
  hashedId: string;
  playing?: boolean;
  controls?: boolean;
  muted?: boolean;
  // override default player options https://wistia.com/support/developers/embed-options#options_list
  embedOptions?: Record<string, unknown>;
}

const WistiaEmbed: FC<WistiaEmbedProps> = ({
  hashedId,
  playing = false,
  controls = false,
  muted = false,
  embedOptions,
}) => {
  useEffect(() => {
    if (typeof window !== undefined && !document.getElementById('wistia_script')) {
      const wistiaScript = document.createElement('script');
      wistiaScript.id = 'wistia_script';
      wistiaScript.type = 'text/javascript';
      wistiaScript.src = 'https://fast.wistia.com/assets/external/E-v1.js';
      wistiaScript.async = true;
      document.body.appendChild(wistiaScript);
    }

    window._wq = window._wq || [];
    window._wq.push({
      id: hashedId,
      options: {
        autoPlay: playing,
        silentAutoPlay: 'allow',
        muted,
        controlsVisibleOnLoad: controls,
        fullscreenButton: controls,
        playbar: controls,
        playbackRateControl: controls,
        qualityControl: controls,
        volumeControl: controls,
        settingsControl: controls,
        smallPlayButton: controls,
        ...embedOptions,
      },
    });
  }, [hashedId, playing, muted, controls, embedOptions]);

  return (
    <Flex className="wistia_responsive_padding" css={{ paddingTop: '56.25%', position: 'relative' }}>
      <Container
        className="wistia_responsive_wrapper"
        position="absolute"
        css={{ height: '100%', left: '0', position: 'absolute', top: '0', width: '100%' }}
      >
        <Flex
          className={`wistia_embed wistia_async_${hashedId} videoFoam=true`}
          css={{ height: '100%', width: '100%' }}
        >
          &nbsp;
        </Flex>
      </Container>
    </Flex>
  );
};

export default WistiaEmbed;
