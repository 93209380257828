import { useLocation } from 'react-use';

import container from 'atoms/spacing/containers';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Heading from 'quarks/Heading';
import Text from 'quarks/Text';

import Breadcrumbs from 'molecules/Breadcrumbs/Breadcrumbs';

import type { CalculatorCopyProps } from 'components/ForecastCalculator/componentCalculatorTypesConfig';

import colorizeText from 'utils/colorizeText';
import RichTextParser from 'utils/richTextParser';

import type { FC } from 'react';

const CalculatorCopy: FC<CalculatorCopyProps> = ({ heading, headingType = 'h2', eyebrow, subheading, ...props }) => {
  const { pathname } = useLocation();

  return (
    <Flex flexDirection="column" {...props}>
      <Container paddingLeft={4} marginBottom={20} md={{ marginBottom: 32 }} xl={{ marginBottom: 58 }}>
        <Breadcrumbs url={pathname} />
      </Container>
      <Flex flexDirection="column" textAlign="center" gap="24px" maxWidth={container.md} marginY={0} marginX="auto">
        {eyebrow && <Text textStyle="eyebrow">{colorizeText(eyebrow, false)}</Text>}
        {heading && (
          <Heading
            as={headingType}
            textColor="gray-900"
            textStyle="md"
            fontWeight="semiBold"
            md={{ textStyle: 'lg', fontWeight: 'semiBold' }}
            xl={{ textStyle: 'xl', fontWeight: 'semiBold' }}
          >
            {heading}
          </Heading>
        )}
        {subheading && (
          <Container textColor="gray-700" fontSize="textLg" fontWeight="medium" md={{ fontSize: 'textXl' }}>
            {RichTextParser(subheading)}
          </Container>
        )}
      </Flex>
    </Flex>
  );
};

export default CalculatorCopy;
