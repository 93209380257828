import React from 'react';

import Flex from 'quarks/Flex';
import Grid from 'quarks/Grid';
import Image from 'quarks/Image';

import { optimizeBuilderImage } from 'utils/functions';

import type { Builder_Integration } from 'graphqlTypes';

export interface IntegrationRefProps {
  integration?: {
    id?: string;
    model?: 'integration';
    value?: Builder_Integration;
  };
}

export interface IntegrationsGridProps {
  list: IntegrationRefProps[];
}

const IntegrationsGrid = ({ list }: IntegrationsGridProps) => (
  <Grid
    maxWidth="100%"
    gridTemplateColumns="repeat(2, 1fr)"
    gap="24px"
    md={{ gridTemplateColumns: 'repeat(2, 225px)' }}
  >
    {list.length > 0 &&
      list.map(({ integration }, index) => {
        const optimizedImageUrl =
          integration?.value?.data?.brandLogo && optimizeBuilderImage(integration?.value?.data?.brandLogo);
        const imageAlt = integration?.value?.data?.name || 'integration logo';

        if (!optimizedImageUrl) {
          return null;
        }

        return (
          <Flex
            key={integration?.id}
            width="100%"
            maxWidth="225px"
            boxShadow="lg"
            transform={`translateX(${index === 2 || index === 3 ? '64px' : 0})`}
            md={{
              width: '225px',
            }}
          >
            <Image
              borderRadius="16px"
              image={optimizedImageUrl}
              alt={imageAlt}
              width="100%"
              objectFit="contain"
              display="block"
            />
          </Flex>
        );
      })}
  </Grid>
);

export default IntegrationsGrid;
