import HorizontalRule from 'builder/HorizontalRule';

import Icon from 'atoms/Icons/Icon';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Grid from 'quarks/Grid';
import Link from 'quarks/Link';
import Text from 'quarks/Text';

import Button from 'molecules/Button/Button';
import Section from 'molecules/Section/Section';

import type {
  SupportCategory,
  SupportGroup,
  SupportListingProps,
  SupportPost,
} from 'components/Support/SupportListing.config';

import useSupportCategories from 'utils/hooks/useSupportCategories';
import useSupportPosts from 'utils/hooks/useSupportPosts';
import scrollToSection from 'utils/scollToSection';

import type { FC } from 'react';

const SupportListing: FC<SupportListingProps> = ({ sectionId }) => {
  const posts: SupportPost[] = useSupportPosts();

  const supportCategories: SupportCategory[] = [...useSupportCategories()];

  const postsByCategory = supportCategories?.map((category): SupportGroup | null => {
    const categoryName = category?.data?.name;
    const categoryIcon = category?.data?.icon;
    if (!categoryName) {
      return null;
    }
    const curPosts = posts?.filter(post => post?.data?.category?.value?.data?.name === category?.data?.name);

    return {
      name: categoryName,
      icon: categoryIcon,
      posts: [...curPosts],
    } as SupportGroup;
  });

  return (
    <Section __typename="SupportListing" background="gray-900" sectionType="listing" id={sectionId}>
      <Flex flexDirection="column" gap="24px" marginY={58} justifyContent="start">
        <Text fontSize="displayXs" fontWeight="medium" textColor="common-white">
          Jump to a section
        </Text>
        <Container overflowX="auto" maxWidth="max-content" paddingBottom={8}>
          <Flex
            display="flex"
            gap="16px"
            justifyContent="start"
            flexDirection="row"
            width="max-content"
            flexWrap="nowrap"
          >
            {postsByCategory?.map(
              categoryGroup =>
                categoryGroup?.name && (
                  <Button
                    key={categoryGroup.name}
                    variant="contained"
                    size="medium"
                    backgroundColor="common-white"
                    borderColor="gray-300"
                    textColor="gray-700"
                    border="1px solid"
                    borderRadius="8px"
                    boxShadow="xs"
                    aria-label={categoryGroup.name}
                    data-analytics-label={categoryGroup.name}
                    hover={{ backgroundColor: 'primary-600', textColor: 'common-white', borderColor: 'primary-600' }}
                    focusVisible={{
                      boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba(255, 255, 255, 0.3)',
                      outline: 0,
                    }}
                    onClick={() => {
                      if (categoryGroup?.name) {
                        scrollToSection(categoryGroup.name);
                      }
                    }}
                  >
                    {categoryGroup.name}
                  </Button>
                ),
            )}
          </Flex>
        </Container>
      </Flex>
      <Flex
        flexDirection="column"
        backgroundColor="common-white"
        borderColor="softWhite-200"
        borderRadius="16px"
        paddingX={48}
        paddingY={58}
      >
        {postsByCategory?.map(
          (categoryGroup, index) =>
            categoryGroup?.name && (
              <Flex
                key={categoryGroup.name}
                id={categoryGroup.name}
                flexDirection="column"
                gap="48px"
                paddingBottom={index === postsByCategory.length - 1 ? 0 : 58}
              >
                <Flex gap="24px" alignItems="center">
                  {categoryGroup?.icon && (
                    <Flex
                      backgroundColor="primary-600"
                      borderRadius="8px"
                      justifyContent="center"
                      alignItems="center"
                      paddingX={12}
                      paddingY={12}
                    >
                      <Icon isBackgroundDark={true} id={categoryGroup.icon} />
                    </Flex>
                  )}
                  {categoryGroup.name && (
                    <Text
                      fontSize="displayXs"
                      md={{ fontSize: 'displaySm' }}
                      lg={{ fontSize: 'displayLg' }}
                      fontWeight="medium"
                      textColor="common-black"
                    >
                      {categoryGroup.name}
                    </Text>
                  )}
                </Flex>
                <Grid gridTemplateColumns="1fr" gap="16px" lg={{ gridTemplateColumns: '1fr 1fr' }}>
                  {categoryGroup?.posts?.map(
                    post =>
                      post?.data?.title &&
                      post?.data?.url && (
                        <Link
                          key={post.data.title}
                          href={post.data.url}
                          textColor="primary-700"
                          fontSize="textMd"
                          lg={{ fontSize: 'textXl' }}
                          fontWeight="semiBold"
                          fontFamily="textFont"
                        >
                          {post.data.title}
                        </Link>
                      ),
                  )}
                </Grid>
                {index !== postsByCategory.length - 1 && <HorizontalRule />}
              </Flex>
            ),
        )}
      </Flex>
    </Section>
  );
};

export default SupportListing;
