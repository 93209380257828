import SectionBuilderConfig from 'molecules/Section/Section.config';

import { doodleField } from 'utils/doodleGenerator';

const HorizontalSwitcherBuilderConfig = {
  name: 'HorizontalSwitcher',
  inputs: [
    { name: 'eyebrow', type: 'text' },
    { name: 'heading', type: 'longText' },
    { name: 'headingType', type: 'text', enum: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] },
    { name: 'desc', type: 'richText' },
    ...doodleField,
    {
      name: 'switcherItems',
      type: 'list',
      required: true,
      subFields: [
        { name: 'itemTitle', type: 'text', required: true },
        { name: 'heading', type: 'longText' },
        { name: 'headingType', type: 'text', enum: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] },
        { name: 'desc', type: 'richText' },
        { name: 'image', type: 'file', allowedFileTypes: ['jpeg', 'png', 'jpg', 'svg'] },
        { name: 'imageAltTag', type: 'text' },
      ],
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onChange: options => {
        if (options.get('switcherItems').length > 6) {
          options.set('switcherItems', options.get('switcherItems').slice(0, 6));
          alert('Only six switcher items allowed.');
        }
      },
    },
    ...SectionBuilderConfig.inputs,
  ],
};

export default HorizontalSwitcherBuilderConfig;
