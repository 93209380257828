import type { iconIds } from 'atoms/Icons/Icon.types';
import { iconValues } from 'atoms/Icons/Icon.types';

import type { BasicProps } from 'quarks/interpolations/basic';

import SectionBuilderConfig from 'molecules/Section/Section.config';

import type { ButtonTypes } from 'components/ComponentButton/buttonTypeConfig';
import { ButtonBuilderConfig } from 'components/ComponentButton/buttonTypeConfig';

import type { Writeable } from 'utils/typeUtils';

type removeReadOnlyModifier = Writeable<typeof iconValues>;

const TimelineBuilderConfig = {
  name: 'ComponentTimeline',
  inputs: [
    {
      name: 'primaryHeading',
      type: 'text',
      helperText: 'This text will be a semibold weight heading.',
      defaultValue: 'Always improving',
    },
    {
      name: 'secondaryHeading',
      type: 'text',
      helperText: 'This text will be a medium weight heading.',
      defaultValue: 'So what is new?',
    },
    { name: 'subheading', type: 'richText' },
    {
      name: 'buttons',
      type: 'list',
      subFields: ButtonBuilderConfig.inputs,
      helperText: 'For the Timeline Component, the Figma Design System has both buttons at md. ',
    },
    {
      name: 'entries',
      type: 'list',
      subFields: [
        {
          name: 'title',
          type: 'text',
        },
        {
          name: 'date',
          type: 'text',
        },
        {
          name: 'icon',
          type: 'text',
          enum: iconValues as removeReadOnlyModifier,
        },
      ],
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onChange: options => {
        if (options.get('features').length > 5) {
          options.set('features', options.get('features').slice(0, 5));
          alert('Only five items allowed.');
        }
      },
    },
    ...SectionBuilderConfig.inputs,
  ],
};

export interface TimelineProps extends BasicProps {
  eyebrow?: string;
  primaryHeading?: string;
  secondaryHeading?: string;
  subheading?: string;
  buttons?: ButtonTypes;
  entries?: Array<{
    title?: string;
    date?: string;
    icon?: iconIds;
  }>;
  sectionId?: string;
}

export default TimelineBuilderConfig;
