import { useCallback, useState } from 'react';

import darkPlayIcon from 'assets/svg/play-dark.svg';
import playIcon from 'assets/svg/play.svg';

import color from 'atoms/colors/colors';
import container from 'atoms/spacing/containers';

import Container from 'quarks/Container';
import Image from 'quarks/Image';
import type { BasicProps } from 'quarks/interpolations/basic';

import VideoModal from 'molecules/Modal/VideoModal';
import VideoContent from 'molecules/VideoPlayer/VideoContent';
import WistiaPlayer from 'molecules/VideoPlayer/WistiaPlayer';

import { optimizeBuilderImage } from 'utils/functions';

import type { Builder_ComponentButton } from 'graphqlTypes';
import type { FC } from 'react';

export interface VideoPlayerProps extends BasicProps {
  videoUrl?: string;
  thumbnailImage?: string;
  darkVideoPlayButton?: boolean;
  title?: string;
  description?: string;
  ctaButton?: { value?: Builder_ComponentButton };
}

const VideoPlayer: FC<VideoPlayerProps> = ({
  videoUrl,
  thumbnailImage,
  darkVideoPlayButton,
  title,
  description,
  ctaButton,
}) => {
  const [playVideo, setPlayVideo] = useState(false);
  const videoHash = videoUrl?.split('/')?.pop();

  const optimizeThumbnail = thumbnailImage && optimizeBuilderImage(thumbnailImage);

  const handlePlayVideo = useCallback(e => {
    if (e.key === 'Enter' || e.key === ' ') {
      setPlayVideo(true);
    }
    if (e.type === 'click') {
      setPlayVideo(true);
    }
  }, []);

  const handleCloseVideo = useCallback(() => setPlayVideo(false), []);

  return (
    <>
      <Container marginX="auto" md={{ maxWidth: container.md }}>
        {optimizeThumbnail && (
          <Container position="relative" cursor="pointer" onKeyDown={handlePlayVideo} onClick={handlePlayVideo}>
            <Image
              alignSelf="center"
              width="100%"
              image={optimizeThumbnail}
              alt="video-player-image"
              objectFit="contain"
              transition="align-self 0.150s ease"
              borderRadius="8px"
              md={{ borderRadius: '20px' }}
            />
            <Image
              tabIndex={0}
              width="30px"
              position="absolute"
              left="50%"
              top="50%"
              transform="translate(-50%, -50%)"
              image={darkVideoPlayButton ? darkPlayIcon : playIcon}
              alt="Play video"
              md={{ width: 'auto' }}
            />
          </Container>
        )}
      </Container>
      <VideoModal open={playVideo} onClose={handleCloseVideo}>
        {videoHash && (
          <VideoContent title={title} description={description} ctaButton={ctaButton?.value}>
            <WistiaPlayer
              hashedId={videoHash}
              playing
              controls
              muted={false}
              embedOptions={{ playerColor: color.gray['800'] }}
            />
          </VideoContent>
        )}
      </VideoModal>
    </>
  );
};

VideoPlayer.defaultProps = {
  title: 'Modal title',
  description: 'Modal description',
};

export const VideoPlayerBuilderConfig = {
  name: 'VideoPlayer',
  inputs: [
    {
      name: 'thumbnailImage',
      type: 'file',
      allowedFileTypes: ['jpeg', 'png', 'jpg', 'svg'],
      defaultValue:
        'https://cdn.builder.io/api/v1/image/assets%2F79098c1284e04893b7e8de5c66f75fd4%2F60580a77c35a47fbb4adca2a912f91d2',
    },
    { name: 'videoUrl', type: 'url', defaultValue: 'https://home.wistia.com/medias/pzffq0x27t' },
    { name: 'title', type: 'text', defaultValue: 'Video Modal Title' },
    {
      name: 'description',
      type: 'richText',
      defaultValue: '<p>Video modal description. Keep this somewhat short.</p>',
    },
    { name: 'ctaButton', type: 'reference', model: 'component-button' },
  ],
};

export default VideoPlayer;
