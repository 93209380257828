import CopyBuilderConfig from 'builder/Text.config';

type BuilderBlock = {
  component: {
    name: string;
    options: {
      copy: string;
    };
  };
};

export interface BlocksData extends BuilderBlock {
  children: BuilderBlock[];
}

/**
 * @param blocks
 * @param wordsPerMinute
 * @param defaultMinutesRead
 * @returns `defaultMinutesRead` if blocks is not defined or does not contain 'copy' elements
 * @returns A ratio based on words `blocks` contains over `wordsPerMinute`
 */
const readingTime = (blocks?: BlocksData[] | null, wordsPerMinute = 225, defaultMinutesRead = 8) => {
  if (!blocks) {
    return defaultMinutesRead;
  }

  const copy = blocks
    .filter(block => block?.component?.name === 'ContentEditor')
    .map(block =>
      block?.children
        ?.filter(childBlock => childBlock?.component?.name === CopyBuilderConfig.name)
        ?.map(childBlock => childBlock.component.options.copy)
        ?.join(),
    )
    ?.join();

  if (!copy.length) {
    return defaultMinutesRead;
  }

  const wordCount = copy.trim().split(/\s+/).length;
  const minutesRead = Math.ceil(wordCount / wordsPerMinute);

  return minutesRead;
};

export default readingTime;
