exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-author-tsx": () => import("./../../../src/templates/author.tsx" /* webpackChunkName: "component---src-templates-author-tsx" */),
  "component---src-templates-builder-page-tsx": () => import("./../../../src/templates/BuilderPage.tsx" /* webpackChunkName: "component---src-templates-builder-page-tsx" */),
  "component---src-templates-case-study-tsx": () => import("./../../../src/templates/caseStudy.tsx" /* webpackChunkName: "component---src-templates-case-study-tsx" */),
  "component---src-templates-legal-tsx": () => import("./../../../src/templates/legal.tsx" /* webpackChunkName: "component---src-templates-legal-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */),
  "component---src-templates-resource-tsx": () => import("./../../../src/templates/resource.tsx" /* webpackChunkName: "component---src-templates-resource-tsx" */),
  "component---src-templates-support-tsx": () => import("./../../../src/templates/support.tsx" /* webpackChunkName: "component---src-templates-support-tsx" */)
}

