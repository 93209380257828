import { useState } from 'react';

import Flex from 'quarks/Flex';
import Text from 'quarks/Text';

import Section from 'molecules/Section/Section';

import type { AccordionItemsProps } from 'components/FAQ/AccordionItems';
import AccordionItems from 'components/FAQ/AccordionItems';
import type { FAQButton } from 'components/Sidebar/Variations/FAQ';
import FAQsSideBar from 'components/Sidebar/Variations/FAQ';

import type { FC } from 'react';

interface FAQComponentProps {
  accordions?: AccordionItemsProps[];
  sectionId?: string;
}

const constructSidebarButtons = (accordions: FAQComponentProps['accordions']) => {
  const buttons = accordions?.map(
    ({ label }) =>
      ({
        id: label,
        title: label,
      } as FAQButton),
  );

  const viewAllButton = {
    id: 'View All',
    title: 'View All',
  };

  buttons?.unshift(viewAllButton);

  return buttons;
};

const FAQ: FC<FAQComponentProps> = ({ accordions, sectionId }) => {
  const [activeAccordion, setActiveAccordion] = useState<string | null>('View All');

  if (!accordions || accordions?.length === 0) {
    return (
      <Section id={sectionId}>
        <Text textStyle="md" fontWeight="bold">
          No accordions provided.
        </Text>
      </Section>
    );
  }

  return (
    <Section id={sectionId}>
      <Flex
        flexDirection="column"
        gap="40px"
        xl={{
          flexDirection: 'row',
          gap: '100px',
        }}
      >
        <Flex
          position="sticky"
          top="73px"
          minWidth="300px"
          height="fit-content"
          backgroundColor="common-white"
          zIndex={1}
          css={`
            margin-left: -10px;
            margin-right: -10px;
            padding: 0 10px;
          `}
        >
          <FAQsSideBar
            label="FAQ categories"
            buttons={constructSidebarButtons(accordions)}
            activeAccordion={activeAccordion}
            setActiveAccordion={setActiveAccordion}
          />
        </Flex>
        <Flex width="100%" flexDirection="column" gap="48px">
          {accordions.map(item => {
            if (item.label !== activeAccordion && activeAccordion !== 'View All') {
              return null;
            }

            return (
              <AccordionItems
                key={item.label}
                label={item.label}
                headingType={item.headingType}
                itemHeadingType={item.itemHeadingType}
                items={item.items}
              />
            );
          })}
        </Flex>
      </Flex>
    </Section>
  );
};

export default FAQ;
