import { graphql, useStaticQuery } from 'gatsby';

const useBlogPosts = () => {
  const data = useStaticQuery(graphql`
    query AllBlogs {
      allBuilderModels {
        post(options: { includeRefs: true, cachebust: true }, limit: 1000) {
          data {
            postAuthor
            category
            featuredImage
            featuredImageCaption
            publishDate
            title
            url
            description
            blocks
          }
          id
        }
      }
    }
  `);

  return data.allBuilderModels.post;
};

export default useBlogPosts;
