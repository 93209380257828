import { useMediaQuery } from '@mui/material';
import { useCallback } from 'react';

import { media } from 'atoms/breakpoints/breakpoints';

import Flex from 'quarks/Flex';
import Text from 'quarks/Text';
import type { FlexProps } from 'quarks/interpolations/flex';

import Dots from 'molecules/ButtonPagination/Dots';

import ComponentButton from 'components/ComponentButton/ComponentButton';

import type { FC, KeyboardEvent } from 'react';

interface PaginationProps extends FlexProps {
  /**
   * The current active dot.
   */
  activeDot: number;
  /**
   * The number of dots to generate.
   */
  dotsCount: number;
  /**
   * Callback to set active dot.
   * @param dot number
   */
  onSetActiveDot: (dot: number) => void;
  /**
   * Callback fired when left arrow is clicked.
   */
  onLeftArrowClick: () => void;
  /**
   * Callback fired when right arrow is clicked.
   */
  onRightArrowClick: () => void;
}

const Pagination: FC<PaginationProps> = ({
  activeDot,
  dotsCount,
  onSetActiveDot,
  onLeftArrowClick,
  onRightArrowClick,
  ...props
}) => {
  const handleRightArrowClick = useCallback(() => onRightArrowClick(), [onRightArrowClick]);
  const handleLeftArrowClick = useCallback(() => onLeftArrowClick(), [onLeftArrowClick]);
  const handleActiveDot = useCallback((dotIndex: number) => onSetActiveDot(dotIndex), [onSetActiveDot]);

  const isMobile = !useMediaQuery(media.sm);

  const paginationButtonHeight = 36;

  const buttonStyles = {
    variant: 'text',
    fontSize: 'textLg',
    fontWeight: 'semiBold',
    tabIndex: 0,
  } as const;

  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      position="relative"
      after={{
        content: '',
        position: 'absolute',
        height: '1px',
        width: '100%',
        backgroundColor: 'gray-200',
        top: '-16px',
      }}
      {...props}
    >
      <ComponentButton
        buttonType="callToAction"
        button={{
          value: {
            data: {
              disabled: activeDot === 0,
              size: isMobile ? 'small' : 'medium',
              hierarchy: isMobile ? 'Secondary Gray' : 'Link Gray',
              label: 'Back',
            },
          },
        }}
        labelIcon={isMobile ? '<-' : ''}
        height={`${paginationButtonHeight}px`}
        {...buttonStyles}
        aria-label="Back"
        onClick={handleLeftArrowClick}
        sm={{ height: '100%' }}
      />
      <Flex>
        {isMobile ? (
          <Text textStyle="sm" fontWeight="regular" textColor="gray-700">
            Page {activeDot + 1} of {dotsCount}
          </Text>
        ) : (
          [...Array(dotsCount)]?.map((_, i: number) => {
            const dotProps = {
              key: i,
              onKeyDown: (e: KeyboardEvent<HTMLDivElement>) => e.key === 'Enter' && handleActiveDot(i),
              onClick: () => handleActiveDot(i),
              number: i + 1,
            } as const;
            switch (i) {
              case activeDot:
                return <Dots isActive {...dotProps} />;
              case 0:
              case dotsCount - 1:
                return <Dots {...dotProps} />;
              case activeDot - 1:
                return (
                  <>
                    {!(activeDot === 2) && <Text paddingTop={6}>...</Text>}
                    <Dots {...dotProps} />
                  </>
                );
              case activeDot + 1:
                return (
                  <>
                    <Dots {...dotProps} />
                    {!(activeDot === dotsCount - 3) && dotsCount > 3 && <Text paddingTop={6}>...</Text>}
                  </>
                );
              default:
                return null;
            }
          })
        )}
      </Flex>
      <ComponentButton
        buttonType="callToAction"
        button={{
          value: {
            data: {
              size: isMobile ? 'small' : 'medium',
              hierarchy: isMobile ? 'Secondary Gray' : 'Link Gray',
              label: 'Next',
              disabled: activeDot === dotsCount - 1,
            },
          },
        }}
        labelIcon={isMobile ? '->' : ''}
        {...buttonStyles}
        aria-label="Next"
        onClick={handleRightArrowClick}
        height={`${paginationButtonHeight}px`}
        sm={{ height: '100%' }}
      />
    </Flex>
  );
};

export default Pagination;
