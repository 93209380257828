import color from 'atoms/colors/colors';
import gradient from 'atoms/colors/gradients';

const rootStyles = {
  height: 4,
  borderRadius: 8,
};

const barStyles = {
  borderRadius: 8,
};

export const lightGradientLinearProgress = {
  MuiLinearProgress: {
    styleOverrides: {
      root: rootStyles,
      bar: barStyles,
      barColorPrimary: {
        background: color.gray[300],
      },
      bar1Buffer: {
        background: gradient.lineupGradient.textOne,
      },
      bar1Indeterminate: {
        background: gradient.lineupGradient.textOne,
      },
      bar1Determinate: {
        background: gradient.lineupGradient.textOne,
      },
    },
  },
};

export const darkGradientLinearProgress = {
  MuiLinearProgress: {
    styleOverrides: {
      root: rootStyles,
      bar: barStyles,
      barColorPrimary: {
        background: gradient.lineupGradient.darkMode,
      },
      bar1Buffer: {
        background: gradient.lineupGradient.darkMode,
      },
      bar1Indeterminate: {
        background: gradient.lineupGradient.darkMode,
      },
      bar1Determinate: {
        background: gradient.lineupGradient.darkMode,
      },
    },
  },
};
