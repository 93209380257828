import { ClickAwayListener } from '@mui/material';

import Flex from 'quarks/Flex';
import Text from 'quarks/Text';

import Button from 'molecules/Button/Button';
import Checkbox from 'molecules/Checkbox/Checkbox';

import type { FilterMenuProps } from 'components/PartnerList/partnerListTypesAndData';

import { handleCheck } from 'utils/filterUtils';
import onKeyDown from 'utils/onKeyDown';

import type { Dispatch, FC, SetStateAction } from 'react';

const FilterMenu: FC<FilterMenuProps> = ({
  handleButtonClick,
  handleClearFilters,
  activePartnership,
  setActivePartnership,
  activeServiceType,
  setActiveServiceType,
}) => {
  const partnerships = ['Integration', 'Special Offer'];
  const serviceTypes = ['POS', 'HR', 'Scheduling', 'Accounting'];

  const checkboxContent = (array: string[], activeArray: string[], func: Dispatch<SetStateAction<string[]>>) => (
    <>
      {array.sort().map(item => (
        <Checkbox
          key={item}
          value={item}
          label={item}
          size="large"
          type="filter"
          checked={activeArray?.includes(item)}
          onChange={() => handleCheck(item, activeArray, func)}
          onKeyDown={e => onKeyDown(e, () => handleCheck(item, activeArray, func))}
        />
      ))}
    </>
  );

  const partnershipCheckboxes = checkboxContent(partnerships, activePartnership, setActivePartnership);
  const serviceTypeCheckboxes = checkboxContent(serviceTypes, activeServiceType, setActiveServiceType);

  return (
    <ClickAwayListener onClickAway={handleButtonClick}>
      <Flex
        position="fixed"
        right="0"
        top="0"
        paddingAll={24}
        backgroundColor="primary-25"
        width="100vw"
        zIndex={10000}
        flexDirection="column"
        height="100vh"
        justifyContent="space-between"
        overflowY="hidden"
        md={{
          boxShadow: 'lg',
          borderRadius: '16px',
          border: '1.5px solid',
          borderColor: 'gray-300',
          maxHeight: 'calc(100% - 100px)',
          height: 'unset',
          position: 'absolute',
          width: '370px',
          top: '100px',
          marginTop: 10,
        }}
      >
        <Flex flexDirection="column" overflowY="auto">
          <Flex
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            paddingBottom={16}
            borderBottom="1px solid"
            borderBottomColor="gray-300"
          >
            <Text textStyle="xl" fontWeight="bold">
              Filters
            </Text>
            <Text
              textColor="gray-900"
              textStyle="md"
              fontWeight="semiBold"
              boxShadow="none"
              paddingAll={0}
              cursor="pointer"
              role="button"
              aria-label="Clear all"
              tabIndex={0}
              hover={{ textColor: 'primary-700' }}
              onClick={() => handleClearFilters()}
              onKeyDown={e => onKeyDown(e, () => handleClearFilters())}
            >
              Clear All
            </Text>
          </Flex>
          <Flex flexDirection="column" paddingTop={16} gap="16px">
            <Text textStyle="lg" fontWeight="semiBold">
              Partnership Type
            </Text>
            {partnershipCheckboxes}
            <Text textStyle="lg" fontWeight="semiBold">
              Service Type
            </Text>
            {serviceTypeCheckboxes}
          </Flex>
        </Flex>
        <Button variant="contained" width="100%" marginTop={32} onClick={handleButtonClick}>
          Save Filters
        </Button>
      </Flex>
    </ClickAwayListener>
  );
};

export default FilterMenu;
