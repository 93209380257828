const getPaginationSpread = (pageArray: number[], currentPage: number, skipValue = '...') => {
  const start = pageArray.slice(0, 3),
    end = pageArray.slice(-3),
    currentInStart = start.slice(0, -1).includes(currentPage),
    currentInEnd = end.slice(1).includes(currentPage),
    currentCloseToStart = currentPage === start[start.length - 1],
    currentCloseToEnd = currentPage === end[0];

  if (currentInStart || currentInEnd) {
    return [...start, skipValue, ...end];
  }

  const currentPages = pageArray.slice(currentPage - 2, currentPage + 1);

  if (currentCloseToStart) {
    return [pageArray[0], ...currentPages, skipValue, ...end.slice(1)];
  }

  if (currentCloseToEnd) {
    return [...start.slice(0, -1), skipValue, ...currentPages, pageArray[pageArray.length - 1]];
  }

  const first = [pageArray[0], skipValue],
    last = [skipValue, pageArray[pageArray.length - 1]];

  return [...first, ...currentPages, ...last];
};

export default getPaginationSpread;
