import Icon from 'atoms/Icons/Icon';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import ImageQuark from 'quarks/Image';
import Text from 'quarks/Text';

import RichTextParser from 'utils/richTextParser';

import type { FC } from 'react';

interface ImageProps {
  image?: string;
  altText?: string;
  caption?: string;
}

const Image: FC<ImageProps> = ({ image, altText, caption }) => (
  <Flex as="figure" flexDirection="column" gap="16px" marginAll={0}>
    {image ? (
      <ImageQuark image={image} alt={altText || ''} borderRadius="12px" />
    ) : (
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        backgroundColor="primary-25"
        borderRadius="12px"
        width="100%"
        paddingX={32}
        paddingY={96}
      >
        <Container border="dashed 3px" borderColor="irisBlue-300" paddingAll={24}>
          <Text>Add Your Image Here</Text>
        </Container>
      </Flex>
    )}
    {caption && (
      <Flex as="figcaption" flexDirection="row" alignItems="center" gap="6px">
        <Icon id="link-01" iconColor="gray-500" />
        <Text textStyle="sm" textColor="gray-700">
          {RichTextParser(caption, true)}
        </Text>
      </Flex>
    )}
  </Flex>
);

export default Image;
