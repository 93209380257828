import Flex from 'quarks/Flex';
import Heading from 'quarks/Heading';
import Text from 'quarks/Text';
import type { BasicProps } from 'quarks/interpolations/basic';

import type { FC } from 'react';

interface StatType extends BasicProps {
  stat: string;
  caption: string;
  backgroundIsDark: boolean;
}

const Stat: FC<StatType> = ({ backgroundIsDark, stat, caption }) => {
  const backgroundColor = backgroundIsDark ? 'gray-900' : 'gray-25';
  const gradientColor = backgroundIsDark ? 'lineupGradient-darkMode' : 'lineupGradient-lightMode';

  return (
    <Flex
      width="100%"
      backgroundColor={backgroundColor}
      backgroundImage={gradientColor}
      borderRadius="8px"
      css={`
        padding: 2px;
      `}
    >
      <Flex
        width="100%"
        backgroundColor={backgroundColor}
        borderRadius="6px"
        paddingY={16}
        paddingX={24}
        justifyContent="center"
        md={{ height: '124px' }}
      >
        <Flex flexDirection="column" gap="8px" textAlign="center">
          <Heading
            textColor={backgroundIsDark ? 'common-white' : 'gray-900'}
            as="h2"
            textStyle="md"
            md={{ textStyle: 'lg' }}
          >
            {stat}
          </Heading>
          <Text textStyle="md" textColor={backgroundIsDark ? 'gray-50' : 'gray-700'}>
            {caption}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Stat;
