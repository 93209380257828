import Icon from 'atoms/Icons/Icon';
import type { iconIds } from 'atoms/Icons/Icon.types';

import Flex from 'quarks/Flex';
import Text from 'quarks/Text';
import type { BasicProps } from 'quarks/interpolations/basic';

import type { FC } from 'react';

interface FeatureProps extends BasicProps {
  copy?: string;
  icon?: iconIds;
}

const HeroFeature: FC<FeatureProps> = ({ copy, icon }) => (
  <Flex alignItems="center" gap="16px" borderRadius="12px" md={{ gap: '24px' }}>
    {icon && (
      <Flex height="fit-content">
        <Icon size={16} id={icon} hasGradientBorder wrapped />
      </Flex>
    )}
    {copy && (
      <Text as="div" textStyle="md" textColor="gray-900" md={{ textStyle: 'lg' }}>
        {copy}
      </Text>
    )}
  </Flex>
);

export default HeroFeature;
