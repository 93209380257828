import type { IntegrationPartnerCardData, PartnerCardData } from 'components/PartnerList/partnerListTypesAndData';

import type { Builder_GatedResource, Builder_Post, Maybe } from 'graphqlTypes';

/**
 * @param An array of objects containing data from post model or resource-post model
 * @param A string with the value of articleCards or resourceCards
 * @returns an object, that contains a property of the card type and holds a value of array of objects. The Array of Objects get cuts down to three.
 */
const generateCardsForDeck = (
  entryList: Maybe<Builder_Post>[] | Maybe<Builder_GatedResource>[],
  entryType: 'articleCards' | 'resourceCards',
) => {
  const cards = entryList.map(entry => {
    const card = {
      card: { value: entry?.content },
    };

    return card;
  });

  const convertToDeckFormat = { [entryType]: cards.splice(0, 3) };

  return convertToDeckFormat;
};

export default generateCardsForDeck;

/**
 *
 * @param intCards - An array of objects containing Integration data referenced within Partner List model.
 * @param specialCards - An array of objects containing special offer data from Partner List model.
 * @returns a single array that combines integration and special offer data.
 */
export const generatePartnerCardsForList = (
  intCards: IntegrationPartnerCardData[] | undefined,
  specialCards: PartnerCardData[] | undefined,
) => {
  const cardList = [] as PartnerCardData[];
  const handleServiceType = (type?: PartnerCardData['serviceType']) => {
    const typeArray = Array.isArray(type) ? type : [type];

    return typeArray.sort();
  };

  intCards?.forEach(int => {
    const intName = int?.integrationData?.value?.data?.name;
    const intBrandLogo = int?.integrationData?.value?.data?.brandLogo;
    const intServiceTypes = handleServiceType(int?.serviceType);

    cardList.push({
      name: intName,
      brandLogo: intBrandLogo,
      description: int?.description,
      isPopular: int?.isPopular,
      link: int?.link,
      serviceType: intServiceTypes,
      offerType: 'Integration',
    });
  });

  specialCards?.forEach(offer => {
    const specialServiceTypes = handleServiceType(offer?.serviceType);

    cardList.push({
      ...offer,
      serviceType: specialServiceTypes,
      offerType: 'Special Offer',
    });
  });

  return cardList.sort((a, b) => a?.name?.localeCompare(b?.name));
};
