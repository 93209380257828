import { type FC } from 'react';

import Section from 'molecules/Section/Section';

import CtaVariantGrid from 'components/FeatureGrid/CtaVariant';
import EmployeeBenefitsGrid from 'components/FeatureGrid/EmployeeBenefits';
import PartnerBenefitsGrid from 'components/FeatureGrid/PartnerBenefits';
import StandardFeatureGrid from 'components/FeatureGrid/Standard';
import type { FeatureGridProps } from 'components/FeatureGrid/featureGridTypesAndData';

import { getBackgroundIsDark } from 'utils/functions';

const FeatureGrid: FC<FeatureGridProps> = ({
  typename = 'Feature Grid',
  variant,
  sectionId,
  featureGridCards,
  ctaVariantCards,
  benefitsGridCards,
  partnerBenefitCards,
  customerBenefitCards,
  ...props
}) => {
  const backgroundIsDark = getBackgroundIsDark(props.background);

  const content = (componentVariant: string | undefined) => {
    switch (componentVariant) {
      case 'Standard':
        return (
          <StandardFeatureGrid
            typename={typename}
            backgroundIsDark={backgroundIsDark}
            featureGridCards={featureGridCards}
            {...props}
          />
        );
      case 'CTA':
        return <CtaVariantGrid ctaVariantCards={ctaVariantCards} />;
      case 'Employee Benefits':
        return (
          <EmployeeBenefitsGrid
            backgroundIsDark={backgroundIsDark}
            benefitsGridCards={benefitsGridCards}
            featureGridCards={featureGridCards}
            {...props}
          />
        );
      case 'Partner Benefits':
        return (
          <PartnerBenefitsGrid
            partnerBenefitCards={partnerBenefitCards}
            customerBenefitCards={customerBenefitCards}
            {...props}
          />
        );
      default:
        return <StandardFeatureGrid typename={typename} backgroundIsDark={backgroundIsDark} {...props} />;
    }
  };

  const gridContent = content(variant);

  return (
    <Section __typename={typename} background={props.background} id={sectionId}>
      {gridContent}
    </Section>
  );
};

export default FeatureGrid;

FeatureGrid.defaultProps = {
  background: 'White',
};
