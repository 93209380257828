import container from 'atoms/spacing/containers';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Heading from 'quarks/Heading';
import Image from 'quarks/Image';
import Text from 'quarks/Text';
import type { BasicProps } from 'quarks/interpolations/basic';

import Section from 'molecules/Section/Section';

import Form from 'components/HsForm/HsForm';

import doodleGenerator from 'utils/doodleGenerator';
import { getBackgroundIsDark } from 'utils/functions';
import RichTextParser from 'utils/richTextParser';

import type { FC } from 'react';

export const backgroundOptions = ['primary-25', 'primary-600'] as const;
export const columnOptions = ['one', 'two'] as const;

interface NewsletterCtaProps extends BasicProps {
  heading: string;
  subheading: string;
  background: typeof backgroundOptions[number];
  columns: typeof columnOptions[number];
  sectionId?: string;
}

const NewsletterCta: FC<NewsletterCtaProps> = ({ columns, background, heading, subheading, sectionId }) => {
  const isTwoCol = columns === 'two';

  const backgroundIsDark = getBackgroundIsDark(background);
  const headingColor = backgroundIsDark ? 'common-white' : 'gray-900';
  const textColor = backgroundIsDark ? 'common-white' : 'gray-700';

  const leftDoodle = doodleGenerator('squiggles');
  const rightDoodle = doodleGenerator('breadloaf');
  const centerDoodle = doodleGenerator('swirl');

  const doodleProps = {
    opacity: backgroundIsDark ? '.3' : '1',
    position: 'absolute',
    alt: 'doodle',
  } as const;

  return (
    <Section id={sectionId}>
      <Flex
        backgroundColor={background}
        position="relative"
        borderRadius="12px"
        paddingY={48}
        paddingX={8}
        md={{
          borderRadius: '24px',
          paddingY: 72,
        }}
        xl={{ paddingY: 80 }}
        overflowX="hidden"
        overflowY="hidden"
      >
        <Container display="none" md={{ display: 'block' }}>
          {!isTwoCol && leftDoodle && (
            <Image
              height="165px"
              width="175px"
              transform="rotate(-30deg)"
              top="-4px"
              left="-14px"
              image={leftDoodle}
              {...doodleProps}
              xl={{
                height: '265px',
                width: '244px',
                top: '-16px',
                left: '0px',
              }}
            />
          )}
          {!isTwoCol && rightDoodle && (
            <Image
              height="125px"
              width="166px"
              bottom="-4px"
              right="-4px"
              image={rightDoodle}
              {...doodleProps}
              xl={{
                height: '230px',
                width: '305px',
                bottom: '-20px',
                right: '-5px',
              }}
            />
          )}
          {isTwoCol && centerDoodle && (
            <Image
              height="105px"
              width="492px"
              bottom="55px"
              left="14%"
              transform="rotate(-59deg)"
              image={centerDoodle}
              {...doodleProps}
              xl={{
                bottom: '65px',
                left: '26%',
                transform: 'rotate(-47deg)',
              }}
            />
          )}
        </Container>

        <Flex
          flexDirection="column"
          textAlign="center"
          alignItems="center"
          marginX="auto"
          gap="32px"
          maxWidth={container.md}
          paddingX={32}
          md={{
            flexDirection: isTwoCol ? 'row' : 'column',
            textAlign: isTwoCol ? 'left' : 'center',
            maxWidth: 'unset',
          }}
          xl={{
            paddingX: 0,
          }}
        >
          {heading && (
            <Heading
              as="h2"
              textStyle="xs"
              fontWeight="semiBold"
              textColor={headingColor}
              md={{
                textStyle: 'md',
                paddingLeft: isTwoCol ? 24 : 0,
              }}
              xl={{
                textStyle: 'lg',
                maxWidth: isTwoCol ? '50%' : 'unset',
                paddingLeft: isTwoCol ? 96 : 0,
              }}
            >
              {heading}
            </Heading>
          )}
          <Flex flexDirection="column" gap="24px" flexGrow="1">
            {subheading && (
              <Text
                textColor={textColor}
                textAlign="center"
                fontSize="textLg"
                fontWeight="medium"
                md={{ fontSize: 'textXl' }}
              >
                {RichTextParser(subheading)}
              </Text>
            )}
            <Form
              formId="Subscribe Form | c9325cee-6d27-41b9-9b9d-731d8cf0cf0e"
              variant="Single Field"
              trackingSchema={{
                location: 'NewsletterCTA',
              }}
            />
          </Flex>
        </Flex>
      </Flex>
    </Section>
  );
};

export default NewsletterCta;
