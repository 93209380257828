import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Heading from 'quarks/Heading';
import Image from 'quarks/Image';
import Paragraph from 'quarks/Paragraph';
import Text from 'quarks/Text';
import type { BasicProps } from 'quarks/interpolations/basic';

import Badge from 'molecules/Badge/Badge';
import BadgeGroup from 'molecules/BadgeGroup/BadgeGroup';
import Breadcrumbs from 'molecules/Breadcrumbs/Breadcrumbs';
import Section from 'molecules/Section/Section';

// TODO: add doodles
import formatDate from 'utils/formatDate';
import { optimizeBuilderImage } from 'utils/functions';
import type { BlocksData } from 'utils/readingTime';
import readingTime from 'utils/readingTime';

import type { Builder_AuthorData, Builder_BlogPostCategoryData, Builder_PostData } from 'graphqlTypes';
import type { FC } from 'react';

interface HeroBlog extends BasicProps {
  blogPost?: { data: Builder_PostData };
}

interface BlogData extends Pick<Builder_PostData, 'featuredImage' | 'publishDate' | 'title' | 'featuredImageCaption'> {
  postAuthor?: { value: { name: string; data: Builder_AuthorData; id: string } } | null;
  category?: { value: { data: Builder_BlogPostCategoryData; id: string } } | null;
  blocks?: BlocksData[] | null;
}

const BlogHero: FC<HeroBlog> = ({ blogPost }) => {
  const { postAuthor, category, publishDate, title, blocks, featuredImage, featuredImageCaption }: BlogData = {
    ...blogPost?.data,
  };
  const authorName = postAuthor?.value?.name;
  const authorData = postAuthor?.value?.data;

  const readTime = readingTime(blocks);
  const pathname = 'blog/' + title;

  return (
    <Section __typename="Blog-Hero" background="gray-100">
      <Container position="relative" paddingTop={24}>
        <Container paddingLeft={4} marginBottom={20} md={{ marginBottom: 32 }} xl={{ marginBottom: 58 }}>
          <Breadcrumbs url={pathname} />
        </Container>
        <Flex flexDirection="column" gap="40px" md={{ gap: '64px' }} xl={{ flexDirection: 'row', gap: '30px' }}>
          <Flex flexDirection="column" gap="24px" xl={{ maxWidth: '370px' }}>
            {category && (
              <BadgeGroup
                backgroundColor="primary-50"
                textColor="primary-700"
                title={`${readTime} min read`}
                badgePosition="start"
              >
                <Badge
                  size="sm"
                  textTransform="uppercase"
                  fontWeight="medium"
                  textColor="primary-600"
                  backgroundColor="common-white"
                >
                  <Paragraph>{category?.value?.data?.title}</Paragraph>
                </Badge>
              </BadgeGroup>
            )}
            <Flex flexDirection="column" gap="32px">
              {title && (
                <Heading
                  as="h1"
                  textColor="gray-900"
                  fontWeight="semiBold"
                  textStyle="sm"
                  md={{ textStyle: 'lg', fontWeight: 'semiBold' }}
                >
                  {title}
                </Heading>
              )}
              {authorName && (
                <Flex gap="12px">
                  {authorData?.headshot && (
                    <Flex width="48px" height="48px" borderRadius="50%" overflowX="hidden" overflowY="hidden">
                      <Image
                        image={optimizeBuilderImage(authorData.headshot)}
                        loading="eager"
                        width="100%"
                        height="100%"
                        alt={authorName || 'Lineup.ai Logo'}
                        objectFit="contain"
                      />
                    </Flex>
                  )}
                  <Flex flexDirection="column">
                    <Text textStyle="md" fontWeight="semiBold" textColor="gray-900">
                      {authorName}
                    </Text>
                    {publishDate && (
                      <Text textStyle="md" fontWeight="regular" textColor="gray-700">
                        {formatDate(publishDate)}
                      </Text>
                    )}
                  </Flex>
                </Flex>
              )}
            </Flex>
          </Flex>
          <Flex>
            {featuredImage && (
              <Flex flex="1 0 50%">
                <Container marginX="auto" width="fit-content" maxWidth="100%">
                  <Image
                    image={optimizeBuilderImage(featuredImage)}
                    loading="eager"
                    alt={featuredImageCaption || 'Blog post Featured Image'}
                    objectPosition="center"
                    borderRadius="9px"
                    maxWidth="inherit"
                    sm={{ borderRadius: '16px' }}
                  />
                </Container>
              </Flex>
            )}
          </Flex>
        </Flex>
      </Container>
    </Section>
  );
};

export default BlogHero;
