import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Heading from 'quarks/Heading';
import Text from 'quarks/Text';

import Section from 'molecules/Section/Section';

import ComponentButton from 'components/ComponentButton/ComponentButton';
import type { TimelineProps } from 'components/Timeline/Timeline.config';
import TimelineEntries from 'components/Timeline/TimelineEntries';

import colorizeText from 'utils/colorizeText';
import RichTextParser from 'utils/richTextParser';

import type { Builder_ComponentButton } from 'graphqlTypes';
import type { FC } from 'react';

const ComponentTimeline: FC<TimelineProps> = ({
  eyebrow,
  primaryHeading,
  secondaryHeading,
  subheading,
  buttons,
  entries,
  sectionId,
}) => (
  <Section __typename="ComponentTimeline" id={sectionId}>
    <Container position="relative">
      <Flex
        flexDirection="column"
        gap="24px"
        borderRadius="16px"
        backgroundColor="gray-100"
        paddingY={24}
        paddingX={16}
        md={{ flexDirection: 'row', justifyContent: 'space-between', paddingY: 32, paddingX: 24 }}
        lg={{
          gap: '64px',
          paddingAll: 48,
        }}
      >
        <Flex flexDirection="column" width="100%" md={{ width: '50%', maxWidth: '470px' }}>
          {eyebrow && (
            <Text textStyle="eyebrow" marginBottom={12}>
              {colorizeText(eyebrow, true)}
            </Text>
          )}
          {primaryHeading && (
            <Heading as="h2" textStyle="md" fontWeight="semiBold" xl={{ textStyle: 'lg', fontWeight: 'semiBold' }}>
              {primaryHeading}
            </Heading>
          )}
          {secondaryHeading && (
            <Heading as="h2" textStyle="md" fontWeight="medium" xl={{ textStyle: 'lg', fontWeight: 'medium' }}>
              {secondaryHeading}
            </Heading>
          )}
          {subheading && (
            <Container textColor="gray-700" fontSize="textLg" marginTop={24}>
              {RichTextParser(subheading)}
            </Container>
          )}
          {buttons && (
            <Flex
              width="100%"
              flexDirection="column"
              marginTop={32}
              gap="16px"
              lg={{ flexDirection: 'row', justifyContent: 'flex-start', gap: '24px' }}
            >
              {buttons?.map((button, index) => (
                <ComponentButton
                  width="100%"
                  buttonType={button?.buttonType || 'callToAction'}
                  key={button.button?.value?.id || index}
                  {...(button as Builder_ComponentButton)}
                  lg={{ width: 'fit-content', whiteSpace: 'nowrap' }}
                />
              ))}
            </Flex>
          )}
        </Flex>
        <TimelineEntries entries={entries} />
      </Flex>
    </Container>
  </Section>
);

ComponentTimeline.defaultProps = {
  eyebrow: 'eyebrow',
  primaryHeading: 'Always improving',
  secondaryHeading: 'So what is new?',
  subheading: '<p>Insert Subheading Here</p>',
};

export default ComponentTimeline;
