import { iconValues } from 'atoms/Icons/Icon.types';

import SectionBuilderConfig from 'molecules/Section/Section.config';

import HeadingBuilderConfig from 'components/Heading/Heading.config';

import type { Writeable } from 'utils/typeUtils';

type removeReadOnlyModifier = Writeable<typeof iconValues>;
const FeatureGridBuilderConfig = {
  name: 'FeatureGrid',
  inputs: [
    {
      name: 'background',
      type: 'text',
      defaultValue: 'White',
      enum: ['Gray-900', 'White', 'Gray-100'],
    },
    {
      name: 'variant',
      type: 'text',
      defaultValue: 'Standard',
      enum: ['Standard', 'CTA', 'Employee Benefits', 'Partner Benefits'],
    },
    {
      name: 'useHeading',
      type: 'boolean',
      helperText: 'Set to true to add Heading Component',
      showIf: (options: { get: (arg0: string) => string }) =>
        options.get('variant') === 'Standard' || options.get('variant') === 'Employee Benefits',
    },
    {
      name: 'headingContent',
      type: 'object',
      subFields: [
        ...HeadingBuilderConfig.inputs.filter(
          (input: { name: string }) =>
            input.name !== 'internalName' && input.name !== 'collapseBottomPadding' && input.name !== 'background',
        ),
      ],
      showIf: (options: { get: (arg0: string) => boolean | string }) =>
        options.get('useHeading') === true || options.get('variant') === 'Partner Benefits',
    },
    {
      name: 'includeImage',
      type: 'boolean',
      defaultValue: false,
      helperText: 'Set to true if you want to include an asset. Max of 4 cards allowed with image variant.',
      showIf: (options: { get: (arg0: string) => string }) => options.get('variant') === 'Standard',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onChange: options => {
        if (options.get('includeImage') === true) {
          if (options.get('featureGridCards').length > 4) {
            options.set('featureGridCards', options.get('featureGridCards').slice(0, 4));
            alert('Only 4 cards allowed with image variant.');
          }
        }
      },
    },
    {
      name: 'image',
      type: 'file',
      allowedFileTypes: ['jpeg', 'png', 'jpg', 'svg'],
      required: false,
      showIf: (options: { get: (arg0: string) => boolean }) => options.get('includeImage') === true,
    },
    {
      name: 'imageAltTag',
      type: 'text',
      helperText: 'Include an alt tag for accessibility',
      required: false,
      showIf: (options: { get: (arg0: string) => boolean }) => options.get('includeImage') === true,
    },
    {
      name: 'ctaVariantCards',
      type: 'list',
      helperText: 'Max of 3 cards with CTA variant.',
      subFields: [
        {
          name: 'icon',
          type: 'text',
          enum: iconValues as removeReadOnlyModifier,
        },
        {
          name: 'title',
          type: 'text',
        },
        {
          name: 'description',
          type: 'longText',
        },
        {
          name: 'link',
          type: 'url',
          helperText: 'For use with CTA variant.',
        },
      ],
      showIf: (options: { get: (arg0: string) => string }) => options.get('variant') === 'CTA',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onChange: options => {
        if (options.get('ctaVariantCards').length > 3) {
          options.set('ctaVariantCards', options.get('ctaVariantCards').slice(0, 3));
          alert('Max of 3 cards allowed.');
        }
      },
    },
    {
      name: 'featureGridCards',
      type: 'list',
      helperText: 'Max of 8 cards without image, max of 4 cards with image or benefit variation.',
      subFields: [
        {
          name: 'icon',
          type: 'text',
          enum: iconValues as removeReadOnlyModifier,
        },
        {
          name: 'title',
          type: 'text',
        },
        {
          name: 'description',
          type: 'longText',
        },
      ],
      defaultValue: [
        {
          icon: 'target-04',
          title: 'Feature title',
          description: 'Feature copy, should be relatively short. Keep this to a sentence or two if you can.',
        },
        {
          icon: 'target-04',
          title: 'Feature title',
          description: 'Feature copy, should be relatively short. Keep this to a sentence or two if you can.',
        },
        {
          icon: 'target-04',
          title: 'Feature title',
          description: 'Feature copy, should be relatively short. Keep this to a sentence or two if you can.',
        },
        {
          icon: 'target-04',
          title: 'Feature title',
          description: 'Feature copy, should be relatively short. Keep this to a sentence or two if you can.',
        },
      ],
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onChange: options => {
        if (options.get('includeImage') === true || options.get('variant') === 'Employee Benefits') {
          if (options.get('featureGridCards').length > 4) {
            options.set('featureGridCards', options.get('featureGridCards').slice(0, 4));
            alert('Only 4 cards allowed with image variant.');
          }
        }
        if (options.get('featureGridCards').length > 8) {
          options.set('featureGridCards', options.get('featureGridCards').slice(0, 8));
          alert('Only 8 cards allowed.');
        }
      },
      showIf: (options: { get: (arg0: string) => string }) =>
        options.get('variant') === 'Standard' || options.get('variant') === 'Employee Benefits',
    },
    {
      name: 'benefitsGridCards',
      type: 'list',
      helperText: 'Max of 8 cards.',
      subFields: [
        {
          name: 'icon',
          type: 'text',
          defaultValue: 'target-04',
          enum: iconValues as removeReadOnlyModifier,
        },
        {
          name: 'title',
          type: 'text',
          defaultValue: 'Pension Option',
        },
        {
          name: 'description',
          type: 'longText',
          defaultValue: 'Multiple options for you to choose from.',
        },
      ],
      showIf: (options: { get: (arg0: string) => string }) => options.get('variant') === 'Employee Benefits',
    },
    {
      name: 'partnerBenefitCards',
      type: 'list',
      helperText: 'Max of 6 cards. These cards will display when the toggle is set to Partner',
      subFields: [
        {
          name: 'icon',
          type: 'text',
          defaultValue: 'target-04',
          enum: iconValues as removeReadOnlyModifier,
        },
        {
          name: 'title',
          type: 'text',
          defaultValue: 'Pension Option',
        },
        {
          name: 'description',
          type: 'longText',
          defaultValue: 'Multiple options for you to choose from.',
        },
      ],
      showIf: (options: { get: (arg0: string) => string }) => options.get('variant') === 'Partner Benefits',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onChange: options => {
        if (options.get('partnerBenefitCards').length > 6) {
          options.set('partnerBenefitCards', options.get('partnerBenefitCards').slice(0, 6));
          alert('Max of 6 cards allowed.');
        }
      },
    },
    {
      name: 'customerBenefitCards',
      type: 'list',
      helperText: 'Max of 6 cards. These cards will display when the toggle is set to Customer.',
      subFields: [
        {
          name: 'icon',
          type: 'text',
          defaultValue: 'target-04',
          enum: iconValues as removeReadOnlyModifier,
        },
        {
          name: 'title',
          type: 'text',
          defaultValue: 'Pension Option',
        },
        {
          name: 'description',
          type: 'longText',
          defaultValue: 'Multiple options for you to choose from.',
        },
      ],
      showIf: (options: { get: (arg0: string) => string }) => options.get('variant') === 'Partner Benefits',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onChange: options => {
        if (options.get('customerBenefitCards').length > 6) {
          options.set('customerBenefitCards', options.get('customerBenefitCards').slice(0, 6));
          alert('Max of 6 cards allowed.');
        }
      },
    },
    {
      name: 'button',
      friendlyName: "Button: Figma has as 'Link Color' and 'xLarge'",
      type: 'reference',
      model: 'component-button',
      showIf: (options: { get: (arg0: string) => string }) => options.get('variant') === 'Standard',
    },
    ...SectionBuilderConfig.inputs,
  ],
};

export default FeatureGridBuilderConfig;
