import color from 'atoms/colors/colors';

import Flex from 'quarks/Flex';
import Text from 'quarks/Text';

import TextField from 'molecules/TextField/TextField';

import { dollarIcon, errorMessage } from 'components/ForecastCalculator/CalculatorDataAndTypes';
import type { CalculatorData, HandleInputChange, RowData } from 'components/ForecastCalculator/CalculatorDataAndTypes';
import CalculatorGrid from 'components/ForecastCalculator/CalculatorGrid';

import type { FC } from 'react';

interface CalculatorRowDesktopProps {
  rowTitle: string;
  rowContent: Record<string, RowData | string | number>;
  handleInputChange: HandleInputChange;
  calculatorData: CalculatorData;
  formatInput: HandleInputChange;
  calculatePressed: boolean;
}

const CalculatorRowDesktop: FC<CalculatorRowDesktopProps> = ({
  rowTitle,
  rowContent,
  handleInputChange,
  calculatorData,
  formatInput,
  calculatePressed,
}) => (
  <CalculatorGrid
    backgroundColor="common-white"
    css={`
      &:nth-of-type(even) {
        background: ${color.softWhite[50]};
      }
      &:last-of-type {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    `}
  >
    <Flex>
      <Text textStyle="lg" fontWeight="medium" xl={{ textStyle: 'xl', fontWeight: 'medium' }}>
        {rowTitle}
      </Text>
    </Flex>
    {Object.keys(rowContent).map(week => (
      <Flex key={week} alignItems="flex-start">
        <TextField
          startIcon={dollarIcon}
          type="number"
          value={calculatorData[rowTitle][week] as string}
          placeholder="1000.00"
          onChange={e => handleInputChange(rowTitle, week, e)}
          onBlur={e => formatInput(rowTitle, week, e)}
          error={calculatePressed && calculatorData[rowTitle][week] === ''}
          errorMessage={errorMessage}
        />
      </Flex>
    ))}
  </CalculatorGrid>
);

export default CalculatorRowDesktop;
