import { type FC, useContext, useEffect, useState } from 'react';

import Icon from 'atoms/Icons/Icon';
import container from 'atoms/spacing/containers';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Heading from 'quarks/Heading';
import Image from 'quarks/Image';
import Text from 'quarks/Text';

import successImageFile from 'components/HsForm/assets/confetti.svg';
import type { ComponentFormProps } from 'components/HsForm/types';
import generateCalendlyScript from 'components/HsForm/utils/generateCalendlyScript';

import onKeyDown from 'utils/onKeyDown';

import { ModalContext } from 'contexts/ModalProvider';

declare const window: Window &
  typeof globalThis & {
    hbspt: any;
    woopra: any;
  };

const HsEmbedForm: FC<ComponentFormProps> = ({
  formId,
  onClose,
  formHeading: heading,
  formHeadingType: headingType,
  subHeading: subHeading,
  successImage,
  trackingSchema,
  calendlyRoutingId,
}) => {
  const hsFormId = formId?.split('|')?.pop()?.trim();
  const idForm = `#hubspotForm-${process.env.GATSBY_HUBSPOT_PORTAL_ID}-${hsFormId}`;

  const { modalState } = useContext(ModalContext);
  const [loading, setLoading] = useState<boolean>(true);
  const [submitted, setSubmitted] = useState<boolean>(false);

  const formSubmitAnalytics = () => {
    window?.analytics?.track(
      'Form Submitted',
      {
        label: `${heading} | ${hsFormId}` || 'Unnamed Form',
        location: trackingSchema?.location ? trackingSchema.location : 'Standalone Form Component',
        url: window.location.href,
      },
      {
        context: {
          traits: window.analytics.user().traits(),
        },
      },
    );
  };

  useEffect(() => {
    const loadForm = () => {
      if (window && window?.hbspt) {
        window.hbspt.forms.create({
          region: 'na1',
          portalId: process.env.GATSBY_HUBSPOT_PORTAL_ID,
          formId: hsFormId,
          target: idForm,
          formInstanceId: `${formId}-${Math.random().toString(36).slice(2, 7)}`,
          onFormReady: () => {
            setLoading(false);
          },
          onFormSubmitted: (_: any, data: any) => {
            setSubmitted(true);
            if (data?.submissionValues) {
              if (window && window.woopra) {
                window.woopra.track('Woopra form submission', { ...data?.submissionValues });
              }
            }
            formSubmitAnalytics();
          },
          translations: {
            en: {
              required: 'This field is required.',
              missingSelect: 'Please select an option.',
              forbiddenEmailDomain: 'Please enter a valid business email.',
            },
          },
        });
      } else {
        const script = document.createElement('script');
        script.src = 'https://js.hsforms.net/forms/v2.js';
        script.onload = loadForm;
        document.body.appendChild(script);
      }
      generateCalendlyScript(hsFormId, calendlyRoutingId);
    };

    const loadFormAndInitializeFormatting = (): void => {
      loadForm();
    };

    loadFormAndInitializeFormatting();
  }, [formId]);

  return (
    <Flex
      position="relative"
      flexWrap="wrap"
      alignItems="flex-start"
      gap="32px"
      width="100%"
      maxWidth={container.md}
      backgroundColor="common-white"
      paddingAll={32}
      paddingTop={modalState.open && 40}
      marginX="auto"
      borderRadius="20px"
      boxShadow="xxl"
    >
      {modalState.open && (
        <Icon
          position="absolute"
          id="x-close"
          size={24}
          top="16px"
          right="20px"
          cursor="pointer"
          aria-label="Close Modal Dialog"
          iconColor="gray-600"
          tabIndex={0}
          onClick={onClose && (() => onClose())}
          onKeyDown={onClose && ((e: React.KeyboardEvent<HTMLElement>) => onKeyDown(e, () => onClose()))}
        />
      )}
      {!submitted ? (
        <>
          <Flex flexDirection="column" gap="4px" alignItems="center" flex="1 0 100%">
            {heading && (
              <Heading
                as={headingType || 'h2'}
                fontSize="displayXs"
                lineHeight="displayXs"
                textAlign="center"
                textColor="common-black"
                sm={{ textStyle: 'xs' }}
              >
                {heading}
              </Heading>
            )}
            {subHeading && (
              <Text textStyle="md" textAlign="center" textColor="gray-700" sm={{ textStyle: 'lg' }}>
                {subHeading}
              </Text>
            )}
          </Flex>
          {loading && (
            <Flex width="100%" flexDirection="column" alignItems="center">
              <Text textStyle="eyebrow" textAlign="center" textColor="primary-600">
                Loading... please wait
              </Text>
            </Flex>
          )}
          {formId && idForm && <Container id={idForm.slice(1)} width="100%" />}
        </>
      ) : (
        <Flex width="100%" flexDirection="column" gap="24px" alignItems="center">
          <Flex flexDirection="column" gap="16px" alignItems="center">
            <Heading textStyle="xs" textAlign="center" textColor="common-black">
              Success
            </Heading>
            <Text textStyle="lg" textAlign="center" textColor="gray-700">
              {'Thanks for your interest in Lineup.ai! A representative will reach out shortly.'}
            </Text>
          </Flex>
          {successImage && (
            <Image
              image={successImageFile}
              alt="Image depicting a state of success"
              objectPosition="center"
              borderRadius="9px"
              maxWidth="510px"
              marginX="auto"
              width="100%"
            />
          )}
        </Flex>
      )}
    </Flex>
  );
};

export default HsEmbedForm;
