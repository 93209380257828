// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import hsFormConfigIds from '/public/static/formConfigIds.json';

export const HsFormConfig = {
  name: 'hsForm',
  inputs: [
    {
      name: 'variant',
      type: 'text',
      enum: ['Basic', 'Single Field', 'Send Grid'],
      defaultValue: 'Basic',
      required: true,
    },
    {
      name: 'formId',
      type: 'text',
      enum: hsFormConfigIds,
      defaultValue: hsFormConfigIds[0],
      required: true,
    },
    {
      name: 'formHeading',
      type: 'text',
      defaultValue: 'Placeholder Text',
    },
    { name: 'formHeadingType', type: 'text', enum: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'], defaultValue: 'h2' },
    {
      name: 'subHeading',
      type: 'text',
      defaultValue: 'Fill out this form to begin your journey.',
      helperText: 'Only works with Basic',
    },
    {
      name: 'successImage',
      type: 'boolean',
      defaultValue: true,
      helperText:
        'Only works with Basic. Toggle on to display confetti success image after successful form submission.',
    },
    {
      name: 'calendlyRoutingId',
      type: 'text',
      required: false,
      helperText:
        'id is located on submission url. ie. https://calendly.com/api/form_builder/forms/{CALENDLY_ID}/submissions',
    },
  ],
};

export default HsFormConfig;
