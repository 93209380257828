import { AnimatePresence, m, useReducedMotion } from 'framer-motion';
import { memo, useMemo, useState } from 'react';

import Icon from 'atoms/Icons/Icon';
import color from 'atoms/colors/colors';

import Flex from 'quarks/Flex';
import Text from 'quarks/Text';

import Section from 'molecules/Section/Section';

import ComponentButton from 'components/ComponentButton/ComponentButton';

import RichTextParser from 'utils/richTextParser';

import type { Builder_ComponentButton } from 'graphqlTypes';
import type { FC } from 'react';

export interface BannerProps {
  includeIcon?: boolean;
  heading?: string;
  body?: string;
  button?: { value: Builder_ComponentButton; id: string };
  hideBanner?: boolean;
}

const Banner: FC<BannerProps> = ({ hideBanner, includeIcon, heading, body, button }) => {
  const typename = 'Banner';

  const [bannerVisible, setBannerVisible] = useState(true);

  const reducedMotion = useReducedMotion();

  const handleClose = () => {
    setBannerVisible(false);
  };

  const isButton = button?.value?.data?.label && button.value.data.label.length > 0;

  const isLongContent = useMemo(() => {
    const headingWidthAdjustment = 1.6;
    const htmlChars = /(<([^>]+)>)/gi;
    if (body && heading) {
      const adjHeadingLength = heading.length * headingWidthAdjustment;
      const adjBodyLength = body.replace(htmlChars, '').length;

      return adjHeadingLength + adjBodyLength > 100;
    } else {
      return false;
    }
  }, [body, heading, includeIcon]);

  const rightPadding = {
    sm: 40,
    md: 58,
  } as const;

  const buttonFocus = {
    boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba(255, 255, 255, 0.3)',
    outline: 0,
  } as const;

  return (
    <AnimatePresence>
      {bannerVisible && !hideBanner && (
        <m.div
          key="banner"
          css="overflow: hidden;"
          animate={{
            height: 'auto',
            opacity: 1,
            transition: {
              height: {
                duration: reducedMotion ? 0 : 0.25,
              },
              opacity: {
                duration: reducedMotion ? 0 : 0.25,
              },
            },
          }}
          exit={{
            height: 0,
            opacity: 1,
            transition: {
              height: {
                duration: reducedMotion ? 0 : 0.25,
              },
              opacity: {
                duration: reducedMotion ? 0 : 0.25,
              },
            },
          }}
        >
          <Section
            __typename={typename}
            background="primary-600"
            css={`
              & > div {
                padding-top: 6px;
                padding-bottom: 6px;
              }
            `}
          >
            <Flex
              alignItems={includeIcon && 'center'}
              textAlign={includeIcon && 'center'}
              flexDirection="column"
              gap="6px"
              md={{
                flexDirection: 'row',
                textAlign: 'left',
                alignItems: 'flex-start',
                gap: '24px',
                position: 'relative',
              }}
              xl={{
                alignItems: 'center',
              }}
            >
              {includeIcon && (
                <Icon
                  id="bell-ringing-03"
                  size={20}
                  iconColor="primary-600"
                  wrapped
                  css={`
                    padding: 8px;
                  `}
                />
              )}
              <Flex
                flexDirection="column"
                gap="6px"
                alignItems={includeIcon && 'center'}
                paddingRight={!includeIcon && rightPadding['sm']}
                width="100%"
                md={{
                  flexDirection: `${includeIcon ? 'column' : 'row'}`,
                  flexWrap: 'wrap',
                  alignItems: 'flex-start',
                  gap: '6px',
                  paddingRight: rightPadding['md'],
                }}
                xl={{
                  flexDirection: 'row',
                  gap: '16px',
                  alignItems: 'center',
                  paddingRight: 0,
                  justifyContent: `${includeIcon || isButton || isLongContent ? 'flex-start' : 'center'}`,
                }}
              >
                <Flex
                  flexDirection="column"
                  gap="4px"
                  width="100%"
                  md={{
                    flexDirection: `${includeIcon || isButton || isLongContent ? 'column' : 'row'}`,
                    gap: `${includeIcon || isButton || isLongContent ? '4px' : '16px'}`,
                  }}
                  xl={{
                    flexDirection: `${isLongContent ? 'column' : 'row'}`,
                    gap: `${isLongContent ? '4px' : '16px'}`,
                    maxWidth: `${includeIcon ? '700px' : '775px'}`,
                  }}
                >
                  {heading && (
                    <Text as="h2" textColor="common-white" textStyle="eyebrow">
                      {heading}
                    </Text>
                  )}
                  {body && (
                    <Text
                      textColor="common-white"
                      textStyle="md"
                      css={`
                        a {
                          color: ${color.common['white']};
                          text-decoration: underline;
                        }
                      `}
                    >
                      {RichTextParser(body)}
                    </Text>
                  )}
                </Flex>

                {isButton && (
                  <ComponentButton
                    buttonType="callToAction"
                    button={{
                      value: {
                        data: {
                          label: button.value?.data?.label,
                          size: 'large',
                          endIcon: '->',
                          link: button.value?.data?.link || '',
                          hierarchy: 'Link White',
                        },
                      },
                    }}
                    paddingAll={0}
                    focus={buttonFocus}
                    md={{
                      fontSize: 'textMd',
                    }}
                    xl={{
                      fontSize: 'textLg',
                      marginLeft: 'auto',
                      marginRight: 72,
                    }}
                    trackingSchema={{
                      hierarchy: '1 of 1',
                      location: typename,
                    }}
                  />
                )}
              </Flex>
              <ComponentButton
                onClick={handleClose}
                labelIcon="x-close"
                paddingAll={16}
                position="absolute"
                top="16px"
                right="16px"
                focus={buttonFocus}
                md={{
                  top: '6px',
                  right: '0px',
                }}
                xl={{
                  top: 'calc(50% - 10px)',
                }}
                css={`
                  margin: -16px !important;
                  svg {
                    transition: stroke 150ms ease-in-out;
                    stroke: ${color.common.white};
                  }
                  &:hover {
                    svg {
                      stroke: ${color.primary[100]};
                    }
                  }
                `}
                trackingSchema={{
                  label: 'X (close)',
                  hierarchy: '1 of 1',
                  location: typename,
                }}
              />
            </Flex>
          </Section>
        </m.div>
      )}
    </AnimatePresence>
  );
};

export default memo(Banner);
