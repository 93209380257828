import color, { colorListArray, getColor } from 'atoms/colors/colors';
import gradient from 'atoms/colors/gradients';

import type { ComponentButtonProps } from 'components/ComponentButton/buttonTypeConfig';

import backgroundImageGenerator from 'utils/grabBackground';
import { doesArrayContainItem, keyExistsOnObject } from 'utils/typeUtils';

import type { Builder_HeaderMenuItemData, Builder_MenuItemData } from 'graphqlTypes';
import type { DefaultTheme } from 'styled-components';

/**
 * @param hexString a valid hex string with or without the leading #
 * @returns an array with matched [RRR, BBB, GGG] values
 */
export const hexToRGB = (hexString: string) => {
  const hex = hexString.length === 7 ? hexString.substring(1).match(/.{1,2}/g) : hexString.match(/.{1,2}/g);
  const rgbArray = hex && [parseInt(hex[0], 16), parseInt(hex[1], 16), parseInt(hex[2], 16)];
  if (!rgbArray) {
    throw new Error('You must provide a valid hex string');
  }

  return rgbArray as [number, number, number];
};

/**
 * @param string any string, including ones already in camelCase
 * @returns the string in camelCase form
 */
export const toCamelCase = (string?: string | null) =>
  string
    ? string
        .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => (index === 0 ? word.toLowerCase() : word.toUpperCase()))
        .replace(/\s+/g, '')
    : '';

/**
 * @param id any string, including special characters
 * @returns an all lowercase string with spaces replaced by hyphens
 */
export const stringToKebabCase = (id: string) =>
  id
    .toLowerCase()
    .replace(/[^A-Za-z0-9 ]/g, '')
    .trim()
    .replace(/\s+/g, '-');

const hexToRgb = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : { r: 0, g: 0, b: 0 };
};

const colorIsDark = (hex: string, threshold = 127.5) => {
  const { r, g, b } = hexToRgb(hex);
  const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  return hsp < threshold;
};

export const optimizeBuilderImage = (image: string) => `${image}?format=webp`;

const gradientBackgrounds = {
  convex: `radial-gradient(124% 100% at 50% 100%, ${color.primary[25]} 0%, ${color.primary[25]} 99.99%, ${color.common.white} 100%)`,
  convexMobile: `radial-gradient(246.52% 100% at 50.13% 100%, ${color.primary[25]} 0%, ${color.primary[25]} 99.96%, ${color.common.white} 100%)`,
  concave: `radial-gradient(65.62% 65.62% at 50% 34.37%, ${color.primary[25]} 0%, ${color.primary[25]} 99.5%, ${color.common.white} 100%)`,
  concaveMobile: `radial-gradient(193.61% 135.62% at 50.13% -35.62%, ${color.primary[25]} 0%, ${color.primary[25]} 99.5%, ${color.common.white} 100%)`,
  darkConcave: `radial-gradient(65.62% 65.62% at 50% 34.37%, ${color.gray[900]} 0%, ${color.gray[900]} 99.5%, ${color.common.white} 100%)`,
  darkConcaveMobile: `radial-gradient(193.61% 135.62% at 50.13% -35.62%, ${color.gray[900]} 0%, ${color.gray[900]} 99.5%, ${color.common.white} 100%)`,
  darkConcaveHalf: `radial-gradient(68.15% 47.74% at 50.13% 18.78%, ${color.gray[900]} 0%, ${color.gray[900]} 99.75%, ${color.primary[25]} 100%)`,
  darkConcaveHalfMobile: `radial-gradient(189.81% 132.96% at 50.13% -34.43%, ${color.gray[900]} 0%, ${color.gray[900]} 99.75%, ${color.primary[25]} 100%)`,
  darkConvex: `radial-gradient(100% 100% at 50% 100%, ${color.primary[25]} 0%, ${color.gray[900]} 0%, ${color.gray[900]} 99.5%, ${color.common.white} 100%)`,
  darkConvexMobile: `radial-gradient(246.52% 100% at 50.13% 100%, ${color.gray[900]} 0%, ${color.gray[900]} 99.5%, ${color.common.white} 100%)`,
  darkConvexGrey: `radial-gradient(100% 100% at 50% 100%, ${color.primary[25]} 0%, ${color.gray[900]} 0%, ${color.gray[900]} 99.5%, ${color.gray[100]} 100%)`,
  darkConvexGreyMobile: `radial-gradient(246.52% 100% at 50.13% 100%, ${color.gray[900]} 0%, ${color.gray[900]} 99.5%, ${color.gray[100]} 100%)`,
  darkSplit: `linear-gradient(180deg, ${color.common.white} 51.55%, ${color.gray[900]} 51.56%)`,
  grayDarkSplit: `linear-gradient(180deg, ${color.primary[25]} 51.55%, ${color.gray[900]} 51.56%)`,
  darkSplitMobile: `linear-gradient(180deg, ${color.common.white} 51.55%, ${color.gray[900]} 51.56%)`,
  heroPricing: `radial-gradient(100% 65.62% at 50% 4.37%, ${color.primary[25]} 0%, ${color.primary[25]} 99.5%, ${color.common.white} 100%)`,
  heroPricingMobile: `radial-gradient(100% 56.7% at 50% 11.99%, ${color.primary[25]} 0%, ${color.primary[25]} 99.99%, ${color.common.white} 100%)`,
  blogListing: `linear-gradient(180deg,  ${color.primary[25]} -14.97%,  ${color.primary[25]} 13%, ${color.common.white} 13%), linear-gradient(180deg, ${color.common.white} 0%, ${color.common.white} 52.59%, ${color.common.white} 52.6%);`,
  resourceListing: `radial-gradient(55% 50% at 50% 0%, ${color.gray[100]} 0%, ${color.gray[100]} 99.99%, ${color.common.white} 100%);`,
};

/**
 * @param typename component content type, @param name the name of the background selected in CMS, @param width the width of the page
 * @param name
 * @param width
 * @returns `backgroundPath`: a file path corresponding to the appropriate static image
 */
export const getBackground = (typename = '', name: string | null = 'White', width: number) => {
  if (!typename || !name || name === 'White') {
    return {
      backgroundPath: undefined,
    };
  }

  const camelCasedName = toCamelCase(name);
  const camelCasedWidth = camelCasedName === 'heroPricing' ? 1280 : 576;

  const camelCasedGradient = width < camelCasedWidth ? `${camelCasedName}Mobile` : camelCasedName;

  const backgroundGradient = keyExistsOnObject(gradientBackgrounds, camelCasedGradient)
    ? gradientBackgrounds[camelCasedGradient]
    : undefined;
  if (backgroundGradient) {
    return {
      backgroundPath: undefined,
      backgroundGradient,
    };
  }

  if (doesArrayContainItem(colorListArray, camelCasedName)) {
    return {
      backgroundColor: camelCasedName,
      backgroundPath: undefined,
      backgroundGradient,
    };
  }
  const simplifiedType = typename.replace('Component', '');
  const componentName = `${simplifiedType.charAt(0).toLowerCase()}${simplifiedType.slice(1)}`;
  const backgroundName = `${name.charAt(0).toUpperCase()}${camelCasedName.slice(1)}`;
  const backgroundWidth = [376, 992, 1700].find(possibleSize => possibleSize > width) || 1700;
  const backgroundPath = `${componentName}${backgroundName}${backgroundWidth}`;
  const backgroundData = backgroundPath.replace('-', '');
  const backgroundImage = backgroundImageGenerator(backgroundData);

  return { backgroundImage };
};

export const getBackgroundIsDark = (name: string | null = 'White') => {
  if (name === 'White') {
    return false;
  }
  const darkBackgrounds = ['darkConcave', 'darkConvex'];
  const camelCasedName = toCamelCase(name);

  let backroundColorIsDark = false;
  if (doesArrayContainItem(colorListArray, camelCasedName)) {
    const retrieveHexValue = getColor(color as DefaultTheme['palette'], camelCasedName);
    backroundColorIsDark = (retrieveHexValue && colorIsDark(retrieveHexValue)) || false;
  }

  const backgroundIsDark =
    backroundColorIsDark || darkBackgrounds.some(darkBackground => camelCasedName.includes(darkBackground));

  return backgroundIsDark;
};

/**
 * @param value any string
 * @returns if string is valid gradient atom, returns `gradient`. if not, `url(inputString)`
 */
export const parseBackgroundGradient = (value = '') => {
  const gradientName = value.split('-')[1];

  return keyExistsOnObject(gradient, gradientName) ? gradient[gradientName] : `url(${value})`;
};

/**
 * @param cookieName any string, including special characters
 * @returns a cookie
 */
export const getCookie = (cookieName: string) => {
  const name = cookieName + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  if (decodedCookie) {
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
  }

  return '';
};

export const unescapehtml = (htmlStr: string) => {
  htmlStr = htmlStr.replace(/&lt;/g, '<');
  htmlStr = htmlStr.replace(/&gt;/g, '>');
  htmlStr = htmlStr.replace(/&quot;/g, '"');
  htmlStr = htmlStr.replace(/&#39;/g, "'");
  htmlStr = htmlStr.replace(/&amp;/g, '&');
  htmlStr = htmlStr.replace(/<\/?[^>]+(>|$)/g, '');
  htmlStr = htmlStr.replace('The Opportunity:', '');

  return htmlStr;
};

export const getButtonHierarchy = (buttonList: ComponentButtonProps[], button: ComponentButtonProps) => {
  const allButtons = buttonList?.map(item => item?.button?.model === 'component-button' && item.button.id);

  const buttonPosition = allButtons ? allButtons?.indexOf(button?.button?.id || false) + 1 : 0;

  return `${buttonPosition} of ${allButtons.length}`;
};

export const getLinkHierarchy = (
  linkList: Builder_MenuItemData[] | Builder_HeaderMenuItemData[],
  linkPosition: number,
) => `${linkPosition + 1} of ${linkList.length}`;

/**
 * @param str string to validate
 * @returns if str is valid JSON
 */
export const isValidJson = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }

  return true;
};

/**
 * @param value If passed a string, will attempt to parse it as an integer. If passed a number, will return it as-is
 * @returns Returns parsed value or as-is value. If no integer is found, returns NaN
 */
export const safeParseInt = (value: string | number): number => {
  let parsedValue = NaN;
  if (typeof value === 'string' && value?.trim() !== '') {
    parsedValue = parseInt(value);
  }
  if (typeof value === 'number') {
    parsedValue = value;
  }

  return parsedValue;
};
