import Icon from 'atoms/Icons/Icon';
import type { iconIds } from 'atoms/Icons/Icon.types';
import color from 'atoms/colors/colors';

import Flex from 'quarks/Flex';
import Link from 'quarks/Link';
import Text from 'quarks/Text';
import type { BasicProps } from 'quarks/interpolations/basic';

import HeroFeature from 'components/Hero/HeroForm/Feature';

import type { FC } from 'react';

export interface FeatureProps extends BasicProps {
  title?: string;
  description?: string;
  copy?: string;
  icon?: iconIds;
  backgroundIsDark?: boolean;
  isHero?: boolean;
  link?: string;
}

const Feature: FC<FeatureProps> = ({ backgroundIsDark, title, description, copy, icon, isHero, link }) => {
  if (isHero) {
    return <HeroFeature copy={copy} icon={icon} />;
  } else {
    return (
      <Flex gap="16px" borderRadius="12px" transition="background-color 0.150s" md={{ gap: '24px' }}>
        {icon && (
          <Flex height="fit-content">
            <Icon size={16} id={icon} hasGradientBorder isBackgroundDark={backgroundIsDark} mdSize={24} wrapped />
          </Flex>
        )}
        <Flex alignSelf="end" width="100%" flexDirection="column" gap="8px" md={{ gap: '12px' }}>
          {title && (
            <Flex justifyContent="space-between" css="> svg { transition: 150ms; }">
              {link ? (
                <Link href={link}>
                  <Text
                    textStyle="lg"
                    fontWeight="bold"
                    textColor={backgroundIsDark ? 'common-white' : 'gray-900'}
                    display="flex"
                    gap="8px"
                    alignItems="center"
                    md={{ textStyle: 'xl', fontWeight: 'bold' }}
                    xl={{ textStyle: 'xxl', fontWeight: 'bold' }}
                    css={`
                      :hover {
                        color: ${backgroundIsDark ? color.primary[100] : color.primary[700]};
                        svg {
                          fill: ${backgroundIsDark ? color.primary[100] : color.primary[700]};
                          stroke: ${backgroundIsDark ? color.primary[100] : color.primary[700]};
                        }
                      }
                    `}
                  >
                    {title}
                    <Icon id="arrow-right" size={20} isBackgroundDark={backgroundIsDark} />
                  </Text>
                </Link>
              ) : (
                <Text
                  textStyle="lg"
                  fontWeight="bold"
                  textColor={backgroundIsDark ? 'common-white' : 'gray-900'}
                  md={{ textStyle: 'xl', fontWeight: 'bold' }}
                  xl={{ textStyle: 'xxl', fontWeight: 'bold' }}
                >
                  {title}
                </Text>
              )}
            </Flex>
          )}
          {description && (
            <Text
              as="div"
              textStyle="md"
              textColor={backgroundIsDark ? 'gray-50' : 'gray-700'}
              overflowY="hidden"
              md={{ textStyle: 'lg' }}
              transition="max-height 0.150s ease"
            >
              {description}
            </Text>
          )}
        </Flex>
      </Flex>
    );
  }
};

export default Feature;
