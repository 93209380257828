import Icon from 'atoms/Icons/Icon';
import type { iconIds } from 'atoms/Icons/Icon.types';

import Flex from 'quarks/Flex';
import Text from 'quarks/Text';
import type { BasicProps } from 'quarks/interpolations/basic';

import type { FC } from 'react';

interface FeatureGridCardProps extends BasicProps {
  /**
   * If selected Determines what Icon gets displayed.
   */
  icon?: iconIds;
  /**
   * Determines the text for the title
   */
  title?: string;
  /**
   * Determines the text for the description
   */
  description?: string;
  /**
   * Determines color scheme if Background is dark or not.
   */
  backgroundIsDark?: boolean;
}

const FeatureGridCard: FC<FeatureGridCardProps> = ({ icon, title, description, backgroundIsDark }) => (
  <Flex flexDirection="column" marginAll="auto" flexWrap="wrap" gap="6px" width="100%" height="100%">
    {icon && <Icon size={20} id={icon} hasGradientBorder isBackgroundDark={backgroundIsDark} wrapped />}
    {title && (
      <Text
        textColor={backgroundIsDark ? 'common-white' : 'gray-900'}
        textStyle="lg"
        fontWeight="semiBold"
        marginTop={4}
        md={{ textStyle: 'xl', fontWeight: 'semiBold', marginTop: 8 }}
      >
        {title}
      </Text>
    )}
    {description && (
      <Text textColor={backgroundIsDark ? 'gray-25' : 'gray-700'} textStyle="md">
        {description}
      </Text>
    )}
  </Flex>
);

export default FeatureGridCard;

FeatureGridCard.defaultProps = {};
