import React from 'react';

import { media } from 'atoms/breakpoints/breakpoints';

import Flex from 'quarks/Flex';
import type { FlexProps } from 'quarks/interpolations/flex';

import ReverseChildren from 'utils/reverseChildren';

import type { FC, ReactNode } from 'react';

interface ButtonWrapperProps extends FlexProps {
  children?: ReactNode | ReactNode[];
  reversed?: boolean;
  offsetConvPanelOverride?: boolean;
}

const ButtonWrapper: FC<ButtonWrapperProps> = ({
  children,
  reversed = false,
  offsetConvPanelOverride = false,
  ...props
}) => {
  const breakPoint = offsetConvPanelOverride ? media.md : media.sm;

  return React.Children.count(children) > 1 ? (
    <Flex
      flexDirection="column"
      flexWrap="nowrap"
      gap="16px"
      md={{ gap: '24px' }}
      justifyContent="center"
      width="100%"
      alignItems="center"
      className="button-wrapper"
      whiteSpace={offsetConvPanelOverride && 'nowrap'}
      css={`
        &&& > a,
        &&& > button {
          width: 100% !important;
        }
        ${breakPoint} {
          &&& > a,
          &&& > button {
            padding-left: 20px;
            width: initial !important;
          }
        }
      `}
      sm={{
        flexDirection: 'row',
        width: 'unset',
      }}
      {...props}
    >
      <ReverseChildren reversed={reversed}>{children as ReactNode[]}</ReverseChildren>
    </Flex>
  ) : (
    <Flex
      width="100%"
      sm={{ width: 'initial' }}
      css={`
        &&& > a,
        &&& > button {
          width: 100%;
        }
        ${media.sm} {
          &&& > a,
          &&& > button {
            width: initial;
          }
        }
      `}
      {...props}
    >
      {children}
    </Flex>
  );
};
export default ButtonWrapper;
