import { media } from 'atoms/breakpoints/breakpoints';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Grid from 'quarks/Grid';
import type { BasicProps } from 'quarks/interpolations/basic';

import type { cardContentTypeList } from 'components/CardDeck/cardDeckPropsConfig';
import type { CardProps } from 'components/Cards/EntryCard/EntryCard';
import Card from 'components/Cards/EntryCard/EntryCard';
import FeaturesCard from 'components/Cards/FeaturesCard/FeaturesCard';
import type { FeaturesCardProps } from 'components/Cards/FeaturesCard/featuresCardConfigAndTypes';
import HistoryCard from 'components/Cards/HistoryCard/HistoryCard';
import type { HistoryCardProps } from 'components/Cards/HistoryCard/historyCardConfigTypes';
import TeamCard from 'components/Cards/TeamCard/TeamCard';
import type { TeamCardProps } from 'components/Cards/TeamCard/teamCardConfigTypes';

import type { FC } from 'react';

interface StandardCardDeckProps extends BasicProps {
  cards: CardProps[] | FeaturesCardProps[] | HistoryCardProps[] | TeamCardProps[];
  cardContentType: typeof cardContentTypeList[number];
  backgroundIsDark: boolean;
}

const cardGenerator = (
  card: CardProps | FeaturesCardProps | HistoryCardProps | TeamCardProps,
  cardContentType: typeof cardContentTypeList[number],
  backgroundIsDark: boolean,
) => {
  switch (cardContentType) {
    case 'Entry Cards':
      return <Card {...card} display="block" />;
    case 'Features Cards':
      return <FeaturesCard {...card} backgroundIsDark={backgroundIsDark} />;
    case 'Team Cards':
      return <TeamCard {...card} backgroundIsDark={backgroundIsDark} />;
    case 'History Cards':
      return <HistoryCard {...card} />;
    default:
      return null;
  }
};

const StandardCardDeck: FC<StandardCardDeckProps> = ({ cards, cardContentType, backgroundIsDark }) => (
  <Flex flexDirection="column">
    <Grid
      gap="32px"
      css={`
        justify-content: center;
        justify-items: center;

        > .features-card {
          width: 100%;
          ${media.md} {
            justify-self: stretch;
            align-self: stretch;
            grid-column: span 2;

            /* two child cards */
            &:last-child:nth-child(3n - 1) {
              grid-column-end: -2;
            }
            &:nth-last-child(2):nth-child(3n + 1) {
              grid-column-end: 4;
            }
            /* single child */
            &:last-child:nth-child(3n - 2) {
              grid-column-end: 5;
            }
          }
        }
      `}
      gridTemplate="none"
      gridTemplateColumns={cardContentType === 'Team Cards' && '1fr'}
      sm={{ gridTemplateColumns: cardContentType === 'Features Cards' && 'repeat(2, 1fr)' }}
      md={{ gridTemplateColumns: cardContentType === 'Features Cards' ? 'repeat(6, 1fr)' : 'repeat(2, 1fr)' }}
      xl={{ gridTemplateColumns: cardContentType === 'Features Cards' ? 'repeat(6, 1fr)' : 'repeat(3, 1fr)' }}
    >
      {cards?.map((card, index) => (
        <Container
          width="100%"
          overflowY="visible"
          className={cardContentType === 'Features Cards' ? 'features-card' : ''}
          // eslint-disable-next-line react/no-array-index-key
          key={`card-${index}`}
        >
          {cardGenerator(card, cardContentType, backgroundIsDark)}
        </Container>
      ))}
    </Grid>
  </Flex>
);

export default StandardCardDeck;
