import type { FC, ReactNode } from 'react';

interface MainProps {
  children: ReactNode | ReactNode[];
}

const Main: FC<MainProps> = ({ children }) => (
  <main
    css={`
      .builder-block {
        content-visibility: unset !important;
        contain-intrinsic-size: unset !important;
      }
    `}
  >
    {children}
  </main>
);

export default Main;
