import type { BasicProps } from 'quarks/interpolations/basic';

import FormConfig from 'components/HsForm/HsForm.config';
import type { ComponentFormProps } from 'components/HsForm/types';

export interface RevenueCardTypes extends BasicProps {
  heading?: string;
  message?: string;
}
export interface ForecastCardTypes extends RevenueCardTypes {
  buttonText?: string;
  hsForm?: ComponentFormProps;
}

export const revenueCardsBuilderDataField = [
  {
    name: 'heading',
    type: 'string',
  },
  {
    name: 'message',
    type: 'string',
  },
];

export const forecastCardBuilderDataField = [
  {
    name: 'buttonText',
    type: 'string',
  },
  {
    name: 'hsForm',
    type: 'object',
    subFields: [...FormConfig.inputs],
  },
];
