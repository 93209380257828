import { useMediaQuery } from '@mui/material';

import { media } from 'atoms/breakpoints/breakpoints';

import Flex from 'quarks/Flex';
import Paragraph from 'quarks/Paragraph';

import Button, { handleButtonTracking } from 'molecules/Button/Button';
import Select from 'molecules/Select/Select';

import type { Dispatch, FC, SetStateAction } from 'react';

export interface FAQButton {
  id?: string;
  title?: string;
}

export interface FAQSidebarProps {
  label?: string;
  buttons?: FAQButton[];
  activeAccordion: string | null;
  setActiveAccordion: Dispatch<SetStateAction<string | null>>;
}

const FAQSideBar: FC<FAQSidebarProps> = ({ label, buttons, activeAccordion, setActiveAccordion }) => {
  const isDesktop = useMediaQuery(media.xl);

  const handleClick = (title: string) => {
    const trackingScheme = {
      label: title,
      location: 'FAQ',
    };

    handleButtonTracking(trackingScheme);

    setActiveAccordion(title);
  };

  return (
    <Flex position="static" width="100%" flexDirection="column" gap="20px">
      {isDesktop ? (
        <>
          {label && (
            <Paragraph textStyle="lg" fontWeight="semiBold" textColor="gray-900">
              {label}
            </Paragraph>
          )}
          <Flex flexDirection="column" gap="6px">
            {buttons?.map(({ id, title }) => {
              if (!title) {
                return;
              }

              return (
                <Button
                  key={id}
                  justifyContent="flex-start"
                  width="100%"
                  paddingY={10}
                  paddingX={14}
                  borderRadius="6px"
                  backgroundColor={activeAccordion === title ? 'primary-50' : 'common-transparent'}
                  fontSize="textMd"
                  lineHeight="textMd"
                  textColor={activeAccordion === title ? 'primary-900' : 'gray-700'}
                  fontWeight="medium"
                  boxShadow="none"
                  hover={{
                    backgroundColor: 'primary-300',
                    textColor: 'common-white',
                  }}
                  onClick={() => handleClick(title)}
                >
                  {title}
                </Button>
              );
            })}
          </Flex>
        </>
      ) : (
        <>
          {buttons && (
            <Select
              list={buttons.map((link: FAQButton) => link.title)}
              defaultValue="View All"
              backgroundColor="common-white"
              onChange={e => setActiveAccordion(e.target.value)}
            />
          )}
        </>
      )}
    </Flex>
  );
};

export default FAQSideBar;
