import type { BasicProps } from 'quarks/interpolations/basic';

import type { CardProps } from 'components/Cards/EntryCard/EntryCard';
import type { FeaturesCardProps } from 'components/Cards/FeaturesCard/featuresCardConfigAndTypes';
import type { HistoryCardProps } from 'components/Cards/HistoryCard/historyCardConfigTypes';
import type { TeamCardProps } from 'components/Cards/TeamCard/teamCardConfigTypes';
import type { ButtonTypes } from 'components/ComponentButton/buttonTypeConfig';

import type { DoodleKey } from 'utils/doodleGenerator';

export const cardContentTypeList = [
  'Entry Cards',
  'Features Cards',
  'Team Cards',
  'History Cards',
  'Newsroom Cards',
] as const;
export const cardDeckTypeList = ['Standard', 'Carousel'] as const;

export interface CardDeckProps extends BasicProps {
  /**
   * Determines the Card Deck's Background Color Scheme or Gradient
   */
  background?: string;
  /**
   * Determines if Card is: Entry Card, Features Card, History Card, or Team Card
   */
  cardContentType: typeof cardContentTypeList[number];
  /**
   * Determines if Card Deck: is Carousel or Standard Card Deck
   */
  cardDeckType: typeof cardDeckTypeList[number];
  /**
   * Determines what doodle gets selected and displayed
   */
  doodle?: DoodleKey;
  /**
   * Determines the text for the heading
   */
  eyebrow?: string;
  /**
   * Determines the text for the heading
   */
  heading?: string;
  /**
   * Determines the text for the subheading
   */
  subheading?: string;

  buttonGroup?: ButtonTypes;
  /**
   * Returns an object that holds two arrays of objects holding card data.
   */
  entryCards?: {
    articleCards?: { card: { value?: { data?: CardProps } } }[];
    resourceCards?: { card: { value?: { data?: CardProps } } }[];
  };
  /**
   * List of Features Cards
   */
  featuresCards?: Array<FeaturesCardProps>;
  /**
   * List of History Cards
   */
  historyCards?: Array<HistoryCardProps>;
  /**
   * List of Team Cards
   */
  teamCards?: Array<TeamCardProps>;
  /**
   * Passed via BuilderComponent through Data prop, holds category data since Builder does not permit nested reference data.
   */
  builderState?: { state: { entryCategories: string } };
  /**
   * For usage for development purposes only when edge cases appear.
   */
  hideSwitch?: boolean;
  /**
   * Sets section id for anchor link.
   */
  sectionId?: string;
}
