const scrollToSection = (id: string) => {
  const target = window?.document?.getElementById(id);
  if (!target) {
    return;
  }
  const yOffset = -100;
  const y = target.getBoundingClientRect().top + window.pageYOffset + yOffset;

  if (target) {
    window.scrollTo({
      top: y,
      behavior: 'smooth',
    });
  }
};

export default scrollToSection;
