import { graphql, useStaticQuery } from 'gatsby';

const useGatedResourcePosts = () => {
  const data = useStaticQuery(graphql`
    query AllGatedResources {
      allBuilderModels {
        gatedResource(options: { includeRefs: true, cachebust: true }, limit: 1000) {
          data {
            category
            featuredImage
            featuredImageCaption
            publishDate
            title
            url
            description
            blocks
          }
          id
        }
      }
    }
  `);

  return data.allBuilderModels.gatedResource;
};

export default useGatedResourcePosts;
