import { memo } from 'react';

import color from 'atoms/colors/colors';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Image from 'quarks/Image';
import Link from 'quarks/Link';

import { sectionMaxWidth, tabletMaxWidth } from 'molecules/Section/Section';

import ComponentButton from 'components/ComponentButton/ComponentButton';
import MenuItems from 'components/Header/DesktopHeaderComponents/MenuItems';
import type { GeneralHeaderProps } from 'components/Header/Header';
import HamburgerMenu from 'components/Header/MobileHeaderComponents/HamburgerMenu';
import MobileNavMenu from 'components/Header/MobileHeaderComponents/MobileNavMenu';

import type { Builder_ComponentHeaderData } from 'graphqlTypes';
import type { Dispatch, FC, MutableRefObject, SetStateAction } from 'react';

interface FullHeaderProps extends Builder_ComponentHeaderData, GeneralHeaderProps {
  isMobileNavOpen: boolean;
  openMenu: number | null;
  handleMenuClick: (menuIndex: number) => void;
  setOpenMenu: Dispatch<SetStateAction<number | null>>;
  mobileNavMenuRef: MutableRefObject<null>;
  setIsMobileNavOpen: Dispatch<SetStateAction<boolean>>;
}

const Header: FC<FullHeaderProps> = ({
  navLoginButton,
  navRegisterButton,
  menuItems,
  pageHasScrolled,
  isMobileNavOpen,
  openMenu,
  borderBottomValue,
  mobileHeaderHeight,
  desktopHeaderHeight,
  handleMenuClick,
  setOpenMenu,
  mobileNavMenuRef,
  setIsMobileNavOpen,
  modalState,
  isDarkHeader,
}) => (
  <Container
    position={modalState.open ? 'inherit' : 'fixed'}
    top={pageHasScrolled || isMobileNavOpen ? 0 : undefined}
    backgroundColor={isDarkHeader ? 'gray-900' : 'common-white'}
    borderBottom={pageHasScrolled || openMenu || isMobileNavOpen || isDarkHeader ? `${borderBottomValue}px solid` : ''}
    boxShadow={isMobileNavOpen ? 'lg' : undefined}
    borderBottomColor={isDarkHeader ? 'gray-800' : 'gray-100'}
    zIndex={100}
    as="header"
    width="100%"
  >
    <Container
      as="nav"
      position="relative"
      zIndex={100}
      marginX="auto"
      marginY={0}
      paddingX={24}
      maxWidth={tabletMaxWidth}
      xl={{ maxWidth: sectionMaxWidth }}
    >
      <Flex
        justifyContent="space-between"
        alignItems="center"
        minHeight={`${mobileHeaderHeight}px`}
        xl={{ minHeight: `${desktopHeaderHeight}px` }}
      >
        <Flex gap="40px" alignItems="center">
          <Link
            href="/"
            trackingSchema={{
              label: 'Logo',
              hierarchy: '1 of 1',
              location: 'Full Header',
            }}
          >
            <Image
              alignSelf="center"
              width="106px"
              image={isDarkHeader ? '/images/lineup_Dark_Logo.svg' : '/images/lineup_Light_Logo.svg'}
              alt="lineup-logo"
              loading="eager"
            />
          </Link>
          <Container display="none" xl={{ display: 'flex' }}>
            <MenuItems
              navbarHeight={desktopHeaderHeight}
              openMenu={openMenu}
              handleMenuClick={handleMenuClick}
              menuItems={menuItems}
              hoverOpenMenu={setOpenMenu}
              mobileNavRef={mobileNavMenuRef}
              isDarkHeader={isDarkHeader}
            />
          </Container>
        </Flex>
        <HamburgerMenu
          isMobileNavOpen={isMobileNavOpen}
          setIsMobileNavOpen={() => setIsMobileNavOpen(!isMobileNavOpen)}
        />
        <Flex display="none" xl={{ display: 'flex', gap: '30px' }}>
          {navLoginButton && (
            <ComponentButton
              buttonType="callToAction"
              button={navLoginButton}
              trackingSchema={{
                hierarchy: '1 of 2',
                location: 'Header',
              }}
              css={`
                color: ${isDarkHeader ? 'white !important' : null};
                &:hover {
                  color: ${isDarkHeader ? color.primary[100] : null} !important;
                }
              `}
            />
          )}
          {navRegisterButton && (
            <ComponentButton
              buttonType="callToAction"
              button={navRegisterButton}
              trackingSchema={{
                hierarchy: '2 of 2',
                location: 'Header',
              }}
            />
          )}
        </Flex>
      </Flex>
      <Flex xl={{ display: 'none' }} ref={mobileNavMenuRef}>
        <MobileNavMenu
          desktopNavbarHeight={desktopHeaderHeight}
          menus={menuItems}
          mobileNavbarHeight={mobileHeaderHeight}
          navLoginButton={navLoginButton}
          navRegisterButton={navRegisterButton}
          isMobileNavOpen={isMobileNavOpen}
          openMenu={openMenu}
          handleMenuClick={handleMenuClick}
        />
      </Flex>
    </Container>
  </Container>
);

export default memo(Header);
