import SectionBuilderConfig from 'molecules/Section/Section.config';

import HeadingBuilderConfig from 'components/Heading/Heading.config';

const QuoteSliderBuilderConfig = {
  name: 'Quote Slider',
  inputs: [
    { name: 'background', type: 'text', enum: ['White', 'Gray-900'], defaultValue: 'Gray-900' },
    { name: 'useHeading', type: 'boolean', helperText: 'Set to true to add Heading Component' },
    {
      name: 'headingContent',
      type: 'object',
      subFields: [
        ...HeadingBuilderConfig.inputs.filter(
          input =>
            input.name !== 'internalName' && input.name !== 'collapseBottomPadding' && input.name !== 'background',
        ),
      ],
      showIf: (options: { get: (arg0: string) => boolean }) => options.get('useHeading') === true,
    },
    {
      name: 'quoteType',
      type: 'text',
      enum: ['Standard', 'No Image'],
    },
    {
      name: 'quotes',
      type: 'list',
      subFields: [{ name: 'quote', type: 'reference', model: 'entity-quote' }],
    },
    ...SectionBuilderConfig.inputs,
  ],
};

export default QuoteSliderBuilderConfig;
