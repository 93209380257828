import { TextareaAutosize } from '@mui/base';
import styled from 'styled-components';

import Flex from 'quarks/Flex';
import Text from 'quarks/Text';
import type { BasicProps } from 'quarks/interpolations/basic';
import { basic } from 'quarks/interpolations/basic';
import { allCSSWithPseudos } from 'quarks/styleProps/all';

import type { ChangeEventHandler, FC } from 'react';

const StyledTextArea = styled(TextareaAutosize).withConfig({
  shouldForwardProp: prop => ![...Object.keys(allCSSWithPseudos), 'helperText'].includes(prop),
})`
  & {
    ${basic}
  }
`;

const StyledLabel = styled.label`
  & {
    ${basic}
  }
`;

interface TextAreaProps extends BasicProps {
  /**
   * Text label in the top left
   */
  label?: string;
  /**
   * Displays text under the TextArea to assist the user
   */
  helperText?: string;
  /**
   * displays error message under the label (replaces helper text)
   */
  errorMessage?: string;
  /**
   * 	The short hint displayed in the `TextArea` before the user enters a value.
   */
  placeholder?: string;
  /**
   * Name attribute of the `TextArea` element.
   */
  name?: string;
  /**
   * 	Callback fired when the value is changed.
   *  **Signature:**
   *  `function(event: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>) => void`
   *  event: The event source of the callback. You can pull out the new value by accessing `event.target.value` (string).
   */
  onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined;
  /**
   * 	If `true`, the component is disabled.
   */
  disabled?: boolean;
  /**
   * If `true`, the label is displayed in an error state.
   */
  error?: boolean;
  /**
   * If `true`, the label is displayed as required and the `TextArea` element is required.
   */
  required?: boolean;
  /**
   * The id of the `TextArea` element. Use this prop to make `label` and `helperText` accessible for screen readers.
   */
  id?: string;
  /**
   * The value of the `TextArea` element, required for a controlled component.
   */
  value?: string;
  /**
   * if user clicks the submit button, it sets true
   */
  submitValidation?: boolean;
}

const TextArea: FC<TextAreaProps> = ({
  id,
  label,
  placeholder,
  name,
  onChange,
  disabled,
  error,
  required,
  value,
  helperText,
  minHeight,
  errorMessage,
  ...props
}) => {
  const textAreaProps = {
    placeholder,
    name,
    onChange,
    disabled,
    error,
    required,
    helperText,
    value,
  };

  return (
    <Flex flexDirection="column" maxWidth="100%" {...props}>
      <StyledLabel
        htmlFor={id || name}
        fontSize="textSm"
        fontWeight="regular"
        fontFamily="textFont"
        textColor={disabled ? 'gray-500' : 'gray-700'}
      >
        {label}
        {required && (
          <Text textColor="bitterSweet-300" textStyle="xs" fontSize="textLg" lineHeight="zero">
            *
          </Text>
        )}
      </StyledLabel>
      <StyledTextArea
        id={id || name}
        minHeight={minHeight ? minHeight : '100px'}
        paddingAll={12}
        fontSize="textMd"
        backgroundColor={disabled ? 'gray-50' : 'common-white'}
        textColor="gray-900"
        borderRadius="8px"
        borderColor={error ? 'error-400' : 'gray-400'}
        borderWidth="1px"
        marginY={4}
        resize="vertical"
        focus={{
          borderColor: error ? 'error-300' : 'primary-300',
          outline: 'none',
          boxShadow: 'focused',
        }}
        focusWithin={{ borderColor: error ? 'bitterSweet-300' : 'primary-300' }}
        focusVisible={{ borderColor: error ? 'bitterSweet-300' : 'primary-300' }}
        hover={{ backgroundColor: 'primary-25' }}
        {...textAreaProps}
      />
      <Text textStyle="sm" fontWeight="semiBold" textColor={error ? 'error-400' : 'gray-700'}>
        {error ? errorMessage : helperText}
      </Text>
    </Flex>
  );
};

export default TextArea;

TextArea.defaultProps = {
  width: '320px',
};
