import Flex from 'quarks/Flex';
import Grid from 'quarks/Grid';
import Heading from 'quarks/Heading';
import Text from 'quarks/Text';
import type { BasicProps } from 'quarks/interpolations/basic';
import type { HeadingTypes } from 'quarks/styleProps/heading';

import type { CardProps } from 'components/Cards/EntryCard/EntryCard';
import EntryCard from 'components/Cards/EntryCard/EntryCard';
import ComponentButton from 'components/ComponentButton/ComponentButton';
import type { ResourcePost } from 'components/ResourceListing/ResourceListing.config';

import type { FC } from 'react';

interface ResourceCategoryGridProps extends BasicProps {
  heading?: string;
  posts?: ResourcePost[];
  categorySelect?: boolean;
  resourceCardsHeadingType?: HeadingTypes;
  handleCategoryClick?: ((arg: string) => void) | (() => void);
}

const ResourceCategoryGrid: FC<ResourceCategoryGridProps> = ({
  heading,
  posts,
  categorySelect,
  resourceCardsHeadingType,
  handleCategoryClick,
}) => (
  <>
    <Flex flexDirection="row" justifyContent="space-between">
      {heading && (
        <Heading as="h2" fontSize="displayXs" lineHeight="displayXs" fontWeight="semiBold">
          {heading}
        </Heading>
      )}
      {categorySelect && heading && handleCategoryClick && (
        <ComponentButton
          button={{ value: { data: { hierarchy: 'Link Color', size: '2xlarge', label: 'View All', endIcon: '>' } } }}
          paddingY={0}
          onClick={() => handleCategoryClick(heading)}
        />
      )}
    </Flex>
    {posts?.length ? (
      <Grid gap="30px" gridTemplateColumns="1fr" sm={{ gridTemplateColumns: 'repeat(2, 1fr)' }}>
        {posts &&
          posts?.map(({ data, id }) => (
            <EntryCard
              key={id}
              headingType={resourceCardsHeadingType}
              {...(data as CardProps)}
              css={`
                margin: 0 auto;
              `}
            />
          ))}
      </Grid>
    ) : (
      <Text textAlign="left">No resources found under {heading}</Text>
    )}
  </>
);

export default ResourceCategoryGrid;
