import { Divider } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useWindowSize } from 'react-use';

import colors from 'atoms/colors/colors';
import container from 'atoms/spacing/containers';

import Flex from 'quarks/Flex';

import ComponentButton from 'components/ComponentButton/ComponentButton';
import PricingCardBanner from 'components/PricingCardDeck/PricingCardBanner';
import type { PricingCardDeckProps } from 'components/PricingCardDeck/PricingCardDeckProps';
import PricingCardFeatureItems from 'components/PricingCardDeck/PricingCardFeatureItems';
import PricingCardHeader from 'components/PricingCardDeck/PricingCardHeader';
import SeeAllFeaturesComponent from 'components/PricingCardDeck/SeeAllFeaturesComponent';

import { getBackground, getBackgroundIsDark } from 'utils/functions';

import type { FC } from 'react';

const PricingCardDeck: FC<PricingCardDeckProps> = ({
  collapsible,
  isPopular,
  isAnnual,
  background = 'White',
  bannerText,
  planTitle,
  planDescription,
  annualPlanDescription,
  priceMonthly,
  priceAnnually,
  seeAllFeaturesText,
  features,
  cta,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { width } = useWindowSize();
  const backgroundIsDark = getBackgroundIsDark(background);
  const textColor = backgroundIsDark ? 'common-white' : 'gray-900';
  const { backgroundColor } = getBackground('pricing-card', background, width);

  const handleCollapse = useCallback(
    e => {
      if (e.key === 'Enter' || e.key === ' ') {
        setIsCollapsed(!isCollapsed);
      }
      if (e.type === 'click') {
        setIsCollapsed(!isCollapsed);
      }
    },
    [isCollapsed],
  );

  useEffect(() => {
    setIsCollapsed(!(collapsible && isPopular));
  }, [collapsible, isPopular]);

  return (
    <Flex
      textColor={textColor}
      flexDirection="column"
      maxWidth="328px"
      backgroundColor={backgroundColor ?? 'common-white'}
      boxShadow="xl"
      overflowX="hidden"
      borderRadius="16px"
      border={!backgroundIsDark && isPopular ? '2px solid' : 'none'}
      borderColor="primary-400"
      md={{ maxWidth: container.sm }}
      xl={{ width: '370px', maxWidth: '370px' }}
    >
      {isPopular && bannerText && <PricingCardBanner bannerText={bannerText} />}
      <Flex
        gap="24px"
        paddingX={32}
        paddingTop={isPopular ? 32 : 48}
        paddingBottom={48}
        md={{ paddingX: 72, paddingTop: isPopular ? 24 : 48 }}
        lg={{ paddingX: 32 }}
        flexDirection="column"
        height="100%"
      >
        <PricingCardHeader
          textColor={textColor}
          backgroundIsDark={backgroundIsDark}
          isAnnual={isAnnual}
          planTitle={planTitle}
          planDescription={planDescription}
          annualPlanDescription={annualPlanDescription}
          priceMonthly={priceMonthly}
          priceAnnually={priceAnnually}
        />
        {collapsible && features.length > 1 && cta && (
          <ComponentButton
            buttonType="callToAction"
            button={{
              value: { data: { ...cta } },
            }}
            width="100%"
          />
        )}
        <Divider
          css={`
            background-color: ${backgroundIsDark ? colors.common.white : colors.primary['50']};
          `}
        />

        {collapsible && seeAllFeaturesText && features.length > 1 && (
          <SeeAllFeaturesComponent
            isCollapsed={isCollapsed}
            backgroundIsDark={backgroundIsDark}
            seeAllFeaturesText={seeAllFeaturesText}
            onCollapse={handleCollapse}
          />
        )}

        {features?.length > 0 && (
          <PricingCardFeatureItems
            isCollapsed={isCollapsed}
            backgroundIsDark={backgroundIsDark}
            collapsible={collapsible && features.length > 1}
            features={collapsible && features.length > 1 ? features.slice(1) : [features[0]]}
          />
        )}
        {!(collapsible && features.length > 1) && cta && (
          <ComponentButton
            buttonType="callToAction"
            button={{
              value: { data: { ...cta } },
            }}
            width="100%"
            marginTop="auto"
          />
        )}
      </Flex>
    </Flex>
  );
};

export default PricingCardDeck;
