import { breakpoints } from 'atoms/breakpoints/breakpoints';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Grid from 'quarks/Grid';
import Heading from 'quarks/Heading';
import Image from 'quarks/Image';
import Text from 'quarks/Text';
import type { HeadingTypes } from 'quarks/styleProps/heading';

import type { SwitcherItemProps } from 'components/HorizontalSwitcher/HorizontalSwitcher';

import colorizeText from 'utils/colorizeText';
import { optimizeBuilderImage } from 'utils/functions';
import RichTextParser from 'utils/richTextParser';

import type { FC } from 'react';

interface HorizontalSwitcherItemProps {
  switcherItems: SwitcherItemProps[];
  currentSwitcherItem: number;
  headingType: HeadingTypes;
}

const HorizontalSwitcherItem: FC<HorizontalSwitcherItemProps> = ({
  switcherItems,
  currentSwitcherItem,
  headingType,
}) => (
  <Grid
    display="block"
    backgroundColor="gray-100"
    borderRadius="16px"
    paddingX={24}
    paddingY={40}
    textAlign="left"
    columnGap="34px"
    gridTemplateColumns="1fr 1.2125fr"
    md={{
      paddingAll: 64,
    }}
    xl={{
      display: 'grid',
      paddingX: 48,
      paddingY: 40,
    }}
  >
    <Container marginY="auto">
      <Flex gap="24px" flexDirection="column">
        {switcherItems[currentSwitcherItem].itemTitle && (
          <Text textStyle="eyebrow">{colorizeText(switcherItems[currentSwitcherItem].itemTitle, false)}</Text>
        )}
        {switcherItems[currentSwitcherItem].heading && (
          <Heading as={headingType} textStyle="xs" md={{ textStyle: 'md' }}>
            {switcherItems[currentSwitcherItem].heading}
          </Heading>
        )}
        {switcherItems[currentSwitcherItem].desc && (
          <Text
            textStyle="lg"
            textColor="gray-700"
            md={{
              textStyle: 'xl',
            }}
          >
            {RichTextParser(switcherItems[currentSwitcherItem].desc || '')}
          </Text>
        )}
      </Flex>
    </Container>

    <Container
      position="relative"
      height="0"
      overflowY="hidden"
      marginTop={40}
      xl={{
        marginTop: 0,
      }}
      css={`
        padding-bottom: 78%;
        @media screen and (min-width: ${breakpoints.md}) {
          padding-bottom: 62.5%;
        }
        @media screen and (min-width: ${breakpoints.xl}) {
          padding-bottom: 70%;
        }
      `}
    >
      {switcherItems[currentSwitcherItem].image && (
        <Image
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
          objectFit="cover"
          objectPosition="center"
          maxWidth="100%"
          borderRadius="12px"
          image={optimizeBuilderImage(switcherItems[currentSwitcherItem].image as string)}
          alt={switcherItems[currentSwitcherItem]?.imageAltTag || 'Switcher item image'}
        />
      )}
    </Container>
  </Grid>
);

export default HorizontalSwitcherItem;
