/* eslint-disable react/no-array-index-key */

import Icon from 'atoms/Icons/Icon';
import type { iconIds } from 'atoms/Icons/Icon.types';
import type { GetColorDefinition } from 'atoms/colors/colors';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Link from 'quarks/Link';
import Paragraph from 'quarks/Paragraph';
import Text from 'quarks/Text';
import type { BasicProps } from 'quarks/interpolations/basic';

import Badge from 'molecules/Badge/Badge';

import type { FC } from 'react';

interface mobileSubMenuItemProps extends BasicProps {
  title?: string;
  description?: string;
  icon?: iconIds;
  link?: string;
  badge?: {
    badgeTextColor?: string;
    badgeText?: string;
    badgeBackgroundColor?: string;
  };
}

const SubMenuItemNavigation: FC<mobileSubMenuItemProps> = ({ badge, title, description, icon, link, ...props }) => {
  const lowerCaseBadgeGuard = badge && {
    textColor: badge?.badgeTextColor?.toLowerCase() as GetColorDefinition,
    backgroundColor: badge?.badgeBackgroundColor?.toLowerCase() as GetColorDefinition,
  };

  return (
    <Container as="li" hover={{ backgroundColor: 'gray-50', borderRadius: '5px' }} {...props}>
      <Link
        href={link || ''}
        trackingSchema={{
          label: title,
          location: 'Submenu in Header',
        }}
      >
        <Flex gap="16px" alignItems="center">
          {icon && <Icon id={icon} hasGradientBorder wrapped />}
          <Flex flexDirection="column" gap="4px">
            <Flex gap="8px">
              <Text
                textColor="gray-900"
                textStyle="md"
                fontWeight="semiBold"
                md={{ textStyle: 'lg', fontWeight: 'semiBold' }}
                xl={{ textStyle: 'md', fontWeight: 'semiBold' }}
              >
                {title}
              </Text>
              {badge && (
                <Badge
                  size="md"
                  fontWeight="medium"
                  textColor={lowerCaseBadgeGuard?.textColor || 'primary-900'}
                  backgroundColor={lowerCaseBadgeGuard?.backgroundColor || 'common-white'}
                >
                  <Paragraph textStyle="sm">{badge?.badgeText}</Paragraph>
                </Badge>
              )}
            </Flex>
            <Text textColor="gray-700" textStyle="sm">
              {description}
            </Text>
          </Flex>
        </Flex>
      </Link>
    </Container>
  );
};

export default SubMenuItemNavigation;
