import Icon from 'atoms/Icons/Icon';
import type { iconIds } from 'atoms/Icons/Icon.types';

import Flex from 'quarks/Flex';
import Paragraph from 'quarks/Paragraph';

import type { FC } from 'react';

export type FeaturesProps = { id: string; icon?: iconIds; copy: string };

interface PostFeaturesProps {
  features: FeaturesProps[];
}

const PostFeatures: FC<PostFeaturesProps> = ({ features }) => (
  <Flex flexDirection="column" gap="16px">
    <Paragraph textStyle="lg" fontWeight="bold" textColor="gray-900">
      Key Results
    </Paragraph>
    {features.map(({ id, icon, copy }) => (
      <Flex key={id} flexDirection="row" alignItems="center" gap="12px">
        {icon && <Icon id={icon} wrapped hasGradientBorder />}
        {copy && (
          <Paragraph textStyle="md" fontWeight="medium" textColor="gray-700">
            {copy}
          </Paragraph>
        )}
      </Flex>
    ))}
  </Flex>
);

export default PostFeatures;
