import Icon from 'atoms/Icons/Icon';
import blur from 'atoms/blurs/blurs';
import type { GetColorDefinition } from 'atoms/colors/colors';
import color from 'atoms/colors/colors';
import gradient from 'atoms/colors/gradients';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Heading from 'quarks/Heading';
import Text from 'quarks/Text';

import type { ComponentStatProps } from 'components/Stats/ComponentStats';

import colorizeText from 'utils/colorizeText';

import type { FC } from 'react';

interface InlineComponentStatsProps extends ComponentStatProps {
  backgroundIsDark: boolean;
}

const InlineComponentStats: FC<InlineComponentStatsProps> = ({
  eyebrow,
  heading,
  headingType,
  stats,
  background,
  ...props
}) => {
  const symbolLightModeColorList = ['irisBlue-500', 'purpleHeart-400', 'bitterSweet-300'];
  const backgroundIsDark = background === 'Primary-600';
  const statsBackground = backgroundIsDark ? color.primary[500] : blur.background.light;

  const hasHeadingContent = !!eyebrow || !!heading;
  const eyebrowContent = backgroundIsDark ? colorizeText(eyebrow || '', backgroundIsDark) : eyebrow;

  return (
    <Flex
      flexDirection="column"
      textAlign="center"
      borderRadius="12px"
      backgroundColor={backgroundIsDark ? 'primary-600' : 'common-transparent'}
      gap="24px"
      maxWidth="100%"
      position="relative"
      boxShadow="lg"
      paddingY={32}
      paddingX={16}
      md={{ gap: '40px', paddingAll: 32 }}
      css={`
        background: ${!backgroundIsDark && gradient.comboGradient.lightMode};
      `}
      {...props}
    >
      {hasHeadingContent && (
        <Flex flexDirection="column" gap="16px">
          {eyebrow && (
            <Text textStyle="eyebrow" textColor={!backgroundIsDark && 'primary-600'}>
              {eyebrowContent}
            </Text>
          )}
          {heading && (
            <Heading
              as={headingType || 'h2'}
              textStyle="xs"
              md={{ textStyle: 'sm' }}
              xl={{ textStyle: 'md' }}
              fontWeight="semiBold"
              textColor={backgroundIsDark ? 'common-white' : 'gray-900'}
            >
              {heading}
            </Heading>
          )}
        </Flex>
      )}
      <Flex
        flexDirection="column"
        width="inherit"
        borderRadius="12px"
        gap="8px"
        justifyContent="space-around"
        textAlign="center"
        sm={{ flexDirection: 'row' }}
        css={`
          background-color: ${hasHeadingContent ? statsBackground : color.common.transparent};
        `}
      >
        {stats?.map((stat, index) => {
          const statWithoutSymbol = stat?.statItem?.statistic?.slice(0, stat.statItem.statistic.length - 1);
          const symbolCharacter = stat?.statItem?.statistic?.charAt(stat.statItem.statistic.length - 1);

          return (
            <Flex
              paddingY={16}
              paddingX={24}
              flexDirection="column"
              alignItems="center"
              gap="8px"
              // eslint-disable-next-line react/no-array-index-key
              key={`${stat?.statItem?.id}-${index}`}
              width="100%"
            >
              {stat?.statItem?.icon && (
                <Icon id={stat?.statItem?.icon} size={45} iconColor={backgroundIsDark ? 'common-white' : 'gray-900'} />
              )}
              <Heading
                as="h3"
                textStyle="sm"
                fontWeight="bold"
                textColor={backgroundIsDark ? 'common-white' : 'gray-900'}
                md={{ textStyle: 'md' }}
              >
                {statWithoutSymbol}
                <Container
                  as="text"
                  textColor={
                    backgroundIsDark ? 'common-white' : (symbolLightModeColorList[index] as GetColorDefinition)
                  }
                >
                  {symbolCharacter}
                </Container>
              </Heading>
              <Text textStyle="md" textColor={backgroundIsDark ? 'gray-50' : 'gray-700'} fontWeight="bold">
                {stat?.statItem?.caption}
              </Text>
            </Flex>
          );
        })}
      </Flex>
    </Flex>
  );
};

export default InlineComponentStats;
