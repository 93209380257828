import { useWindowSize } from 'react-use';

import container from 'atoms/spacing/containers';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Image from 'quarks/Image';

import { getBackground } from 'utils/functions';

import type { FC, ReactNode } from 'react';

interface SectionProps {
  __typename?: string;
  internalName?: string | null;
  background?: string;
  contained?: boolean;
  children: ReactNode;
  sectionType?: 'default' | 'listing' | 'noBottomPadding' | 'noPadding';
  id?: string;
  playbook?: boolean | null;
  playbookHero?: boolean | null;
}

const smallPaddingX = 24 as const;

const smallPaddingY = 40 as const;
const mediumPaddingY = 72 as const;
const largePaddingY = 96 as const;

const mediumContainer = Number(container.md.slice(0, -2));
const largeContainer = Number(container.lg.slice(0, -2));

const totalMaxColumnPadding = smallPaddingX * 2;

export const tabletMaxWidth = `${mediumContainer + totalMaxColumnPadding}px`;
export const sectionMaxWidth = `${largeContainer + totalMaxColumnPadding}px`;

export const sectionSizing = {
  maxWidth: container.xs,
  width: '100%',
  paddingX: smallPaddingX,
  paddingY: smallPaddingY,
  margin: '0 auto',
  xs: {
    maxWidth: tabletMaxWidth,
  },
  lg: {
    paddingY: mediumPaddingY,
  },
  xl: {
    paddingY: largePaddingY,
    maxWidth: sectionMaxWidth,
  },
} as const;

export const noBottomPaddingSizing = {
  maxWidth: container.xs,
  width: '100%',
  paddingX: smallPaddingX,
  paddingTop: smallPaddingY,
  margin: '0 auto',
  xs: {
    maxWidth: tabletMaxWidth,
  },
  lg: {
    paddingTop: mediumPaddingY,
  },
  xl: {
    paddingTop: largePaddingY,
    maxWidth: sectionMaxWidth,
  },
} as const;

export const noPadding = {
  maxWidth: container.xs,
  width: '100%',
  paddingX: smallPaddingX,
  margin: '0 auto',
  overflowX: 'scroll',
  scrollbarWidth: 'none',
  xs: {
    maxWidth: tabletMaxWidth,
  },
  xl: {
    maxWidth: sectionMaxWidth,
  },
} as const;

export const listingSizing = {
  maxWidth: container.xs,
  width: '100%',
  paddingX: smallPaddingX,
  paddingBottom: smallPaddingY,
  margin: '0 auto',
  xs: {
    maxWidth: tabletMaxWidth,
  },
  lg: {
    paddingBottom: mediumPaddingY,
  },
  xl: {
    paddingBottom: largePaddingY,
    maxWidth: sectionMaxWidth,
  },
} as const;

const Section: FC<SectionProps> = ({
  background,
  __typename,
  id,
  children,
  contained,
  playbook,
  playbookHero,
  sectionType = 'default',
  ...props
}) => {
  const { width } = useWindowSize();
  const { backgroundPath, backgroundColor, backgroundGradient } = getBackground(__typename, background, width);

  let retrieveSizing;
  switch (sectionType) {
    case 'default':
      retrieveSizing = sectionSizing;
      break;
    case 'noBottomPadding':
      retrieveSizing = noBottomPaddingSizing;
      break;
    case 'noPadding':
      retrieveSizing = noPadding;
      break;
    case 'listing':
      retrieveSizing = listingSizing;
      break;
    default:
      retrieveSizing = sectionSizing;
  }

  return (
    <Flex
      as="section"
      id={id}
      position="relative"
      contain="content"
      justifyContent="center"
      width="100%"
      backgroundShorthand={backgroundGradient}
      backgroundColor={backgroundColor}
      {...props}
      css={`
        margin-top: ${playbook ? '-150px' : 0};
      `}
      paddingBottom={playbookHero && 128}
    >
      {backgroundPath && (
        <Image
          position="absolute"
          width="100%"
          top="0px"
          left="0px"
          image={{ file: { url: backgroundPath } }}
          objectFit="cover"
          alt=""
          zIndex={-1}
        />
      )}
      <Container
        {...retrieveSizing}
        maxWidth={contained ? '363px' : container.xs}
        md={{ maxWidth: contained ? '944px' : tabletMaxWidth }}
        css={`
          ::-webkit-scrollbar {
            display: ${sectionType === 'noPadding' && 'none'};
          }
          margin-top: ${playbook ? '110px' : 0};
          overflow-y: ${playbook ? 'visible' : 'unset'};
        `}
      >
        {children}
      </Container>
    </Flex>
  );
};
export default Section;
