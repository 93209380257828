import { useEffect, useState } from 'react';

import Flex from 'quarks/Flex';
import Grid from 'quarks/Grid';
import Heading from 'quarks/Heading';
import Text from 'quarks/Text';
import type { BasicProps } from 'quarks/interpolations/basic';

import Pagination from 'molecules/ButtonPagination/ButtonPagination';
import Section from 'molecules/Section/Section';
import Select from 'molecules/Select/Select';

import CareerCard from 'components/CareerListing/CareerCard';
import type { GreenhouseJobData, Job } from 'components/CareerListing/greenhouseTypes';
import ComponentButton from 'components/ComponentButton/ComponentButton';

import colorizeText from 'utils/colorizeText';

import type { FC } from 'react';

interface CareerListingProps extends BasicProps {
  eyebrow?: string;
  heading?: string;
  sectionId?: string;
}

const GREENHOUSE_JOBS_URL = 'https://boards-api.greenhouse.io/v1/boards/lineup/jobs?content=true';

const CareerListing: FC<CareerListingProps> = ({ eyebrow, heading, sectionId }) => {
  const [currentJobs, setCurrentJobs] = useState<Job[]>([]);
  const [jobList, setJobList] = useState<Job[]>([]);
  const [department, setDepartment] = useState('All');
  const [currentPage, setCurrentPage] = useState(0);
  const [amountOfPages, setAmountOfPages] = useState(0);

  const fetchJobs = async () => {
    try {
      const response = await fetch(GREENHOUSE_JOBS_URL);
      const processedResponse: GreenhouseJobData = await response.json();
      const listOfJobs = processedResponse?.jobs;
      const jobs = listOfJobs?.slice(currentPage * 6, currentPage * 6 + 6);
      setCurrentJobs(jobs);
      setAmountOfPages(Math.ceil(listOfJobs.length / 6));
      setJobList(listOfJobs);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchJobs();
  }, []);

  useEffect(() => {
    if (department !== 'All') {
      const listofValidJobs = jobList && jobList.filter(job => job.departments[0]?.name === department);
      const sliceValidJobs = listofValidJobs?.slice(currentPage * 6, currentPage * 6 + 6);
      setCurrentJobs(sliceValidJobs);
      setCurrentPage(0);

      return setAmountOfPages(Math.ceil(listofValidJobs.length / 6));
    }
    const jobs = jobList?.slice(currentPage * 6, currentPage * 6 + 6);
    setCurrentJobs(jobs);
    setAmountOfPages(Math.ceil(jobList.length / 6));
  }, [currentPage, amountOfPages, department]);

  const listofValidJobs = jobList?.filter(job => job.departments.length > 0);
  const validJobNames = listofValidJobs?.map(jobData => jobData?.departments[0].name);
  const jobsNoDuplicates = [...new Set(validJobNames)];

  const enableSelectOnLargerDevices = jobsNoDuplicates.length > 4;

  return (
    <Section id={sectionId}>
      <Flex flexDirection="column" gap="65px" md={{ gap: '81px' }} xl={{ gap: '91px' }}>
        <Flex flexDirection="column" gap="24px">
          {eyebrow && <Text textStyle="eyebrow">{colorizeText(eyebrow, false)}</Text>}
          <Flex flexDirection="column" gap="32px" xl={{ flexDirection: 'row', justifyContent: 'space-between' }}>
            <Flex xl={{ minWidth: '50%' }}>
              {heading && (
                <Heading as="h3" textStyle="sm" md={{ textStyle: 'md' }}>
                  {heading}
                </Heading>
              )}
            </Flex>
            <Select
              list={['All', ...jobsNoDuplicates]}
              defaultValue="All"
              onChange={e => setDepartment(e.target.value)}
              label="Category"
              md={{ display: enableSelectOnLargerDevices ? 'flex' : 'none' }}
            />
            {!enableSelectOnLargerDevices && (
              // TODO: Create a function to automate the Button Prop
              <Flex gap="16px" display="none" md={{ display: 'flex', flexWrap: 'wrap' }}>
                <ComponentButton
                  buttonType="callToAction"
                  button={{
                    value: {
                      data: {
                        label: 'All',
                        hierarchy: department === 'All' ? 'Primary Button' : 'Secondary Gray',
                        size: 'medium',
                      },
                    },
                  }}
                  height="38px"
                  onClick={() => setDepartment('All')}
                  trackingSchema={{
                    hierarchy: '1 of 1',
                    location: 'CareerListing',
                  }}
                />
                {jobsNoDuplicates.map((string, index) => (
                  <ComponentButton
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    buttonType="callToAction"
                    button={{
                      value: {
                        data: {
                          label: string,
                          hierarchy: string === department ? 'Primary Button' : 'Secondary Gray',
                          size: 'medium',
                        },
                      },
                    }}
                    height="38px"
                    onClick={() => setDepartment(string)}
                    trackingSchema={{
                      hierarchy: '1 of 1',
                      location: 'CareerListing',
                    }}
                  />
                ))}
              </Flex>
            )}
          </Flex>
        </Flex>
        {currentJobs?.length > 0 && (
          <Grid
            gap="24px"
            alignSelf="center"
            gridTemplateColumns="repeat(1, 1fr)"
            md={{ gridTemplateColumns: 'repeat(2, 1fr)' }}
            xl={{ gridTemplateColumns: 'repeat(3, 1fr)' }}
          >
            {currentJobs.map(job => (
              <CareerCard
                key={job.id}
                title={job.title}
                department={job.departments[0]?.name}
                location={job.location?.name}
                href={job.absolute_url}
                caption={job.content}
              />
            ))}
          </Grid>
        )}
      </Flex>
      {jobList?.length > 6 && (
        <Pagination
          marginTop={40}
          dotsCount={amountOfPages}
          activeDot={currentPage}
          onLeftArrowClick={() => setCurrentPage(currentPage - 1)}
          onRightArrowClick={() => setCurrentPage(currentPage + 1)}
          onSetActiveDot={number => setCurrentPage(number)}
          xl={{ marginTop: 48 }}
        />
      )}
    </Section>
  );
};

export default CareerListing;
