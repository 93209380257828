import container from 'atoms/spacing/containers';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Heading from 'quarks/Heading';
import Text from 'quarks/Text';

import type { PricingCopyProps } from 'components/PricingTable/PricingTableTypes';

import RichTextParser from 'utils/richTextParser';

import type { FC } from 'react';

const PricingCopy: FC<PricingCopyProps> = ({ eyebrow, styledEyebrow, heading, subheading }) => (
  <Flex flexDirection="column" textAlign="center" alignItems="center" gap="24px" maxWidth={container.md} marginX="auto">
    {eyebrow && <Text textStyle="eyebrow">{styledEyebrow}</Text>}
    {heading && (
      <Heading as="h2" textStyle="xs" md={{ textStyle: 'md' }} xl={{ textStyle: 'lg' }} fontWeight="semiBold">
        {heading}
      </Heading>
    )}
    {subheading && (
      <Container textColor="gray-700" fontSize="textLg" fontWeight="medium" md={{ fontSize: 'textXl' }}>
        {RichTextParser(subheading)}
      </Container>
    )}
  </Flex>
);

export default PricingCopy;
