import Flex from 'quarks/Flex';
import Text from 'quarks/Text';

import type { FC } from 'react';

const PricingCardBanner: FC<{ bannerText: string }> = ({ bannerText }) => (
  <Flex backgroundColor="primary-50" justifyContent="center" paddingY={8}>
    <Text textColor="primary-900" textStyle="lg" fontWeight="medium" xl={{ textStyle: 'md', fontWeight: 'semiBold' }}>
      {bannerText || 'Most popular plan'}
    </Text>
  </Flex>
);

export default PricingCardBanner;
