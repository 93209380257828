import Flex from 'quarks/Flex';
import Text from 'quarks/Text';
import type { FlexProps } from 'quarks/interpolations/flex';

import IntegrationCard from 'components/Cards/IntegrationCard/IntegrationCard';
import type { CategoryIntegrationsProps } from 'components/IntegrationsList/IntegrationsList';

import type { FC } from 'react';

interface IntegrationGroupProps extends FlexProps {
  category?: string;
  integrations: (CategoryIntegrationsProps | null)[];
}

const IntegrationGroup: FC<IntegrationGroupProps> = ({ category, integrations, ...props }) => (
  <Flex flexDirection="column" gap="30px" width="100%" {...props}>
    {category && (
      <Text fontStyle="displayXs" fontWeight="semiBold" textColor="gray-900">
        {category}
      </Text>
    )}
    <Flex width="100%" flexDirection="column" gap="30px" sm={{ flexWrap: 'wrap', flexDirection: 'row' }}>
      {integrations.length > 0 &&
        integrations.map(integration =>
          integration ? <IntegrationCard key={integration.id} integration={integration} /> : null,
        )}
    </Flex>
  </Flex>
);

export default IntegrationGroup;
