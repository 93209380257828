import { useEffect } from 'react';

import Flex from 'quarks/Flex';
import Text from 'quarks/Text';

import TableItem from 'components/Sidebar/TableItem';

import { stringToKebabCase } from 'utils/functions';

import useActiveContent from 'contexts/ContentEditorProvider';

import type { FC } from 'react';

interface TableOfContentProps {
  content: { heading: string; headingType: string }[];
}

const TableOfContent: FC<TableOfContentProps> = ({ content }) => {
  const [_, setId] = useActiveContent();

  useEffect(() => {
    if (content && content.length > 0) {
      setId(stringToKebabCase(content[0].heading));
    }
  }, [content]);

  if (!content || !Array.isArray(content) || content.length === 0) {
    return null;
  }

  return (
    <Flex
      flexDirection="column"
      gap="16px"
      backgroundColor="common-white"
      width="calc(100% + 3px)"
      before={{
        content: '',
        position: 'absolute',
        bottom: '100%',
        width: '100%',
        height: '32px',
        backgroundColor: 'common-white',
      }}
    >
      <Text textStyle="lg" fontWeight="semiBold" textColor="gray-900">
        In this article
      </Text>
      <Flex
        flexDirection="column"
        gap="16px"
        paddingAll={24}
        border="1px solid"
        borderColor="gray-400"
        borderRadius="12px"
      >
        {content.map(({ heading }, ind) => (
          // eslint-disable-next-line react/no-array-index-key
          <TableItem key={`${heading}-${ind}`} heading={heading} />
        ))}
      </Flex>
    </Flex>
  );
};

export default TableOfContent;
