import { useMediaQuery } from '@mui/material';

import { media } from 'atoms/breakpoints/breakpoints';
import container from 'atoms/spacing/containers';

import Flex from 'quarks/Flex';
import Heading from 'quarks/Heading';
import Image from 'quarks/Image';
import Text from 'quarks/Text';

import Section from 'molecules/Section/Section';

import ComponentButton from 'components/ComponentButton/ComponentButton';
import type { ButtonTypes } from 'components/ComponentButton/buttonTypeConfig';
import ButtonWrapper from 'components/ComponentButton/buttonWrapper';
import GhostMarqueeRow from 'components/IntegrationsCarousel/Components/GhostMarqueeRow';
import MarqueeRow from 'components/IntegrationsCarousel/Components/MarqueeRow';

import colorizeText from 'utils/colorizeText';
import doodleGenerator from 'utils/doodleGenerator';

import type { Builder_ComponentButton, Builder_Integration } from 'graphqlTypes';
import type { FC } from 'react';

interface IntegrationsCarosuelProps {
  sectionId?: string;
  buttons?: ButtonTypes;
  inverseMovementDirection?: boolean;
  background?: string;
  eyebrow?: string;
  heading?: string;
  description?: string;
  integrations?: {
    integration: {
      value: Builder_Integration;
    };
  }[];
  alignment?: 'vertical' | 'horizontal';
}

interface TextContentProps {
  description?: string;
  alignment?: 'vertical' | 'horizontal';
  buttons?: ButtonTypes;
}

const TextContent: FC<TextContentProps> = ({ description, alignment, buttons }) => (
  <>
    {description && (
      <Text
        textStyle="lg"
        textColor="gray-700"
        textAlign="center"
        md={{
          textStyle: 'xl',
        }}
        xl={{
          textAlign: alignment === 'horizontal' && 'left',
        }}
      >
        {description}
      </Text>
    )}
    {buttons && (
      <Flex
        justifyContent="center"
        width="100%"
        xl={{
          justifyContent: alignment === 'horizontal' && 'start',
        }}
      >
        <ButtonWrapper>
          {buttons?.map((button, index) => (
            <ComponentButton
              buttonType={button?.buttonType || 'callToAction'}
              key={button.button?.value?.id || index}
              {...(button as Builder_ComponentButton)}
            />
          ))}
        </ButtonWrapper>
      </Flex>
    )}
  </>
);

const IntegrationsCarousel: FC<IntegrationsCarosuelProps> = ({
  sectionId,
  background,
  buttons,
  alignment = 'vertical',
  eyebrow = 'Integrations',
  heading = 'Lineup brings all of the solutions in one place.',
  description = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci donec magna cursus nisl id consequat',
  inverseMovementDirection = false,
  integrations,
}) => {
  const doodleName = 'doubleknotthirdinverted';
  const doodleImage = doodleGenerator(doodleName);

  const isDesktop = useMediaQuery(media.xl);
  const isHorizontalDesktop = isDesktop && alignment === 'horizontal';
  const isVerticalDesktop = isDesktop && alignment === 'vertical';

  const styledEyebrow = colorizeText(eyebrow.toLocaleUpperCase(), false);

  const getDirection = (direction: 'left' | 'right', reverse: boolean): 'left' | 'right' => {
    if (reverse) {
      return direction === 'left' ? 'right' : 'left';
    }

    return direction;
  };

  const halfwayLength = integrations && Math.floor(integrations.length / 2);
  const firstRow = integrations && integrations.slice(0, halfwayLength);
  const secondRow = integrations && integrations.slice(halfwayLength, integrations.length);

  return (
    <Section id={sectionId} __typename="ComponentIntegrationsCarousel" background={background}>
      <Flex
        flexDirection="column"
        gap="32px"
        xl={{
          flexDirection: alignment === 'horizontal' && 'row',
          alignItems: 'center',
        }}
      >
        <Flex
          flexDirection="column"
          alignItems="center"
          gap="10px"
          textAlign="center"
          xl={{
            alignItems: alignment === 'horizontal' && 'start',
            textAlign: alignment === 'horizontal' && 'left',
          }}
        >
          {styledEyebrow && <Text textStyle="eyebrow">{styledEyebrow}</Text>}
          {heading && (
            <Heading
              as="h2"
              textStyle="xs"
              fontWeight="semiBold"
              maxWidth={container.md}
              md={{ textStyle: 'md', fontWeight: 'semiBold' }}
              xl={{ textStyle: alignment === 'vertical' ? 'lg' : 'md', fontWeight: 'semiBold' }}
            >
              {heading}
            </Heading>
          )}
          {(!isDesktop || isHorizontalDesktop) && (
            <TextContent alignment={alignment} description={description} buttons={buttons} />
          )}
        </Flex>
        <Flex
          maxWidth={container.md}
          flexDirection="column"
          gap="16px"
          xl={{ maxWidth: alignment === 'horizontal' && '50%' }}
        >
          <GhostMarqueeRow position="top" direction={getDirection('left', inverseMovementDirection)} />
          <MarqueeRow array={firstRow} direction={getDirection('right', inverseMovementDirection)} />
          <MarqueeRow array={secondRow} direction={getDirection('left', inverseMovementDirection)} />
          <GhostMarqueeRow position="bottom" direction={getDirection('right', inverseMovementDirection)} />
        </Flex>
        {isVerticalDesktop && <TextContent alignment={alignment} description={description} buttons={buttons} />}
      </Flex>
      {doodleImage && (
        <Image
          width="300px"
          right="-200px"
          bottom="20px"
          transform="rotate(169deg)"
          position="absolute"
          zIndex={-10}
          image={doodleImage}
          alt={doodleName}
          lg={{ width: '450px' }}
          xl={{ bottom: alignment === 'vertical' ? '50%' : '50px' }}
        />
      )}
    </Section>
  );
};

IntegrationsCarousel.defaultProps = {
  background: 'gray-100',
  inverseMovementDirection: false,
  heading: 'Lineup brings all of the solution in one place.',
  description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci donec magna cursus nisl id consequat.',
  alignment: 'vertical',
};

export default IntegrationsCarousel;
