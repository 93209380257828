import type { BasicProps } from 'quarks/interpolations/basic';
import type { HeadingTypes } from 'quarks/styleProps/heading';

import SectionBuilderConfig from 'molecules/Section/Section.config';

import type { ReportCardTypes } from 'components/Cards/ReportCard/reportCardConfigTypes';
import { reportCardsBuilderDataField } from 'components/Cards/ReportCard/reportCardConfigTypes';
import type { ForecastCardTypes, RevenueCardTypes } from 'components/Cards/RevenueCard/revenueCardConfigTypes';
import {
  forecastCardBuilderDataField,
  revenueCardsBuilderDataField,
} from 'components/Cards/RevenueCard/revenueCardConfigTypes';
import type { ForecastData, weeks } from 'components/ForecastCalculator/CalculatorDataAndTypes';
import type { ComponentFormProps } from 'components/HsForm/types';

export interface CalculatorCopyProps extends BasicProps {
  eyebrow?: string;
  heading?: string;
  headingType?: HeadingTypes;
  sectionId?: string;
  subheading?: string;
}
export interface CalculatorResultsTypes extends BasicProps {
  eyebrow?: string;
  heading?: string;
  headingType?: HeadingTypes;
  subheading?: string;
  resultsCopy?: CalculatorResultsTypes;
  revenueCard?: RevenueCardTypes;
  forecastCard?: ForecastCardTypes;
  reportCard?: ReportCardTypes;
  weeks?: typeof weeks;
  forecastData?: ForecastData;
  calculatePressed?: boolean;
  calculatorFormWithData?: ComponentFormProps;
}

const CalculatorBuilderConfig = {
  name: 'Calculator Component',
  inputs: [
    { name: 'eyebrow', type: 'text' },
    { name: 'heading', type: 'text' },
    { name: 'headingType', type: 'text', enum: ['h2', 'h3', 'h4', 'h5', 'h6'] },
    { name: 'subheading', type: 'richText' },
    {
      name: 'hourlyField',
      type: 'object',
      subFields: [
        { name: 'toolTipTitle', type: 'text' },
        { name: 'toolTipDescription', type: 'text' },
      ],
    },
    {
      name: 'targetLaborCostField',
      type: 'object',
      subFields: [
        { name: 'toolTipTitle', type: 'text' },
        { name: 'toolTipDescription', type: 'text' },
      ],
    },
    {
      name: 'resultsCopy',
      type: 'object',
      subFields: [
        { name: 'eyebrow', type: 'text', defaultValue: 'Forecast Calculations' },
        { name: 'heading', type: 'text', defaultValue: 'Your Results' },
        { name: 'headingType', type: 'text', enum: ['h2', 'h3', 'h4', 'h5', 'h6'] },
        { name: 'subheading', type: 'richText' },
      ],
    },
    {
      name: 'revenueCard',
      type: 'object',
      subFields: [...revenueCardsBuilderDataField],
    },
    {
      name: 'forecastCard',
      type: 'object',
      subFields: [...revenueCardsBuilderDataField, ...forecastCardBuilderDataField],
    },
    ...reportCardsBuilderDataField,
    ...SectionBuilderConfig.inputs,
  ],
};

export default CalculatorBuilderConfig;
