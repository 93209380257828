import { useMediaQuery } from '@mui/material';
import { useRef } from 'react';
import { useWindowSize } from 'react-use';

import { media } from 'atoms/breakpoints/breakpoints';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Heading from 'quarks/Heading';
import Text from 'quarks/Text';

import PlanCards from 'components/PricingTable/PlanCards';
import PlanWrapper from 'components/PricingTable/PlanWrapper';
import PricingCopy from 'components/PricingTable/PricingCopy';
import type { PricingTableProps } from 'components/PricingTable/PricingTableTypes';

import colorizeText from 'utils/colorizeText';
import { useSticky } from 'utils/hooks';
import RichTextParser from 'utils/richTextParser';

import type { FC } from 'react';

const PricingTable: FC<PricingTableProps> = ({
  coloredEyebrow,
  eyebrow,
  heading,
  subheading,
  planCheckList,
  pricingDetails,
}) => {
  const priceDetails = pricingDetails?.value?.data;
  const features = priceDetails?.features;

  const styledEyebrow = coloredEyebrow && eyebrow ? colorizeText(eyebrow, false) : eyebrow;

  const { width } = useWindowSize();
  const isDesktop = useMediaQuery(media.lg);

  const refCard = useRef(null);

  const isSticky = useSticky(refCard);

  if (!isDesktop) {
    return null;
  }

  return (
    <Flex backgroundColor="common-white" width="100%">
      <Container width="100%">
        <Flex flexDirection="column" justifyContent="center" gap="64px" width="100%" paddingBottom={96}>
          <PricingCopy eyebrow={eyebrow} styledEyebrow={styledEyebrow} heading={heading} subheading={subheading} />

          <Flex justifyContent="center" alignItems="flex-start">
            <Flex flexDirection="column">
              <Flex
                ref={refCard}
                justifyContent="center"
                position="sticky"
                top="84px"
                height="160px"
                boxShadow={isSticky ? 'lg' : 'none'}
              >
                <Flex alignItems="flex-start" width="225px" xl={{ width: '292px' }}>
                  <Flex
                    paddingX={32}
                    flexDirection="column"
                    justifyContent="center"
                    backgroundColor="common-white"
                    height={isSticky ? '160px' : '248px'}
                    transition="height 0.25s ease"
                  >
                    {priceDetails?.title && (
                      <Heading as="h3" textStyle="md" fontWeight="semiBold">
                        {priceDetails.title}
                      </Heading>
                    )}
                    {priceDetails?.subtitle && (
                      <Text textStyle="md" textColor="gray-700">
                        {RichTextParser(priceDetails.subtitle)}
                      </Text>
                    )}
                  </Flex>
                </Flex>

                {planCheckList?.map(({ plan, background, ctaButton }, index) => (
                  <PlanCards
                    key={plan?.value?.data?.planTitle || index}
                    isSticky={isSticky}
                    width={width}
                    background={background}
                    ctaButton={ctaButton}
                    plan={plan}
                  />
                ))}
              </Flex>

              <Flex justifyContent="center">
                <Flex
                  flexDirection="column"
                  alignItems="flex-start"
                  paddingTop={80}
                  width="225px"
                  xl={{ width: '292px' }}
                >
                  <Flex flexDirection="column" width="250px" xl={{ width: '292px' }}>
                    {features?.map(({ feature }, index) => (
                      <Flex key={feature?.title || index} flexDirection="column">
                        <Flex
                          paddingLeft={32}
                          alignItems="center"
                          borderBottom="1px solid"
                          borderColor="gray-300"
                          height="70px"
                        >
                          {feature?.title && (
                            <Text fontWeight="bold" fontSize="textXl" fontFamily="textFont" textColor="gray-900">
                              {feature.title}
                            </Text>
                          )}
                        </Flex>
                        {feature?.featureList?.map(({ featureItem }) => (
                          <Flex
                            key={featureItem?.label}
                            flexDirection="column"
                            paddingX={32}
                            paddingY={16}
                            borderBottom="1px solid"
                            borderColor="gray-300"
                            height="60px"
                          >
                            {featureItem?.label && (
                              <Text fontWeight="regular" fontSize="textLg" fontFamily="textFont" textColor="gray-900">
                                {featureItem.label}
                              </Text>
                            )}
                          </Flex>
                        ))}
                      </Flex>
                    ))}
                  </Flex>
                </Flex>
                {planCheckList?.map(({ plan, background }) => (
                  <PlanWrapper key={plan?.value?.data?.planTitle} width={width} background={background} plan={plan} />
                ))}
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Container>
    </Flex>
  );
};

export default PricingTable;
