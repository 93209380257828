import Flex from 'quarks/Flex';
import Text from 'quarks/Text';
import type { FlexProps } from 'quarks/interpolations/flex';

import createBreadCrumbDataModel from 'molecules/Breadcrumbs/generateBreadCrumbData';
import Button, { handleButtonTracking } from 'molecules/Button/Button';

import type { FC } from 'react';

interface BreadcrumbsProps extends FlexProps {
  colorways?: string;
  backgroundIsDark?: boolean;
  url?: string;
}

const Breadcrumbs: FC<BreadcrumbsProps> = ({ backgroundIsDark, url, ...props }) => {
  const breadcrumbs = createBreadCrumbDataModel(url || '');
  const activateBreadCrumbsEllipsis = breadcrumbs.length > 3;
  const indexToInclude = [0, 1, breadcrumbs.length - 1];

  const handleClick = (title?: string) => {
    const trackingScheme = {
      label: title,
      location: 'Breadcrumbs',
    };

    handleButtonTracking(trackingScheme);
  };

  return (
    <Flex
      active={{
        boxShadow: 'none',
      }}
      hover={{
        boxShadow: 'none',
        textDecoration: 'none',
      }}
    >
      <Flex width="fit-content" paddingY={24} gap="12px" alignItems="center" justifyContent="start">
        {breadcrumbs?.map((menuItem, index) => {
          const isEllipsisBreadCrumb = activateBreadCrumbsEllipsis && index === 2;
          const isLast: boolean = index === breadcrumbs.length - 1;

          const nonLastTextColor = backgroundIsDark ? 'gray-300' : 'gray-700';
          const lastIndexTextColor = backgroundIsDark ? 'common-white' : 'primary-700';
          const textColor = isLast ? lastIndexTextColor : nonLastTextColor;
          if (indexToInclude.includes(index) || isEllipsisBreadCrumb) {
            return (
              <>
                <Button
                  size="large"
                  variant="text"
                  key={menuItem?.id || index}
                  href={isLast || isEllipsisBreadCrumb ? '' : menuItem?.link || ''}
                  boxShadow="none"
                  aria-label={menuItem.label || 'breadcrumb'}
                  paddingX={0}
                  paddingY={0}
                  backgroundColor="common-transparent"
                  textColor="gray-700"
                  cursor={isLast || isEllipsisBreadCrumb ? 'default' : 'pointer'}
                  onClick={() => handleClick(menuItem?.label)}
                  {...props}
                >
                  <Text
                    textStyle="sm"
                    textColor={textColor}
                    textAlign="left"
                    css={
                      isLast
                        ? `
                      display: -webkit-box;
                      -webkit-line-clamp: 1;
                      -webkit-box-orient: vertical;
                      overflow: hidden;`
                        : ''
                    }
                  >
                    {isEllipsisBreadCrumb ? '...' : menuItem?.label}
                  </Text>
                </Button>
                {!isLast && (
                  <Text textStyle="sm" fontWeight="regular" textColor={backgroundIsDark ? 'gray-400' : 'gray-500'}>
                    /
                  </Text>
                )}
              </>
            );
          }

          return;
        })}
      </Flex>
    </Flex>
  );
};

export default Breadcrumbs;

Breadcrumbs.defaultProps = {};
