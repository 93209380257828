import CustomHSForm from 'components/HsForm/CustomHSForm';
import HsEmbedForm from 'components/HsForm/Variants/HsEmbedForm';
import type { ComponentFormProps } from 'components/HsForm/types';

import type { FC } from 'react';

const Form: FC<ComponentFormProps> = ({ formId, calendlyRoutingId, ...props }) => {
  if (!formId) {
    return null;
  } else if (calendlyRoutingId && formId) {
    return <HsEmbedForm formId={formId} calendlyRoutingId={calendlyRoutingId} {...props} />;
  } else {
    return <CustomHSForm formId={formId} {...props} />;
  }
};

export default Form;
