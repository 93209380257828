import { media } from 'atoms/breakpoints/breakpoints';
import container from 'atoms/spacing/containers';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Image from 'quarks/Image';
import Text from 'quarks/Text';
import type { BasicProps } from 'quarks/interpolations/basic';

import Section from 'molecules/Section/Section';

import type { trustbarBackgrounds } from 'components/TrustBar/TrustBar.config';

import { getBackgroundIsDark, optimizeBuilderImage } from 'utils/functions';

import type { Builder_EntityCompany } from 'graphqlTypes';
import type { FC } from 'react';

type TrustBarProps = BasicProps & {
  heading: string;
  background: typeof trustbarBackgrounds[number];
  companies: { company: { value: Builder_EntityCompany; id: string } }[];
  collapseTopPadding?: boolean;
  sectionId?: string;
};

const TrustBar: FC<TrustBarProps> = ({ heading, background, companies, collapseTopPadding, sectionId }) => {
  const backgroundIsDark = getBackgroundIsDark(background);
  const textColor = backgroundIsDark ? 'common-white' : 'gray-700';

  return (
    <Section
      __typename="ComponentTrustBar"
      background={background}
      id={sectionId}
      css={`
        ${media.xl} {
          & > div {
            padding-top: ${collapseTopPadding && 0};
          }
        }
      `}
    >
      <Container>
        <Flex
          flexDirection="column"
          flexWrap="nowrap"
          textAlign="center"
          alignItems="center"
          marginX="auto"
          gap="32px"
          textColor={textColor}
          width="100%"
        >
          <Flex flexDirection="column" gap="24px" maxWidth={container.md}>
            {heading && <Text textStyle="eyebrow">{heading}</Text>}
          </Flex>
          {companies && (
            <Flex
              flexWrap="wrap"
              justifyContent="center"
              gap="40px 42px"
              flexDirection="column"
              sm={{
                flexDirection: 'row',
              }}
              md={{
                gap: '30px',
              }}
              lg={{
                gap: '60px',
                minWidth: '1170px',
              }}
            >
              {companies.map(({ company }, index) => {
                const data = company?.value?.data;
                const logo = backgroundIsDark ? data?.logoDark : data?.logoLight;
                const optimizeImage = logo && optimizeBuilderImage(logo);
                const optimizedFallbackImage = data?.logoLight && optimizeBuilderImage(data?.logoLight);

                return (
                  data && (
                    <Image
                      // eslint-disable-next-line react/no-array-index-key
                      key={`${data.companyName}-${index}`}
                      image={optimizeImage || optimizedFallbackImage}
                      alignSelf="center"
                      white={backgroundIsDark}
                      alt={`${data.companyName}-logo`}
                      minWidth="150px"
                      maxWidth="220px"
                      md={{
                        maxWidth: '100px',
                      }}
                      xl={{
                        maxWidth: '170px',
                      }}
                    />
                  )
                );
              })}
            </Flex>
          )}
        </Flex>
      </Container>
    </Section>
  );
};

export default TrustBar;
