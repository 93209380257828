import { graphql, useStaticQuery } from 'gatsby';

const useResourcesCategories = () => {
  const data = useStaticQuery(graphql`
    query AllResourcesCategories {
      allBuilderModels {
        resourcesCategory(sort: { data: { title: 1 } }) {
          data {
            title
            slug
          }
          id
        }
      }
    }
  `);

  return data.allBuilderModels.resourcesCategory;
};

export default useResourcesCategories;
