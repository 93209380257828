import { useMediaQuery } from '@mui/material';
import { type FC } from 'react';

import { media } from 'atoms/breakpoints/breakpoints';

import Container from 'quarks/Container';
import Grid from 'quarks/Grid';
import Image from 'quarks/Image';

import HeadingContent from 'molecules/HeadingContent/HeadingContent';

import FeatureGridCard from 'components/Cards/FeatureGridCard/FeatureGridCard';
import ComponentButton from 'components/ComponentButton/ComponentButton';
import type { FeatureGridVariantProps } from 'components/FeatureGrid/featureGridTypesAndData';

import { optimizeBuilderImage } from 'utils/functions';

const StandardFeatureGrid: FC<FeatureGridVariantProps> = ({
  useHeading,
  background,
  headingContent,
  includeImage,
  image,
  imageAltTag,
  featureGridCards,
  button,
  typename,
  backgroundIsDark,
}) => {
  const isDesktop = useMediaQuery(media.xl);
  const retrieveOptimizedImage = image && optimizeBuilderImage(image);

  const hasHeading = useHeading && headingContent;

  return (
    <>
      {hasHeading && (
        <Container paddingBottom={48} md={{ paddingBottom: 64 }}>
          <HeadingContent background={background || ''} {...headingContent} />
        </Container>
      )}
      {includeImage ? (
        <Grid
          display="block"
          xl={{
            display: 'grid',
            gridTemplateColumns: '500px 1fr',
            rowGap: '24px',
            columnGap: '100px',
          }}
        >
          {isDesktop && retrieveOptimizedImage && (
            <Image image={retrieveOptimizedImage} alt={imageAltTag || ''} width="100%" alignSelf="center" />
          )}
          <Container>
            <Grid
              rowGap="32px"
              marginBottom={16}
              md={{
                gridTemplateColumns: '1fr 1fr',
                columnGap: '30px',
                marginBottom: 32,
              }}
            >
              {featureGridCards?.map((card, index) => (
                <FeatureGridCard
                  key={`${card?.title}-${index}` || index}
                  icon={card?.icon}
                  title={card?.title}
                  description={card?.description}
                  backgroundIsDark={backgroundIsDark}
                />
              ))}
            </Grid>
            {button && (
              <ComponentButton
                buttonType="callToAction"
                button={{
                  value: {
                    data: {
                      link: button.value?.data?.link,
                      label: button.value?.data?.label,
                      hierarchy: backgroundIsDark ? 'Link White' : 'Link Color',
                      size: 'xlarge',
                      endIcon: '->',
                    },
                  },
                }}
                css="padding-left: 0 !important;"
                trackingSchema={{
                  hierarchy: '1 of 1',
                  location: typename,
                }}
              />
            )}
          </Container>
          {!isDesktop && image && (
            <Image
              image={image}
              alt={imageAltTag || ''}
              width="100%"
              maxWidth="670px"
              marginTop={20}
              md={{ marginTop: 46 }}
              css="grid-column: image;"
            />
          )}
        </Grid>
      ) : (
        <Container borderRadius="0" backgroundImage="none" paddingAll={0}>
          <Container>
            <Grid
              backgroundColor="common-transparent"
              columnGap="30px"
              rowGap="24px"
              paddingTop={!useHeading ? 48 : 0}
              paddingBottom={32}
              paddingX={0}
              md={{
                gridTemplateColumns: '1fr 1fr',
                rowGap: '32px',
                paddingTop: !useHeading ? 48 : 0,
                paddingX: 0,
              }}
              xl={{
                gridTemplateColumns: '1fr 1fr 1fr 1fr',
                paddingBottom: 48,
                rowGap: '64px',
              }}
            >
              {featureGridCards?.map((card, index) => (
                <FeatureGridCard
                  key={card?.title || index}
                  icon={card?.icon}
                  title={card?.title}
                  description={card?.description}
                  backgroundIsDark={backgroundIsDark}
                />
              ))}
            </Grid>
            {button?.value && (
              <ComponentButton
                buttonType="callToAction"
                button={{
                  value: {
                    data: {
                      label: button.value?.data?.label,
                      size: 'xLarge',
                      hierarchy: backgroundIsDark ? 'Link White' : 'Link Color',
                      endIcon: button.value?.data?.endIcon || '',
                      link: button.value?.data?.link || '',
                    },
                  },
                }}
                marginAll="auto"
                trackingSchema={{
                  hierarchy: '1 of 1',
                  location: typename,
                }}
              />
            )}
          </Container>
        </Container>
      )}
    </>
  );
};

export default StandardFeatureGrid;
