import { useEffect, useState } from 'react';

import Icon from 'atoms/Icons/Icon';
import color from 'atoms/colors/colors';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Link from 'quarks/Link';

import type { FC, MouseEventHandler } from 'react';

interface SocialShareLinksProps {
  href: string;
  pageCopyLink?: MouseEventHandler<HTMLDivElement>;
}

const SocialShareLink: FC<SocialShareLinksProps> = ({ href, pageCopyLink }) => {
  const url = encodeURIComponent(href);
  const [title, setTitle] = useState('Lineup.ai | AI Forecasting Software for Restaurants');

  const socialShares = [
    {
      icon: 'link-01',
      link: '',
      onClick: pageCopyLink,
    },
    {
      icon: 'twitter',
      link: `https://twitter.com/intent/tweet?text=${title}&url=${url}&via=LineupAi`,
      onClick: undefined,
    },
    {
      icon: 'facebook',
      link: `https://www.facebook.com/sharer.php?u=${url}`,
      onClick: undefined,
    },
    {
      icon: 'linkedin',
      link: `https://www.LinkedIn.com/shareArticle?mini=true&url=${url}&title=${title}&source=LineupAi`,
      onClick: undefined,
    },
  ] as const;

  useEffect(() => {
    setTimeout(() => {
      const pageTitle = document.querySelector('meta#title') as { content: string } | null;
      pageTitle?.content && setTitle(pageTitle.content);
    }, 1000);
  }, []);

  return (
    <Flex flexDirection="row" gap="12px">
      {socialShares.map(social => (
        <Link
          key={social.icon}
          href={social.link || undefined}
          tabIndex={0}
          focusVisible={{
            boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba(255, 255, 255, 0.3)',
            outline: 0,
          }}
          trackingSchema={{
            label: social.icon,
            location: 'Social share links',
          }}
        >
          <Container
            paddingAll={10}
            border="1px solid"
            borderColor="gray-300"
            borderRadius="8px"
            onClick={social.onClick || undefined}
            css={{
              lineHeight: 0,
              '& > svg': {
                fill: color.gray[500],
                stroke: color.gray[500],
                transition: 'fill 200ms ease-in-out, stroke 200ms ease-in-out',
              },
              '&:hover > svg': {
                fill: color.gray[900],
                stroke: color.gray[900],
              },
            }}
          >
            <Icon id={social.icon} />
          </Container>
        </Link>
      ))}
    </Flex>
  );
};

export default SocialShareLink;
