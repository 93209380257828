import { useRef } from 'react';
import { ChevronDown } from 'react-feather';
import { useMeasure } from 'react-use';

import color from 'atoms/colors/colors';
import container from 'atoms/spacing/containers';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Link from 'quarks/Link';
import Text from 'quarks/Text';
import type { BasicProps } from 'quarks/interpolations/basic';

import { tabletMaxWidth } from 'molecules/Section/Section';

import ComponentButton from 'components/ComponentButton/ComponentButton';
import MobileSubMenu from 'components/Header/MobileHeaderComponents/MobileSubMenu';

import type { Builder_ComponentButton, Builder_ComponentButtonData, Maybe, Scalars } from 'graphqlTypes';
import type { FC } from 'react';

interface MobileNavMenuProps extends BasicProps {
  isMobileNavOpen: boolean;
  navLoginButton: Builder_ComponentButtonData;
  navRegisterButton: Builder_ComponentButtonData;
  menus: Array<{
    __typename?: 'builder_HeaderMenuItemData';
    internalName?: Maybe<Scalars['String']>;
    menuItemLabel?: Maybe<Scalars['String']>;
    /** For usage when Menu Item does not contain any Submenus. See Pricing as an example on Global Navigation Figma. */
    menuItemLink?: Maybe<Scalars['String']>;
    subMenus?: Maybe<Scalars['builder_JSON']>;
  }>;
  mobileNavbarHeight: number;
  desktopNavbarHeight: number;
  handleMenuClick: (menuIndex: number) => void;
  openMenu?: number | null;
}

const MobileNavMenu: FC<MobileNavMenuProps> = ({
  desktopNavbarHeight,
  handleMenuClick,
  openMenu,
  isMobileNavOpen,
  mobileNavbarHeight,
  navLoginButton,
  navRegisterButton,
  menus,
  ...props
}) => {
  const [ref, { height: menuHeight }] = useMeasure<HTMLDivElement>();
  const liRef = useRef<HTMLLIElement>(null);

  return (
    <Flex
      ref={ref}
      zIndex={10}
      paddingBottom={48}
      boxShadow="xl"
      height="auto"
      position="fixed"
      flexDirection="column"
      justifyContent="space-between"
      opacity={isMobileNavOpen ? 1 : 0}
      transform={isMobileNavOpen ? 'translateY(0px)' : `translateY(-${menuHeight + 200}px)`}
      transition={isMobileNavOpen ? 'transform 0.2s ease-in-out' : undefined}
      top={`${mobileNavbarHeight + 1}px`}
      left={0}
      right={0}
      backgroundColor="common-white"
      css={`
        ::-webkit-scrollbar {
          width: 0;
        }
      `}
      md={{ paddingBottom: 72 }}
      xl={{ display: 'none', top: `${desktopNavbarHeight}px` }}
      {...props}
    >
      <Container overflowY="scroll" height="90vh">
        <Flex
          as="ul"
          flexDirection="column"
          flex="1 1 0%"
          overflowY="hidden"
          marginX="auto"
          marginY={0}
          paddingX={24}
          paddingY={4}
          width="100%"
          gap="16px"
          maxWidth={tabletMaxWidth}
          css={`
            list-style-type: none;
          `}
        >
          {menus?.map((subMenu, index) => {
            const isActive = openMenu === index;
            const containsSubMenuItems = subMenu?.subMenus;

            const handleKeyboardEvent = (event: React.KeyboardEvent<HTMLLIElement>) => {
              const { className } = event.target as HTMLLIElement;
              if (
                (event.key === ' ' || event.key === 'Enter' || event.key === 'Space') &&
                className === liRef?.current?.className
              ) {
                event.preventDefault();
                handleMenuClick(index);
              }
            };

            if (!subMenu) {
              return;
            }

            return (
              <Flex
                key={subMenu.internalName}
                flexDirection="column"
                as="li"
                ref={liRef}
                cursor="pointer"
                tabIndex={containsSubMenuItems && !subMenu.menuItemLink ? 0 : undefined}
                onKeyDown={
                  containsSubMenuItems ? (e: React.KeyboardEvent<HTMLLIElement>) => handleKeyboardEvent(e) : undefined
                }
                onClick={containsSubMenuItems ? () => handleMenuClick(index) : undefined}
                css={`
                  :hover,
                  :focus {
                    > div > span {
                      color: ${color.primary['600']};
                    }
                  }
                `}
              >
                <Link
                  height="52px"
                  paddingAll={8}
                  hover={{ backgroundColor: 'primary-25', borderRadius: '6px' }}
                  display="flex"
                  href={subMenu.menuItemLink && !containsSubMenuItems ? subMenu?.menuItemLink : ''}
                  width="100%"
                  css={`
                    align-items: center;
                    justify-content: space-between;
                    > svg {
                      transition: 150ms;
                    }
                    :hover,
                    :focus {
                      > span {
                        color: ${color.primary['600']};
                      }
                    }
                  `}
                  trackingSchema={{
                    label: subMenu.menuItemLabel,
                    location: 'Mobile Nav',
                  }}
                >
                  <Text textStyle="lg" textColor={isActive ? 'primary-600' : 'gray-900'} fontWeight="semiBold">
                    {subMenu.menuItemLabel}
                  </Text>
                  {containsSubMenuItems && (
                    <ChevronDown
                      stroke={color.gray['500']}
                      size={20}
                      css={`
                        transform: ${`rotate(${isActive ? '180deg' : '0deg'})`};
                        align-self: center;
                      `}
                    />
                  )}
                </Link>
                {containsSubMenuItems && isActive && (
                  <MobileSubMenu parentLabel={subMenu.menuItemLabel || ''} menus={subMenu.subMenus} />
                )}
              </Flex>
            );
          })}
        </Flex>
        <Flex
          flexDirection="column"
          gap="12px"
          marginX="auto"
          marginY={0}
          width="92%"
          maxWidth={container.md}
          alignItems="center"
          css={`
            &&& > a,
            &&& > button {
              width: 100%;
            }
          `}
        >
          {navLoginButton && (
            <ComponentButton buttonType="callToAction" {...({ button: navLoginButton } as Builder_ComponentButton)} />
          )}
          {navRegisterButton && (
            <ComponentButton
              buttonType="callToAction"
              {...({ button: navRegisterButton } as Builder_ComponentButton)}
            />
          )}
        </Flex>
      </Container>
    </Flex>
  );
};

export default MobileNavMenu;
