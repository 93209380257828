import container from 'atoms/spacing/containers';

import Flex from 'quarks/Flex';
import Heading from 'quarks/Heading';
import Image from 'quarks/Image';
import Text from 'quarks/Text';

import Badge from 'molecules/Badge/Badge';
import BadgeGroup from 'molecules/BadgeGroup/BadgeGroup';

import type { CardProps } from 'components/Cards/EntryCard/EntryCard';
import ComponentButton from 'components/ComponentButton/ComponentButton';

import { optimizeBuilderImage } from 'utils/functions';
import readingTime from 'utils/readingTime';

import type { FC } from 'react';

export interface NewsroomCardProps extends Omit<CardProps, 'category'> {
  backgroundIsDark?: boolean;
  background?: string;
  category?: { model?: string };
}

const NewsroomCard: FC<NewsroomCardProps> = ({
  category,
  featuredImage,
  featuredImageCaption: alt,
  title,
  description,
  url: slug,
  blocks,
  backgroundIsDark,
  background,
}) => {
  const whiteBackground =
    backgroundIsDark || background === 'Gray-100' || background === 'Convex' || background === 'Concave';

  const optimizedImage = featuredImage && optimizeBuilderImage(featuredImage);

  let badgeText;
  switch (category?.model) {
    case 'resources-category':
      badgeText = 'Resource';
      break;
    case 'blog-post-category':
      badgeText = 'Blog';
      break;
    default:
      return null;
  }

  return (
    <Flex
      flexDirection="column-reverse"
      maxWidth={container.lg}
      width="100%"
      height="100%"
      boxShadow="md"
      lg={{
        flexDirection: 'row',
      }}
    >
      <Flex
        flexDirection="column"
        paddingY={24}
        paddingX={16}
        backgroundColor={whiteBackground ? 'common-white' : 'primary-25'}
        borderBottomLeftRadius="16px"
        borderBottomRightRadius="16px"
        gap="12px"
        height="100%"
        md={{
          paddingAll: 32,
        }}
        lg={{
          paddingAll: 40,
          borderTopLeftRadius: '16px',
          borderBottomLeftRadius: '16px',
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
        }}
      >
        {category && (
          <BadgeGroup
            title={<Text textStyle="sm" textColor="primary-700">{`${readingTime(blocks)} min read`}</Text>}
            badgePosition="start"
            backgroundColor="primary-50"
            marginBottom={4}
            md={{
              marginBottom: 12,
            }}
          >
            <Badge size="sm" backgroundColor="common-white">
              <Text textStyle="sm" textColor="primary-700">
                {badgeText}
              </Text>
            </Badge>
          </BadgeGroup>
        )}
        {title && (
          <Heading
            textColor="gray-900"
            as="h3"
            fontSize="textXl"
            lineHeight="textXl"
            lg={{
              fontSize: 'displayXs',
              lineHeight: 'displayXs',
            }}
          >
            {title}
          </Heading>
        )}
        {description && (
          <Text textStyle="md" textColor="gray-700" md={{ textStyle: 'lg' }}>
            {description}
          </Text>
        )}
        {slug && (
          <ComponentButton
            buttonType="callToAction"
            paddingAll={0}
            marginTop="auto"
            button={{
              value: {
                data: {
                  hierarchy: 'Link Blue',
                  size: 'large',
                  label: 'Learn More',
                  link: slug,
                  endIcon: '>',
                },
              },
            }}
            trackingSchema={{
              hierarchy: '1 of 1',
              location: 'NewsroomCard',
            }}
          />
        )}
      </Flex>
      <Image
        image={optimizedImage}
        alt={alt || `Featured Image for ${title}`}
        borderTopRightRadius="16px"
        borderTopLeftRadius="16px"
        height="100%"
        maxHeight="200px"
        objectFit="cover"
        objectPosition="center"
        width="100%"
        md={{
          maxHeight: '465px',
        }}
        lg={{
          width: '50%',
          borderTopRightRadius: '16px',
          borderBottomRightRadius: '16px',
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
        }}
      />
    </Flex>
  );
};

export default NewsroomCard;
