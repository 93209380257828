import SectionBuilderConfig from 'molecules/Section/Section.config';

const InlineQuoteBuilderConfig = {
  name: 'Quote',
  inputs: [
    { name: 'background', type: 'text', enum: ['Primary-600', 'Gradient-Light'], defaultValue: 'Primary-600' },
    { name: 'alignment', type: 'text', enum: ['left', 'center'], defaultValue: 'left' },
    { name: 'size', type: 'text', enum: ['small', 'large'], defaultValue: 'small' },
    { name: 'rating', type: 'number', helperText: 'Review rating by the author' },
    { name: 'quote', type: 'longText', defaultValue: 'Quote text' },
    { name: 'author', type: 'text', defaultValue: 'First Name Last Name' },
    { name: 'role', type: 'text', defaultValue: "'Role" },
    { name: 'company', type: 'text', defaultValue: 'Company' },
    ...SectionBuilderConfig.inputs,
  ],
};

export default InlineQuoteBuilderConfig;
