import gradient from 'atoms/colors/gradients';

import Container from 'quarks/Container';
import Image from 'quarks/Image';
import type { BasicProps } from 'quarks/interpolations/basic';

import Breadcrumbs from 'molecules/Breadcrumbs/Breadcrumbs';
import Section from 'molecules/Section/Section';

import EntryCard from 'components/Cards/EntryCard/EntryCard';

import doodleGenerator from 'utils/doodleGenerator';

import type { Builder_AuthorData } from 'graphqlTypes';
import type { FC } from 'react';

type AuthorProps = BasicProps & {
  authorInfo?: { data: Builder_AuthorData };
  authorName?: string;
};

const AuthorDetailHero: FC<AuthorProps> = ({ authorInfo, authorName }) => {
  const { headshot, authorsTitle, funFact, badges, bio, socials } = { ...authorInfo?.data };
  const authorsCompany = authorInfo?.data?.company?.value?.name;
  const pathname = 'authors/' + `${authorName}'s Posts`;

  const doodle = doodleGenerator('tripleknotswirl');

  const doodleProps = {
    opacity: '1',
    position: 'absolute',
    alt: 'doodle',
    zIndex: '-1',
  } as const;

  return (
    <Section
      __typename="Resource-Hero"
      css={`
        &:after {
          content: '';
          display: block;
          z-index: -2;
          height: 50%;
          background: ${gradient.radialGradient.convex};
          position: absolute;
          bottom: -10%;
          left: 0;
          width: 100%;
        }
      `}
    >
      <Container position="relative" paddingTop={24}>
        <Container display="none" md={{ display: 'block' }}>
          {doodle && (
            <>
              <Image
                height="140px"
                width="688px"
                bottom="122px"
                left="-36%"
                image={doodle}
                display="none"
                xl={{
                  display: 'block',
                }}
                {...doodleProps}
              />
              <Image
                height="85px"
                width="416px"
                top="126px"
                right="-37%"
                image={doodle}
                {...doodleProps}
                xl={{
                  top: '142px',
                  right: '-39%',
                  height: '140px',
                  width: '688px',
                }}
              />
            </>
          )}
        </Container>
        <Container
          paddingLeft={4}
          marginBottom={20}
          md={{ marginBottom: 32 }}
          xl={{ marginBottom: 58 }}
          position="relative"
        >
          <Breadcrumbs url={pathname} />
        </Container>
        <EntryCard
          variant="author"
          title={authorName}
          authorsTitle={authorsTitle}
          authorsCompany={authorsCompany}
          description={bio}
          funFact={funFact}
          featuredImage={headshot}
          featuredImageCaption={`${authorName}'s headshot`}
          authorBadges={badges}
          authorSocials={socials}
        />
      </Container>
    </Section>
  );
};

export default AuthorDetailHero;
