import { useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';

import { media } from 'atoms/breakpoints/breakpoints';
import color from 'atoms/colors/colors';
import container from 'atoms/spacing/containers';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import type { BasicProps } from 'quarks/interpolations/basic';

import { paginationButtonProps } from 'molecules/Pagination/Pagination';

import type { cardContentTypeList } from 'components/CardDeck/cardDeckPropsConfig';
import type { CardProps } from 'components/Cards/EntryCard/EntryCard';
import FeaturesCard from 'components/Cards/FeaturesCard/FeaturesCard';
import type { FeaturesCardProps } from 'components/Cards/FeaturesCard/featuresCardConfigAndTypes';
import HistoryCard from 'components/Cards/HistoryCard/HistoryCard';
import type { HistoryCardProps } from 'components/Cards/HistoryCard/historyCardConfigTypes';
import NewsroomCard from 'components/Cards/NewsroomCard';
import type { NewsroomCardProps } from 'components/Cards/NewsroomCard';
import TeamCard from 'components/Cards/TeamCard/TeamCard';
import type { TeamCardProps } from 'components/Cards/TeamCard/teamCardConfigTypes';
import ComponentButton from 'components/ComponentButton/ComponentButton';

import type { FC } from 'react';

interface CardCarouselProps extends BasicProps {
  cards: Array<CardProps | FeaturesCardProps | HistoryCardProps | TeamCardProps | NewsroomCardProps>;
  cardContentType: typeof cardContentTypeList[number];
  backgroundIsDark: boolean;
  background?: string;
}

const cardGenerator = (
  card: FeaturesCardProps | HistoryCardProps | TeamCardProps | NewsroomCardProps,
  cardContentType: typeof cardContentTypeList[number],
  backgroundIsDark: boolean,
  background?: string,
) => {
  switch (cardContentType) {
    case 'Features Cards':
      return <FeaturesCard {...card} backgroundIsDark={backgroundIsDark} />;
    case 'Team Cards':
      return <TeamCard {...card} backgroundIsDark={backgroundIsDark} />;
    case 'History Cards':
      return <HistoryCard {...card} />;
    case 'Newsroom Cards':
      return <NewsroomCard {...card} backgroundIsDark={backgroundIsDark} background={background} />;
    default:
      return null;
  }
};

const CardCarousel: FC<CardCarouselProps> = ({ cards, cardContentType, backgroundIsDark, background }) => {
  const [activeIndex, setActive] = useState(0);
  const [cardWidths, setCardWidths] = useState<number[]>([]);

  const isNewsroom = cardContentType === 'Newsroom Cards';
  const isLarge = useMediaQuery(media.sm);

  if (!cards) {
    throw new Error('Cards are required for Card Deck');
  }

  const gapBetweenCards = 30;

  const handleArrowClick = (direction: 'Right' | 'Left') => {
    const cardCount = cards?.length;

    if (direction === 'Right' && cardCount) {
      activeIndex === cardCount - 1 ? setActive(0) : setActive(activeIndex + 1);
    } else if (direction === 'Left' && cardCount) {
      activeIndex === 0 ? setActive(cardCount - 1) : setActive(activeIndex - 1);
    }
  };

  useEffect(() => {
    const allCards = [...document.querySelectorAll('.card-deck-items')];
    setCardWidths(allCards.map(card => card.getBoundingClientRect().width));
  }, [activeIndex]);

  const calculateSlide = (arr: number[], gap: number) => arr.reduce((a, b) => a + b + gap, 0);

  const standardCarouselButtonProps = {
    backgroundColor: backgroundIsDark ? 'gray-900' : 'gray-25',
    border: `1px solid ${color.gray[400]}`,
    textColor: backgroundIsDark ? 'common-white' : 'gray-700',
    height: '56px',
    width: '56px',
  } as const;

  const newsroomCarouselButtonProps = {
    backgroundColor: backgroundIsDark ? 'common-white' : 'primary-50',
    textColor: 'primary-600',
    height: isLarge ? '48px' : '40px',
    width: isLarge ? '48px' : '40px',
  } as const;

  // eslint-disable-next-line no-shadow
  const getButtonProps = (isNewsroom: boolean) => {
    const buttonProps = isNewsroom ? newsroomCarouselButtonProps : standardCarouselButtonProps;

    return buttonProps;
  };

  return (
    <>
      <Container width="max-content">
        <Flex flexDirection="column" gap="32px">
          <Flex
            gap={`${gapBetweenCards}px`}
            transform={`translateX(-${calculateSlide(cardWidths.slice(0, activeIndex), gapBetweenCards)}px)`}
            transition="transform 0.4s"
            flexWrap="nowrap"
            alignItems="stretch"
          >
            {cards?.map((card, index) => (
              <Container
                // eslint-disable-next-line react/no-array-index-key
                key={`'card'}-${index}`}
                className="card-deck-items"
                maxWidth={isNewsroom && 'calc(100vw - 48px)'}
                width={isNewsroom && container.sm}
                md={{
                  width: isNewsroom && container.md,
                }}
                xl={{
                  width: isNewsroom && container.lg,
                }}
              >
                {cardGenerator(card, cardContentType, backgroundIsDark, background)}
              </Container>
            ))}
          </Flex>
        </Flex>
      </Container>
      <Flex justifyContent="space-between" alignItems="center" position="relative">
        <Flex
          gap="24px"
          width={isNewsroom && !isLarge && '100%'}
          justifyContent={isNewsroom && !isLarge && 'space-between'}
        >
          <ComponentButton
            buttonType="control"
            button={{ value: { data: { disabled: activeIndex === 0, size: 'xlarge' } } }}
            labelIcon={isNewsroom ? '<-' : '<'}
            onClick={() => handleArrowClick('Left')}
            {...paginationButtonProps}
            {...getButtonProps(isNewsroom)}
            css={`
              &&&.Mui-disabled {
                opacity: ${backgroundIsDark ? 0.3 : 0.5};
              }
              svg {
                padding-right: 2px;
              }
            `}
            trackingSchema={{
              hierarchy: '1 of 2',
              location: 'CardCarousel',
            }}
          />
          <ComponentButton
            buttonType="control"
            button={{ value: { data: { size: 'xlarge' } } }}
            labelIcon={isNewsroom ? '->' : '>'}
            onClick={() => handleArrowClick('Right')}
            {...paginationButtonProps}
            {...getButtonProps(isNewsroom)}
            css={`
              &&&.Mui-disabled {
                opacity: ${backgroundIsDark ? 0.3 : 0.5};
              }
              svg {
                padding-left: 2px;
              }
            `}
            trackingSchema={{
              hierarchy: '2 of 2',
              location: 'CardCarousel',
            }}
          />
        </Flex>
        {isNewsroom && (
          <Flex
            gap="12px"
            position={!isLarge && 'absolute'}
            left={!isLarge && '50%'}
            transform={!isLarge && 'translate(-50%, -50%)'}
          >
            {cards.map((_, index) => (
              <Container
                width="8px"
                height="8px"
                backgroundColor={backgroundIsDark ? 'common-white' : 'primary-700'}
                opacity={index === activeIndex ? 1 : 0.3}
                borderRadius="50%"
                // eslint-disable-next-line react/no-array-index-key
                key={`dot-${index}`}
              />
            ))}
          </Flex>
        )}
      </Flex>
    </>
  );
};

export default CardCarousel;
