import type { GetColorDefinition } from 'atoms/colors/colors';

import Flex from 'quarks/Flex';

import type { FC, ReactNode } from 'react';

export interface IconWrapperProps {
  backgroundColor?: GetColorDefinition;
  children: ReactNode;
  hasGradientBorder?: boolean;
  isBackgroundDark?: boolean;
  isSquare?: boolean;
}

const IconWrapper: FC<IconWrapperProps> = ({
  backgroundColor,
  hasGradientBorder,
  children,
  isBackgroundDark,
  isSquare,
  ...props
}) => {
  const gradientColor = isBackgroundDark ? 'lineupGradient-darkMode' : 'lineupGradient-lightMode';

  return (
    <Flex
      backgroundColor={backgroundColor}
      backgroundImage={hasGradientBorder ? gradientColor : 'none'}
      borderRadius={isSquare ? '8px' : '50%'}
      width="fit-content"
      css={`
        padding: 2px;
      `}
    >
      <Flex backgroundColor="inherit" borderRadius="inherit" paddingAll={10} md={{ paddingAll: 14 }} {...props}>
        {children}
      </Flex>
    </Flex>
  );
};

export default IconWrapper;
