import { graphql, useStaticQuery } from 'gatsby';

const useCaseStudyPosts = () => {
  const data = useStaticQuery(graphql`
    query AllCaseStudies {
      allBuilderModels {
        caseStudy(options: { includeRefs: true, cachebust: true }, limit: 1000) {
          data {
            author
            category
            featuredImage
            featuredImageCaption
            publishDate
            title
            url
            description
            blocks
          }
          id
        }
      }
    }
  `);

  return data.allBuilderModels.caseStudy;
};

export default useCaseStudyPosts;
