import { useMediaQuery } from '@mui/material';
import { useCallback, useState } from 'react';
import { useLocation } from 'react-use';

import { media } from 'atoms/breakpoints/breakpoints';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Image from 'quarks/Image';

import Breadcrumbs from 'molecules/Breadcrumbs/Breadcrumbs';
import Section from 'molecules/Section/Section';

import HeroPricingCards from 'components/HeroPricing/HeroPricingCards';
import HeroPricingCopy from 'components/HeroPricing/HeroPricingCopy';
import PlanSwitcher from 'components/HeroPricing/PlanSwitcher';
import type { HeroPricingProps } from 'components/HeroPricing/heroTypesAndData';
import { heroDoodleSpecs } from 'components/HeroPricing/heroTypesAndData';

import { doodleGenerator } from 'utils/doodleGenerator';
import { getBackgroundIsDark } from 'utils/functions';

import type { FC } from 'react';

const ComponentHeroPricing: FC<HeroPricingProps> = ({
  enablePlanSwitcher,
  isAnnual,
  background,
  eyebrow,
  heading,
  subheading,
  discountText,
  monthlySupplementText,
  annualSupplementText,
  doodle,
  plans,
  sectionId,
}) => {
  const [isAnnualPlan, setIsAnnualPlan] = useState(isAnnual);

  const backgroundIsDark = getBackgroundIsDark(background);
  const doodleImage = doodle && doodleGenerator(doodle);
  const secondDoodle = doodleGenerator('doodle');

  const { pathname } = useLocation();
  const isDesktop = useMediaQuery(media.lg);

  const handleAnnualToggle = useCallback(() => setIsAnnualPlan(!isAnnualPlan), [isAnnualPlan]);

  return (
    <Section __typename="ComponentHeroPricing" background={background} id={sectionId}>
      <Flex flexDirection="column" gap="36px" md={{ gap: '32px' }} paddingTop={24}>
        <Container>
          <Container paddingLeft={4} marginBottom={32} xl={{ marginBottom: 58 }}>
            <Breadcrumbs url={pathname} backgroundIsDark={backgroundIsDark} />
          </Container>
          <Flex flexDirection="column" alignItems="center" textAlign="center" gap="32px">
            <HeroPricingCopy
              eyebrow={eyebrow}
              heading={heading}
              subheading={subheading}
              backgroundIsDark={backgroundIsDark}
            />
            {enablePlanSwitcher && (
              <PlanSwitcher
                isAnnual={isAnnualPlan}
                discountText={discountText}
                monthlySupplementText={monthlySupplementText}
                annualSupplementText={annualSupplementText}
                onChangePlan={handleAnnualToggle}
              />
            )}
          </Flex>
          {doodleImage && (
            <Image
              position="absolute"
              transform="matrix(-0.95, 0.09, 0.07, 0.95, 0, 0);"
              width={heroDoodleSpecs.standard.centeredDoodle.width}
              height={heroDoodleSpecs.standard.centeredDoodle.height}
              zIndex="-10"
              top={heroDoodleSpecs.standard.centeredDoodle.top}
              left={heroDoodleSpecs.standard.centeredDoodle.left}
              image={doodleImage}
              alt={`${doodle}-doodle`}
              lg={{
                left: heroDoodleSpecs.medium.centeredDoodle.left,
                width: heroDoodleSpecs.medium.centeredDoodle.width,
                height: heroDoodleSpecs.medium.centeredDoodle.height,
                top: heroDoodleSpecs.medium.centeredDoodle.top,
              }}
              xl={{
                width: heroDoodleSpecs.xLarge.centeredDoodle.width,
                height: heroDoodleSpecs.xLarge.centeredDoodle.height,
                left: heroDoodleSpecs.xLarge.centeredDoodle.left,
                top: heroDoodleSpecs.xLarge.centeredDoodle.top,
              }}
            />
          )}
          {doodleImage && secondDoodle && (
            <Image
              position="absolute"
              zIndex="-10"
              image={secondDoodle}
              alt="doodle-decoration"
              display="none"
              md={{
                display: 'block',
                bottom: '0px',
                right: '-200px',
                width: '346px',
                height: '146px',
                transform: 'rotate(-11.07deg)',
              }}
              lg={{ top: '400px', right: '-93px' }}
              xl={{ width: '542px', height: '225px', right: '-150px', top: '250px' }}
            />
          )}
        </Container>
        <HeroPricingCards isAnnual={isAnnualPlan} isCollapsible={!isDesktop} plans={plans} />
      </Flex>
    </Section>
  );
};

export default ComponentHeroPricing;
