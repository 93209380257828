import { iconValues } from 'atoms/Icons/Icon.types';

import SectionBuilderConfig from 'molecules/Section/Section.config';

import type { Writeable } from 'utils/typeUtils';

type removeReadOnlyModifier = Writeable<typeof iconValues>;

const CalculatorBuilderConfig = {
  name: 'Component ROI Calculator',
  inputs: [
    { name: 'eyebrow', type: 'text', defaultValue: 'Eyebrow' },
    { name: 'heading', type: 'text', defaultValue: 'ROI Calculator' },
    { name: 'headingType', type: 'text', enum: ['h2', 'h3', 'h4', 'h5', 'h6'] },
    { name: 'subheading', type: 'richText' },
    {
      name: 'downloadSubheading',
      type: 'longText',
      defaultValue: 'ROI Calculator',
      helperText: 'Set the subheading content for the results download.',
    },
    {
      name: 'features',
      type: 'list',
      helperText: 'If features are present, they will also render on the results download.',
      subFields: [
        {
          name: 'icon',
          type: 'text',
          enum: iconValues as removeReadOnlyModifier,
        },
        {
          name: 'copy',
          type: 'text',
        },
      ],
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onChange: options => {
        if (options.get('features').length > 5) {
          options.set('features', options.get('features').slice(0, 5));
          alert('Max of five features allowed.');
        }
      },
    },
    {
      name: 'calculatorTitle',
      type: 'text',
      defaultValue: 'ROI Calculator',
      helperText: 'Set the title inside the calculator component.',
    },
    {
      name: 'calculatorDescription',
      type: 'longText',
      defaultValue: 'Enter your information to get your annual ROI with the Lineup ROI Calculator.',
      helperText: 'Set the description inside the calculator component.',
    },
    {
      name: 'averageMonthlySales',
      type: 'object',
      subFields: [
        { name: 'label', type: 'text' },
        { name: 'placeholder', type: 'number' },
        { name: 'toolTipTitle', type: 'text' },
        { name: 'toolTipDescription', type: 'text' },
      ],
      defaultValue: {
        label: 'Avg. Monthly Sales',
        placeholder: 1500,
      },
    },
    {
      name: 'percentTowardLabor',
      type: 'object',
      subFields: [
        { name: 'label', type: 'text' },
        { name: 'placeholder', type: 'number' },
        { name: 'toolTipTitle', type: 'text' },
        { name: 'toolTipDescription', type: 'text' },
      ],
      defaultValue: {
        label: '% Labor',
        placeholder: 20,
      },
    },
    {
      name: 'improvedPercentTowardLabor',
      type: 'object',
      subFields: [
        { name: 'label', type: 'text' },
        { name: 'placeholder', type: 'number' },
        { name: 'toolTipTitle', type: 'text' },
        { name: 'toolTipDescription', type: 'text' },
      ],
      defaultValue: {
        label: 'Improved % Labor',
        placeholder: 18,
      },
    },
    {
      name: 'numberOfLocations',
      type: 'object',
      subFields: [
        { name: 'label', type: 'text' },
        { name: 'placeholder', type: 'number' },
        { name: 'toolTipTitle', type: 'text' },
        { name: 'toolTipDescription', type: 'text' },
      ],
      defaultValue: {
        label: 'Number of Locations',
        placeholder: 5,
      },
    },
    {
      name: 'instructionText',
      type: 'string',
      defaultValue: 'This total is calculated from your inputs above.',
      helperText: 'Set the instruction text inside the calculator component.',
    },
    {
      name: 'planToggle',
      type: 'boolean',
      defaultValue: 'true',
      helperText: 'Toggle off if you do not wish to include the plan toggle in the calculator.',
    },
    {
      name: 'aboutLineup',
      type: 'object',
      subFields: [
        { name: 'heading', type: 'text' },
        { name: 'subheading', type: 'longText' },
        { name: 'bulletOne', type: 'longText' },
        { name: 'bulletTwo', type: 'longText' },
        { name: 'bulletThree', type: 'longText' },
      ],
      defaultValue: {
        heading: 'About Lineup.ai',
        subheading: 'Implementing restaurant automation technology can allow you to:',
        bulletOne:
          'Decrease labor costs - Automating certain parts of your restaurant business means that you’ll need fewer staff members on you team, resulting in decreased labor costs.',
        bulletTwo:
          'Eliminate errors - Automation can also help eliminate errors by improving accuracy when taking orders, delivering food, or creating employee schedules.',
        bulletThree:
          'Reduce waste - Automated inventory management solutions can help reduce waste and lower food costs.',
      },
      helperText: 'Set the About Lineup.ai text for the results download. ',
    },
    ...SectionBuilderConfig.inputs,
  ],
};

export default CalculatorBuilderConfig;
