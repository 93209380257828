import { forwardRef } from 'react';

import Grid from 'quarks/Grid';
import Text from 'quarks/Text';
import type { BasicProps } from 'quarks/interpolations/basic';

import type { FC, KeyboardEventHandler, MouseEventHandler } from 'react';

interface DotsProps extends BasicProps {
  number?: number;
  /**
   * The flag for an active dot.
   */
  isActive?: boolean;
  /**
   * Callback function fired on Keyboard `onKeyDown` event.
   */
  onKeyDown?: KeyboardEventHandler<HTMLDivElement>;
  /**
   * Callback function fired on Mouse `onClick` event.
   */
  onClick?: MouseEventHandler<HTMLDivElement>;
}

const Dots: FC<DotsProps> = forwardRef(({ isActive, onKeyDown, onClick, number, ...props }: DotsProps, ref) => (
  <Grid
    as={Text}
    textStyle="sm"
    fontWeight="regular"
    placeItems="center"
    textAlign="center"
    alignItems="center"
    tabIndex={0}
    cursor="pointer"
    width="40px"
    height="40px"
    borderRadius="8px"
    backgroundColor={isActive ? 'primary-50' : 'common-transparent'}
    textColor={isActive ? 'primary-900' : 'gray-700'}
    onKeyDown={onKeyDown}
    onClick={onClick}
    {...ref}
    {...props}
  >
    {number && number}
  </Grid>
));

export default Dots;
