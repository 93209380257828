import Flex from 'quarks/Flex';
import Text from 'quarks/Text';

import type { weeks } from 'components/ForecastCalculator/CalculatorDataAndTypes';
import CalculatorGrid from 'components/ForecastCalculator/CalculatorGrid';

import type { FC } from 'react';

interface CalculatorHeaderProps {
  rowTitle: string;
  rowContent: typeof weeks;
}

const textProps = {
  textStyle: 'xl',
  textColor: 'primary-700',
  fontWeight: 'bold',
} as const;

const CalculatorHeader: FC<CalculatorHeaderProps> = ({ rowTitle, rowContent }) => (
  <CalculatorGrid backgroundColor="primary-50" paddingY={12} borderTopLeftRadius="8px" borderTopRightRadius="8px">
    <Flex>
      <Text {...textProps}>{rowTitle}</Text>
    </Flex>
    {rowContent.map((content: string, index: number) => (
      <Text key={content || index} {...textProps}>
        {content}
      </Text>
    ))}
  </CalculatorGrid>
);

export default CalculatorHeader;
