import Icon from 'atoms/Icons/Icon';

export interface RowData {
  [key: string]: string | number;
}

export interface CalculatorData {
  [key: string]: Record<RowData[string], RowData | string | number>;
}

export interface LaborData {
  weeklyTotals: Record<string, string>;
  laborCost: string;
}

export interface ForecastData extends LaborData {
  sales: string;
  laborHours: string;
}

export interface ToolTipFields {
  toolTipTitle?: string;
  toolTipDescription?: string;
}

export type HandleInputChange = (
  day: string,
  week: string,
  e: { nativeEvent: { data?: string }; target?: { value: string } },
) => void;

export const formatCurrency = (number: number) =>
  number.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

export const dollarIcon = <Icon id="currency-dollar" iconColor="gray-500" />;

export const percentIcon = <Icon id="percent-02" iconColor="gray-500" />;

export const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'] as const;

export const weeks = ['Week 1', 'Week 2', 'Week 3', 'Week 4'] as const;

export const weeksObj = weeks.reduce((obj, key) => ({ ...obj, [key]: '' }), {});

export const calculatorObj = days.reduce((obj, key) => ({ ...obj, [key]: weeksObj }), {});

export const mobileObj = weeks.reduce(
  (weekObj, week) => ({
    ...weekObj,
    [week]: days.reduce(
      (dayObj, day) =>
        // TODO: Fix type error
        ({ ...dayObj, [day]: calculatorObj[day][week] }),
      {},
    ),
  }),
  {},
);

export const forecastObj = {
  weeklyTotals: weeksObj,
  sales: '0',
  laborCost: '0',
  laborHours: '0',
};

export const getForecastData = (pastData: Record<string, string>, laborCost: string, hourlyRate: string) => {
  const pastArray = Object.values(pastData).map(past => parseFloat(past.replace(',', '')) || 0);

  const pastAvg = Object.values(pastArray).reduce((total, next) => total + next, 0) / pastArray.length;

  const laborDecimals = parseFloat(laborCost) * 0.01 || 0;

  const forecastLaborCost = pastAvg * laborDecimals;

  const forecastLaborHours = forecastLaborCost / parseFloat(hourlyRate) || 0;

  return {
    sales: formatCurrency(pastAvg),
    laborCost: formatCurrency(forecastLaborCost),
    laborHours: Math.ceil(forecastLaborHours).toString(),
  };
};

export const errorMessage = 'Field cannot be blank';
