import Flex from 'quarks/Flex';
import Image from 'quarks/Image';
import Link from 'quarks/Link';

import type { GeneralHeaderProps } from 'components/Header/Header';

import type { FC } from 'react';

interface SimpleHeaderProps extends GeneralHeaderProps {
  pageHasScrolled: boolean;
}

const SimpleHeader: FC<SimpleHeaderProps> = ({
  pageHasScrolled,
  borderBottomValue,
  mobileHeaderHeight,
  desktopHeaderHeight,
  modalState,
}) => (
  <Flex
    position={modalState.open ? 'inherit' : 'fixed'}
    top={pageHasScrolled ? 0 : undefined}
    backgroundColor="common-white"
    borderBottom={pageHasScrolled ? `${borderBottomValue}px solid` : ''}
    borderBottomColor="gray-100"
    zIndex={100}
    justifyContent="center"
    alignItems="center"
    as="header"
    width="100%"
    minHeight={`${mobileHeaderHeight}px`}
    lg={{ minHeight: `${desktopHeaderHeight}px` }}
  >
    <Link
      href="/"
      trackingSchema={{
        label: 'Logo',
        hierarchy: '1 of 1',
        location: 'SimpleHeader',
      }}
    >
      <Image alignSelf="center" height="38px" image="/images/lineup_Light_Logo.svg" alt="lineup-logo" loading="eager" />
    </Link>
  </Flex>
);

export default SimpleHeader;
