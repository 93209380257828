import Container from 'quarks/Container';

import Breadcrumbs from 'molecules/Breadcrumbs/Breadcrumbs';
import Section from 'molecules/Section/Section';
import SwitchbackForm from 'molecules/Switchback/SwitchbackForm';

import type { HeroProps } from 'components/Hero/HeroStandard/heroTypesAndData';

import type { FC } from 'react';

const ComponentHeroForm: FC<HeroProps> = ({
  eyebrow,
  heading,
  subheading,
  featureListHeading,
  featureList,
  hsForm,
  leftDoodle,
  rightDoodle,
  sectionId,
  ...props
}) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const pageURL = props?.builderState?.state?.location?.pathname;
  const pageURLWithoutHyphens = pageURL?.replace(/-/g, ' ');

  return (
    <Section __typename="ComponentHeroForm" background="primary-25" id={sectionId}>
      <Container position="relative" paddingTop={24}>
        <Container paddingLeft={4} marginBottom={20} md={{ marginBottom: 32 }} xl={{ marginBottom: 58 }}>
          <Breadcrumbs url={pageURLWithoutHyphens} />
        </Container>
        <SwitchbackForm
          eyebrow={eyebrow}
          heading={heading}
          features={featureList}
          featureListHeading={featureListHeading}
          leftDoodle={leftDoodle}
          rightDoodle={rightDoodle}
          subheading={subheading}
          hsForm={hsForm}
          background="gray-100"
          isHero
        />
      </Container>
    </Section>
  );
};

export default ComponentHeroForm;
