import MuiModal from '@mui/material/Modal';
import { useCallback, useRef } from 'react';
import { X } from 'react-feather';
import { useClickAway, useWindowSize } from 'react-use';
import styled from 'styled-components';

import colors from 'atoms/colors/colors';
import container from 'atoms/spacing/containers';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import type { GridProps } from 'quarks/interpolations/grid';

import type { FC, ReactNode } from 'react';

const StyledModal = styled(MuiModal)`
  .MuiBackdrop-root {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
    backdrop-filter: blur(5px);
  }
`;

interface ModalProps extends GridProps {
  /**
   * if true, the modal will be open
   */
  open: boolean;
  /**
   * Callback function for closing the modal.
   */
  onClose: () => void;
  /**
   * Modal is the only molecule that has children.
   */
  children: ReactNode;
}

const VideoModal: FC<ModalProps> = ({ open, onClose, children, ...props }) => {
  const { height } = useWindowSize();
  const containerRef = useRef<HTMLDivElement | null>(null);
  useClickAway(containerRef, onClose);

  const handleClose = useCallback(
    e => {
      if (e.type === 'click') {
        onClose();
      }
      if (e.key === 'Enter' || e.key === ' ') {
        onClose();
      }
    },
    [onClose],
  );

  return (
    <StyledModal open={open} onClose={onClose}>
      <Flex height="100vh" justifyContent="center" flexDirection="column" alignItems="center">
        <Container
          ref={containerRef}
          position="relative"
          width="100%"
          maxWidth={container.md}
          paddingX={24}
          paddingY={58}
          md={{ paddingX: 0, overflowY: 'auto' }}
          xl={{ maxWidth: height <= 750 ? `${height}px` : '1170px', paddingRight: 4 }}
          css={`
            ::-webkit-scrollbar {
              width: 0;
              background: transparent;
            }
          `}
          {...props}
        >
          <Flex position="relative" paddingRight={24} md={{ paddingRight: 0 }}>
            <X
              tabIndex={0}
              size="26px"
              css={{
                position: 'absolute',
                right: 0,
                top: -40,
                color: colors.common.white,
                cursor: 'pointer',
              }}
              onClick={handleClose}
              onKeyDown={handleClose}
            />
          </Flex>

          {children}
        </Container>
      </Flex>
    </StyledModal>
  );
};

export default VideoModal;
