import { createContext, useReducer } from 'react';

import type { FormProps } from 'components/HsForm/types';

import type { Dispatch, FC, ReactNode, Reducer } from 'react';

export interface ModalReferenceProps {
  __typename?: 'banner' | 'form';
  position?: 'top' | 'bottom';
  data?: Partial<FormProps>;
}
export interface ContextState {
  open: boolean;
  reference: ModalReferenceProps | undefined;
}

interface ContextAction {
  type: 'OPEN_MODAL' | 'CLOSE_MODAL';
  payload?: ModalReferenceProps;
}

export interface ModalContextProps {
  modalState: ContextState;
  modalDispatch: Dispatch<ContextAction>;
}

interface ModalProviderProps {
  children: ReactNode;
}

export const initialState = {
  open: false,
  reference: undefined,
};

const reducer = (_state: ContextState, action: ContextAction) => {
  switch (action.type) {
    case 'OPEN_MODAL':
      return { open: true, reference: action.payload };
    case 'CLOSE_MODAL':
      return { ...initialState };
    default: {
      throw new Error(`Unhandled action type - ${JSON.stringify(action)}`);
    }
  }
};

export const ModalContext = createContext<ModalContextProps>({
  modalState: initialState,
  modalDispatch: () => null,
});

export const ModalProvider: FC<ModalProviderProps> = ({ children }) => {
  const [modalState, modalDispatch] = useReducer<Reducer<ContextState, ContextAction>>(reducer, initialState);

  return <ModalContext.Provider value={{ modalState, modalDispatch }}>{children}</ModalContext.Provider>;
};
