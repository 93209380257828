import Flex from 'quarks/Flex';

import RichTextParser from 'utils/richTextParser';

import type { FC } from 'react';

interface CopyProps {
  copy?: string;
}

const Copy: FC<CopyProps> = ({ copy }) => (
  <Flex flexDirection="column" gap="16px" textColor="gray-700">
    {copy ? RichTextParser(copy, true) : 'Start Writing...'}
  </Flex>
);

export default Copy;
