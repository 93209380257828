const ComponentStatsBarBuilderConfig = {
  name: 'Stats Bar',
  image: 'https://tabler-icons.io/static/tabler-icons/icons-png/chart-bar.png',
  inputs: [
    {
      name: 'stats',
      type: 'list',
      subFields: [
        {
          name: 'statItem',
          type: 'object',
          subFields: [
            { name: 'statistic', type: 'text', defaultValue: 'stat' },
            { name: 'caption', type: 'text', defaultValue: 'insert caption here' },
            { name: 'id', type: 'text' },
          ],
        },
      ],
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onChange: options => {
        if (options.get('stats').length > 0) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          options.get('statItem').forEach(({ statItem }, index: number) => {
            statItem.set('id', `stat-item-${index}`);
          });
        }
        if (options.get('stats').length > 3) {
          options.set('stats', options.get('stats').slice(0, 3));
          alert('Maximum of three stats are allowed.');
        }
      },
    },
  ],
};

export default ComponentStatsBarBuilderConfig;
