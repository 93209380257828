import { graphql, useStaticQuery } from 'gatsby';
import { useMemo, useState } from 'react';

import container from 'atoms/spacing/containers';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Image from 'quarks/Image';
import Paragraph from 'quarks/Paragraph';

import Section from 'molecules/Section/Section';

import IntegrationGroup from 'components/IntegrationsList/IntegrationGroup';
import IntegrationsSidebar from 'components/Sidebar/Variations/Integrations';

import doodleGenerator from 'utils/doodleGenerator';

import type { Builder_Integration, Builder_IntegrationsCategory, Builder_IntegrationsCategoryData } from 'graphqlTypes';

interface IntegrationsListProps {
  enableDoodle?: boolean;
  sectionId?: string;
}

export interface CategoryProps extends Builder_IntegrationsCategoryData {
  id?: string;
}

export interface CategoryIntegrationsProps {
  id: string;
  heading: string;
  subheading: string;
  href?: string;
  image?: string;
  integrationCategory: Builder_IntegrationsCategory;
}

export interface JoinedCategoryIntegrationProps {
  categoryId: string;
  categoryName: string;
  categorySlug: string;
  categoryCount: number;
  categoryIntegrations: (CategoryIntegrationsProps | null)[];
}

const IntegrationsList = ({ enableDoodle, sectionId }: IntegrationsListProps) => {
  const [activeCategorySlug, setActiveCategorySlug] = useState<string>('all');
  const doodle = enableDoodle && doodleGenerator('pot');

  // Query Categories and Integrations
  const queryData = useStaticQuery(graphql`
    query CategoryQuery {
      allBuilderModels {
        integrationsCategory(sort: { data: { title: 1 } }) {
          id
          data {
            title
            slug
          }
        }
        integration(sort: { name: 1 }) {
          id
          name
          data {
            name
            description
            category
            href
            brandLogo
          }
        }
      }
    }
  `);

  // Parse Categories and Integrations
  const allIntegrations: Builder_Integration[] = queryData?.allBuilderModels?.integration || [];
  const defaultCategoryList: CategoryProps[] = [{ id: 'all', title: 'View All', slug: 'all' }];
  const allCategories: CategoryProps[] = queryData?.allBuilderModels?.integrationsCategory.reduce(
    (arr: CategoryProps[], category: Builder_IntegrationsCategory) => {
      const obj: CategoryProps = {
        id: category?.id || '',
        title: category?.data?.title,
        slug: category?.data?.slug,
      };
      arr.push(obj);

      return arr;
    },
    defaultCategoryList,
  );

  // Combine Categories and Integrations into one array, memoize for performance
  const updateIntegrationsList = () => {
    const sortedIntegrations = allCategories.slice(1).reduce((arr: JoinedCategoryIntegrationProps[], category) => {
      const categoryIntegrations = allIntegrations
        .map(integration => {
          const { id, data } = integration;

          if (data) {
            const { name, category: integrationCategory, description, href, brandLogo } = data;

            return {
              id,
              heading: name,
              subheading: description,
              brandLogo,
              integrationCategory,
              href,
            } as CategoryIntegrationsProps;
          }

          return null;
        })
        .filter(integration => integration?.integrationCategory.id === category.id);

      const categoryObj: JoinedCategoryIntegrationProps = {
        categoryId: category.id as string,
        categoryName: category.title as string,
        categorySlug: category?.slug as string,
        categoryCount: categoryIntegrations.length,
        categoryIntegrations,
      };
      arr.push(categoryObj);

      return arr;
    }, []);

    return sortedIntegrations;
  };

  const getIntegrationsList = useMemo(() => updateIntegrationsList(), []);

  // Helper Functions
  const updateActiveCategory = (value = 'all', valueType = 'slug') => {
    if (valueType === 'slug') {
      setActiveCategorySlug(value);
    } else {
      const categorySlug: string = allCategories.find(category => category.title === value)?.slug || 'all';
      setActiveCategorySlug(categorySlug);
    }
  };

  const getIntegrationCount = () => {
    if (activeCategorySlug === 'all') {
      return getIntegrationsList.reduce((tot, category) => (tot += category.categoryIntegrations.length), 0);
    }

    const currentCategory = getIntegrationsList.find(category => activeCategorySlug === category.categorySlug);

    return currentCategory?.categoryIntegrations?.length as number;
  };

  return (
    <Section id={sectionId}>
      <Container>
        <Flex
          flexDirection="column"
          justifyContent="space-between"
          gap="40px"
          lg={{ flexDirection: 'row', gap: '36px' }}
        >
          <IntegrationsSidebar
            categories={allCategories}
            activeCategory={activeCategorySlug}
            onClick={updateActiveCategory}
          />
          <Flex
            flexDirection="column"
            gap="40px"
            width="100%"
            maxWidth={container.md}
            css={{ scrollPaddingTop: '120px' }}
          >
            <Paragraph textStyle="lg" fontWeight="semiBold" textColor="gray-800">
              Displaying {getIntegrationCount()} out of {allIntegrations.length} integrations
            </Paragraph>
            {activeCategorySlug === 'all' &&
              getIntegrationsList.length > 0 &&
              getIntegrationsList.map(categoryGroup => (
                <IntegrationGroup
                  key={categoryGroup.categoryId}
                  category={categoryGroup.categoryName as string}
                  integrations={categoryGroup.categoryIntegrations}
                />
              ))}
            {activeCategorySlug !== 'all' &&
              getIntegrationsList.length > 0 &&
              getIntegrationsList
                .filter(category => category.categorySlug === activeCategorySlug)
                .map(categoryGroup => (
                  <IntegrationGroup
                    key={categoryGroup.categoryId}
                    category={categoryGroup.categoryName}
                    integrations={categoryGroup.categoryIntegrations}
                  />
                ))}
          </Flex>
        </Flex>
        {doodle && enableDoodle && (
          <Image
            opacity=".2"
            width="100%"
            maxWidth="410px"
            transform="rotate(-5deg)"
            position="absolute"
            zIndex={-10}
            image={doodle}
            alt="doodle"
            top="0%"
            right="0"
            display="none"
            sm={{
              display: 'block',
              top: '6%',
              transform: 'rotate(-5deg) translateX(30%)',
            }}
            lg={{
              top: '2%',
              transform: 'rotate(-5deg)',
            }}
          />
        )}
      </Container>
    </Section>
  );
};

export default IntegrationsList;
