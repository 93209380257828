import Breadloaf from 'assets/images/bread_Loaf.svg';
import Chefshat from 'assets/images/chefs_Hat.svg';
import Croissant from 'assets/images/croissant.svg';
import Cup from 'assets/images/cup.svg';
import CupReversed from 'assets/images/cup_Reversed.svg';
import Cutlery from 'assets/images/cutlery.svg';
import Doodle from 'assets/images/doodle.svg';
import DoodleGroup from 'assets/images/doodle_Group.svg';
import DoubleKnotThirdInverted from 'assets/images/double_Knot_Third_Inverted.svg';
import Plate from 'assets/images/plate.svg';
import Pot from 'assets/images/pot.svg';
import Potcuttingboard from 'assets/images/pot_Cutting_Board.svg';
import Skillet from 'assets/images/skillet.svg';
import Spatula from 'assets/images/spatula.svg';
import Squiggles from 'assets/images/squiggles.svg';
import Swirl from 'assets/images/swirl.svg';
import TripleKnotSwirl from 'assets/images/triple_Knot_Swirl.svg';
import Whisk from 'assets/images/whisk.svg';

const doodleData = {
  breadloaf: Breadloaf,
  chefshat: Chefshat,
  croissant: Croissant,
  cup: Cup,
  cupreversed: CupReversed,
  cutlery: Cutlery,
  doodle: Doodle,
  doodlegroup: DoodleGroup,
  plate: Plate,
  potcuttingboard: Potcuttingboard,
  pot: Pot,
  skillet: Skillet,
  spatula: Spatula,
  squiggles: Squiggles,
  swirl: Swirl,
  whisk: Whisk,
  tripleknotswirl: TripleKnotSwirl,
  doubleknotthirdinverted: DoubleKnotThirdInverted,
} as const;

export type DoodleKey = keyof typeof doodleData;

export const doodleGenerator = (doodleType: DoodleKey) => (doodleData[doodleType] ? doodleData[doodleType] : false);

export const listOfDoodles = ['no-doodle', ...Object.keys(doodleData)];

export const doodleField = [
  {
    name: 'doodle',
    type: 'text',
    enum: listOfDoodles,
    defaultValue: 'no-doodle',
  },
];

export default doodleGenerator;
