import { useState } from 'react';

import { breakpoints } from 'atoms/breakpoints/breakpoints';
import container from 'atoms/spacing/containers';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Heading from 'quarks/Heading';
import Image from 'quarks/Image';
import Text from 'quarks/Text';
import type { BasicProps } from 'quarks/interpolations/basic';
import type { HeadingTypes } from 'quarks/styleProps/heading';

import Section from 'molecules/Section/Section';

import HorizontalSwitcherItem from 'components/HorizontalSwitcher/HorizontalSwitcherItem';
import HorizontalSwitcherTabs from 'components/HorizontalSwitcher/HorizontalSwitcherTabs';

import colorizeText from 'utils/colorizeText';
import type { DoodleKey } from 'utils/doodleGenerator';
import { doodleGenerator } from 'utils/doodleGenerator';
import RichTextParser from 'utils/richTextParser';

import type { FC } from 'react';

export interface SwitcherItemProps {
  /**
   * Provides switcher item title.
   */
  itemTitle: string;
  /**
   * Provides switcher item heading.
   */
  heading?: string;
  /**
   * Provides switcher item heading hierarchy type.
   */
  headingType?: HeadingTypes;
  /**
   * Provides switcher item description text which is ran through the rich text parser.
   */
  desc?: string;
  /**
   * Provides switcher item image url.
   */
  image?: string;
  /**
   * Provides switcher item image alt tag.
   */
  imageAltTag?: string;
}

interface SwitcherProps extends BasicProps {
  /**
   * Provides eyebrow text.
   */
  eyebrow?: string;
  /**
   * Provides heading text.
   */
  heading?: string;
  /**
   * Provides heading hierarchy type.
   */
  headingType?: HeadingTypes;
  /**
   * Provides description text which is ran through the rich text parser.
   */
  desc?: string;
  /**
   * Determines what doodle gets selected and displayed
   */
  doodle?: DoodleKey;
  /**
   * Provides an array of switcher items.
   */
  switcherItems?: SwitcherItemProps[];
  /**
   * Sets section id for anchor link.
   */
  sectionId?: string;
}

const HorizontalSwitcher: FC<SwitcherProps> = ({
  eyebrow,
  heading,
  headingType,
  desc,
  switcherItems,
  doodle,
  sectionId,
}) => {
  const [currentSwitcherItem, setCurrentSwitcherItem] = useState(0);
  const doodleImage = doodle && doodleGenerator(doodle);

  return (
    <Section __typename="ComponentHorizontalSwitcher" id={sectionId}>
      <Flex
        marginX="auto"
        textAlign="center"
        flexDirection="column"
        flexWrap="nowrap"
        gap="40px"
        position="relative"
        md={{
          paddingY: 32,
        }}
      >
        <Flex width="100%" maxWidth={container.md} marginAll="auto" gap="24px" flexWrap="wrap" justifyContent="center">
          {eyebrow && <Text textStyle="eyebrow">{colorizeText(eyebrow, false)}</Text>}
          {heading && (
            <Heading as={headingType || 'h2'} textStyle="xs" md={{ textStyle: 'md' }} xl={{ textStyle: 'lg' }}>
              {heading}
            </Heading>
          )}
          {desc && (
            <Text
              marginBottom={10}
              textColor="gray-700"
              md={{
                textStyle: 'xl',
                marginBottom: 20,
              }}
            >
              {RichTextParser(desc)}
            </Text>
          )}
          {switcherItems && switcherItems.length > 0 && (
            <Container
              as="ul"
              display="flex"
              overflowX="auto"
              paddingY={16}
              maxWidth="580px"
              md={{
                paddingY: 0,
                overflowX: 'unset',
              }}
              css={`
                list-style-type: none;
                padding-inline-start: unset;
                justify-content: flex-start;
                margin: -16px 0 -22px;
                @media screen and (min-width: ${breakpoints.lg}) {
                  margin: 0 0 -6px;
                  justify-content: center;
                }
              `}
            >
              {switcherItems.map((switcherItem, index) => {
                const isCurrentTab = currentSwitcherItem === index;

                return (
                  <HorizontalSwitcherTabs
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${switcherItem.itemTitle}-${index}`}
                    switcherItem={switcherItem}
                    isCurrentTab={isCurrentTab}
                    setCurrentSwitcherItem={setCurrentSwitcherItem}
                    index={index}
                  />
                );
              })}
            </Container>
          )}
        </Flex>

        {switcherItems && switcherItems.length > 0 && (
          <HorizontalSwitcherItem
            switcherItems={switcherItems}
            currentSwitcherItem={currentSwitcherItem}
            headingType={switcherItems[currentSwitcherItem].headingType || 'h3'}
          />
        )}

        {doodleImage && (
          <Image
            image={doodleImage}
            alt={`doodle of ${doodle}`}
            position="absolute"
            bottom="-40px"
            left="-125px"
            width="265px"
            display="none"
            md={{
              display: 'block',
            }}
            xl={{
              bottom: '-55px',
              left: '-255px',
              width: '345px',
            }}
            zIndex="-1"
          />
        )}
      </Flex>
    </Section>
  );
};

export default HorizontalSwitcher;
