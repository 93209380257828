import SectionBuilderConfig from 'molecules/Section/Section.config';

const sharedFields = [
  {
    name: 'description',
    type: 'longText',
  },
  {
    name: 'isPopular',
    type: 'boolean',
    helperText: 'If set to True, the card will render with a Popular banner.',
  },
  {
    name: 'serviceType',
    type: 'Tags',
    enum: ['POS', 'HR', 'Scheduling', 'Accounting'],
  },
  {
    name: 'link',
    type: 'url',
  },
];

const partnerListBuilderConfig = {
  name: 'PartnerList',
  inputs: [
    {
      name: 'integrationPartnerCards',
      type: 'list',
      helperText: 'Link to existing Integration.',
      subFields: [
        {
          name: 'integrationData',
          type: 'reference',
          model: 'integration',
        },
        ...sharedFields,
      ],
    },

    {
      name: 'specialOfferPartnerCards',
      type: 'list',
      helperText: 'Manually enter necessary partner data.',
      subFields: [
        {
          name: 'name',
          type: 'text',
        },
        {
          name: 'brandLogo',
          type: 'file',
        },
        ...sharedFields,
      ],
    },
    ...SectionBuilderConfig.inputs,
  ],
};

export default partnerListBuilderConfig;
