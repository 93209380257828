import type { FC, ReactNode } from 'react';

interface ReverseChildrenProps {
  children: ReactNode[];
  reversed: boolean;
}

const ReverseChildren: FC<ReverseChildrenProps> = ({ children, reversed }) => {
  const reversedChildren = [...children].reverse();

  return <>{reversed ? reversedChildren : children}</>;
};

export default ReverseChildren;
