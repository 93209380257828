import SectionBuilderConfig from 'molecules/Section/Section.config';

import { backgroundOptions, columnOptions } from 'components/NewsletterCta/NewsletterCta';

const NewsletterCtaBuilderConfig = {
  name: 'NewsletterCallToAction',
  inputs: [
    {
      name: 'columns',
      type: 'text',
      enum: columnOptions,
      defaultValue: 'one',
    },
    {
      name: 'background',
      type: 'text',
      enum: backgroundOptions,
      defaultValue: 'primary-25',
    },
    { name: 'heading', type: 'text', defaultValue: 'Sign up for our newsletter' },
    {
      name: 'subheading',
      type: 'richText',
      defaultValue: '<p>Fewer surprises. Greater restaurant efficiency.</p>',
    },
    ...SectionBuilderConfig.inputs,
  ],
};

export default NewsletterCtaBuilderConfig;
