import Container from 'quarks/Container';
import Image from 'quarks/Image';

import LottieAnimation from 'molecules/LottieAnimation/LottieAnimation';

import type { SlideProps } from 'components/ProgressBarSwitcher';

import { optimizeBuilderImage } from 'utils/functions';

import type { FC } from 'react';

interface ProgressBarSlideProps extends SlideProps {
  index: number;
  activeSlide: number;
  rowSize: {
    mobile: string;
    desktop: string;
  };
}

const ProgressBarSlide: FC<ProgressBarSlideProps> = ({ index, lottieFile, activeSlide, rowSize, slideImage }) => {
  const lottieNotActive = lottieFile && index !== activeSlide;
  const displayImage = !lottieFile || lottieNotActive;
  const slidePadding = index > activeSlide + 1 ? 32 : 0;
  const slideOpacity = index > activeSlide ? '.5' : '1';
  const getLeftPosition = (size: string) =>
    `calc((${index - activeSlide} * ${size}) + (32px * ${index - activeSlide}))`;
  const optimizedSlideImage = slideImage && optimizeBuilderImage(slideImage);

  return (
    <Container
      display={index < activeSlide && 'none'}
      position="absolute"
      transition="left ease-in-out 250ms"
      paddingAll={slidePadding}
      opacity={slideOpacity}
      width={rowSize.mobile}
      height={rowSize.mobile}
      left={index === activeSlide ? 0 : `${getLeftPosition(rowSize.mobile)}`}
      sm={{
        width: `${rowSize.desktop}`,
        height: `${rowSize.desktop}`,
        left: `${index === activeSlide ? 0 : getLeftPosition(rowSize.desktop)}`,
      }}
    >
      {displayImage && <Image image={optimizedSlideImage} alt="" position="relative" maxWidth="100%" />}
      {index === activeSlide && lottieFile && (
        <LottieAnimation lottieAnimation={lottieFile} placeholderImage={slideImage} isHomePage />
      )}
    </Container>
  );
};

export default ProgressBarSlide;
