export default {
  name: 'ComponentPricingTable',
  inputs: [
    { name: 'coloredEyebrow', type: 'boolean' },
    { name: 'eyebrow', type: 'text', defaultValue: 'Eyebrow' },
    { name: 'heading', type: 'text', defaultValue: 'Placeholder' },
    {
      name: 'subheading',
      type: 'richText',
      defaultValue: '<p>This is a placeholder text. Should be kept relatively short.</p>',
    },
    {
      name: 'pricingDetails',
      type: 'reference',
      model: 'pricing-details',
    },
    {
      name: 'planCheckList',
      type: 'list',
      required: true,
      subFields: [
        {
          name: 'background',
          type: 'text',
          enum: ['White', 'Gray-100', 'Gray-900', 'Primary-500', 'Primary-900'],
          defaultValue: 'White',
        },
        { name: 'plan', type: 'reference', model: 'pricing-plan' },
        {
          name: 'ctaButton',
          type: 'reference',
          model: 'component-button',
        },
      ],
    },
  ],
};
