import { media } from 'atoms/breakpoints/breakpoints';
import type { ColorList } from 'atoms/colors/colors';

import Flex from 'quarks/Flex';
import Heading from 'quarks/Heading';
import Text from 'quarks/Text';

import ComponentButton from 'components/ComponentButton/ComponentButton';
import type { ComponentFormProps } from 'components/HsForm/types';

import type { FC } from 'react';

interface ReportCardTypes {
  heading?: string;
  headingBackgroundColor?: ColorList;
  headingColor?: ColorList;
  resultsHeadingColor?: ColorList;
  message?: string;
  results?: { heading?: string; value?: string; isHours?: boolean }[];
  buttonText?: string;
  form?: ComponentFormProps;
  calculatePressed?: boolean;
}

const RevenueCard: FC<ReportCardTypes> = ({
  heading,
  headingBackgroundColor = 'primary-50',
  headingColor = 'primary-900',
  resultsHeadingColor = 'gray-900',
  message,
  results,
  buttonText,
  form,
  calculatePressed,
}) => (
  <Flex
    flexDirection="column"
    borderRadius="16px"
    backgroundColor="common-white"
    border="2px solid"
    borderColor="primary-400"
    xl={{ maxWidth: '370px' }}
  >
    <Flex
      height="40px"
      backgroundColor={headingBackgroundColor}
      borderRadius="14px 14px 0px 0px"
      alignItems="center"
      justifyContent="center"
    >
      {heading && (
        <Text textColor={headingColor} textStyle="md" fontWeight="semiBold">
          {heading}
        </Text>
      )}
    </Flex>
    {results && (
      <Flex flexDirection="column" gap="16px" paddingAll={24}>
        {results?.map(result => (
          <Flex gap="8px" flexDirection="column" key={result.heading}>
            {result.heading && (
              <Text textColor={resultsHeadingColor} textStyle="eyebrow">
                {result.heading}
              </Text>
            )}
            {result.value && (
              <Flex gap={result.isHours ? '5px' : '0px'}>
                <Heading
                  as="h2"
                  textStyle="sm"
                  fontWeight="bold"
                  textColor="primary-700"
                  sm={{ textStyle: 'md', fontWeight: 'bold' }}
                >
                  {result.isHours ? result.value : `$${result.value.substring(0, result.value.length - 2)}`}
                </Heading>
                <Heading
                  as="h2"
                  textStyle="xs"
                  fontWeight="semiBold"
                  textColor="primary-700"
                  css={`
                    line-height: 42px;
                    ${media.sm} {
                      line-height: 50px;
                    }
                  `}
                >
                  {result.isHours ? 'hrs' : result.value.slice(-2)}
                </Heading>
              </Flex>
            )}
          </Flex>
        ))}
      </Flex>
    )}
    {message && (
      <Flex
        paddingX={32}
        paddingY={24}
        position="relative"
        gap="16px"
        flexDirection="column"
        marginTop="auto"
        after={{
          content: '',
          position: 'absolute',
          width: 'calc(100% - 64px)',
          height: '1px',
          backgroundColor: 'gray-400',
          top: '0px',
        }}
      >
        <Text textStyle="lg" fontWeight="semiBold" textColor="gray-700" textAlign="center">
          {message}
        </Text>
        {form && (
          <ComponentButton
            buttonType="modalButton"
            width="100%"
            md={{
              width: 'auto',
              minWidth: '170px',
            }}
            modalButton={{
              form,
              buttonStyle: {
                label: buttonText,
                size: 'large',
                hierarchy: 'Primary Button',
                disabled: !calculatePressed,
              },
            }}
            xl={{ height: '52px' }}
            trackingSchema={{
              hierarchy: '1 of 1',
              location: 'RevenueCard',
            }}
          />
        )}
      </Flex>
    )}
  </Flex>
);

export default RevenueCard;

RevenueCard.defaultProps = {};
