import { useMediaQuery } from '@mui/material';
import { useLayoutEffect, useState } from 'react';

import { media } from 'atoms/breakpoints/breakpoints';
import container from 'atoms/spacing/containers';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Grid from 'quarks/Grid';
import Image from 'quarks/Image';

import ComponentButton from 'components/ComponentButton/ComponentButton';
import ButtonWrapper from 'components/ComponentButton/buttonWrapper';
import type { SwitchbackProps } from 'components/ComponentSwitchback/ComponentSwitchback';
import Feature from 'components/ComponentSwitchback/Feature';
import Stat from 'components/ComponentSwitchback/Stat';
import SwitchbackCopy from 'components/ComponentSwitchback/SwitchbackCopy';

import renderAsset from 'utils/assetGenerator';
import { doodleGenerator } from 'utils/doodleGenerator';
import { getBackgroundIsDark, optimizeBuilderImage } from 'utils/functions';
import ReverseChildren from 'utils/reverseChildren';

import type { Builder_ComponentButton } from 'graphqlTypes';
import type { FC } from 'react';

const Switchback: FC<SwitchbackProps> = ({
  assetType,
  background,
  buttons,
  featureType,
  coloredEyebrow,
  eyebrow,
  heading,
  headingType,
  imageCaption,
  subheading,
  doodle,
  switchbackImage,
  assetSide,
  features,
  stats,
  lottieFile,
  ariaLabel,
}) => {
  const [isReversed, setIsReversed] = useState(false);
  const isDesktop = useMediaQuery(media.xl);

  const backgroundIsDark = getBackgroundIsDark(background);
  const doodleImage = doodle && doodleGenerator(doodle);
  const textColor = backgroundIsDark ? 'common-white' : 'gray-900';

  const isAssetLeft = assetSide === 'Left';
  const isAssetRight = !isAssetLeft;
  const optimizedSwitchbackImage = switchbackImage && optimizeBuilderImage(switchbackImage);

  useLayoutEffect(() => {
    setIsReversed(isAssetLeft && isDesktop);
  }, [isDesktop, isAssetLeft]);

  return (
    <Container>
      <Grid
        alignItems="center"
        gap="32px"
        md={{
          gap: '56px',
        }}
        xl={{
          gridTemplateColumns: '1fr 1fr',
          gap: '130px',
        }}
      >
        <ReverseChildren reversed={isReversed}>
          <Flex
            flexDirection="column"
            flexWrap="nowrap"
            gap="32px"
            textColor={textColor}
            maxWidth={container.md}
            xl={{ maxWidth: container.sm }}
          >
            <SwitchbackCopy
              eyebrow={eyebrow}
              heading={heading}
              coloredEyebrow={coloredEyebrow}
              headingType={headingType}
              subheading={subheading}
              backgroundIsDark={backgroundIsDark}
            />
            {features && featureType === 'Feature List' && (
              <Flex flexDirection="column" gap="24px" xl={{ gap: '32px' }}>
                {features.map(feature => (
                  <Feature
                    backgroundIsDark={backgroundIsDark}
                    icon={feature.icon}
                    key={feature.title}
                    title={feature.title}
                    description={feature.description}
                  />
                ))}
              </Flex>
            )}
            {buttons && (
              <Flex justifyContent="start" width="100%">
                <ButtonWrapper>
                  {buttons?.map((button, index) => (
                    <ComponentButton
                      buttonType={button?.buttonType || 'callToAction'}
                      key={button.button?.value?.id || index}
                      {...(button as Builder_ComponentButton)}
                    />
                  ))}
                </ButtonWrapper>
              </Flex>
            )}
            {stats && featureType === 'Stats' && (
              <Flex flexDirection="column" gap="16px" md={{ flexDirection: 'row', gap: '32px' }}>
                {stats.map(stat => (
                  <Stat backgroundIsDark={backgroundIsDark} key={stat.stat} stat={stat.stat} caption={stat.caption} />
                ))}
              </Flex>
            )}
          </Flex>
          <Container>
            <Container position="relative">
              {renderAsset(assetType, optimizedSwitchbackImage, imageCaption, lottieFile, ariaLabel)}
              {doodleImage && (
                <Image
                  width={isAssetRight ? '283px' : '247px'}
                  left={isAssetRight ? '195px' : 'auto'}
                  right={isAssetRight ? 'auto' : '175px'}
                  top={isAssetRight ? '-30px' : 'auto'}
                  bottom={isAssetRight ? 'auto' : '30px'}
                  position="absolute"
                  zIndex={-10}
                  image={doodleImage}
                  alt={doodle}
                  md={{
                    width: isAssetRight ? '496px' : '537px',
                    left: isAssetRight ? '330px' : 'auto',
                    right: isAssetRight ? 'auto' : '670px',
                    top: isAssetRight ? '-50px' : 'auto',
                    bottom: isAssetRight ? 'auto' : '0px',
                  }}
                  xl={{ right: isAssetRight ? 'auto' : '470px' }}
                />
              )}
            </Container>
          </Container>
        </ReverseChildren>
      </Grid>
    </Container>
  );
};

export default Switchback;
