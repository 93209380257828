import { useContext } from 'react';
import { ArrowLeft, ArrowRight, ChevronDown, ChevronLeft, ChevronRight, ChevronUp, Circle } from 'react-feather';

import Icon from 'atoms/Icons/Icon';
import type { iconIds } from 'atoms/Icons/Icon.types';
import { iconValues } from 'atoms/Icons/Icon.types';
import type { ColorList } from 'atoms/colors/colors';

import type { ButtonProps } from 'molecules/Button/Button';
import Button from 'molecules/Button/Button';

import type { HierarchyDefinition } from 'components/ComponentButton/buttonThemeHierachy';
import themeHierarchy from 'components/ComponentButton/buttonThemeHierachy';
import type { ComponentButtonProps } from 'components/ComponentButton/buttonTypeConfig';

import { toCamelCase } from 'utils/functions';

import { ModalContext } from 'contexts/ModalProvider';

import type { FC } from 'react';

export const buttonFocus = {
  boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #c0c4f8',
  outline: 0,
} as const;

const getIcon = (icon?: string | null, iconColor?: ColorList, size?: number) => {
  switch (icon) {
    case '<':
      return <ChevronLeft size={size || 18} />;
    case '>':
      return <ChevronRight size={size || 18} />;
    case 'ChevronUp':
      return <ChevronUp size={size || 18} />;
    case 'ChevronDown':
      return <ChevronDown size={size || 18} />;
    case 'o':
      return <Circle size={size || 18} />;
    case '->':
      return <ArrowRight size={size || 18} />;
    case '<-':
      return <ArrowLeft size={size || 18} />;
    case 'video':
      return <Icon id="video-recorder" iconColor={iconColor} size={size || 18} />;
    default:
      return icon && iconValues.includes(icon as iconIds) ? (
        <Icon id={icon as iconIds} iconColor={iconColor} size={size || 18} />
      ) : null;
  }
};

const getIconOnlyButtonPadding = (size?: string) => {
  switch (size) {
    case 'small':
      return '8px';
    case 'medium':
      return '12px';
    case 'large':
      return '14px';
    case 'xlarge':
      return '16px';
    default:
      return undefined;
  }
};

const ComponentButton: FC<ComponentButtonProps> = ({
  buttonType,
  labelIcon,
  button,
  modalButton,
  trackingSchema,
  ...props
}) => {
  const { modalDispatch } = useContext(ModalContext);

  const handleModal = () => {
    if (modalButton?.form) {
      modalDispatch({
        type: 'OPEN_MODAL',
        payload: {
          __typename: 'form',
          position: 'top',
          data: modalButton.form,
        },
      });
    }
  };

  const { label, startIcon, endIcon, hierarchy, link, size, disabled } =
    buttonType === 'modalButton' ? { ...modalButton?.buttonStyle } : { ...button?.value?.data };

  const buttonTheme = themeHierarchy[toCamelCase(hierarchy || 'Primary Color') as HierarchyDefinition];
  const href = disabled ? '' : link;
  const hierarchyListToRemovePadding = ['Link Color', 'Link Gray', 'Link White'];
  const isLinkButton = hierarchyListToRemovePadding?.includes(hierarchy || '');

  const onButtonClick = () => {
    handleModal();

    window.analytics.track(
      'Button Clicked',
      {
        label: label ? label : startIcon || 'Unnamed Button',
        location: trackingSchema?.location ? trackingSchema.location : 'Unnamed Component',
        url: window.location.href,
        color: hierarchy ? hierarchy : 'Undefined Button Color',
        size: size ? size : 'Undefined Button Size',
        hierarchy: trackingSchema?.hierarchy ? trackingSchema.hierarchy : 'Unknown Location',
      },
      {
        context: {
          traits: window.analytics.user().traits(),
        },
      },
    );
  };

  return (
    <Button
      paddingX={isLinkButton ? 0 : undefined}
      href={href ? href : undefined}
      startIcon={
        startIcon
          ? getIcon(
              startIcon,
              disabled ? buttonTheme?.disabledBg : buttonTheme?.text,
              size?.toLowerCase() === 'medium' ? 16 : 18,
            )
          : undefined
      }
      endIcon={
        endIcon
          ? getIcon(
              endIcon,
              disabled ? buttonTheme?.disabledBg : buttonTheme?.text,
              size?.toLowerCase() === 'medium' ? 16 : 18,
            )
          : undefined
      }
      variant={buttonTheme?.variant}
      size={size?.toLowerCase() as ButtonProps['size']}
      backgroundColor={disabled ? buttonTheme?.disabledBg : buttonTheme?.bg}
      textColor={disabled ? buttonTheme?.disabledText : buttonTheme?.text}
      border={`1px solid ${disabled ? buttonTheme?.disabledBorder : buttonTheme?.border}`}
      borderRadius={isLinkButton ? '2px' : '8px'}
      boxShadow="none"
      aria-label={labelIcon ? labelIcon : label}
      disabled={disabled || false}
      hover={{ backgroundColor: buttonTheme?.hoverBg, textColor: buttonTheme?.hoverText }}
      data-analytics-label={label}
      data-analytics-target={href}
      focusVisible={buttonFocus}
      onClick={onButtonClick}
      css={`
        && {
          padding: ${labelIcon ? getIconOnlyButtonPadding(size?.toLowerCase()) : undefined};
        }
      `}
      {...props}
    >
      {labelIcon ? getIcon(labelIcon, buttonTheme?.text) : label}
    </Button>
  );
};

export default ComponentButton;
