import { type FC, useState } from 'react';

import Icon from 'atoms/Icons/Icon';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Text from 'quarks/Text';

import type { TimelineProps } from 'components/Timeline/Timeline.config';

import onKeyDown from 'utils/onKeyDown';

const TimelineEntries: FC<TimelineProps> = ({ entries }) => {
  const [featuredEntry, setFeaturedEntry] = useState(0);
  const [timelineTranslate, setTimelineTranslate] = useState(0);

  const translationAmount = 100 * (1 / ((entries && entries?.length) || 10));

  const handleUpClick = () => {
    if (timelineTranslate < 0) {
      setTimelineTranslate(timelineTranslate + translationAmount - 4);
    }
    setFeaturedEntry(featuredEntry - 1);
  };

  const handleDownClick = () => {
    if (timelineTranslate > -60) {
      setTimelineTranslate(timelineTranslate - translationAmount + 4);
    }
    setFeaturedEntry(featuredEntry + 1);
  };

  return (
    <Container position="relative" width="100%" md={{ width: '50%', maxWidth: '470px' }}>
      <Flex
        position="absolute"
        top="80%"
        bottom="-10%"
        left={0}
        right={0}
        zIndex={10}
        backgroundShorthand="linear-gradient(0deg, #F6F6FF 59.38%, rgba(246, 246, 255, 0.00) 100%)"
        md={{ bottom: 0 }}
      />
      <Flex
        display={timelineTranslate < 0 ? 'flex' : 'none'}
        position="absolute"
        top="-25px"
        left="calc(50% - 16px);"
        width="fit-content"
        paddingAll={8}
        backgroundColor="common-white"
        borderRadius="50%"
        cursor="pointer"
        zIndex={20}
        onClick={handleUpClick}
        onKeyDown={e => onKeyDown(e, () => handleUpClick())}
        tabIndex={0}
        role="button"
        aria-label="Scroll Up Button"
        md={{ top: '-20px' }}
      >
        <Icon size={24} id="chevron-up-double" />
      </Flex>
      <Container position="relative" overflowY="hidden" height="250px" md={{ height: '100%' }}>
        <Flex
          position="relative"
          flexDirection="column"
          height="100%"
          maxHeight="250px"
          overflowY="hidden"
          md={{ maxHeight: 'unset' }}
        >
          {entries && (
            <Flex
              position="absolute"
              width="100%"
              flexDirection="column"
              transition="transform 0.5s ease-out"
              transform={`translateY(${timelineTranslate}%)`}
            >
              {entries.map((entry, index) => {
                const isFeaturedEntry = index === featuredEntry;

                return (
                  <Flex key={entry?.title || index} position="relative" marginLeft={20} alignItems="center" gap="16px">
                    <Flex
                      position="absolute"
                      top={index === 0 ? '50%' : 0}
                      bottom={index === entries.length - 1 ? '50%' : 0}
                      left="-13.5px"
                      width="2px"
                      backgroundColor="primary-100"
                    />
                    <Flex
                      position="absolute"
                      top="calc(50% - 7.5px)"
                      left="-20px"
                      height="15px"
                      width="15px"
                      borderRadius="50%"
                      backgroundColor={isFeaturedEntry ? 'primary-300' : 'primary-100'}
                    />
                    <Flex
                      gap="16px"
                      alignItems="center"
                      borderRadius="8px"
                      backgroundColor={isFeaturedEntry ? 'primary-50' : 'unset'}
                      paddingAll={8}
                      flexGrow={1}
                      md={{ paddingAll: 12 }}
                      lg={{ paddingAll: 16, gap: '24px' }}
                    >
                      {entry?.icon && (
                        <Icon iconColor={isFeaturedEntry ? 'primary-600' : 'primary-300'} size={50} id={entry.icon} />
                      )}
                      <Flex flexDirection="column" maxWidth="325px">
                        {entry?.title && (
                          <Text textStyle="lg" fontWeight="semiBold" textColor="gray-900">
                            {entry.title}
                          </Text>
                        )}
                        {entry?.date && (
                          <Text textStyle="md" textColor="gray-700">
                            {entry.date}
                          </Text>
                        )}
                      </Flex>
                    </Flex>
                  </Flex>
                );
              })}
            </Flex>
          )}
        </Flex>
      </Container>
      <Flex
        display={timelineTranslate > -60 ? 'flex' : 'none'}
        position="absolute"
        left="calc(50% - 16px);"
        bottom="-20px"
        width="fit-content"
        paddingAll={8}
        backgroundColor="common-white"
        borderRadius="50%"
        cursor="pointer"
        zIndex={20}
        onClick={handleDownClick}
        onKeyDown={e => onKeyDown(e, () => handleDownClick())}
        tabIndex={0}
        role="button"
        aria-label="Scroll Down Button"
      >
        <Icon size={24} id="chevron-down-double" />
      </Flex>
    </Container>
  );
};

export default TimelineEntries;
