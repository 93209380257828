import { type FC, useState } from 'react';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Grid from 'quarks/Grid';

import HeadingContent from 'molecules/HeadingContent/HeadingContent';

import BenefitsGridCard from 'components/Cards/BenefitsGridCard/BenefitsGridCard';
import MainBenefitsGridCard from 'components/Cards/BenefitsGridCard/MainBenefitsGridCard';
import ComponentButton from 'components/ComponentButton/ComponentButton';
import type { FeatureGridVariantProps } from 'components/FeatureGrid/featureGridTypesAndData';

const EmployeeBenefitsGrid: FC<FeatureGridVariantProps> = ({
  useHeading,
  headingContent,
  featureGridCards,
  benefitsGridCards,
}) => {
  const [seeAll, setSeeAll] = useState(false);

  const hasHeading = useHeading && headingContent;

  const onSeeAllClick = () => {
    setSeeAll(!seeAll);
  };

  return (
    <Container borderRadius="12px" backgroundImage="lineupGradient-lightMode" paddingAll={2}>
      <Container backgroundColor="common-white" borderRadius="12px 12px 0 0">
        {hasHeading && (
          <Container paddingY={48} paddingX={32} md={{ paddingX: 40, paddingY: 64 }}>
            <HeadingContent background="" gapOverride="16px" {...headingContent} />
          </Container>
        )}
        <Grid
          backgroundColor="common-white"
          columnGap="30px"
          rowGap="24px"
          paddingTop={!useHeading ? 48 : 0}
          paddingBottom={32}
          paddingX={32}
          borderTopLeftRadius="12px"
          borderTopRightRadius="12px"
          md={{
            gridTemplateColumns: '1fr 1fr',
            rowGap: '32px',
            paddingTop: !useHeading ? 48 : 0,
            paddingX: 40,
          }}
          xl={{
            gridTemplateColumns: '1fr 1fr 1fr 1fr',
            paddingBottom: 48,
            rowGap: '64px',
          }}
        >
          {featureGridCards?.map((card, index) => (
            <MainBenefitsGridCard
              key={card?.title || index}
              icon={card?.icon}
              title={card?.title}
              description={card?.description}
            />
          ))}
        </Grid>
      </Container>
      <Flex
        flexDirection="column"
        gap="32px"
        width="100%"
        borderRadius="0 0 12px 12px"
        backgroundColor={seeAll ? 'primary-25' : 'common-white'}
        paddingTop={seeAll ? 32 : 0}
        paddingBottom={40}
        paddingX={32}
        md={{ gap: '48px', paddingTop: seeAll ? 48 : 0, paddingBottom: 48, paddingX: 40 }}
      >
        {seeAll && (
          <Grid
            gridTemplateColumns="1fr"
            columnGap="30px"
            rowGap="30px"
            md={{
              gridTemplateColumns: '1fr 1fr',
            }}
            xl={{
              gridTemplateColumns: '1fr 1fr 1fr 1fr',
              rowGap: '48px',
            }}
          >
            {benefitsGridCards?.map((card, index) => (
              <BenefitsGridCard
                key={card?.title || index}
                icon={card?.icon}
                title={card?.title}
                description={card?.description}
              />
            ))}
          </Grid>
        )}
        <Flex width="100%" justifyContent="center">
          <ComponentButton
            buttonType="control"
            onClick={onSeeAllClick}
            button={{
              value: {
                data: {
                  hierarchy: 'Secondary Color',
                  size: 'large',
                  label: 'See all benefits',
                  endIcon: seeAll ? 'ChevronUp' : 'ChevronDown',
                },
              },
            }}
          />
        </Flex>
      </Flex>
    </Container>
  );
};

export default EmployeeBenefitsGrid;
