import Icon from 'atoms/Icons/Icon';
import type { iconIds } from 'atoms/Icons/Icon.types';

import type { BasicProps } from 'quarks/interpolations/basic';
import type { HeadingTypes } from 'quarks/styleProps/heading';

interface FieldProps {
  label?: string;
  placeholder?: string | number;
  toolTipTitle?: string;
  toolTipDescription?: string;
}

export interface ComponentRoiCalculatorProps extends BasicProps, RoiCalculatorProps {
  eyebrow?: string;
  heading?: string;
  headingType?: HeadingTypes;
  sectionId?: string;
  subheading?: string;
  downloadSubheading?: string;
  features?: { icon?: iconIds; copy?: string }[];
  calculatorTitle?: string;
  calculatorDescription?: string;
  averageMonthlySales?: FieldProps;
  percentTowardLabor?: FieldProps;
  improvedPercentTowardLabor?: FieldProps;
  numberOfLocations?: FieldProps;
  instructionText?: string;
  aboutLineup?: { heading?: string; subheading?: string; bulletOne?: string; bulletTwo?: string; bulletThree?: string };
  planToggle?: boolean;
}

export interface RoiCalculatorProps {
  title?: string;
  description?: string;
  avgMonthlySalesField?: FieldProps;
  percentLaborField?: FieldProps;
  improvedPercentLaborField?: FieldProps;
  numberOfLocationsField?: FieldProps;
  instructionText?: string;
  downloadSubheading?: string;
  downloadFeatureText?: string[];
  aboutLineup?: { heading?: string; subheading?: string; bulletOne?: string; bulletTwo?: string; bulletThree?: string };
  planToggle?: boolean;
}

export interface planObj {
  name: string;
  value: string;
}

export interface RoiData {
  avgMonthlySales: string;
  percentLabor: string;
  improvedPercentLabor: string;
  numberOfLocations: string;
  plan: planObj;
}

export interface ToolTipFields {
  toolTipTitle?: string;
  toolTipDescription?: string;
}

export type HandleInputChange = (e: {
  target?: {
    value: string;
    name: string;
  };
}) => void;

export type ErrorData = {
  [key: string]: string | undefined;
};

export const formatCurrency = (number: number) =>
  number.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

export const formatUSDCurrency = (number: number) =>
  number.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: 'currency',
    currency: 'USD',
  });

export const dollarIcon = <Icon id="currency-dollar" iconColor="gray-500" />;

export const percentIcon = <Icon id="percent-02" iconColor="gray-500" />;

export const calculatorObj = {};

export const planOptions = [
  { name: 'Forecast + Scheduling', value: '149' },
  { name: 'Forecasts Only', value: '79' },
];

export const roiCalculatorObj = {
  avgMonthlySales: '',
  percentLabor: '20',
  improvedPercentLabor: '18',
  numberOfLocations: '',
  plan: planOptions[0],
};

/**
 * Formula: sales ratio = (A * B * D) - (A * C * D) - (D*E)
 * A = Average Monthly Sales
 * B = % of Sales Cost Toward Labor (default to 20%)
 * C = Improved % of Sales Cost Toward Labor (default to 18%)
 * D = Number of Locations
 * E = Plan (default to Forecast + Scheduling = $149)
 */

export const calculateRoi = (roiData: RoiData, planToggle?: boolean) => {
  const { avgMonthlySales, percentLabor, improvedPercentLabor, numberOfLocations, plan } = roiData;

  const avgMonthlySalesValue = avgMonthlySales;
  const laborDecimals = parseFloat(percentLabor) * 0.01 || 0;
  const improvedLabotDecimal = parseFloat(improvedPercentLabor) * 0.01 || 0;

  const totalMonthlyLaborCost = laborDecimals * parseFloat(avgMonthlySalesValue) * parseFloat(numberOfLocations);
  const improveTotalMonthlyLaborCost =
    improvedLabotDecimal * parseFloat(avgMonthlySalesValue) * parseFloat(numberOfLocations);
  const totalForecasSchedulingtPrice = parseFloat(planToggle ? plan.value : '0') * parseFloat(numberOfLocations);

  return totalMonthlyLaborCost - improveTotalMonthlyLaborCost - totalForecasSchedulingtPrice;
};

export const requiredErrorMessage = 'Field cannot be blank';
export const positiveValueErrorMessage = 'Field cannot be negative';
