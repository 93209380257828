import Flex from 'quarks/Flex';
import Heading from 'quarks/Heading';
import Image from 'quarks/Image';
import Text from 'quarks/Text';
import type { BasicProps } from 'quarks/interpolations/basic';

import Rating from 'molecules/Rating/Rating';

import { optimizeBuilderImage } from 'utils/functions';

import type { Builder_EntityQuoteData } from 'graphqlTypes';
import type { FC, ReactNode } from 'react';

interface StandardQuoteProps extends BasicProps, Builder_EntityQuoteData {
  /** Optional prop for slider control within Quote Component */
  pagination?: ReactNode;
}

const StandardQuote: FC<StandardQuoteProps> = ({
  quote,
  rating,
  image,
  author,
  role,
  company,
  pagination,
  ...props
}) => {
  const ratingToDisplay = rating && rating > 0 ? rating : false;
  const optimizedImage = image && optimizeBuilderImage(image);

  return (
    <Flex
      flexDirection="column"
      md={{
        flexDirection: 'row',
      }}
      textColor="common-white"
      borderRadius="24px"
      {...props}
    >
      <Flex
        flexDirection="column"
        justifyContent="space-between"
        paddingAll={32}
        md={{
          paddingAll: 48,
        }}
        lg={{
          paddingAll: 64,
        }}
      >
        <Flex flexDirection="column" marginBottom={32}>
          {ratingToDisplay && rating && <Rating readOnly value={rating < 5 ? ratingToDisplay : 5} marginBottom={24} />}
          {quote && (
            <Heading
              as="h6"
              fontWeight="semiBold"
              fontSize="textXl"
              lineHeight="textXl"
              md={{ textStyle: 'xs' }}
              lg={{ textStyle: 'md' }}
              marginBottom={32}
            >
              {quote}
            </Heading>
          )}

          {author && (
            <Flex flexDirection="column">
              <Text textStyle="lg">{`— ${author}`}</Text>
              {role && company && <Text textStyle="md" textColor="gray-200">{`${role} of ${company}`}</Text>}
            </Flex>
          )}
        </Flex>

        {pagination && pagination}
      </Flex>
      {optimizedImage && (
        <Image
          image={optimizedImage}
          alt={`${author} Quote Image`}
          height="100%"
          maxWidth="100%"
          objectFit="cover"
          borderBottomLeftRadius="24px"
          borderBottomRightRadius="24px"
          md={{
            maxWidth: '50%',
            borderBottomLeftRadius: 'unset',
            borderTopRightRadius: '24px',
          }}
          lg={{
            maxWidth: '40%',
          }}
        />
      )}
    </Flex>
  );
};

export default StandardQuote;
