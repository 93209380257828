import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Grid from 'quarks/Grid';
import Image from 'quarks/Image';
import Text from 'quarks/Text';

import type { SwitchbackProps } from 'components/ComponentSwitchback/ComponentSwitchback';
import Feature from 'components/ComponentSwitchback/Feature';
import SwitchbackCopy from 'components/ComponentSwitchback/SwitchbackCopy';
import HeroCopy from 'components/Hero/HeroStandard/HeroCopy';
import Form from 'components/HsForm/HsForm';

import doodleGenerator from 'utils/doodleGenerator';
import type { DoodleKey } from 'utils/doodleGenerator';
import { getBackgroundIsDark } from 'utils/functions';

import type { FC } from 'react';

interface SwitchbackFormProps extends Omit<SwitchbackProps, 'assetType'> {
  leftDoodle?: DoodleKey;
  rightDoodle?: DoodleKey;
  isHero?: boolean;
}

const SwitchbackForm: FC<SwitchbackFormProps> = ({
  eyebrow,
  heading,
  features,
  featureListHeading,
  leftDoodle,
  rightDoodle,
  hsForm,
  subheading,
  background,
  isHero,
  coloredEyebrow,
}) => {
  const leftDoodleImage = leftDoodle && doodleGenerator(leftDoodle);
  const rightDoodleImage = rightDoodle && doodleGenerator(rightDoodle);

  const backgroundIsDark = getBackgroundIsDark(background);

  const doodleProps = {
    position: 'absolute',
    alt: 'doodle',
    display: 'none',
    zIndex: '-1',
    lg: {
      display: 'block',
    },
  } as const;

  return (
    <Grid
      display="block"
      xl={{
        display: 'grid',
        columnGap: '100px',
        gridTemplateColumns: '1fr 570px',
      }}
    >
      <Flex flexDirection="column" gap="32px" position="relative">
        {isHero ? (
          <HeroCopy eyebrow={eyebrow} heading={heading} subheading={subheading} backgroundIsDark={backgroundIsDark} />
        ) : (
          <SwitchbackCopy
            coloredEyebrow={coloredEyebrow}
            eyebrow={eyebrow}
            heading={heading}
            subheading={subheading}
            backgroundIsDark={backgroundIsDark}
          />
        )}
        {features && (
          <Flex flexDirection="column" gap="24px" xl={{ gap: '32px' }}>
            {featureListHeading && (
              <Text
                as="div"
                textStyle="xl"
                fontWeight="semiBold"
                textColor={backgroundIsDark ? 'common-white' : 'gray-900'}
                md={{ textStyle: 'xxl', fontWeight: 'semiBold' }}
              >
                {featureListHeading}
              </Text>
            )}
            {features.map((feature, index) => (
              <Feature
                icon={feature?.icon}
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                backgroundIsDark={backgroundIsDark}
                title={feature?.title}
                description={feature?.description}
                copy={feature?.copy}
                isHero={isHero}
                link={feature?.link}
              />
            ))}
          </Flex>
        )}
        {leftDoodleImage && (
          <Image
            height="120px"
            width="555px"
            transform="scaleY(-1) rotate(326deg)"
            top="5px"
            left="-500px"
            image={leftDoodleImage}
            {...doodleProps}
            xl={{
              top: '70px',
              left: '-510px',
            }}
          />
        )}
      </Flex>
      {hsForm && (
        <Container
          marginTop={40}
          position="relative"
          md={{
            marginTop: 64,
          }}
          xl={{
            marginTop: 0,
          }}
        >
          {rightDoodleImage && (
            <Image
              height="205px"
              width="738px"
              top="-145px"
              right="-603px"
              image={rightDoodleImage}
              {...doodleProps}
              xl={{
                top: '-85px',
                right: '-730px',
              }}
            />
          )}
          <Form
            {...hsForm}
            transition={undefined}
            trackingSchema={{
              location: 'Hero',
            }}
          />
        </Container>
      )}
    </Grid>
  );
};

export default SwitchbackForm;
