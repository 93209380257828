import { css } from 'styled-components';

import type { GetGradientDefinition } from 'atoms/colors/gradients';
import { parseBackgroundGradient } from 'atoms/colors/gradients';
import { font } from 'atoms/typography/typography';

import typography from 'theme/typography';

import type { DefaultTheme } from 'styled-components';

export type HeadingTypes = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

type TextStyles = keyof typeof font.display;

const headingCSS = {
  /**
   * Element's `font` shorthand property. Only accepts values set in the `display` typography atom.
   * Ex: `xs`, `sm`, etc.
   */
  textStyle: (value: TextStyles) => css`
    font: ${font.display[value].font()};
    letter-spacing: ${font.display[value].letterSpacing};
  `,
  /**
   * The heading's element type. Options are `h1`, `h2`, `h3`, `h4`, `h5`, `h6`.
   * The element will automatically be styled accordingly as defined in the typography atom.
   */
  as: (value: HeadingTypes) => css`
    font-size: ${typography.typography[value].fontSize};
    line-height: ${typography.typography[value].lineHeight};
    letter-spacing: ${typography.typography[value].letterSpacing};
    font-weight: ${typography.typography[value].fontWeight};
  `,
  /**
   * Adds a gradient to the text. Uses predefined gradients from the color atom.
   */
  gradient: (value: GetGradientDefinition, palette: DefaultTheme['palette']) =>
    value
      ? css`
          background: ${parseBackgroundGradient(palette, value)};
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          padding: 10px 0;
        `
      : undefined,
};

export default headingCSS;
