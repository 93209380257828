import { Menu, X } from 'react-feather';

import color from 'atoms/colors/colors';

import Container from 'quarks/Container';
import type { BasicProps } from 'quarks/interpolations/basic';

import type { FC } from 'react';

interface HamburgerProps extends BasicProps {
  isMobileNavOpen: boolean;
  setIsMobileNavOpen: () => void;
}

const Hamburger: FC<HamburgerProps> = ({ isMobileNavOpen, setIsMobileNavOpen }) => (
  <Container
    as="button"
    aria-label="mobileMenuToggleButton"
    cursor="pointer"
    border="none"
    backgroundColor="common-transparent"
    title={isMobileNavOpen ? 'close' : 'open'}
    onClick={setIsMobileNavOpen}
    onKeyDown={(e: { key: string; preventDefault: () => void }) => {
      if (e.key === ' ' || e.key === 'Enter' || e.key === 'Space') {
        e.preventDefault();
        setIsMobileNavOpen();
      }
    }}
    xl={{ display: 'none' }}
  >
    {isMobileNavOpen ? <X size={24} stroke={color.gray['700']} /> : <Menu stroke={color.gray['500']} size="24px" />}
  </Container>
);

export default Hamburger;
