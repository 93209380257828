import { useEffect, useRef } from 'react';
import { useWindowSize } from 'react-use';

import Icon from 'atoms/Icons/Icon';
import { media } from 'atoms/breakpoints/breakpoints';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Heading from 'quarks/Heading';
import Image from 'quarks/Image';
import type { BasicProps } from 'quarks/interpolations/basic';

import Button from 'molecules/Button/Button';

import ComponentButton from 'components/ComponentButton/ComponentButton';
import type { SolutionsCardType } from 'components/SolutionsCardGrid/SolutionsCardGridTypes';

import { doodleGenerator } from 'utils/doodleGenerator';
import type { DoodleKey } from 'utils/doodleGenerator';
import { optimizeBuilderImage } from 'utils/functions';
import RichTextParser from 'utils/richTextParser';

import type { Dispatch, FC, SetStateAction } from 'react';

interface CardProps extends SolutionsCardType, BasicProps {
  activeCard: number;
  index: number;
  isLast?: boolean;
  onClick?: Dispatch<SetStateAction<number>>;
  handleHeight?: (height: number) => void;
}

const SolutionsCard: FC<CardProps> = ({
  title,
  description,
  doodle,
  cardImage,
  imageCaption,
  imagePosition = 'center',
  link,
  activeCard,
  index,
  isLast,
  onClick,
  handleHeight,
  ...props
}) => {
  const cardRef = useRef<HTMLDivElement>(null);
  const { width } = useWindowSize();

  const optimizedImage = cardImage && optimizeBuilderImage(cardImage);
  const doodleImage = doodle && doodleGenerator(doodle as DoodleKey);
  const bgList = ['primary-50', 'primary-200', 'primary-100'] as const;
  const zIndex = Math.abs(index - activeCard);

  const isPrevCard = index < activeCard;
  const isNextCard = index > activeCard;

  useEffect(() => {
    if (cardRef.current) {
      const posInfo = cardRef.current.getBoundingClientRect();
      handleHeight && handleHeight(posInfo.height);
    }
  }, [cardRef.current, width]);

  return (
    <Flex
      ref={cardRef}
      borderRadius="16px"
      overflowX="hidden"
      overflowY="hidden"
      position={index === 0 ? 'relative' : 'absolute'}
      zIndex={10 - zIndex}
      top="0"
      backgroundColor={activeCard === index ? 'primary-500' : bgList[index % 3]}
      transition="clip-path 300ms ease-in-out"
      css={`
        clip-path: inset(${isNextCard ? 'calc(100% - 100px)' : '0'} 0 ${isPrevCard ? 'calc(100% - 100px)' : '-50px'} 0);
        ${media.md} {
          clip-path: inset(0 ${isPrevCard ? 'calc(100% - 100px)' : '0'} 0 ${isNextCard ? 'calc(100% - 100px)' : '0'});
        }
      `}
      {...props}
    >
      {cardImage && (
        <Flex width="200px" flex="0 0 200px" display="none" xl={{ display: 'flex' }}>
          <Image
            alignSelf="center"
            objectFit="cover"
            objectPosition={imagePosition}
            width="100%"
            height="100%"
            image={optimizedImage}
            alt={imageCaption || ''}
            transition="align-self 0.150s ease"
          />
        </Flex>
      )}
      <Flex
        flexDirection="column"
        flexGrow={1}
        paddingAll={24}
        textColor="common-white"
        position="relative"
        overflowX="hidden"
        overflowY="hidden"
        zIndex={10}
        md={{
          paddingX: 48,
          paddingTop: 48,
          paddingBottom: 32,
        }}
      >
        {doodleImage && (
          <Image
            maxWidth="300px"
            height="50%"
            right="0"
            top="auto"
            bottom="0"
            position="absolute"
            zIndex={0}
            image={doodleImage}
            alt={doodle}
            opacity="0.8"
            md={{
              height: '40%',
              bottom: '0px',
            }}
          />
        )}
        <Heading
          textStyle="xs"
          fontWeight="semiBold"
          letterSpacing="neg2"
          marginBottom={40}
          md={{ fontSize: 'displaySm' }}
          xl={{ fontSize: 'displayMd' }}
        >
          {title}
        </Heading>
        {description && (
          <Flex
            flexDirection="column"
            flexGrow={1}
            fontSize="textMd"
            md={{ fontSize: 'textLg' }}
            xl={{ fontSize: 'textXl' }}
          >
            {RichTextParser(description)}
          </Flex>
        )}
        {link && <ComponentButton buttonType="callToAction" {...link} />}
        <Flex gap="16px" marginTop={32}>
          <Button
            borderRadius="50%"
            border="1px solid"
            borderColor="gray-300"
            color="secondary"
            variant="contained"
            paddingAll={0}
            width="36px"
            height="36px"
            disabled={index === 0}
            onClick={() => index !== 0 && onClick && onClick(index - 1)}
            md={{
              width: '40px',
              height: '40px',
            }}
          >
            <Icon size={16} id="arrow-left" iconColor="primary-600" display="none" md={{ display: 'flex' }} />
            <Icon size={16} id="arrow-up" iconColor="primary-600" display="flex" md={{ display: 'none' }} />
          </Button>
          <Button
            borderRadius="50%"
            border="1px solid"
            borderColor="gray-300"
            color="secondary"
            variant="contained"
            paddingAll={0}
            width="36px"
            height="36px"
            disabled={isLast}
            onClick={() => !isLast && onClick && onClick(index + 1)}
            md={{
              width: '40px',
              height: '40px',
            }}
          >
            <Icon size={16} id="arrow-right" iconColor="primary-600" display="none" md={{ display: 'flex' }} />
            <Icon size={16} id="arrow-down" iconColor="primary-600" display="flex" md={{ display: 'none' }} />
          </Button>
        </Flex>
      </Flex>
      <Container
        position="absolute"
        zIndex={activeCard === index ? 0 : 10}
        width="100%"
        height="100%"
        opacity={activeCard === index ? 0 : 1}
        transition="all 300ms ease-in-out"
        backgroundColor={activeCard === index ? 'primary-500' : bgList[index % 3]}
      >
        <Heading
          fontSize="textMd"
          fontWeight="semiBold"
          position="absolute"
          whiteSpace="nowrap"
          opacity={activeCard === index ? 0 : 1}
          bottom={activeCard > index ? 'auto' : '16px'}
          top={activeCard < index ? 'auto' : '16px'}
          left="16px"
          textColor="primary-600"
          zIndex={10}
          cursor="pointer"
          onClick={() => onClick && onClick(index)}
          md={{
            transform: `rotate(90deg)${activeCard > index ? 'translateY(-100%)' : 'translateY(100%)'}`,
            right: activeCard > index ? '100%' : '0',
            bottom: '16px',
            top: 'auto',
            left: 'auto',
          }}
          css={`
            transform-origin: right;
            user-select: none;
          `}
        >
          {title}
        </Heading>
        {doodleImage && (
          <>
            <Image
              maxWidth="300px"
              height="50%"
              left="0"
              bottom="0"
              position="absolute"
              zIndex={0}
              image={doodleImage}
              alt={doodle}
              opacity="0.8"
            />
            <Image
              maxWidth="300px"
              height="50%"
              right="0"
              bottom="0"
              position="absolute"
              zIndex={0}
              image={doodleImage}
              alt={doodle}
              opacity="0.8"
              md={{
                height: '40%',
                bottom: '0px',
              }}
            />
          </>
        )}
      </Container>
    </Flex>
  );
};

SolutionsCard.defaultProps = {
  id: 'card-1',
  title: 'Solutions Card',
  description: '<p>Insert Subheading Here</p>',
};

export default SolutionsCard;
