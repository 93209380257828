import { iconValues } from 'atoms/Icons/Icon.types';

import SectionBuilderConfig from 'molecules/Section/Section.config';
import { VideoPlayerBuilderConfig } from 'molecules/VideoPlayer/VideoPlayer';

import { ButtonBuilderConfig } from 'components/ComponentButton/buttonTypeConfig';

import { doodleField, listOfDoodles } from 'utils/doodleGenerator';

const HeroBuilderConfig = {
  name: 'ComponentHero',
  inputs: [
    { name: 'heroAlignment', type: 'text', enum: ['left-aligned', 'centered'] },
    {
      name: 'badgeGroup',
      type: 'object',
      subFields: [
        {
          name: 'badgeTitle',
          type: 'text',
        },
        {
          name: 'badgeDescription',
          type: 'text',
        },
      ],
    },
    { name: 'background', type: 'text', enum: ['Gray-900', 'White', 'Gray-100', 'Concave', 'Primary-25'] },
    ...doodleField,
    { name: 'eyebrow', type: 'text' },
    { name: 'heading', type: 'text' },
    { name: 'subheading', type: 'richText' },
    {
      name: 'buttons',
      type: 'list',
      subFields: ButtonBuilderConfig.inputs,
      helperText: 'For the Hero Component, the Figma Design System has both buttons at xLarge. ',
    },
    { name: 'assetType', type: 'text', enum: ['Image', 'Animation', 'Integration', 'Video'], defaultValue: 'Image' },
    {
      name: 'assetAlignment',
      type: 'text',
      enum: ['Right', 'Contained'],
      defaultValue: 'Right',
      helperText:
        'If the asset should be to the far Right on desktop screens, select `Right`. To keep the asset within the container width, select `Contained`.',
    },
    {
      name: 'heroImage',
      type: 'file',
      showIf: (options: { get: (arg0: string) => string }) => options.get('assetType') === 'Image',
    },
    {
      name: 'videoData',
      type: 'object',
      subFields: [...VideoPlayerBuilderConfig.inputs],
      showIf: (options: { get: (arg0: string) => string }) => options.get('assetType') === 'Video',
    },
    {
      name: 'imageCaption',
      type: 'text',
      helperText: 'Caption for an Image',
      showIf: (options: { get: (arg0: string) => string }) => options.get('assetType') === 'Image',
    },
    {
      name: 'lottieFile',
      type: 'file',
      allowedFileTypes: ['lottie', 'json'],
      helperText: 'Only accepts .json files',
      showIf: (options: { get: (arg0: string) => string }) => options.get('assetType') === 'Animation',
    },
    {
      name: 'ariaLabel',
      type: 'text',
      helperText: 'Aria Label for the Lottie',
      showIf: (options: { get: (arg0: string) => string }) => options.get('assetType') === 'Animation',
    },
    {
      name: 'integrationList',
      type: 'list',
      subFields: [
        {
          name: 'integration',
          type: 'reference',
          model: 'integration',
        },
      ],
      helperText: 'Grid of Integrations logos',
      showIf: (options: { get: (arg0: string) => string }) => options.get('assetType') === 'Integration',
      onChange: (options: { get: (arg0: string) => string; set: (arg0: string, arg1: string) => void }) => {
        if (options.get('integrationList').length > 6) {
          options.set('integrationList', options.get('integrationList').slice(0, 6));
          alert('Maximum of 6 allowed.');
        }
      },
    },
    {
      name: 'includePopularArticles',
      type: 'boolean',
      helperText: 'Toggle on to display the Popular Articles section in this hero.',
    },
    {
      name: 'popularArticles',
      type: 'list',
      helperText: 'Adding content will display the Popular Articles section in this hero.',
      subFields: [
        {
          name: 'label',
          type: 'text',
        },
        {
          name: 'link',
          type: 'url',
        },
        {
          name: 'icon',
          type: 'text',
          enum: iconValues,
        },
      ],
      showIf: (options: { get: (arg0: string) => boolean }) => options.get('includePopularArticles') === true,
      onChange: (options: { get: (arg0: string) => string; set: (arg0: string, arg1: string) => void }) => {
        if (options.get('popularArticles').length > 4) {
          options.set('popularArticles', options.get('popularArticles').slice(0, 4));
          alert('Maximum of 4 allowed.');
        }
      },
    },
    {
      name: 'rightDoodle',
      type: 'text',
      enum: listOfDoodles,
      defaultValue: 'no-doodle',
      showIf: (options: { get: (arg0: string) => string }) => options.get('assetType') === 'Integration',
    },
    {
      name: 'includeAnchorLink',
      type: 'boolean',
      helperText: 'Toggle on to include a chevron anchor button that links to another section on the page.',
    },
    {
      name: 'anchorLinkId',
      type: 'text',
      helperText: 'Insert the section ID you would like the anchor button to link to.',
      showIf: (options: { get: (arg0: string) => boolean }) => options.get('includeAnchorLink') === true,
    },
    ...SectionBuilderConfig.inputs,
  ],
};

export default HeroBuilderConfig;
