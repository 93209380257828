import { type FC, useEffect, useState } from 'react';

import color from 'atoms/colors/colors';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Text from 'quarks/Text';

import { handleButtonTracking } from 'molecules/Button/Button';
import TextField from 'molecules/TextField/TextField';

import ComponentButton, { buttonFocus } from 'components/ComponentButton/ComponentButton';
import type { ProfitMarginData } from 'components/ProfitMarginCalculator/ProfitMarginCalcDataAndTypes';
import {
  type ErrorData,
  type HandleInputChange,
  type ProfitMarginCalculatorProps,
  dollarIcon,
  positiveValueErrorMessage,
  profitMarginCalculatorObj,
  requiredErrorMessage,
} from 'components/ProfitMarginCalculator/ProfitMarginCalcDataAndTypes';
import handleCalculate from 'components/ProfitMarginCalculator/utils/calculation';

import colorizeText from 'utils/colorizeText';
import RichTextParser from 'utils/richTextParser';
import { objectKeys } from 'utils/typeUtils';

const ProfitMarginCalculator: FC<ProfitMarginCalculatorProps> = ({
  eyebrow,
  title,
  description,
  totalRevenueField,
  costOfGoodsSoldField,
  totalOperatingCostsField,
  calculatorCaption,
}) => {
  const [grossProfitMargin, setGrossProfitMargin] = useState<string | null>('0');
  const [netProfitMargin, setNetProfitMargin] = useState<string | null>('0');
  const [formData, setFormData] = useState<ProfitMarginData>(profitMarginCalculatorObj);
  const [errors, setErrors] = useState<ErrorData>({});

  const labels = {
    totalRevenue: (totalRevenueField?.label || 'Total Revenue').toLocaleString(),
    costOfGoodsSold: (costOfGoodsSoldField?.label || 'Cost of Goods Sold').toLocaleString(),
    totalOperatingCosts: (totalOperatingCostsField?.label || 'Total Operating Costs').toLocaleString(),
  };

  const placeholders = {
    totalRevenue: (totalRevenueField?.placeholder || 5000).toLocaleString(),
    costOfGoodsSold: (costOfGoodsSoldField?.placeholder || 2100).toLocaleString(),
    totalOperatingCosts: (totalOperatingCostsField?.placeholder || 500).toLocaleString(),
  };

  const handleErrors = () => {
    const blankCalcFields = objectKeys(formData).some(key => formData[key] === '');

    if (blankCalcFields) {
      return setErrors({
        ...errors,
        formData: 'All required fields must be filled in.',
      });
    }

    return setErrors({});
  };

  const handleInputChange: HandleInputChange = e => {
    const checkValue = (name: string, value: string) => {
      if (value === '') {
        setErrors({
          ...errors,
          [name]: requiredErrorMessage,
        });

        return '';
      }

      const numberValue = parseFloat(value);

      if (numberValue <= 0) {
        setErrors({
          ...errors,
          [name]: positiveValueErrorMessage,
        });

        return '';
      } else {
        delete errors[name];
      }

      return numberValue;
    };

    if (!e.target || !e.target.name) {
      return null;
    }

    setFormData(prevState => ({
      ...prevState,
      [e?.target?.name || '']: checkValue(e?.target?.name || '', e?.target?.value || ''),
    }));
  };

  const handleReset = () => {
    const trackingScheme = {
      label: 'Reset',
      hierarchy: '1 of 1',
      location: 'ProfitMarginCalculator',
    };

    handleButtonTracking(trackingScheme);

    setGrossProfitMargin('0');
    setNetProfitMargin('0');
    setFormData({
      ...profitMarginCalculatorObj,
      totalRevenue: '',
      costOfGoodsSold: '',
      totalOperatingCosts: '',
    });
    setErrors({});
  };

  useEffect(() => {
    handleErrors();
  }, [formData]);

  return (
    <Container
      paddingAll={16}
      borderRadius="20px"
      border="1px solid"
      borderColor="gray-200"
      boxShadow="md"
      backgroundColor="common-white"
      maxWidth="870px"
      md={{ paddingAll: 32, boxShadow: 'xxl' }}
    >
      <Flex flexDirection="column" gap="16px" marginBottom={40} alignItems="center">
        {eyebrow && <Text textStyle="eyebrow">{colorizeText(eyebrow, false)}</Text>}
        <Text fontSize="displayXs" textColor="common-black" fontWeight="bold" md={{ fontSize: 'displaySm' }}>
          {title || 'Profit Margin Calculator'}
        </Text>
        {description && (
          <Text textStyle="md" textColor="gray-700" md={{ textStyle: 'lg' }}>
            {description}
          </Text>
        )}
      </Flex>
      <Flex
        gap="16px"
        flexDirection="column"
        md={{
          flexDirection: 'row',
          justifyContent: 'center',
          gap: '30px',
        }}
      >
        <TextField
          label={labels['totalRevenue']}
          labelSize="textMd"
          name="totalRevenue"
          type="number"
          placeholder={placeholders['totalRevenue']}
          startIcon={dollarIcon}
          value={formData?.totalRevenue}
          onChange={e => {
            handleInputChange(e);
          }}
          tooltip={totalRevenueField?.toolTipTitle}
          tooltipDescription={totalRevenueField?.toolTipDescription}
          error={!!errors.totalRevenue}
          errorMessage={errors.totalRevenue}
          md={{ flex: '1 1 33%' }}
          css={`
            label {
              color: ${color.gray[900]};
              font-weight: 500;
            }
          `}
        />
        <TextField
          label={labels['costOfGoodsSold']}
          labelSize="textMd"
          name="costOfGoodsSold"
          type="number"
          placeholder={placeholders['costOfGoodsSold']}
          startIcon={dollarIcon}
          value={formData?.costOfGoodsSold}
          onChange={e => {
            handleInputChange(e);
          }}
          tooltip={costOfGoodsSoldField?.toolTipTitle}
          tooltipDescription={costOfGoodsSoldField?.toolTipDescription}
          error={!!errors.costOfGoodsSold}
          errorMessage={errors.costOfGoodsSold}
          md={{ flex: '1 1 33%' }}
          css={`
            label {
              color: ${color.gray[900]};
              font-weight: 500;
            }
          `}
        />
        <TextField
          label={labels['totalOperatingCosts']}
          labelSize="textMd"
          name="totalOperatingCosts"
          type="number"
          placeholder={placeholders['totalOperatingCosts']}
          startIcon={dollarIcon}
          value={formData?.totalOperatingCosts}
          onChange={e => {
            handleInputChange(e);
          }}
          tooltip={totalOperatingCostsField?.toolTipTitle}
          tooltipDescription={totalOperatingCostsField?.toolTipDescription}
          error={!!errors.totalOperatingCosts}
          errorMessage={errors.totalOperatingCosts}
          md={{ flex: '1 1 33%' }}
          css={`
            label {
              color: ${color.gray[900]};
              font-weight: 500;
            }
          `}
        />
      </Flex>
      <Flex
        gap="16px"
        marginTop={32}
        flexDirection="column"
        sm={{
          flexDirection: 'row',
          gap: '30px',
          justifyContent: 'center',
        }}
      >
        <ComponentButton
          buttonType="callToAction"
          width="100%"
          button={{
            value: {
              data: {
                label: 'Calculate',
                size: 'large',
                hierarchy: 'Primary Button',
                disabled: !!objectKeys(errors).length,
              },
            },
          }}
          focusVisible={buttonFocus}
          onClick={() => handleCalculate(formData, setGrossProfitMargin, setNetProfitMargin)}
          xl={{ height: '52px' }}
        />
        <ComponentButton
          buttonType="callToAction"
          width="100%"
          button={{
            value: {
              data: {
                label: 'Reset',
                size: 'large',
                hierarchy: 'Secondary Gray',
              },
            },
          }}
          focusVisible={buttonFocus}
          onClick={handleReset}
          xl={{ height: '52px' }}
        />
      </Flex>
      <Flex
        flexDirection="column"
        alignItems="center"
        gap="32px"
        marginTop={32}
        backgroundColor="primary-25"
        borderRadius="12px"
        paddingAll={24}
        md={{ flexDirection: 'row', justifyContent: 'space-between' }}
      >
        <Container maxWidth="289px" width="100%">
          <Flex flexDirection="column" alignItems="center" gap="12px">
            <Text textColor="gray-900" fontSize="textLg" fontWeight="bold" textAlign="center">
              Gross Profit Margin
            </Text>
            <Flex flexDirection="column" gap="6px">
              <Text textColor="primary-900" fontSize="displayMd" fontWeight="bold" textAlign="center">
                {grossProfitMargin}%
              </Text>
              <Text textColor="gray-800" fontSize="textMd" fontWeight="regular" textAlign="center">
                per month
              </Text>
            </Flex>
          </Flex>
        </Container>
        <Container maxWidth="289px" width="100%">
          <Flex flexDirection="column" alignItems="center" gap="12px">
            <Text textColor="gray-900" fontSize="textLg" fontWeight="bold" textAlign="center">
              Net Profit Margin
            </Text>
            <Flex flexDirection="column" gap="6px">
              <Text textColor="primary-900" fontSize="displayMd" fontWeight="bold" textAlign="center">
                {netProfitMargin}%
              </Text>
              <Text textColor="gray-800" fontSize="textMd" fontWeight="regular" textAlign="center">
                per month
              </Text>
            </Flex>
          </Flex>
        </Container>
      </Flex>
      {calculatorCaption && (
        <Container
          marginTop={12}
          maxWidth="570px"
          marginX="auto"
          textAlign="center"
          textColor="gray-700"
          fontSize="textXs"
        >
          {RichTextParser(calculatorCaption)}
        </Container>
      )}
    </Container>
  );
};

export default ProfitMarginCalculator;
