import { useCallback } from 'react';

import Flex from 'quarks/Flex';
import type { BasicProps } from 'quarks/interpolations/basic';
import type { FlexProps } from 'quarks/interpolations/flex';

import Dots from 'molecules/Pagination/Dots';

import ComponentButton from 'components/ComponentButton/ComponentButton';

import type { CSSProps } from 'theme/getAppTheme';

import type { FC } from 'react';

interface PaginationProps extends FlexProps {
  /**
   * if `true`, arrows will show up.
   */
  isWithArrow?: boolean;
  /**
   * Determines between light or dark style dots.
   */
  variant?: 'light' | 'dark';
  /**
   * The current active dot.
   */
  activeDot?: number;
  /**
   * The number of dots to generate.
   */
  dotsCount?: number;
  /**
   * Callback to set active dot.
   * @param dot number
   */
  onSetActiveDot: (dot: number) => void;
  /**
   * Callback fired when left arrow is clicked.
   */
  onLeftArrowClick: () => void;
  /**
   * Callback fired when right arrow is clicked.
   */
  onRightArrowClick: () => void;
}

export const paginationButtonProps: BasicProps = {
  borderRadius: '50%',
  paddingAll: 0,
  minWidth: '25px',
  width: '25px',
  height: '25px',
  backgroundColor: 'common-white',
  boxShadow: '0px 4px 8px -2px rgba(35, 35, 37, 0.1), 0px 2px 4px -2px rgba(35, 35, 37, 0.06)',
};

const Pagination: FC<PaginationProps> = ({
  isWithArrow = true,
  variant = 'dark',
  activeDot,
  dotsCount,
  onSetActiveDot,
  onLeftArrowClick,
  onRightArrowClick,
  ...props
}) => {
  const handleRightArrowClick = useCallback(() => onRightArrowClick(), [onRightArrowClick]);
  const handleLeftArrowClick = useCallback(() => onLeftArrowClick(), [onLeftArrowClick]);
  const handleActiveDot = useCallback((dotIndex: number) => onSetActiveDot(dotIndex), [onSetActiveDot]);

  return (
    <Flex gap="32px" justifyContent="center" alignItems="center" {...props}>
      {isWithArrow && (
        <ComponentButton
          button={{ value: { data: { disabled: activeDot === 0, size: 'xlarge' } } }}
          labelIcon="<"
          onClick={handleLeftArrowClick}
          {...paginationButtonProps}
          textColor={props.textColor ? props.textColor : 'common-black'}
          css={`
            &&&.Mui-disabled {
              background-color: ${(muiProps: CSSProps) => muiProps.theme.palette.gray[200]};
            }
            svg {
              padding-right: 2px;
            }
          `}
        />
      )}
      <Flex gap="16px">
        {[...Array(dotsCount).keys()]?.map((_, index: number) =>
          index === activeDot ? (
            <Dots
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              isActive
              color={variant && variant === 'light' ? 'gray-900' : 'common-white'}
              onClick={() => handleActiveDot(index)}
            />
          ) : (
            <Dots
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              color="gray-500"
              onKeyDown={e => e.key === 'Enter' && handleActiveDot(index)}
              onClick={() => handleActiveDot(index)}
            />
          ),
        )}
      </Flex>
      {isWithArrow && (
        <ComponentButton
          buttonType="callToAction"
          button={{ value: { data: { disabled: dotsCount ? activeDot === dotsCount - 1 : false, size: 'xlarge' } } }}
          labelIcon=">"
          onClick={handleRightArrowClick}
          {...paginationButtonProps}
          textColor={props.textColor ? props.textColor : 'common-black'}
          css={`
            &&&.Mui-disabled {
              background-color: ${(muiProps: CSSProps) => muiProps.theme.palette.gray[200]};
            }
            svg {
              padding-left: 2px;
            }
          `}
        />
      )}
    </Flex>
  );
};

export default Pagination;
