import SectionBuilderConfig from 'molecules/Section/Section.config';
import { VideoPlayerBuilderConfig } from 'molecules/VideoPlayer/VideoPlayer';

import { doodleField } from 'utils/doodleGenerator';

const VideoSectionBuilderConfig = {
  name: 'VideoPlayer',
  inputs: [
    {
      name: 'background',
      type: 'text',
      enum: ['White', 'Gray-100', 'Gray-900'],
      defaultValue: 'White',
    },
    {
      name: 'coloredEyebrow',
      type: 'boolean',
      helperText: 'Set to true if you want your heading colorized',
      defaultValue: true,
    },
    { name: 'eyebrow', type: 'text', defaultValue: 'Eyebrow' },
    { name: 'heading', type: 'text', defaultValue: 'Video Section' },
    {
      name: 'subheading',
      type: 'richText',
      defaultValue: '<p>This is placeholder text. Should be kept relatively short.</p>',
    },
    ...doodleField,
    {
      name: 'darkVideoPlayButton',
      type: 'boolean',
      helperText: 'Set to true if you want a dark video play button',
      defaultValue: false,
    },
    ...VideoPlayerBuilderConfig.inputs,
    ...SectionBuilderConfig.inputs,
  ],
};

export default VideoSectionBuilderConfig;
