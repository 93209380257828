import { media } from 'atoms/breakpoints/breakpoints';

import Flex from 'quarks/Flex';
import Grid from 'quarks/Grid';
import Image from 'quarks/Image';
import type { Url } from 'quarks/Image';
import Paragraph from 'quarks/Paragraph';
import Text from 'quarks/Text';

import ComponentButton from 'components/ComponentButton/ComponentButton';

import doodleGenerator from 'utils/doodleGenerator';
import { optimizeBuilderImage } from 'utils/functions';

import type { Builder_AuthorData } from 'graphqlTypes';
import type { FC } from 'react';

interface AuthorCardProps {
  author: Builder_AuthorData;
  authorName: string;
}

const AuthorCard: FC<AuthorCardProps> = ({ authorName, author: { headshot, authorsTitle, url, bio, funFact } }) => {
  const optimizedHeadshot = headshot && optimizeBuilderImage(headshot);

  return (
    <Grid
      position="relative"
      gap="24px"
      gridTemplateColumns="1fr 9fr"
      backgroundColor="gray-100"
      paddingAll={24}
      borderRadius="12px"
      contain="content"
      md={{ paddingAll: 40 }}
    >
      <Image
        image={doodleGenerator('skillet') as Url}
        alt="doodle"
        width="30%"
        left="75%"
        top="25%"
        position="absolute"
        visibility="hidden"
        zIndex={-1}
        sm={{
          visibility: 'visible',
        }}
      />
      <Image
        image={doodleGenerator('swirl') as Url}
        alt="doodle"
        width="50%"
        right="85%"
        top="2%"
        position="absolute"
        transform="rotate(35deg)"
        visibility="hidden"
        zIndex={-1}
        sm={{
          visibility: 'visible',
        }}
      />
      {optimizedHeadshot && (
        <Flex width="48px" height="48px" borderRadius="50%" flexShrink={0} contain="content">
          <Image image={optimizedHeadshot} alt={authorName || `${authorName || 'Author'}'s Headshot`} />
        </Flex>
      )}

      <Flex flexDirection="row" alignItems="center" flexWrap="wrap" gap="4px 8px">
        {authorName && (
          <Text textStyle="lg" fontWeight="bold" textColor="gray-900">
            {authorName}
          </Text>
        )}
        {authorsTitle && (
          <Text textStyle="sm" fontWeight="regular" textColor="gray-900" sm={{ textStyle: 'lg' }}>
            {authorsTitle}
          </Text>
        )}
      </Flex>
      <Flex flexDirection="column" gap="16px" css={{ gridColumn: '1/-1', [media.sm]: { gridColumn: '2/2' } }}>
        {bio && (
          <Paragraph textStyle="md" textColor="gray-700">
            {bio}
          </Paragraph>
        )}
        {funFact && (
          <Paragraph textStyle="md" fontStyle="italic" textColor="gray-700">
            {funFact}
          </Paragraph>
        )}
        <ComponentButton
          buttonType="callToAction"
          button={{
            value: {
              data: {
                label: 'More about the author',
                size: 'large',
                endIcon: '->',
                link: url || '',
                hierarchy: 'Link Gray',
              },
            },
          }}
          trackingSchema={{
            hierarchy: '1 of 1',
            location: 'AuthorCard',
          }}
        />
      </Flex>
    </Grid>
  );
};

export default AuthorCard;
