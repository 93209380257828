import { Builder, BuilderComponent, builder } from '@builder.io/react';
import { graphql } from 'gatsby';
import customComponentsRegistration, { richTextModels } from 'templates/componentGenerator';

import CardDeck from 'components/CardDeck/CardDeck';
import Footer from 'components/Footer/Footer';
import Header from 'components/Header/Header';
import Hero from 'components/Hero/Hero404/Hero404';

import generateCardsForDeck from 'utils/dataConversion';

import type { Query } from 'graphqlTypes';

const NotFoundPage = (query: { data: Query }) => {
  const {
    data: {
      allBuilderModels: {
        oneComponentHeader: header,
        oneComponentFooter: footer,
        onePageNotFound,
        post,
        gatedResource,
        blogPostCategory,
        resourcesCategory,
      },
    },
  } = query;

  const blogCards = post && generateCardsForDeck(post, 'articleCards');
  const resourceCards = gatedResource && generateCardsForDeck(gatedResource, 'resourceCards');
  const entryCards = { ...blogCards, ...resourceCards };
  const builderStateReplica = { state: { entryCategories: JSON.stringify({ blogPostCategory, resourcesCategory }) } };

  customComponentsRegistration();

  const retrievePreviewModel = Object.keys(builder.overrides)[0] || null;
  const model = builder.editingModel || retrievePreviewModel;

  if (Builder.isPreviewing || Builder.isEditing) {
    switch (model) {
      case 'page': {
        {
          return (
            <>
              {header?.content && <Header {...header?.content?.data} />}
              <BuilderComponent model="page" data={{ template: 'page' }} options={{ includeRefs: true }} />
              {footer?.content && <Footer content={footer.content.data} />}
            </>
          );
        }
      }
      case 'post': {
        Builder.register('insertMenu', {
          name: 'Blog Components',
          items: richTextModels.map(builderModel => ({ name: builderModel.config.name })),
        });

        return (
          <>
            {header?.content && <Header {...header?.content?.data} />}
            <BuilderComponent model="post" data={{ template: 'post' }} options={{ includeRefs: true }} />
            {footer?.content && <Footer content={footer.content.data} />}
          </>
        );
      }
      case 'legal': {
        Builder.register('insertMenu', {
          name: 'Legal Components',
          items: richTextModels.map(builderModel => ({ name: builderModel.config.name })),
        });

        return (
          <>
            {header?.content && <Header {...header?.content?.data} />}
            <BuilderComponent model="legal" data={{ template: 'legal' }} options={{ includeRefs: true }} />
            {footer?.content && <Footer content={footer.content.data} />}
          </>
        );
      }

      case 'gated-resource': {
        Builder.register('insertMenu', {
          name: 'Gated Resource Components',
          items: richTextModels.map(builderModel => ({ name: builderModel.config.name })),
        });

        return (
          <>
            {header?.content && <Header {...header?.content?.data} />}
            <BuilderComponent
              model="gated-resource"
              data={{ template: 'gatedResource' }}
              options={{ includeRefs: true }}
            />
            {footer?.content && <Footer content={footer.content.data} />}
          </>
        );
      }

      case 'caseStudy': {
        Builder.register('insertMenu', {
          name: 'Case Study Components',
          items: richTextModels.map(builderModel => ({ name: builderModel.config.name })),
        });

        return (
          <>
            {header?.content && <Header {...header.content.data} />}
            <BuilderComponent
              model="case-study"
              data={{ template: 'caseStudy' }}
              options={{ includeRefs: true, cachebust: true }}
            />
            {footer?.content && <Footer isDark={true} content={footer.content.data} />}
          </>
        );
      }
      case 'author': {
        {
          return (
            <>
              {header?.content && <Header {...header?.content?.data} />}
              <BuilderComponent model="author" data={{ template: 'author' }} options={{ includeRefs: true }} />
              {footer?.content && <Footer content={footer.content.data} />}
            </>
          );
        }
      }
      default:
        return (
          <>
            {header?.content && <Header {...header?.content?.data} />}
            <BuilderComponent model="page" options={{ includeRefs: true }} />
            {footer?.content && <Footer content={footer.content.data} />}
          </>
        );
    }
  } else {
    return (
      <>
        {header?.content && <Header {...header?.content?.data} />}
        <Hero {...onePageNotFound?.data?.heroCopy} heroAlignment="centered" doodle="chefshat" />
        <CardDeck
          cardDeckType="Standard"
          cardContentType="Entry Cards"
          entryCards={entryCards}
          builderState={builderStateReplica}
          hideSwitch
          {...onePageNotFound?.data?.cardDeckCopy}
        />
        {footer?.content && <Footer isDark={onePageNotFound?.data?.isDarkFooter} content={footer.content.data} />}
      </>
    );
  }
};

export const notFoundPageQuery = graphql`
  query notFoundPageQuery {
    allBuilderModels {
      oneComponentHeader(
        options: { includeRefs: true, cachebust: true }
        target: { id: "8fe1129198a9478c9cc7346a40a63805" }
      ) {
        content
      }
      oneComponentFooter(
        options: { includeRefs: true, cachebust: true }
        target: { id: "a7c491a16336403c94432e7b3ebb419b" }
      ) {
        content
      }
      onePageNotFound(options: { includeRefs: true, cachebust: true }) {
        data {
          heroCopy
          cardDeckCopy
          isDarkFooter
        }
      }
      post(options: { includeRefs: true, cachebust: true }, limit: 3) {
        content
      }
      gatedResource(options: { includeRefs: true, cachebust: true }, limit: 3) {
        content
      }
      caseStudy(options: { includeRefs: true, cachebust: true }, limit: 3) {
        content
      }
      blogPostCategory(options: { includeRefs: true, cachebust: true }) {
        content
      }
      resourcesCategory(options: { includeRefs: true, cachebust: true }) {
        content
      }
    }
  }
`;

export default NotFoundPage;
