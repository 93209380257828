import Button, { handleButtonTracking } from 'molecules/Button/Button';

import { stringToKebabCase } from 'utils/functions';

import useActiveContent from 'contexts/ContentEditorProvider';

import type { FC } from 'react';

interface TableItemProps {
  heading: string;
}

const yOffset = -100;

const scrollTo = (offset: number, callback: () => void) => {
  const fixedOffset = offset.toFixed();
  const onScroll = () => {
    if (window.scrollY.toFixed() === fixedOffset) {
      window.removeEventListener('scroll', onScroll);
      callback();
    }
  };

  window.addEventListener('scroll', onScroll);
  onScroll();
  window.scrollTo({
    top: parseInt(fixedOffset),
    behavior: 'smooth',
  });
};

const TableItem: FC<TableItemProps> = ({ heading }) => {
  const [activeId, setId, setPause] = useActiveContent();
  const id = stringToKebabCase(heading || '');
  const isActive = activeId === id;

  const handleClick = (e: React.MouseEvent<Element, MouseEvent>) => {
    e.preventDefault();
    const target = window?.document?.getElementById(id);

    if (target) {
      setPause(true);
      setId(id);
      const y = target.getBoundingClientRect().top + window.pageYOffset + yOffset;
      scrollTo(y, () => setPause(false));
    }

    const trackingScheme = {
      label: heading,
      location: 'Sidebar',
    };

    handleButtonTracking(trackingScheme);
  };

  return (
    <Button
      key={heading}
      variant="text"
      fontSize="textMd"
      fontWeight="regular"
      textDecoration="none"
      textColor={isActive ? 'gray-900' : 'gray-700'}
      paddingY={0}
      paddingX={12}
      backgroundColor="common-transparent"
      borderRadius="0"
      textAlign="left"
      transition="color 100ms ease, background-color 150ms ease-in-out"
      onClick={handleClick}
      borderLeft={isActive && '3px solid'}
      borderLeftColor={isActive && 'primary-700'}
      boxShadow="none"
      focusVisible={{
        boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba(255, 255, 255, 0.3)',
        outline: 0,
      }}
    >
      {heading}
    </Button>
  );
};

export default TableItem;
