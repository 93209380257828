import type { BasicProps } from 'quarks/interpolations/basic';

import type { ButtonProps } from 'molecules/Button/Button';

import FormConfig from 'components/HsForm/HsForm.config';
import type { ComponentFormProps } from 'components/HsForm/types';

import type { Builder_ComponentButton, Builder_ComponentButtonData } from 'graphqlTypes';

interface button
  extends Builder_ComponentButtonData,
    Omit<ButtonProps, 'disabled' | 'endIcon' | 'startIcon' | 'size' | 'children'> {}

export interface ComponentButtonProps extends BasicProps {
  type?: 'button' | 'submit' | 'reset' | HTMLAnchorElement['type'];
  labelIcon?: string;
  onClick?: () => void;
  buttonType?: 'callToAction' | 'modalButton' | 'submit' | 'control';
  button?: {
    value?: { data: button } | Builder_ComponentButton;
    model?: string;
    id?: string;
  };
  modalButton?: { buttonStyle?: button; form?: ComponentFormProps };
  trackingSchema?: ButtonTrackingSchema;
}

export interface ButtonTrackingSchema {
  /**
   * Schema of the component. refer to: [Website Tracking Plan](https://docs.google.com/spreadsheets/d/1pNO_Vs9CsNgUwoWnkOZWgZCamCiUK9c3MOqtLKyxKfU/)
   */
  location?: string;
  /**
   * The buttons position based on its own button group. ie: "1 of 2" if two buttons are together
   */
  hierarchy?: string;
  /**
   * The link's label
   */
  label?: string | null;
}

export type SingleButtonType = {
  button?: { value?: Builder_ComponentButton };
  buttonType?: 'modalButton' | 'callToAction';
};
export type ButtonTypes = {
  button?: { value?: Builder_ComponentButton };
  buttonType?: 'modalButton' | 'callToAction';
}[];

export const ButtonBuilderConfig = {
  name: 'ComponentButton',
  inputs: [
    { name: 'buttonType', type: 'text', defaultValue: 'callToAction', enum: ['callToAction', 'modalButton'] },
    {
      name: 'button',
      type: 'reference',
      model: 'component-button',
      showIf: "options.get('buttonType') === 'callToAction'",
    },
    {
      name: 'modalButton',
      type: 'object',
      subFields: [
        {
          name: 'modalButtonType',
          type: 'text',
          defaultValue: 'form',
          enum: ['form'],
        },
        {
          name: 'buttonStyle',
          type: 'object',
          subFields: [
            {
              name: 'label',
              type: 'text',
              defaultValue: 'Label',
            },
            {
              name: 'disabled',
              type: 'boolean',
              defaultValue: 'disabled',
            },
            {
              name: 'hierarchy',
              type: 'text',
              defaultValue: 'Primary Button',
              enum: [
                'Primary Button',
                'Secondary Gray',
                'Secondary Color',
                'Tertiary Gray',
                'Tertiary Color',
                'Link Gray',
                'Link Color',
                'Tertiary White',
                'Link White',
              ],
              helperText: 'Use the Hierarchy to distinguish each button. ',
            },
            {
              name: 'startIcon',
              type: 'text',
              enum: ['<', '>', 'Chevron Up', 'Chevron Down', 'o', '->', 'video', 'none'],
            },
            {
              name: 'endIcon',
              type: 'text',
              enum: ['<', '>', 'Chevron Up', 'Chevron Down', 'o', '->', 'video', 'none'],
            },
          ],
        },
        {
          name: 'form',
          type: 'object',
          subFields: [...FormConfig.inputs],
          showIf: "options.get('modalButtonType') === 'form'",
        },
      ],
      showIf: "options.get('buttonType') === 'modalButton'",
    },
  ],
};
