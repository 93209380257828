import Icon from 'atoms/Icons/Icon';
import type { iconIds } from 'atoms/Icons/Icon.types';

import Flex from 'quarks/Flex';
import Grid from 'quarks/Grid';
import Heading from 'quarks/Heading';
import type { Url } from 'quarks/Image';
import Image from 'quarks/Image';
import Paragraph from 'quarks/Paragraph';

import doodleGenerator from 'utils/doodleGenerator';

import type { FC } from 'react';

interface ArticleOutlineProps {
  heading?: string;
  headingType?: 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  features?: { id: string; icon?: iconIds; copy?: string }[];
}

const ArticleOutline: FC<ArticleOutlineProps> = ({ heading, headingType, features }) => (
  <Flex
    position="relative"
    flexDirection="column"
    gap="32px"
    backgroundColor="gray-100"
    borderRadius="16px"
    paddingAll={32}
    contain="content"
    sm={{ paddingAll: 48 }}
  >
    <Image
      image={doodleGenerator('doubleknotthirdinverted') as Url}
      alt="doodle"
      width="30%"
      left="80%"
      top="85%"
      position="absolute"
      visibility="hidden"
      transform="rotate(-35deg)"
      zIndex={-1}
      sm={{
        visibility: 'visible',
      }}
    />
    <Image
      image={doodleGenerator('doubleknotthirdinverted') as Url}
      alt="doodle"
      width="30%"
      right="82%"
      bottom="82%"
      position="absolute"
      visibility="hidden"
      transform="rotate(135deg)"
      zIndex={-1}
      sm={{
        visibility: 'visible',
      }}
    />
    {heading && (
      <Heading as={headingType || 'h2'} textStyle="xs" fontWeight="semiBold" textColor="gray-900">
        {heading}
      </Heading>
    )}
    {features && (
      <Grid gridTemplateColumns="1fr" gap="30px" sm={{ gridTemplateColumns: 'repeat(2, 1fr)' }}>
        {features.map(({ id, icon, copy }) => (
          <Flex key={id} flexDirection="row" alignItems="flex-start" gap="16px" sm={{ flexDirection: 'column' }}>
            {icon && <Icon id={icon} wrapped hasGradientBorder />}
            {copy && (
              <Paragraph textStyle="sm" fontWeight="medium" textColor="gray-700">
                {copy}
              </Paragraph>
            )}
          </Flex>
        ))}
      </Grid>
    )}
  </Flex>
);

export default ArticleOutline;
