import { css, keyframes } from 'styled-components';

const rotateAnimation = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

const rotate = css`
  .MuiButton-startIcon {
    animation: ${rotateAnimation} 750ms ease-in-out infinite;
  }
`;

export default rotate;
