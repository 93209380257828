import { type FC, useState } from 'react';

import color from 'atoms/colors/colors';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Grid from 'quarks/Grid';
import Text from 'quarks/Text';

import HeadingContent from 'molecules/HeadingContent/HeadingContent';
import Switch from 'molecules/Switch/Switch';

import PartnerBenefitsCard from 'components/Cards/PartnerBenefitsCard';
import type { FeatureGridVariantProps } from 'components/FeatureGrid/featureGridTypesAndData';

const PartnerBenefitsGrid: FC<FeatureGridVariantProps> = ({
  headingContent,
  partnerBenefitCards,
  customerBenefitCards,
}) => {
  const [benefitType, setBenefitType] = useState<'partner' | 'customer'>('partner');
  const [benefitCards, setBenefitCards] = useState<typeof partnerBenefitCards | typeof customerBenefitCards>(
    partnerBenefitCards,
  );

  const handleBenefitType = (currentValue: string) => {
    switch (currentValue) {
      case 'partner':
        setBenefitType('customer');
        setBenefitCards(customerBenefitCards);
        break;
      case 'customer':
        setBenefitType('partner');
        setBenefitCards(partnerBenefitCards);
        break;
      default:
        null;
    }
  };

  return (
    <Container
      backgroundColor="common-white"
      border="1px solid"
      borderColor="primary-50"
      borderRadius="16px"
      boxShadow="lg"
      paddingY={40}
      paddingX={24}
      sm={{
        paddingX: 40,
      }}
      lg={{
        paddingY: 48,
        paddingX: 56,
      }}
    >
      {headingContent && (
        <Container paddingBottom={24}>
          <HeadingContent background="" gapOverride="16px" headingSizeOverride="md" {...headingContent} />
        </Container>
      )}
      <Flex
        justifyContent="center"
        alignItems="center"
        paddingBottom={40}
        md={{ paddingBottom: 48 }}
        lg={{ paddingBottom: 64 }}
      >
        <Text
          textColor={benefitType === 'partner' ? 'gray-900' : 'gray-700'}
          textStyle="md"
          fontWeight="semiBold"
          marginRight={20}
        >
          Partner
        </Text>
        <Switch
          checked={benefitType !== 'partner'}
          label=""
          onChange={() => handleBenefitType(benefitType)}
          offBackgroundColor={color.primary[100]}
        />
        <Text textColor={benefitType === 'customer' ? 'gray-900' : 'gray-700'} textStyle="md" fontWeight="semiBold">
          Customer
        </Text>
      </Flex>
      <Grid
        rowGap="32px"
        sm={{
          gridTemplateColumns: 'repeat(2, 1fr)',
          columnGap: '24px',
        }}
        md={{
          gridTemplateColumns: 'repeat(3, 1fr)',
        }}
        lg={{
          columnGap: '30px',
        }}
      >
        {benefitCards?.map((card, index) => (
          <PartnerBenefitsCard
            key={`${card?.title}-${index}` || index}
            icon={card?.icon}
            title={card?.title}
            description={card?.description}
            backgroundIsDark={false}
          />
        ))}
      </Grid>
    </Container>
  );
};

export default PartnerBenefitsGrid;
