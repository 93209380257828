const ImageBuilderConfig = {
  name: 'Images',
  image: 'https://tabler-icons.io/static/tabler-icons/icons-png/photo.png',
  requiresParent: {
    message: 'Block must be placed inside the Content Editor',
    component: 'ContentEditor',
  },
  inputs: [
    {
      name: 'image',
      type: 'file',
      allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
    },
    { name: 'altText', type: 'text' },
    { name: 'caption', type: 'richText' },
  ],
};
export default ImageBuilderConfig;
