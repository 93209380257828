import Grid from 'quarks/Grid';
import type { GridProps } from 'quarks/interpolations/grid';

import type { FC, ReactNode } from 'react';

interface CalculatorGridProps extends GridProps {
  children: ReactNode | ReactNode[];
}

const CalculatorGrid: FC<CalculatorGridProps> = ({ children, ...props }) => (
  <Grid
    gridTemplateColumns="repeat(5, 1fr)"
    gap="24px"
    width="100%"
    paddingY={16}
    paddingLeft={16}
    paddingRight={40}
    xl={{
      gap: '60px',
    }}
    {...props}
  >
    {children}
  </Grid>
);

export default CalculatorGrid;
