import { useEffect, useRef } from 'react';
import { useIntersection } from 'react-use';

import Heading from 'quarks/Heading';

import { stringToKebabCase } from 'utils/functions';

import useActiveContent from 'contexts/ContentEditorProvider';

import type { FC } from 'react';

interface HeadingsProps {
  heading?: string;
  headingType?: keyof typeof headingSizes;
}

const headingSizes = {
  h2: {
    sm: 'displayXs',
    md: 'displaySm',
  },
  h3: {
    sm: 'textXl',
    md: 'displayXs',
  },
  h4: {
    sm: 'textLg',
    md: 'textXl',
  },
  h5: {
    sm: 'textMd',
    md: 'textLg',
  },
  h6: {
    sm: 'textSm',
    md: 'textMd',
  },
} as const;

const Headings: FC<HeadingsProps> = ({ heading, headingType = 'h2' }) => {
  const [_, setId] = useActiveContent();
  const intersectionRef = useRef(null);
  const isInView = useIntersection(intersectionRef, {
    rootMargin: '0px 0px -60%',
    threshold: 1,
  });

  const id = stringToKebabCase(heading || '');

  useEffect(() => {
    if (headingType === 'h2' && isInView) {
      setId(id);
    }
  }, [isInView]);

  return (
    <Heading
      as={headingType}
      ref={intersectionRef}
      id={id}
      textColor="gray-900"
      fontSize={headingSizes[headingType].sm}
      lineHeight={headingSizes[headingType].sm}
      fontWeight="bold"
      sm={{
        fontSize: headingSizes[headingType].md,
        lineHeight: headingSizes[headingType].md,
      }}
    >
      {heading || 'Start Writing...'}
    </Heading>
  );
};

export default Headings;
