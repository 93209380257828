import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Heading from 'quarks/Heading';
import Text from 'quarks/Text';
import type { BasicProps } from 'quarks/interpolations/basic';
import type { HeadingTypes } from 'quarks/styleProps/heading';

import colorizeText from 'utils/colorizeText';
import RichTextParser from 'utils/richTextParser';

import type { FC } from 'react';

interface SwitchbackCopyProps extends BasicProps {
  heading?: string;
  backgroundIsDark: boolean;
  subheading?: string;
  eyebrow?: string;
  headingType?: HeadingTypes;
  coloredEyebrow?: boolean;
}

const SwitchbackCopy: FC<SwitchbackCopyProps> = ({
  eyebrow,
  coloredEyebrow,
  heading,
  headingType,
  subheading,
  backgroundIsDark,
}) => {
  const styledEyebrow = coloredEyebrow && eyebrow ? colorizeText(eyebrow, backgroundIsDark) : eyebrow;
  const textColor = backgroundIsDark ? 'common-white' : 'gray-900';

  return (
    <Flex flexDirection="column" gap="24px" textColor={textColor}>
      {eyebrow && <Text textStyle="eyebrow">{styledEyebrow}</Text>}
      {heading && (
        <Heading
          as={headingType || 'h2'}
          textStyle="xs"
          fontWeight="semiBold"
          md={{ textStyle: 'sm', fontWeight: 'semiBold' }}
          xl={{ textStyle: 'md', fontWeight: 'semiBold' }}
        >
          {heading}
        </Heading>
      )}
      {subheading && (
        <Container textColor={backgroundIsDark ? 'gray-50' : 'gray-700'} fontSize="textLg" md={{ fontSize: 'textXl' }}>
          {RichTextParser(subheading)}
        </Container>
      )}
    </Flex>
  );
};

export default SwitchbackCopy;
