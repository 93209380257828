import { graphql, useStaticQuery } from 'gatsby';

const useSupportCategories = () => {
  const data = useStaticQuery(graphql`
    query AllSupportCategories {
      allBuilderModels {
        supportCategory {
          data {
            icon
            name
          }
          id
        }
      }
    }
  `);

  return data.allBuilderModels.supportCategory;
};

export default useSupportCategories;
