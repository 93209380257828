import container from 'atoms/spacing/containers';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Heading from 'quarks/Heading';
import Text from 'quarks/Text';

import ComponentButton from 'components/ComponentButton/ComponentButton';
import ButtonWrapper from 'components/ComponentButton/buttonWrapper';
import type { HeadingProps } from 'components/Heading/Heading';

import colorizeText from 'utils/colorizeText';
import { getBackgroundIsDark } from 'utils/functions';
import RichTextParser from 'utils/richTextParser';

import type { Builder_ComponentButton } from 'graphqlTypes';
import type { FC } from 'react';

interface HeadingContentProps extends HeadingProps {
  /**
   * Defines the flex gap if default needs to be overridden.
   */
  gapOverride?: string;
  /**
   * Defines the desktop heading size if default needs to be overridden.
   */
  headingSizeOverride?: 'md' | 'lg';
}

const HeadingContent: FC<HeadingContentProps> = ({
  leftAlign,
  eyebrow,
  coloredEyebrow,
  gapOverride,
  headingSizeOverride,
  heading,
  subheading,
  subheadingFontWeight,
  buttonGroup,
  background,
}) => {
  const backgroundIsDark = getBackgroundIsDark(background);
  const textColor = backgroundIsDark ? 'common-white' : 'gray-900';
  const styledEyebrow = coloredEyebrow ? colorizeText(eyebrow, backgroundIsDark) : eyebrow;

  return (
    <Container>
      <Flex
        flexDirection="column"
        flexWrap="nowrap"
        textAlign={leftAlign ? 'left' : 'center'}
        alignItems={leftAlign ? 'flex-start' : 'center'}
        marginX={leftAlign ? 0 : 'auto'}
        gap="32px"
        textColor={textColor}
        maxWidth={container.md}
      >
        <Flex flexDirection="column" gap={gapOverride ? gapOverride : '24px'}>
          {eyebrow && <Text textStyle="eyebrow">{styledEyebrow}</Text>}
          {heading && (
            <Heading
              as="h2"
              textStyle="xs"
              md={{ textStyle: 'md' }}
              xl={{ textStyle: headingSizeOverride ? headingSizeOverride : 'lg' }}
              fontWeight="semiBold"
            >
              {heading}
            </Heading>
          )}
          {subheading && (
            <Container
              textColor={backgroundIsDark ? 'gray-50' : 'gray-700'}
              fontSize="textLg"
              fontWeight={subheadingFontWeight || 'medium'}
              md={{ fontSize: 'textXl' }}
            >
              {RichTextParser(subheading)}
            </Container>
          )}
        </Flex>
        {buttonGroup && (
          <ButtonWrapper>
            {buttonGroup?.map((button, index) => (
              <ComponentButton
                buttonType={button?.buttonType || 'callToAction'}
                key={button.button?.value?.id || index}
                {...(button as Builder_ComponentButton)}
              />
            ))}
          </ButtonWrapper>
        )}
      </Flex>
    </Container>
  );
};

export default HeadingContent;
