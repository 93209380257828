import { useContext, useEffect, useState } from 'react';

import color from 'atoms/colors/colors';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Text from 'quarks/Text';

import { handleButtonTracking } from 'molecules/Button/Button';
import Switch from 'molecules/Switch/Switch';
import TextField from 'molecules/TextField/TextField';

import ComponentButton from 'components/ComponentButton/ComponentButton';
import type {
  ErrorData,
  HandleInputChange,
  RoiCalculatorProps,
  RoiData,
} from 'components/RoiCalculator/RoiCalcDataAndTypes';
import {
  calculateRoi,
  dollarIcon,
  percentIcon,
  planOptions,
  positiveValueErrorMessage,
  requiredErrorMessage,
  roiCalculatorObj,
} from 'components/RoiCalculator/RoiCalcDataAndTypes';
import RoiCalculatorResult from 'components/RoiCalculator/RoiResult';

import { objectKeys } from 'utils/typeUtils';

import { ModalContext } from 'contexts/ModalProvider';

import type { FC } from 'react';

const RoiCalculator: FC<RoiCalculatorProps> = ({
  title,
  description,
  avgMonthlySalesField,
  percentLaborField,
  improvedPercentLaborField,
  numberOfLocationsField,
  instructionText,
  aboutLineup,
  downloadSubheading,
  downloadFeatureText,
  planToggle,
}) => {
  const [annualRoi, setAnnualRoi] = useState<number | null>(null);
  const [formData, setFormData] = useState<RoiData>(roiCalculatorObj);
  const [selectedPlan, setSelectedPlan] = useState<number>(1);
  const [errors, setErrors] = useState<ErrorData>({});

  const labels = {
    avgMonthlySales: (avgMonthlySalesField?.label || 'Avg. Monthly Sales').toLocaleString(),
    percentLabor: (percentLaborField?.label || '% Labor').toLocaleString(),
    improvedPercentLabor: (improvedPercentLaborField?.label || 'Improved % Labor').toLocaleString(),
    numberOfLocations: (numberOfLocationsField?.label || 'Number of Locations').toLocaleString(),
  };

  const placeholders = {
    avgMonthlySales: (avgMonthlySalesField?.placeholder || 1500).toLocaleString(),
    percentLabor: (percentLaborField?.placeholder || 20).toLocaleString(),
    improvedPercentLabor: (improvedPercentLaborField?.placeholder || 18).toLocaleString(),
    numberOfLocations: (numberOfLocationsField?.placeholder || 5).toLocaleString(),
  };

  const { modalState } = useContext(ModalContext);

  const fillHiddenFields = () => {
    const hiddenFields = [
      ...document.querySelectorAll('.MuiModal-root .MuiPaper-root form input[type=hidden]'),
    ] as HTMLInputElement[];

    hiddenFields.forEach(field => {
      if (field?.name === 'plan') {
        field.value = formData.plan.value;
      } else {
        field.value = formData[field?.name as keyof Omit<RoiData, 'plan'>];
      }
    });
  };

  const handleErrors = () => {
    const blankCalcFields = objectKeys(formData).some(key => {
      if (key !== 'plan') {
        return formData[key] === '';
      }

      return false;
    });

    if (blankCalcFields) {
      return setErrors({
        ...errors,
        formData: 'Not all filled in',
      });
    }

    return setErrors({});
  };

  const handleCalculate = () => {
    const trackingScheme = {
      label: 'Calculate ROI',
      hierarchy: '1 of 1',
      location: 'RoiCalculator',
    };

    handleButtonTracking(trackingScheme);

    const total = calculateRoi(formData, planToggle);
    setAnnualRoi(total);
  };

  const handleInputChange: HandleInputChange = e => {
    const checkValue = (name: string, value: string) => {
      if (value === '') {
        setErrors({
          ...errors,
          [name]: requiredErrorMessage,
        });

        return '';
      }

      const numberValue = parseFloat(value);

      if (numberValue <= 0) {
        setErrors({
          ...errors,
          [name]: positiveValueErrorMessage,
        });

        return '';
      } else {
        delete errors[name];
      }

      return numberValue;
    };

    if (!e.target || !e.target.name) {
      return null;
    }

    setFormData(prevState => ({
      ...prevState,
      [e?.target?.name || '']: checkValue(e?.target?.name || '', e?.target?.value || ''),
    }));
  };

  const handleSwitchChange = () => {
    setSelectedPlan(prevState => 1 - prevState);
  };

  const handleReset = () => {
    const trackingScheme = {
      label: 'Reset',
      hierarchy: '2 of 2',
      location: 'RoiCalculator',
    };

    handleButtonTracking(trackingScheme);

    setAnnualRoi(null);
    setFormData({
      ...roiCalculatorObj,
      percentLabor: placeholders['percentLabor'],
      improvedPercentLabor: placeholders['improvedPercentLabor'],
    });
    setSelectedPlan(1);
    setErrors({});
  };

  useEffect(() => {
    if (!document) {
      return;
    }
    if (modalState.open) {
      setTimeout(() => {
        fillHiddenFields();
      }, 100);
    }
  }, [modalState]);

  useEffect(() => {
    setFormData(prevState => ({
      ...prevState,
      percentLabor: placeholders['percentLabor'] || prevState.percentLabor,
      improvedPercentLabor: placeholders['improvedPercentLabor'] || prevState.improvedPercentLabor,
    }));
  }, [percentLaborField, improvedPercentLaborField]);

  useEffect(() => {
    setFormData(prevState => ({
      ...prevState,
      plan: planOptions[1 - selectedPlan],
    }));
  }, [selectedPlan]);

  useEffect(() => {
    handleErrors();
  }, [formData]);

  return annualRoi === null ? (
    <Container paddingAll={32} borderRadius="20px" boxShadow="xxl" backgroundColor="common-white">
      <Flex flexDirection="column" gap="16px" marginBottom={24}>
        <Text fontSize="displayXs" textColor="common-black" fontWeight="bold" md={{ fontSize: 'displaySm' }}>
          {title || 'ROI Calculator'}
        </Text>
        {description && (
          <Text textStyle="md" textColor="gray-700" md={{ textStyle: 'lg' }}>
            {description}
          </Text>
        )}
      </Flex>
      <Flex
        gap="16px"
        flexDirection="column"
        md={{
          justifyContent: 'center',
          gap: '30px',
        }}
      >
        <TextField
          label={labels['avgMonthlySales']}
          labelSize="textMd"
          name="avgMonthlySales"
          type="number"
          placeholder={placeholders['avgMonthlySales']}
          startIcon={dollarIcon}
          onChange={e => {
            handleInputChange(e);
          }}
          tooltip={avgMonthlySalesField?.toolTipTitle}
          tooltipDescription={avgMonthlySalesField?.toolTipDescription}
          error={!!errors.avgMonthlySales}
          errorMessage={errors.avgMonthlySales}
          css={`
            label {
              color: ${color.gray[900]};
              font-weight: 500;
            }
          `}
        />
        <TextField
          label={labels['percentLabor']}
          labelSize="textMd"
          name="percentLabor"
          type="number"
          placeholder={placeholders['percentLabor']}
          endIcon={percentIcon}
          onChange={e => {
            handleInputChange(e);
          }}
          tooltip={percentLaborField?.toolTipTitle}
          tooltipDescription={percentLaborField?.toolTipDescription}
          error={!!errors.percentLabor}
          errorMessage={errors.percentLabor}
          css={`
            label {
              color: ${color.gray[900]};
              font-weight: 500;
            }
          `}
        />
        <TextField
          label={labels['improvedPercentLabor']}
          labelSize="textMd"
          name="improvedPercentLabor"
          type="number"
          placeholder={placeholders['improvedPercentLabor']}
          endIcon={percentIcon}
          onChange={e => {
            handleInputChange(e);
          }}
          tooltip={improvedPercentLaborField?.toolTipTitle}
          tooltipDescription={improvedPercentLaborField?.toolTipDescription}
          error={!!errors.improvedPercentLabor}
          errorMessage={errors.improvedPercentLabor}
          css={`
            label {
              color: ${color.gray[900]};
              font-weight: 500;
            }
          `}
        />
        <TextField
          label={labels['numberOfLocations']}
          labelSize="textMd"
          name="numberOfLocations"
          type="number"
          placeholder={placeholders['numberOfLocations']}
          onChange={e => {
            handleInputChange(e);
          }}
          tooltip={numberOfLocationsField?.toolTipTitle}
          tooltipDescription={numberOfLocationsField?.toolTipDescription}
          error={!!errors.numberOfLocations}
          errorMessage={errors.numberOfLocations}
          css={`
            label {
              color: ${color.gray[900]};
              font-weight: 500;
            }
          `}
        />
        {planToggle && (
          <Switch
            label={`${formData.plan.name || 'Forecasting Schedule'} $${formData.plan.value || 149}`}
            labelColor="gray-900"
            size="xlarge"
            checked={!!selectedPlan}
            required
            helperText="per location/mo"
            keyDown={handleSwitchChange}
            onChange={handleSwitchChange}
            checkedOffBackgroundColor={color.primary[600]}
            offBackgroundColor={color.primary[100]}
          />
        )}
      </Flex>
      <Flex justifyContent="center" marginTop={24} paddingTop={16} borderTop="1px solid" borderColor="gray-300">
        <Text textColor="gray-700" textStyle="lg" textAlign="center">
          {instructionText || 'This total is calculated from your inputs above.'}
        </Text>
      </Flex>
      <Flex
        gap="16px"
        marginTop={32}
        flexDirection="column"
        sm={{
          flexDirection: 'row',
          gap: '30px',
          justifyContent: 'center',
        }}
      >
        <ComponentButton
          buttonType="callToAction"
          width="100%"
          button={{
            value: {
              data: {
                label: 'Calculate',
                size: 'large',
                hierarchy: 'Primary Button',
                disabled: !!objectKeys(errors).length,
              },
            },
          }}
          onClick={handleCalculate}
          xl={{ height: '52px' }}
        />
      </Flex>
    </Container>
  ) : (
    <RoiCalculatorResult
      annualRoi={annualRoi}
      handleReset={handleReset}
      description={description}
      downloadFeatureText={downloadFeatureText}
      aboutLineup={aboutLineup}
      downloadSubheading={downloadSubheading}
      planToggle={planToggle}
      {...formData}
    />
  );
};

export default RoiCalculator;
