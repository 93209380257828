import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Heading from 'quarks/Heading';
import Image from 'quarks/Image';
import Text from 'quarks/Text';
import type { BasicProps } from 'quarks/interpolations/basic';

import Breadcrumbs from 'molecules/Breadcrumbs/Breadcrumbs';
import Section from 'molecules/Section/Section';

import doodleGenerator from 'utils/doodleGenerator';
import type { BlocksData } from 'utils/readingTime';

import type { Builder_SupportCategoryData, Builder_SupportData } from 'graphqlTypes';
import type { FC } from 'react';

interface HeroSupport extends BasicProps {
  blogPost?: { data: Builder_SupportData };
}

interface SupportData extends Pick<Builder_SupportData, 'title'> {
  category?: { value: { data: Builder_SupportCategoryData; id: string } } | null;
  blocks?: BlocksData[] | null;
}

const SupportHero: FC<HeroSupport> = ({ blogPost }) => {
  const { category, title }: SupportData = {
    ...blogPost?.data,
  };

  const bottomLeftDoodle = doodleGenerator('tripleknotswirl');
  const topRightDoodle = doodleGenerator('tripleknotswirl');

  const pathname = `support/${title}`;

  return (
    <Section __typename="Hero-Support" background="gray-900">
      <Container position="relative" paddingTop={24}>
        {bottomLeftDoodle && (
          <Image
            opacity=".2"
            width="689px"
            left="-50%"
            bottom="-33%"
            position="absolute"
            display="none"
            md={{ display: 'block' }}
            zIndex={-10}
            image={bottomLeftDoodle}
            alt="doodle"
          />
        )}
        <Container paddingLeft={4} marginBottom={20} md={{ marginBottom: 32 }} xl={{ marginBottom: 58 }}>
          <Breadcrumbs url={pathname} backgroundIsDark={true} />
        </Container>
        <Flex flexDirection="column" gap="40px" md={{ gap: '64px' }} xl={{ flexDirection: 'row', gap: '30px' }}>
          <Flex flexDirection="column" gap="24px">
            {category && (
              <Text
                textStyle="eyebrow"
                textTransform="uppercase"
                fontWeight="bold"
                textColor="primary-600"
                gradient="lineupGradient-darkMode"
                width="fit-content"
              >
                {category?.value?.data?.name}
              </Text>
            )}
            <Flex flexDirection="column" gap="32px">
              {title && (
                <Heading as="h1" textColor="common-white" fontWeight="semiBold" textStyle="md" md={{ textStyle: 'lg' }}>
                  {title}
                </Heading>
              )}
            </Flex>
          </Flex>
        </Flex>
        {topRightDoodle && (
          <Image
            opacity=".2"
            width="689px"
            right="-33%"
            top="20%"
            position="absolute"
            display="none"
            md={{ display: 'block' }}
            zIndex={-10}
            image={topRightDoodle}
            alt="doodle"
          />
        )}
      </Container>
    </Section>
  );
};

export default SupportHero;
