import type { iconIds } from 'atoms/Icons/Icon.types';

import type { BasicProps } from 'quarks/interpolations/basic';

import type { IntegrationRefProps } from 'molecules/IntegrationsGrid/IntegrationsGrid';
import type { VideoPlayerProps } from 'molecules/VideoPlayer/VideoPlayer';

import type { ButtonTypes } from 'components/ComponentButton/buttonTypeConfig';
import type { ComponentFormProps } from 'components/HsForm/types';

import type { DoodleKey } from 'utils/doodleGenerator';

export interface HeroCopyProps extends BasicProps {
  heading?: string;
  backgroundIsDark: boolean;
  subheading?: string;
  enableCenteredCopy?: boolean;
  badgeGroup?: {
    badgeTitle?: string;
    badgeDescription?: string;
  };
  eyebrow?: string;
  buttons?: ButtonTypes;
  // Below Needed to keep other Hero Variants consistent.
  buttonGroup?: ButtonTypes;
  sectionId?: string;
}

export interface PopularArticle {
  label?: string | undefined;
  link?: string | undefined;
  icon?: iconIds | undefined;
}

export type featureList = { copy?: string; icon?: iconIds }[];

export interface HeroProps extends HeroCopyProps {
  assetType: 'Image' | 'Animation' | 'Integration' | 'Video';
  assetAlignment: 'Right' | 'Contained';
  background: string;
  doodle?: DoodleKey;
  heroImage?: string;
  imageCaption?: string;
  lottieFile?: string;
  ariaLabel?: string;
  heroAlignment?: 'left-aligned' | 'centered';
  integrationList?: IntegrationRefProps[];
  leftDoodle?: DoodleKey;
  rightDoodle?: DoodleKey;
  hsForm?: ComponentFormProps;
  featureListHeading?: string;
  featureList?: featureList;
  videoData?: VideoPlayerProps;
  includePopularArticles?: boolean;
  popularArticles?: PopularArticle[];
  includeAnchorLink?: boolean;
  anchorLinkId?: string;
}

export interface HeroHomepageFrames {
  staticTextPart1?: string;
  staticTextPart2?: string;
  staticTextPart3?: string;
  animationText?: {
    [key: string]: {
      text1: string;
      text2: string;
    };
  };
  isLottiePlaying?: boolean;
}
export type HeroHomepageProps = HeroProps & HeroHomepageFrames & { collapseBottomPadding?: boolean };

export const heroDoodleSpecs = {
  standard: {
    centeredDoodle: {
      width: '187px',
      height: '190px',
      top: '10px',
      left: '-80px',
    },
    standardDoodle: {
      width: '240px',
      height: '218px',
      bottom: '0px',
      left: '-120px',
    },
  },
  small: {
    centeredDoodle: {
      width: '256px',
      height: '252px',
    },
    standardDoodle: {
      width: '377px',
      height: '342px',
    },
  },
  medium: {
    centeredDoodle: {
      width: '330px',
      height: '230px',
      left: '-200px',
    },
    standardDoodle: {
      width: '514px',
      height: '462px',
      left: '-300px',
    },
  },
  xLarge: {
    centeredDoodle: {
      width: '497px',
      height: '347px',
      left: '-320px',
    },
    standardDoodle: {
      width: '723px',
      height: '462px',
      left: '-550px',
    },
  },
};
