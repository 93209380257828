import { useEffect, useState } from 'react';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Grid from 'quarks/Grid';
import Text from 'quarks/Text';

import NumberedPagination from 'molecules/Pagination/NumberedPagination';
import Section from 'molecules/Section/Section';

import type { CardProps } from 'components/Cards/EntryCard/EntryCard';
import EntryCard from 'components/Cards/EntryCard/EntryCard';
import type { CaseStudy, CaseStudyListingProps } from 'components/CaseStudyListing/CaseStudyListing.config';
import Form from 'components/HsForm/HsForm';
import SocialShareLink from 'components/Sidebar/SocialShareLinks';

import useSharePage from 'utils/copyShareLink';
import useCaseStudyPosts from 'utils/hooks/useCaseStudyPosts';

import type { FC } from 'react';

const CaseStudyListing: FC<CaseStudyListingProps> = ({
  postsPerPage = 8,
  featureCardHeadingType = 'h2',
  caseStudyHeadingType,
  sectionId,
}) => {
  const posts: CaseStudy[] = useCaseStudyPosts().sort((a: CaseStudy, b: CaseStudy) => {
    if (a.data && b.data) {
      if (!a.data.publishDate || !b.data.publishDate) {
        return 0;
      }

      return new Date(b.data.publishDate).valueOf() - new Date(a.data.publishDate).valueOf();
    } else {
      return 0;
    }
  });

  const [activePage, setActivePage] = useState(1);
  const [postOffset, setOffset] = useState(0);
  const { handleCopy } = useSharePage();

  useEffect(() => {
    setOffset(postsPerPage * (activePage - 1));
  }, [activePage, postsPerPage]);

  return (
    <Section __typename="CaseStudyListing" sectionType="listing" id={sectionId}>
      <Container>
        {posts?.[0]?.data && (
          <Flex paddingBottom={96} justifyContent="center">
            <EntryCard variant="featured" headingType={featureCardHeadingType} {...(posts[0].data as CardProps)} />
          </Flex>
        )}
        <Grid gridTemplateColumns="1fr" lg={{ gridTemplateColumns: '1fr 2fr' }}>
          <Flex flexDirection="column" gap="20px" paddingBottom={32} lg={{ paddingRight: 96, paddingBottom: 0 }}>
            <Flex flexDirection="column" gap="24px">
              <Text textStyle="lg" fontWeight="semiBold">
                Sign up for our newsletter
              </Text>
              <Form
                formId="Subscribe Form | c9325cee-6d27-41b9-9b9d-731d8cf0cf0e"
                variant="Stacked"
                submitButtonText="Subscribe"
                trackingSchema={{
                  location: 'CaseStudyListing',
                }}
              />
              <SocialShareLink href="https://lineup.ai/customers}" pageCopyLink={handleCopy} />
            </Flex>
          </Flex>
          {posts && (
            <Flex flexDirection="column" gap="30px">
              <Text textStyle="lg" fontWeight="semiBold">
                Latest Customer Stories
              </Text>
              {posts.length ? (
                <>
                  <Grid gap="30px" gridTemplateColumns="1fr" sm={{ gridTemplateColumns: 'repeat(2, 1fr)' }}>
                    {posts.slice(postOffset, postOffset + postsPerPage).map(
                      ({ data, id }) =>
                        data && (
                          <EntryCard
                            key={id}
                            headingType={caseStudyHeadingType}
                            {...(data as CardProps)}
                            css={`
                              margin: 0 auto;
                            `}
                          />
                        ),
                    )}
                  </Grid>
                  <NumberedPagination
                    activePage={activePage}
                    pageCount={Math.ceil(posts?.length / postsPerPage)}
                    setActivePage={setActivePage}
                  />
                </>
              ) : (
                <Text textAlign="center">No customer stories found</Text>
              )}
            </Flex>
          )}
        </Grid>
      </Container>
    </Section>
  );
};

export default CaseStudyListing;
