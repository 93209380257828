import { useState } from 'react';

import color from 'atoms/colors/colors';
import container from 'atoms/spacing/containers';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Heading from 'quarks/Heading';
import Image from 'quarks/Image';
import Text from 'quarks/Text';

import Section from 'molecules/Section/Section';
import Switch from 'molecules/Switch/Switch';

import CardCarousel from 'components/CardDeck/CardCarousel';
import StandardCardDeck from 'components/CardDeck/StandardDeck';
import type { CardDeckProps } from 'components/CardDeck/cardDeckPropsConfig';
import type { NewsroomCardProps } from 'components/Cards/NewsroomCard';
import ComponentButton from 'components/ComponentButton/ComponentButton';
import ButtonWrapper from 'components/ComponentButton/buttonWrapper';

import colorizeText from 'utils/colorizeText';
import { doodleGenerator } from 'utils/doodleGenerator';
import { getBackgroundIsDark, getButtonHierarchy } from 'utils/functions';
import RichTextParser from 'utils/richTextParser';

import type { Builder_ComponentButton } from 'graphqlTypes';
import type { FC } from 'react';

const CardDeck: FC<CardDeckProps> = ({
  background,
  buttonGroup,
  cardContentType,
  cardDeckType,
  entryCards,
  doodle,
  eyebrow,
  featuresCards,
  heading,
  historyCards,
  subheading,
  teamCards,
  builderState,
  hideSwitch,
  sectionId,
}) => {
  const [entryCardType, setEntryCardType] = useState<'articleCards' | 'resourceCards'>('articleCards');
  const backgroundIsDark = getBackgroundIsDark(background);

  const handleCardType = (currentValue: string) => {
    if (currentValue !== 'articleCards') {
      setEntryCardType('articleCards');
    } else {
      setEntryCardType('resourceCards');
    }
  };
  const { blogPostCategory, resourcesCategory } =
    (builderState?.state?.entryCategories && JSON.parse(builderState?.state?.entryCategories)) || {};

  const isArticleCards = entryCardType === 'articleCards';
  const isNewsroomCards = cardContentType === 'Newsroom Cards';
  const isCarousel = cardDeckType === 'Carousel' || isNewsroomCards;
  const isStandard = !isCarousel || (cardContentType && cardDeckType === 'Standard' && !isNewsroomCards);

  const currentCategoryList = isArticleCards ? blogPostCategory : resourcesCategory;
  const getCategoryTypeName = isArticleCards ? 'builder_BlogPostCategoryData' : 'builder_ResourcesCategoryData';

  const retrieveCardList = entryCards ? entryCards[entryCardType] : [];

  const extractEntryCardContent = retrieveCardList?.map(entryCard => {
    const cardData = entryCard['card']?.value?.data;
    const cardCategoryId = cardData?.category?.id;

    const findCategory = currentCategoryList?.find(
      (category: { content: { id: string } }) => category?.content?.id === cardCategoryId,
    );

    const categoryData = { ...findCategory?.content?.data, __typename: getCategoryTypeName };
    const updateCategoryObject = { category: { value: { data: categoryData } } };
    const entryCardData = { ...cardData, ...updateCategoryObject };

    return entryCardData;
  });

  const newsroomCards = [];
  const newsroomObject = entryCards && Object.entries(entryCards);
  if (newsroomObject) {
    for (const [_, value] of newsroomObject) {
      newsroomCards.push(...value);
    }
  }

  const extractNewsroomCardContent = newsroomCards.map(newsroomCard => {
    const newsroomCardData = newsroomCard?.card?.value?.data as NewsroomCardProps;

    return newsroomCardData;
  });

  const cardMap = {
    entrycards: extractEntryCardContent,
    featurescards: featuresCards,
    historycards: historyCards,
    teamcards: teamCards,
    newsroomcards: extractNewsroomCardContent,
    invalid: [],
  };

  const renameCardType = cardContentType ? cardContentType?.toLowerCase().replace(' ', '') : 'invalid';
  const listOfCards = cardMap[renameCardType as keyof typeof cardMap] || [];
  const doodleImage = doodle && doodleGenerator(doodle);

  const switchTextColorDefault = backgroundIsDark ? 'gray-400' : 'gray-700';
  const switchTextColorHighlighted = backgroundIsDark ? 'common-white' : 'gray-900';

  const typename = 'CardDeck';

  return (
    <Section __typename={typename} background={background} id={sectionId}>
      <Flex flexDirection="column" position="relative" gap="40px" md={{ gap: '64px' }}>
        {doodleImage && (
          <Image
            display="none"
            position="absolute"
            alt="doodle-image-card-deck"
            image={doodleImage}
            width="470px"
            height="295px"
            transform="rotate(2deg)"
            top={cardContentType === 'Entry Cards' ? '80px' : '-40px'}
            right={cardContentType === 'Entry Cards' ? '-300px' : '-380px'}
            overflowX="hidden"
            zIndex={-1}
            md={{ display: 'block' }}
            xl={{
              top: cardContentType === 'Entry Cards' ? '200px' : '20px',
              right: cardContentType === 'Entry Cards' ? '-400px' : '-250px',
            }}
          />
        )}
        <Flex
          textAlign={isNewsroomCards ? 'left' : 'center'}
          flexDirection="column"
          gap="24px"
          marginX={isNewsroomCards ? 0 : 'auto'}
          maxWidth={container.md}
        >
          {eyebrow && <Text textStyle="eyebrow">{colorizeText(eyebrow, backgroundIsDark)}</Text>}
          {heading && (
            <Heading
              as="h2"
              textColor={backgroundIsDark ? 'common-white' : 'gray-900'}
              textStyle="xs"
              md={{ textStyle: 'md' }}
              xl={{ textStyle: 'lg' }}
              fontWeight="semiBold"
            >
              {heading}
            </Heading>
          )}
          {subheading && (
            <Container
              textColor={backgroundIsDark ? 'gray-50' : 'gray-700'}
              fontSize="textLg"
              fontWeight="regular"
              md={{ fontSize: 'textXl', fontWeight: 'regular' }}
            >
              {RichTextParser(subheading)}
            </Container>
          )}
          {buttonGroup && (
            <Flex justifyContent={!isNewsroomCards && 'center'} width="100%">
              <ButtonWrapper>
                {buttonGroup?.map(button => (
                  <ComponentButton
                    buttonType={button?.buttonType || 'callToAction'}
                    key={button.button?.value?.id}
                    trackingSchema={{
                      hierarchy: getButtonHierarchy(buttonGroup, button),
                      location: typename,
                    }}
                    {...(button as Builder_ComponentButton)}
                  />
                ))}
              </ButtonWrapper>
            </Flex>
          )}
          {cardContentType === 'Entry Cards' && !hideSwitch && (
            <Flex justifyContent="center" alignItems="center">
              <Text
                textColor={isArticleCards ? switchTextColorHighlighted : switchTextColorDefault}
                textStyle="md"
                fontWeight="semiBold"
                marginRight={20}
              >
                Articles
              </Text>
              <Switch
                checked={entryCardType !== 'articleCards'}
                label=""
                onChange={() => handleCardType(entryCardType)}
                offBackgroundColor={color.primary[100]}
              />
              <Text
                textColor={!isArticleCards ? switchTextColorHighlighted : switchTextColorDefault}
                textStyle="md"
                fontWeight="semiBold"
              >
                Resources
              </Text>
            </Flex>
          )}
        </Flex>
        {isStandard ? (
          <StandardCardDeck cards={listOfCards} cardContentType={cardContentType} backgroundIsDark={backgroundIsDark} />
        ) : (
          <CardCarousel
            cards={listOfCards}
            cardContentType={cardContentType}
            backgroundIsDark={backgroundIsDark}
            background={background}
          />
        )}
      </Flex>
    </Section>
  );
};

export default CardDeck;

CardDeck.defaultProps = {
  eyebrow: 'eyebrow',
  heading: 'Component Card Deck',
};
