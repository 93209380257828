import Icon from 'atoms/Icons/Icon';
import { media } from 'atoms/breakpoints/breakpoints';
import container from 'atoms/spacing/containers';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Image from 'quarks/Image';

import Breadcrumbs from 'molecules/Breadcrumbs/Breadcrumbs';
import Button from 'molecules/Button/Button';
import IntegrationsGrid from 'molecules/IntegrationsGrid/IntegrationsGrid';
import type { IntegrationRefProps } from 'molecules/IntegrationsGrid/IntegrationsGrid';
import LottieAnimation from 'molecules/LottieAnimation/LottieAnimation';
import Section from 'molecules/Section/Section';
import Video from 'molecules/VideoPlayer/VideoPlayer';
import type { VideoPlayerProps } from 'molecules/VideoPlayer/VideoPlayer';

import HeroCopy from 'components/Hero/HeroStandard/HeroCopy';
import { heroDoodleSpecs } from 'components/Hero/HeroStandard/heroTypesAndData';
import type { HeroProps } from 'components/Hero/HeroStandard/heroTypesAndData';
import PopularArticlesGrid from 'components/PopularArticlesGrid/PopularArticlesGrid';

import { doodleGenerator } from 'utils/doodleGenerator';
import { getBackgroundIsDark, optimizeBuilderImage } from 'utils/functions';

import type { FC } from 'react';

const renderAsset = (
  assetType?: 'Image' | 'Animation' | 'Integration' | 'Video',
  assetAlignment?: 'Right' | 'Contained',
  optimizedImage?: string,
  imageCaption?: string,
  lottieFile?: string,
  ariaLabel?: string,
  enableCenteredCopy?: boolean,
  integrationList?: IntegrationRefProps[],
  videoData?: VideoPlayerProps,
) => {
  const noRightMargin = enableCenteredCopy || assetAlignment === 'Contained';

  if (!assetType) {
    return null;
  }

  switch (assetType) {
    case 'Animation':
      if (!lottieFile) {
        return null;
      }

      return (
        <Container
          maxWidth={container.md}
          alignSelf="center"
          width="100%"
          css={`
            ${media.xl} {
              margin-right: ${noRightMargin ? 0 : -268}px;
            }
          `}
        >
          <LottieAnimation ariaLabel={ariaLabel || 'Lottie Animation'} lottieAnimation={lottieFile} loop />
        </Container>
      );
    case 'Image':
      if (!optimizedImage) {
        return null;
      }

      return (
        <Image
          alignSelf={enableCenteredCopy && 'center'}
          image={optimizedImage}
          loading="eager"
          borderRadius="8px"
          alt={imageCaption || ''}
          width="100%"
          maxWidth={container.md}
          css={`
            ${media.xl} {
              margin-right: ${noRightMargin ? 0 : -268}px;
            }
          `}
        />
      );
    case 'Integration':
      if (!integrationList) {
        return null;
      }

      return (
        <Flex
          maxWidth={container.md}
          alignSelf="center"
          justifyContent="center"
          width="100%"
          css={`
            ${media.xl} {
              margin-right: ${noRightMargin ? 0 : -268}px;
            }
          `}
          xl={{
            justifyContent: 'flex-start',
          }}
        >
          <IntegrationsGrid list={integrationList} />
        </Flex>
      );
    case 'Video':
      if (!videoData?.videoUrl) {
        return null;
      }

      return <Video {...videoData} />;
    default:
      return null;
  }
};

const ComponentHero: FC<HeroProps> = ({
  ariaLabel,
  assetType = 'Image',
  assetAlignment,
  background,
  eyebrow,
  heading,
  heroImage,
  imageCaption,
  lottieFile,
  integrationList,
  rightDoodle,
  subheading,
  badgeGroup,
  buttons,
  doodle,
  heroAlignment = 'left-aligned',
  videoData,
  sectionId,
  includePopularArticles,
  popularArticles,
  includeAnchorLink,
  anchorLinkId,
  ...props
}) => {
  const enableCenteredCopy = (heroAlignment === 'centered' && assetType !== 'Integration') || assetType === 'Video';
  const optimizedImage = heroImage && optimizeBuilderImage(heroImage);
  const backgroundIsDark = getBackgroundIsDark(background);
  const doodleImage = doodle && doodleGenerator(doodle);
  const secondDoodle = doodleGenerator('doodle');
  const rightDoodleImage = rightDoodle && doodleGenerator(rightDoodle);

  const filledCopy = heading && eyebrow && subheading && badgeGroup;
  const gapBetweenCopy = 24;
  const eyebrowHeight = 24;
  const totalHeightToCompensate = gapBetweenCopy + eyebrowHeight;
  const lowerDoodle = filledCopy ? totalHeightToCompensate : 0;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const pageURL = props?.builderState?.state?.location?.pathname;
  const pageURLWithoutHyphens = pageURL?.replace(/-/g, ' ');

  const hasAnchorLink = includeAnchorLink && anchorLinkId;

  return (
    <>
      <Section __typename="ComponentHero" background={background} id={sectionId}>
        <Container position="relative" paddingTop={24}>
          <Container paddingLeft={4} marginBottom={20} md={{ marginBottom: 32 }} xl={{ marginBottom: 58 }}>
            <Breadcrumbs url={pageURLWithoutHyphens} backgroundIsDark={backgroundIsDark} />
          </Container>
          <Flex
            flexDirection="column"
            justifyContent={enableCenteredCopy ? 'center' : 'start'}
            alignItems={assetType === 'Integration' && 'center'}
            textAlign={enableCenteredCopy ? 'center' : 'start'}
            gap="24px"
            md={{ gap: '48px' }}
            xl={{
              flexDirection: enableCenteredCopy ? 'column' : 'row',
              justifyContent: 'space-around',
              gap: '64px',
            }}
          >
            <HeroCopy
              heading={heading}
              subheading={subheading}
              backgroundIsDark={backgroundIsDark}
              eyebrow={eyebrow}
              badgeGroup={badgeGroup}
              buttons={buttons}
              enableCenteredCopy={enableCenteredCopy}
            />
            {renderAsset(
              assetType,
              assetAlignment,
              optimizedImage,
              imageCaption,
              lottieFile,
              ariaLabel,
              enableCenteredCopy,
              integrationList,
              videoData,
            )}
          </Flex>
          {doodleImage && (
            <Image
              position="absolute"
              transform={enableCenteredCopy ? 'matrix(-0.95, 0.09, 0.07, 0.95, 0, 0);' : ''}
              width={
                enableCenteredCopy
                  ? heroDoodleSpecs.standard.centeredDoodle.width
                  : heroDoodleSpecs.standard.standardDoodle.width
              }
              height={
                enableCenteredCopy
                  ? heroDoodleSpecs.standard.centeredDoodle.height
                  : heroDoodleSpecs.standard.standardDoodle.height
              }
              zIndex="-10"
              bottom={enableCenteredCopy ? 'auto' : heroDoodleSpecs.standard.standardDoodle.bottom}
              top={enableCenteredCopy ? heroDoodleSpecs.standard.centeredDoodle.top : 'auto'}
              left={
                enableCenteredCopy
                  ? heroDoodleSpecs.standard.centeredDoodle.left
                  : heroDoodleSpecs.standard.standardDoodle.left
              }
              image={doodleImage}
              alt={doodle}
              sm={{
                width: enableCenteredCopy
                  ? heroDoodleSpecs.small.centeredDoodle.width
                  : heroDoodleSpecs.small.standardDoodle.width,
                height: enableCenteredCopy
                  ? heroDoodleSpecs.small.centeredDoodle.height
                  : heroDoodleSpecs.small.standardDoodle.height,
                left: '-180px',
              }}
              md={{
                left: enableCenteredCopy
                  ? heroDoodleSpecs.medium.centeredDoodle.left
                  : heroDoodleSpecs.medium.standardDoodle.left,
                width: enableCenteredCopy
                  ? heroDoodleSpecs.medium.centeredDoodle.width
                  : heroDoodleSpecs.medium.standardDoodle.width,
                height: enableCenteredCopy
                  ? heroDoodleSpecs.medium.centeredDoodle.height
                  : heroDoodleSpecs.medium.standardDoodle.height,
                bottom: enableCenteredCopy ? 'auto' : `${-80 - lowerDoodle}px`,
              }}
              xl={{
                width: enableCenteredCopy
                  ? heroDoodleSpecs.xLarge.centeredDoodle.width
                  : heroDoodleSpecs.xLarge.standardDoodle.width,
                height: enableCenteredCopy
                  ? heroDoodleSpecs.xLarge.centeredDoodle.height
                  : heroDoodleSpecs.xLarge.standardDoodle.height,
                left: enableCenteredCopy
                  ? heroDoodleSpecs.xLarge.centeredDoodle.left
                  : heroDoodleSpecs.xLarge.standardDoodle.left,
                bottom: enableCenteredCopy ? 'auto' : `${-155 - lowerDoodle}px`,
              }}
            />
          )}
          {doodleImage && secondDoodle && enableCenteredCopy && (
            <Image
              position="absolute"
              zIndex="-10"
              image={secondDoodle}
              alt="second-doodle"
              display="none"
              md={{
                display: 'block',
                bottom: '0px',
                right: '-200px',
                width: '346px',
                height: '146px',
                transform: 'rotate(-11.07deg)',
              }}
              xl={{ width: '542px', height: '225px', right: '-350px' }}
            />
          )}
          {assetType === 'Integration' && rightDoodleImage && (
            <Image
              position="absolute"
              zIndex="-10"
              image={rightDoodleImage}
              alt="second-doodle"
              display="none"
              md={{
                display: 'block',
                bottom: '0px',
                right: '-200px',
                width: '346px',
                height: '146px',
                transform: 'rotate(-11.07deg)',
              }}
              xl={{ width: '542px', height: '225px', right: '-350px' }}
            />
          )}
        </Container>
        {hasAnchorLink && (
          <Flex justifyContent="center" paddingTop={32}>
            <Button
              href={`#${anchorLinkId}`}
              borderRadius="50%"
              paddingAll={8}
              border="1px solid"
              borderColor="gray-200"
              backgroundColor="common-white"
              hover={{
                boxShadow: 'lg',
              }}
            >
              <Icon id="chevron-down" iconColor="primary-600" size={34} />
            </Button>
          </Flex>
        )}
      </Section>
      {includePopularArticles && popularArticles && <PopularArticlesGrid popularArticles={popularArticles} />}
    </>
  );
};

ComponentHero.defaultProps = {
  heading: 'Component Hero',
  subheading: '<p> Missing Subheading for Hero Component!</p>',
  background: 'White',
};

export default ComponentHero;
