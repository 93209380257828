import { PDFDownloadLink } from '@react-pdf/renderer';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Text from 'quarks/Text';

import ComponentButton from 'components/ComponentButton/ComponentButton';
import type { RoiData } from 'components/RoiCalculator/RoiCalcDataAndTypes';
import { formatCurrency, formatUSDCurrency } from 'components/RoiCalculator/RoiCalcDataAndTypes';
import ResultsDocument from 'components/RoiCalculator/RoiResultsDocument';

import type { FC } from 'react';

interface RoiCalculatorResultProps extends RoiData {
  annualRoi: number;
  handleReset: () => void;
  description?: string;
  downloadSubheading?: string;
  downloadFeatureText?: string[];
  aboutLineup?: { heading?: string; subheading?: string; bulletOne?: string; bulletTwo?: string; bulletThree?: string };
  planToggle?: boolean;
}

const RoiCalculatorResult: FC<RoiCalculatorResultProps> = ({
  avgMonthlySales,
  percentLabor,
  improvedPercentLabor,
  numberOfLocations,
  plan,
  annualRoi,
  handleReset,
  description,
  downloadSubheading,
  downloadFeatureText,
  aboutLineup,
  planToggle,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const MyPDFDownloadLink: any = PDFDownloadLink;
  const finalAvgMonthlySales = formatCurrency(parseInt(avgMonthlySales)) || '1,500';
  const finalPercentLabor = percentLabor || 20;
  const finalPercentImprovedLabor = improvedPercentLabor || 18;
  const finalNumberLocations = numberOfLocations || 0;
  const finalPlanChosen = `${plan.name || 'Forecasting Schedule'} $${plan.value || 149}`;
  const finalRoi = formatUSDCurrency(annualRoi) || '$133,659';

  return (
    <Container>
      <Container
        paddingAll={24}
        borderRadius="20px 20px 0px 0px"
        boxShadow="xxl"
        backgroundColor="common-white"
        xs={{ paddingAll: 32 }}
      >
        <Flex flexDirection="column" gap="16px" marginBottom={24}>
          <Text fontSize="displayXs" fontWeight="bold" md={{ fontSize: 'displaySm' }}>
            ROI Calculator
          </Text>
          {description && (
            <Text textStyle="md" textColor="gray-700" md={{ textStyle: 'lg' }}>
              {description}
            </Text>
          )}
        </Flex>
        <Flex
          gap="16px"
          flexDirection="column"
          md={{
            justifyContent: 'center',
            gap: '24px',
          }}
        >
          <Flex
            flexDirection="column"
            justifyContent="space-between"
            gap="12px"
            paddingBottom={24}
            borderBottom="1px solid"
            borderColor="gray-300"
            xs={{ flexDirection: 'row', alignItems: 'center' }}
          >
            <Text textStyle="lg" textColor="gray-900" fontWeight="medium" xs={{ textStyle: 'xl' }}>
              Avg. Monthy Sales
            </Text>
            <Text textStyle="xxl" textColor="primary-600" fontWeight="bold">
              {finalAvgMonthlySales}
            </Text>
          </Flex>
          <Flex
            flexDirection="column"
            justifyContent="space-between"
            gap="12px"
            paddingBottom={24}
            borderBottom="1px solid"
            borderColor="gray-300"
            xs={{ flexDirection: 'row', alignItems: 'center' }}
          >
            <Text textStyle="lg" textColor="gray-900" fontWeight="medium" xs={{ textStyle: 'xl' }}>
              % Toward Labor
            </Text>
            <Text textStyle="xxl" textColor="primary-600" fontWeight="bold">
              {finalPercentLabor}%
            </Text>
          </Flex>
          <Flex
            flexDirection="column"
            justifyContent="space-between"
            gap="12px"
            paddingBottom={24}
            borderBottom="1px solid"
            borderColor="gray-300"
            xs={{ flexDirection: 'row', alignItems: 'center' }}
          >
            <Text textStyle="lg" textColor="gray-900" fontWeight="medium" xs={{ textStyle: 'xl' }}>
              Improved % Toward Labor
            </Text>
            <Text textStyle="xxl" textColor="primary-600" fontWeight="bold">
              {finalPercentImprovedLabor}%
            </Text>
          </Flex>
          <Flex
            flexDirection="column"
            justifyContent="space-between"
            gap="12px"
            paddingBottom={24}
            borderBottom="1px solid"
            borderColor="gray-300"
            xs={{ flexDirection: 'row', alignItems: 'center' }}
          >
            <Text textStyle="lg" textColor="gray-900" fontWeight="medium" xs={{ textStyle: 'xl' }}>
              Number of Locations
            </Text>
            <Text textStyle="xxl" textColor="primary-600" fontWeight="bold">
              {finalNumberLocations}
            </Text>
          </Flex>
          {planToggle && (
            <Flex
              flexDirection="column"
              justifyContent="space-between"
              gap="12px"
              paddingTop={24}
              xs={{ flexDirection: 'row', alignItems: 'center' }}
            >
              <Text textStyle="xxl" textColor="primary-600" fontWeight="bold" xs={{ fontSize: 'displayXs' }}>
                Plan Chosen:
              </Text>
              <Text textStyle="xl" textColor="primary-600" fontWeight="semiBold">
                {finalPlanChosen}
              </Text>
            </Flex>
          )}
        </Flex>
      </Container>
      <Flex
        gap="48px"
        flexDirection="column"
        paddingAll={24}
        backgroundColor="primary-25"
        borderRadius="0px 0px 20px 20px"
        xs={{ paddingAll: 32 }}
      >
        <Flex justifyContent="space-between" alignItems="center">
          <Text fontSize="displayXs" textColor="primary-700" fontWeight="bold" sm={{ fontSize: 'displaySm' }}>
            Annual ROI
          </Text>
          <Text fontSize="displayXs" textColor="primary-700" fontWeight="semiBold">
            {finalRoi}
          </Text>
        </Flex>
        <Flex
          gap="16px"
          flexDirection="column"
          sm={{
            flexDirection: 'row',
            gap: '30px',
            justifyContent: 'center',
          }}
        >
          <Container width="100%">
            <MyPDFDownloadLink
              document={
                <ResultsDocument
                  finalAvgMonthlySales={finalAvgMonthlySales}
                  finalPercentLabor={finalPercentLabor}
                  finalPercentImprovedLabor={finalPercentImprovedLabor}
                  finalNumberLocations={finalNumberLocations}
                  finalPlanChosen={finalPlanChosen}
                  planToggle={planToggle}
                  finalRoi={finalRoi}
                  downloadSubheading={downloadSubheading}
                  downloadFeatureText={downloadFeatureText}
                  aboutLineup={aboutLineup}
                />
              }
              fileName="lineup-roi-results.pdf"
            >
              <ComponentButton
                buttonType="callToAction"
                width="100%"
                button={{
                  value: {
                    data: {
                      label: 'Download',
                      size: 'xlarge',
                      hierarchy: 'Primary Button',
                    },
                  },
                }}
                xl={{ height: '52px' }}
                trackingSchema={{
                  hierarchy: '1 of 2',
                  location: 'RoiCalculator',
                }}
              />
            </MyPDFDownloadLink>
          </Container>
          <ComponentButton
            buttonType="callToAction"
            width="100%"
            button={{
              value: {
                data: {
                  label: 'Reset',
                  size: 'xlarge',
                  hierarchy: 'Secondary Gray',
                },
              },
            }}
            onClick={handleReset}
            xl={{ height: '52px' }}
          />
        </Flex>
      </Flex>
    </Container>
  );
};

export default RoiCalculatorResult;
