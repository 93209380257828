import SectionBuilderConfig from 'molecules/Section/Section.config';

import { ButtonBuilderConfig } from 'components/ComponentButton/buttonTypeConfig';

import { doodleField } from 'utils/doodleGenerator';

const SwitcherBuilderConfig = {
  name: 'Switcher',
  inputs: [
    { name: 'internalname', type: 'text' },
    { name: 'eyebrow', type: 'text', defaultValue: 'Eyebrow' },
    { name: 'heading', type: 'text', defaultValue: 'Switcher' },
    { name: 'background', type: 'text', enum: ['White', 'Gray-100', 'Gray-900'] },
    {
      name: 'tabs',
      type: 'list',
      subFields: [
        {
          name: 'title',
          type: 'text',
        },
        {
          name: 'tabIcon',
          type: 'file',
          allowedFileTypes: ['jpeg', 'png', 'jpg', 'svg'],
        },
        {
          name: 'switchbacks',
          type: 'list',
          helperText: 'The Design System recommends a maximum of 3 switchbacks per tab.',
          subFields: [
            { name: 'coloredEyebrow', type: 'boolean', helperText: 'Set to true if you want your heading colorized' },
            { name: 'assetSide', type: 'text', enum: ['Left', 'Right'] },
            { name: 'eyebrow', type: 'text' },
            { name: 'heading', type: 'text' },
            { name: 'headingType', type: 'text', enum: ['h2', 'h3', 'h4', 'h5', 'h6'] },
            { name: 'subheading', type: 'richText' },
            {
              name: 'buttons',
              type: 'list',
              subFields: ButtonBuilderConfig.inputs,
              helperText: 'The Design System has both buttons at xLarge. ',
            },
            {
              name: 'switchbackImage',
              type: 'file',
              allowedFileTypes: ['jpeg', 'png', 'jpg', 'svg'],
            },
            {
              name: 'imageCaption',
              type: 'text',
              helperText: 'Caption for an Image',
            },
            ...doodleField,
          ],
        },
        { name: 'quoteAuthor', type: 'reference', model: 'entity-person' },
        { name: 'quote', type: 'text' },
      ],
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onChange: options => {
        if (options.get('tabs').length > 5) {
          options.set('tabs', options.get('tabs').slice(0, 5));
          alert('Maximum of five tabs allowed.');
        }
      },
    },
    ...SectionBuilderConfig.inputs,
  ],
};

export default SwitcherBuilderConfig;
