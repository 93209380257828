import HeadingsBuilderConfig from 'builder/Headings.config';
import HorizontalRule from 'builder/HorizontalRule';
import { useLocation } from 'react-use';

import Flex from 'quarks/Flex';

import type { FeaturesProps } from 'components/Sidebar/PostFeatures';
import PostFeatures from 'components/Sidebar/PostFeatures';
import SideBarConversionPanel from 'components/Sidebar/SideBarConversionPanel';
import type { SideBarConversionPanelProps } from 'components/Sidebar/SideBarConversionPanel';
import SocialShareLink from 'components/Sidebar/SocialShareLinks';
import TableOfContent from 'components/Sidebar/TableOfContents';

import useSharePage from 'utils/copyShareLink';

import type { FC, ReactElement } from 'react';

export interface PostSideBarProps {
  content: ReactElement[];
  postFeatures?: FeaturesProps[];
  conversionPanel?: SideBarConversionPanelProps;
}

const PostSideBar: FC<PostSideBarProps> = ({ content, postFeatures, conversionPanel }) => {
  const { pathname } = useLocation();
  const { handleCopy } = useSharePage();

  const headings = content
    .filter(
      node =>
        node.props.block.component.name === HeadingsBuilderConfig.name &&
        node.props.block.component.options.headingType === 'h2',
    )
    .map(node => ({
      ...node.props.block.component.options,
    }));

  if (!headings) {
    return null;
  }

  return (
    <Flex flexDirection="column" height="auto" gap="32px" flex="0 0 25%">
      {postFeatures && postFeatures.length > 0 && (
        <>
          <PostFeatures features={postFeatures} />
          <HorizontalRule />
        </>
      )}
      {conversionPanel?.heading && (
        <>
          <SideBarConversionPanel {...conversionPanel} />
          <HorizontalRule />
        </>
      )}
      <Flex flexDirection="column" position="static" top="110px" zIndex="5" gap="32px" lg={{ position: 'sticky' }}>
        {headings.length > 0 && (
          <>
            <TableOfContent content={headings} />
            <HorizontalRule />
          </>
        )}
        {/* TODO: Add copied popover */}
        <SocialShareLink href={`https://lineup.ai${pathname}`} pageCopyLink={handleCopy} />
      </Flex>
    </Flex>
  );
};

export default PostSideBar;
