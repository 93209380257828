import { type FC } from 'react';

import container from 'atoms/spacing/containers';

import Container from 'quarks/Container';
import Grid from 'quarks/Grid';

import CtaCard from 'components/Cards/CtaCard/CtaCard';
import type { FeatureGridProps } from 'components/FeatureGrid/featureGridTypesAndData';

const CtaVariantGrid: FC<FeatureGridProps> = ({ ctaVariantCards }) => {
  const cardAmount = ctaVariantCards?.length && ctaVariantCards.length;

  return (
    <Container textColor="common-white" maxWidth={container.lg}>
      {cardAmount && (
        <Grid
          gridTemplateColumns="1fr"
          rowGap="24px"
          columnGap="24px"
          width="fit-content"
          marginX="auto"
          sm={{
            gridTemplateColumns: `repeat(${cardAmount}, 1fr)`,
          }}
          md={{
            columnGap: '30px',
          }}
        >
          {ctaVariantCards.map(card => (
            <CtaCard
              key={card?.title}
              icon={card?.icon}
              description={card?.description}
              link={card?.link}
              title={card?.title}
            />
          ))}
        </Grid>
      )}
    </Container>
  );
};

export default CtaVariantGrid;
