import Flex from 'quarks/Flex';
import Text from 'quarks/Text';

import RichTextParser from 'utils/richTextParser';

import type { FC } from 'react';

interface PricingCardHeaderProps {
  isAnnual?: boolean;
  backgroundIsDark?: boolean;
  textColor?: 'common-white' | 'gray-900';
  planTitle?: string;
  planDescription?: string;
  annualPlanDescription?: string;
  priceMonthly?: string;
  priceAnnually?: string;
}

const PricingCardHeader: FC<PricingCardHeaderProps> = ({
  isAnnual,
  backgroundIsDark,
  textColor,
  planTitle,
  planDescription,
  annualPlanDescription,
  priceMonthly,
  priceAnnually,
}) => (
  <Flex flexDirection="column" alignItems="center" textAlign="center" gap="12px">
    {planTitle && (
      <Text
        fontWeight="bold"
        fontSize="textMd"
        fontFamily="displayFont"
        textTransform="uppercase"
        textColor={textColor}
      >
        {planTitle}
      </Text>
    )}
    {(priceMonthly || priceAnnually) && (
      <Text
        fontWeight="bold"
        fontFamily="displayFont"
        fontSize="displayMd"
        textColor={textColor}
        md={{ fontSize: 'displayLg' }}
        xl={{ fontSize: 'displayMd' }}
      >
        ${isAnnual ? priceAnnually : priceMonthly}
        <Text
          fontWeight="semiBold"
          fontFamily="displayFont"
          fontSize="displayXs"
          md={{ fontSize: 'displaySm' }}
          xl={{ fontSize: 'displayXs' }}
        >
          /loc/month
        </Text>
      </Text>
    )}
    {isAnnual && annualPlanDescription ? (
      <Text
        fontWeight="regular"
        textStyle="xl"
        fontSize="textLg"
        textColor={backgroundIsDark ? 'gray-100' : 'gray-700'}
        md={{ fontSize: 'textXl' }}
        xl={{ fontSize: 'textLg' }}
      >
        {RichTextParser(annualPlanDescription)}
      </Text>
    ) : (
      planDescription && (
        <Text
          fontWeight="regular"
          textStyle="xl"
          fontSize="textLg"
          textColor={backgroundIsDark ? 'gray-100' : 'gray-700'}
          md={{ fontSize: 'textXl' }}
          xl={{ fontSize: 'textLg' }}
        >
          {RichTextParser(planDescription)}
        </Text>
      )
    )}
  </Flex>
);

export default PricingCardHeader;
