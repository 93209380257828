import styled from 'styled-components';

import type { BasicProps } from 'quarks/interpolations/basic';
import { basic } from 'quarks/interpolations/basic';

import type { FC } from 'react';

export type StaticImage = {
  file: {
    url: string;
  };
};

export type Url = string | null | undefined;

const StyledImage = styled.img`
  ${basic}
`;

const isStaticImage = (image: StaticImage | Url): image is StaticImage =>
  (image as StaticImage)?.file?.url !== undefined;

const imageResolver = (image: StaticImage | Url) => {
  const url = typeof image === 'string' ? image : undefined;

  return {
    as: 'img' as const,
    src: isStaticImage(image) ? image.file.url : url,
  };
};

interface ImageProps extends BasicProps {
  image: StaticImage | Url;
  alt: string;
  title?: string;
  loading?: 'eager' | 'lazy' | null;
  /**
   * if true, image will be all white (use for svg's in dark mode)
   */
  white?: boolean;
  /**
   * The tab index number
   */
  tabIndex?: number;
}

/**
 * Conditionally renders a GatsbyImage if possible or a <img> tag if not.
 */
const Image: FC<ImageProps> = ({ image, alt, loading, title, white, ...props }) => (
  // @ts-expect-error StyledComponents' definition of onLoad conflicts with GatsbyImage's
  <StyledImage
    {...imageResolver(image)}
    title={title ?? alt}
    alt={alt}
    filter={white ? 'brightness(0) invert(1)' : undefined}
    loading={loading || 'lazy'}
    {...props}
  />
);

export default Image;
