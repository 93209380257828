import { handleButtonTracking } from 'molecules/Button/Button';

import {
  calculateGrossProfitMargin,
  calculateNetProfitMargin,
} from 'components/ProfitMarginCalculator/ProfitMarginCalcDataAndTypes';
import type { ProfitMarginData } from 'components/ProfitMarginCalculator/ProfitMarginCalcDataAndTypes';

import type { Dispatch, SetStateAction } from 'react';

const handleGrossProfitMarginCalculate = (formData: ProfitMarginData) => {
  const trackingScheme = {
    label: 'Calculate Gross Profit Margin',
    hierarchy: '1 of 1',
    location: 'ProfitMarginCalculator',
  };

  handleButtonTracking(trackingScheme);

  return calculateGrossProfitMargin(formData);
};

const handleNetProfitMarginCalculate = (formData: ProfitMarginData) => {
  const trackingScheme = {
    label: 'Calculate Net Profit Margin',
    hierarchy: '1 of 1',
    location: 'ProfitMarginCalculator',
  };

  handleButtonTracking(trackingScheme);

  return calculateNetProfitMargin(formData);
};

const handleCalculate = (
  formData: ProfitMarginData,
  setGrossProfitMargin: Dispatch<SetStateAction<string | null>>,
  setNetProfitMargin: Dispatch<SetStateAction<string | null>>,
) => {
  const grossProfit = handleGrossProfitMarginCalculate(formData);
  const netProfit = handleNetProfitMarginCalculate(formData);

  setGrossProfitMargin(grossProfit);
  setNetProfitMargin(netProfit);
};

export default handleCalculate;
