import { useMediaQuery } from '@mui/material';
import HeadingsBuilderConfig from 'builder/Headings.config';
import HorizontalRule from 'builder/HorizontalRule';
import { useLocation } from 'react-use';

import { media } from 'atoms/breakpoints/breakpoints';

import Flex from 'quarks/Flex';
import Text from 'quarks/Text';

import Form from 'components/HsForm/HsForm';
import type { FeaturesProps } from 'components/Sidebar/PostFeatures';
import PostFeatures from 'components/Sidebar/PostFeatures';
import SideBarConversionPanel from 'components/Sidebar/SideBarConversionPanel';
import type { SideBarConversionPanelProps } from 'components/Sidebar/SideBarConversionPanel';
import SocialShareLink from 'components/Sidebar/SocialShareLinks';
import TableOfContent from 'components/Sidebar/TableOfContents';

import useSharePage from 'utils/copyShareLink';

import type { FC, ReactElement } from 'react';

export interface SupportSideBarProps {
  content: ReactElement[];
  postFeatures?: FeaturesProps[];
  conversionPanel?: SideBarConversionPanelProps;
}

const SupportSideBar: FC<SupportSideBarProps> = ({ content, postFeatures, conversionPanel }) => {
  const isDesktop = useMediaQuery(media.lg);
  const { pathname } = useLocation();
  const { handleCopy } = useSharePage();

  const headings = content
    .filter(
      node =>
        node.props.block.component.name === HeadingsBuilderConfig.name &&
        node.props.block.component.options.headingType === 'h2',
    )
    .map(node => ({
      ...node.props.block.component.options,
    }));

  if (!headings) {
    return null;
  }

  return (
    <Flex flexDirection="column" height="auto" gap="32px" flex="0 0 25%">
      {postFeatures && postFeatures.length > 0 && (
        <>
          <PostFeatures features={postFeatures} />
          <HorizontalRule />
        </>
      )}
      {conversionPanel?.heading && (
        <>
          <SideBarConversionPanel {...conversionPanel} />
          <HorizontalRule />
        </>
      )}
      <Flex flexDirection="column" position="static" top="110px" zIndex="5" gap="32px" lg={{ position: 'sticky' }}>
        {headings.length > 0 && (
          <>
            <TableOfContent content={headings} />
            <HorizontalRule />
          </>
        )}
        {isDesktop && (
          <Flex flexDirection="column" position="static" gap="32px">
            <Flex
              flexDirection="column"
              gap="24px"
              paddingX={32}
              paddingY={32}
              borderRadius="20px"
              backgroundColor="primary-25"
            >
              <Text fontSize="displayXs" textColor="gray-900" fontWeight="semiBold">
                Sign up for our newsletter
              </Text>
              <Text textStyle="md" textColor="gray-700" fontWeight="regular">
                Fewer surprises. Greater restaurant efficiency.
              </Text>
              <Form
                formId="Subscribe Form | c9325cee-6d27-41b9-9b9d-731d8cf0cf0e"
                variant="Stacked"
                submitButtonText="Subscribe"
                trackingSchema={{
                  location: 'Support',
                }}
              />
            </Flex>
            <HorizontalRule />
          </Flex>
        )}
        <SocialShareLink href={`https://lineup.ai${pathname}`} pageCopyLink={handleCopy} />
      </Flex>
    </Flex>
  );
};

export default SupportSideBar;
