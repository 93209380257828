import { forwardRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import rotate from 'atoms/animations/rotate';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Text from 'quarks/Text';

import ComponentButton from 'components/ComponentButton/ComponentButton';
import type { FormProps } from 'components/HsForm/types';

import type { FC } from 'react';

const SingleInputForm: FC<FormProps> = forwardRef(
  ({
    heading,
    headingType,
    subHeading,
    formFields,
    postSubmitMessage,
    isSubmitting,
    isSubmitSuccessful,
    handleFormSubmit,
    isDark,
    submitButtonText,
    loading,
    captchaRef,
    isValid,
    ...props
  }) => {
    const errorMessage = !formFields
      ? 'No form fields returned.'
      : formFields?.length > 1 && 'Too many form fields, please use Basic Form';

    if (loading) {
      return (
        <Container>
          <Text textStyle="eyebrow" textColor={isDark ? 'common-white' : 'primary-600'}>
            Loading...please wait
          </Text>
        </Container>
      );
    } else if (!loading && (!formFields || formFields.length > 1)) {
      console.error(errorMessage);

      return (
        <Flex flexDirection="column" gap="10px" paddingAll={24} backgroundColor="gray-200">
          <Text textStyle="eyebrow" fontWeight="bold" textColor={{ light: 'gray-900', dark: 'common-white' }}>
            Development Alert
          </Text>
          <Text textStyle="xxl" fontWeight="bold" textColor={{ light: 'gray-900', dark: 'common-white' }}>
            {errorMessage}
          </Text>
        </Flex>
      );
    }

    const textColor = isDark ? 'gray-200' : 'gray-800';
    const labelProp = submitButtonText
      ? {
          label: isSubmitting ? '...Submitting' : submitButtonText || undefined,
        }
      : ({ labelIcon: isSubmitting ? 'refresh-cw-01' : 'send-01' } as const);

    return (
      <Flex as="form" flexDirection="column" gap="6px" onSubmit={handleFormSubmit} {...props}>
        {heading && (
          <Text as={headingType || 'p'} textStyle="lg" fontWeight="bold" textColor={textColor}>
            {heading}
          </Text>
        )}
        {subHeading && (
          <Text textStyle="sm" textColor={textColor}>
            {subHeading}
          </Text>
        )}
        {formFields && (
          <Flex flexDirection="column" gap="10px" sm={{ flexDirection: 'row' }}>
            {formFields}
            <ReCAPTCHA ref={captchaRef} size="invisible" sitekey={`${process.env.GATSBY_RECAPTCHA_SITE_KEY}`} />
            <ComponentButton
              type="submit"
              buttonType="submit"
              button={{
                value: {
                  data: { hierarchy: 'Primary Button', size: 'medium', label: 'Subscribe', disabled: !isValid },
                },
              }}
              width="100%"
              sm={{ width: 'auto' }}
              css={rotate}
              trackingSchema={{
                hierarchy: '1 of 1',
                location: `Form: ${heading}`,
              }}
              {...labelProp}
            />
          </Flex>
        )}
        {isSubmitSuccessful && (
          <Text textStyle="xs" textColor={isDark ? 'gray-200' : 'primary-700'}>
            {postSubmitMessage ? postSubmitMessage : 'Success! Check your email.'}
          </Text>
        )}
      </Flex>
    );
  },
);

export default SingleInputForm;
