const BlockQuoteBuilderConfig = {
  name: 'blockQuote',
  bubble: true,
  image: 'https://tabler-icons.io/static/tabler-icons/icons-png/blockquote.png',
  requiresParent: {
    message: 'Block must be placed inside the Content Editor',
    component: 'ContentEditor',
  },
  inputs: [
    { name: 'quote', type: 'text' },
    { name: 'author', type: 'reference', model: 'entityPerson' },
  ],
};

export default BlockQuoteBuilderConfig;
