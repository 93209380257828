import { Controller } from 'react-hook-form';

import Checkbox from 'molecules/Checkbox/Checkbox';
import HiddenInput from 'molecules/HiddenField';
import RadioGroup from 'molecules/Radio/RadioGroup/RadioGroup';
import Select from 'molecules/Select/Select';
import type { SelectProps } from 'molecules/Select/Select';
import TextArea from 'molecules/TextArea/TextArea';
import TextField from 'molecules/TextField/TextField';

import type { ManualFormData, fieldGroups as fieldGroupProps, field as fieldProps } from 'components/HsForm/types';
import handleDefaultValue from 'components/HsForm/utils/fieldDefaultValue';

import type { Control, FieldErrors } from 'react-hook-form';

const updateInputType = (name?: string) => {
  switch (name) {
    case 'firstname':
    case 'lastname':
    case 'fullname':
      return 'text';
    case 'email':
      return 'email';
    case 'phone':
      return 'number';
    default:
      return 'text';
  }
};

const generator = (input: fieldProps, control: any, error: any) => {
  const isRequiredError = error?.type === 'required';

  const props = {
    label: input.label || '',
    required: input.required || false,
    placeholder: input.placeholder || '',
    errorMessage: isRequiredError ? 'Field is required' : error?.message?.toString() || '',
    error: !!error,
    maxWidth: '100%',
    ...input,
    ...control,
  };

  if (input.hidden) {
    return <HiddenInput key={input.name} {...props} />;
  }

  switch (input.fieldType) {
    case 'text':
    case 'phonenumber':
    case 'number':
      return <TextField hsType={updateInputType(input.name)} flex="1 0 45%" {...props} />;
    case 'textarea':
      return <TextArea flex="1 0 100%" {...props} />;
    case 'select':
      return input?.options && input.options.length > 0 ? (
        <Select
          list={input.options as SelectProps['list']}
          fontSize="textSm"
          fontWeight="regular"
          lineHeight="textXs"
          flex="1 0 45%"
          {...props}
        />
      ) : (
        <></>
      );
    case 'radio':
      return input?.options && input.options.length > 0 ? (
        <RadioGroup options={input.options} flex="1 0 45%" {...props} />
      ) : (
        <></>
      );
    case 'checkbox':
      return <Checkbox flex="1 0 45%" {...props} />;
    default:
      return <></>;
  }
};

const fieldGenerator = (
  fieldGroups: fieldGroupProps[],
  control: Control,
  errors: FieldErrors,
  hiddenFieldsManualData?: ManualFormData,
) => {
  if (!fieldGroups || fieldGroups.length < 1) {
    console.error('[HubForm] - No fields returned');

    return null;
  }

  const fields = fieldGroups.flatMap(groups => groups.fields);
  const normalFieldNumber = fields.filter(field => !field.hidden).length;
  const validEmailSyntax = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;

  return fields.map((input, i) => (
    <Controller
      key={input.name || i}
      name={input.name || ''}
      defaultValue={handleDefaultValue(input, i, normalFieldNumber, hiddenFieldsManualData)}
      control={control}
      render={({ field }) => generator(input, field, errors?.[field.name])}
      rules={{
        required: input?.required || false,
        pattern:
          input?.name === 'email'
            ? {
                value: validEmailSyntax,
                message: 'Please enter a valid email address',
              }
            : undefined,
      }}
    />
  ));
};

export default fieldGenerator;
