import Icon from 'atoms/Icons/Icon';
import type { iconIds } from 'atoms/Icons/Icon.types';
import color from 'atoms/colors/colors';

import Flex from 'quarks/Flex';
import Link from 'quarks/Link';
import Text from 'quarks/Text';
import type { BasicProps } from 'quarks/interpolations/basic';

import type { AuthorBadges, AuthorSocials } from 'components/Cards/EntryCard/EntryCard';

import type { FC } from 'react';

interface AuthorBadgeFeatureProps extends BasicProps {
  authorBadges?: AuthorBadges;
}

interface AuthorSocialFeatureProps extends BasicProps {
  authorSocials?: AuthorSocials;
}

export const AuthorBadgeFeature: FC<AuthorBadgeFeatureProps> = ({ authorBadges }) => {
  const getIcon = (icon: iconIds, iconLabel: string) => (
    <Flex alignItems="center" gap="12px" borderRadius="12px">
      <Flex height="fit-content">
        <Icon size={24} id={icon} iconColor="primary-500" />
      </Flex>
      <Text as="div" textStyle="md" fontWeight="semiBold" textColor="gray-700">
        {iconLabel}
      </Text>
    </Flex>
  );

  return (
    <Flex
      gap="16px"
      flexDirection="column"
      flexWrap="wrap"
      md={{
        flexDirection: 'row',
        gap: '34px',
      }}
    >
      {authorBadges?.topContributor && getIcon('bookmark', 'Top Contributor')}
      {authorBadges?.evidenceBased && getIcon('check-verified-01', 'Evidence Based')}
      {authorBadges?.factChecked && getIcon('check-verified-01', 'Fact Checked')}
    </Flex>
  );
};

export const AuthorSocialsFeature: FC<AuthorSocialFeatureProps> = ({ authorSocials }) => {
  const getSocial = (icon: iconIds, url: string) => (
    <Link
      href={url}
      css={`
        svg {
          transition: fill 200ms ease-in-out;
        }
        &:hover svg {
          fill: ${color.primary[700]};
        }
      `}
      trackingSchema={{
        label: icon,
        location: 'AuthorCardFeatures',
      }}
    >
      <Icon size={28} id={icon} iconColor="primary-500" />
    </Link>
  );

  return (
    <Flex gap="28px" flexWrap="wrap">
      {authorSocials?.twitter && getSocial('twitter', authorSocials.twitter)}
      {authorSocials?.facebook && getSocial('facebook', authorSocials.facebook)}
      {authorSocials?.linkedin && getSocial('linkedin', authorSocials.linkedin)}
    </Flex>
  );
};
