import conversionPanelGrayDesktop from 'assets/backgrounds/conversionPanelGray-100Contained1700.svg';
import conversionPanelGrayMobile from 'assets/backgrounds/conversionPanelGray-100Contained375.svg';
import conversionPanelGrayTablet from 'assets/backgrounds/conversionPanelGray-100Contained992.svg';
import offsetConversionPanelGrayDesktop from 'assets/backgrounds/conversionPanelGray-100ContainedOffset1700.svg';
import offsetConversionPanelGrayMobile from 'assets/backgrounds/conversionPanelGray-100ContainedOffset375.svg';
import offsetConversionPanelGrayTablet from 'assets/backgrounds/conversionPanelGray-100ContainedOffset992.svg';
import conversionPanelDarkDesktop from 'assets/backgrounds/conversionPanelGray-900Contained1700.svg';
import conversionPanelDarkMobile from 'assets/backgrounds/conversionPanelGray-900Contained375.svg';
import conversionPanelDarkTablet from 'assets/backgrounds/conversionPanelGray-900Contained992.svg';
import offsetConversionPanelDarkDesktop from 'assets/backgrounds/conversionPanelGray-900ContainedOffset1700.svg';
import offsetConversionPanelDarkMobile from 'assets/backgrounds/conversionPanelGray-900ContainedOffset375.svg';
import offsetConversionPanelDarkTablet from 'assets/backgrounds/conversionPanelGray-900ContainedOffset992.svg';
import conversionPanelPrimaryDesktop from 'assets/backgrounds/conversionPanelPrimary-600Contained1700.svg';
import conversionPanelPrimaryMobile from 'assets/backgrounds/conversionPanelPrimary-600Contained375.svg';
import conversionPanelPrimaryTablet from 'assets/backgrounds/conversionPanelPrimary-600Contained992.svg';

import { keyExistsOnObject } from 'utils/typeUtils';

const backgroundImageData = {
  conversionPanelGray100Contained376: conversionPanelGrayMobile,
  conversionPanelGray100Contained992: conversionPanelGrayTablet,
  conversionPanelGray100Contained1700: conversionPanelGrayDesktop,
  conversionPanelPrimary600Contained376: conversionPanelPrimaryMobile,
  conversionPanelPrimary600Contained992: conversionPanelPrimaryTablet,
  conversionPanelPrimary600Contained1700: conversionPanelPrimaryDesktop,
  conversionPanelGray900Contained376: conversionPanelDarkMobile,
  conversionPanelGray900Contained992: conversionPanelDarkTablet,
  conversionPanelGray900Contained1700: conversionPanelDarkDesktop,
  conversionPanelGray100ContainedOffset376: offsetConversionPanelGrayMobile,
  conversionPanelGray100ContainedOffset992: offsetConversionPanelGrayTablet,
  conversionPanelGray100ContainedOffset1700: offsetConversionPanelGrayDesktop,
  conversionPanelGray900ContainedOffset376: offsetConversionPanelDarkMobile,
  conversionPanelGray900ContainedOffset992: offsetConversionPanelDarkTablet,
  conversionPanelGray900ContainedOffset1700: offsetConversionPanelDarkDesktop,
} as const;

const backgroundImageGenerator = (imageName: string) =>
  keyExistsOnObject(backgroundImageData, imageName) ? backgroundImageData[imageName] : undefined;
export default backgroundImageGenerator;
