const cardsBuilderDataField = [
  {
    name: 'entryCards',
    type: 'object',
    subFields: [
      {
        name: 'articleCards',
        type: 'list',
        subFields: [
          {
            name: 'card',
            type: 'reference',
            model: 'post',
          },
        ],
      },
      {
        name: 'resourceCards',
        type: 'list',
        subFields: [
          {
            name: 'card',
            type: 'reference',
            model: 'gated-resource',
          },
        ],
      },
    ],
    showIf: (options: { get: (arg0: string) => string }) =>
      options.get('cardContentType') === 'Entry Cards' || options.get('cardContentType') === 'Newsroom Cards',
  },
];

export default cardsBuilderDataField;
