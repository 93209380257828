/* eslint-disable react/no-array-index-key */
import { useMemo, useRef } from 'react';
import { useClickAway } from 'react-use';

import Icon from 'atoms/Icons/Icon';
import color from 'atoms/colors/colors';

import Flex from 'quarks/Flex';
import Link from 'quarks/Link';
import Text from 'quarks/Text';
import type { BasicProps } from 'quarks/interpolations/basic';

import DesktopSubMenu from 'components/Header/DesktopHeaderComponents/DesktopSubMenu';

import { getLinkHierarchy } from 'utils/functions';

import type { Builder_SubMenu, Builder_SubMenuData, Maybe, Scalars } from 'graphqlTypes';
import type { Dispatch, FC, RefObject, SetStateAction } from 'react';

interface MenuItemProps extends BasicProps {
  menuItems?: Array<{
    __typename?: 'builder_HeaderMenuItemData';
    internalName?: Maybe<Scalars['String']>;
    menuItemLabel?: Maybe<Scalars['String']>;
    /** For usage when Menu Item does not contain any Submenus. See Pricing as an example on Global Navigation Figma. */
    menuItemLink?: Maybe<Scalars['String']>;
    subMenus?: Maybe<Scalars['builder_JSON']>;
  }>;
  handleMenuClick: (menuIndex: number) => void;
  hoverOpenMenu: Dispatch<SetStateAction<number | null>>;
  openMenu?: number | null;
  navbarHeight: number;
  mobileNavRef: RefObject<HTMLDivElement>;
  isDarkHeader?: boolean;
}

const MenuItems: FC<MenuItemProps> = ({
  menuItems,
  navbarHeight,
  handleMenuClick,
  hoverOpenMenu,
  openMenu,
  mobileNavRef,
  isDarkHeader,
}) => {
  const liRef = useRef<HTMLLIElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  useClickAway(menuRef, event => {
    if (
      menuRef.current &&
      mobileNavRef.current &&
      !menuRef.current.contains(event.target as HTMLDivElement) &&
      !mobileNavRef.current.contains(event.target as HTMLDivElement)
    ) {
      hoverOpenMenu(null);
    }
  });

  return (
    <Flex
      as="ul"
      paddingLeft={0}
      gap="32px"
      css={`
        list-style-type: none;
        scrollbar-width: none;
        ::-webkit-scrollbar {
          display: none;
        }
      `}
    >
      {useMemo(
        () =>
          menuItems &&
          menuItems.map((menuItem, index) => {
            const isOpened = index === openMenu;

            const getTextColor = () => {
              if (isDarkHeader) {
                return 'common-white';
              }

              return isOpened ? 'primary-700' : 'gray-900';
            };

            const getIconColor = () => {
              if (isDarkHeader) {
                return 'gray-50';
              }

              return isOpened ? 'primary-700' : 'gray-600';
            };

            const iconHoverColor = isDarkHeader ? color.primary['100'] : color.primary['600'];

            const handleKeyboardEvent = (event: React.KeyboardEvent<HTMLLIElement>) => {
              const { className } = event.target as HTMLLIElement;
              if (
                (event.key === ' ' || event.key === 'Enter' || event.key === 'Space') &&
                className === liRef?.current?.className
              ) {
                event.preventDefault();
                handleMenuClick(index);
              }
            };

            const retrieveSubMenuData = menuItem.subMenus?.map(
              (menu: { subMenu: { value: Builder_SubMenu } }) => menu?.subMenu?.value?.data,
            );
            if (!menuItem) {
              return;
            }
            const containsSubMenuItems = menuItem?.subMenus;

            return (
              <Flex
                as="li"
                ref={liRef}
                flexDirection="column"
                justifyContent="center"
                key={`${menuItem.menuItemLabel}-${index}`}
                tabIndex={containsSubMenuItems && !menuItem.menuItemLink ? 0 : undefined}
                onKeyDown={
                  containsSubMenuItems ? (e: React.KeyboardEvent<HTMLLIElement>) => handleKeyboardEvent(e) : undefined
                }
                onMouseEnter={() => {
                  if (!containsSubMenuItems) {
                    return;
                  }
                  hoverOpenMenu(index);
                }}
              >
                <Link
                  display="flex"
                  href={menuItem.menuItemLink && !containsSubMenuItems ? menuItem?.menuItemLink : ''}
                  width="100%"
                  css={`
                    gap: 8px;
                    align-items: center;
                    > svg {
                      transition: 150ms;
                    }
                    :hover,
                    :focus {
                      > span,
                      svg {
                        color: ${iconHoverColor};
                        stroke: ${iconHoverColor};
                      }
                    }
                  `}
                  trackingSchema={{
                    label: menuItem.menuItemLabel,
                    location: 'Desktop Nav',
                    hierarchy: getLinkHierarchy(menuItems, index),
                  }}
                >
                  <Text textStyle="lg" textColor={getTextColor()} fontWeight="semiBold">
                    {menuItem.menuItemLabel}
                  </Text>
                  {containsSubMenuItems && (
                    <Icon
                      id="chevron-down"
                      size={20}
                      iconColor={getIconColor()}
                      css={`
                        align-self: center;
                        transform: ${`rotate(${isOpened ? '180deg' : '0deg'})`};
                        transition: 150ms;
                      `}
                    />
                  )}
                </Link>
                {isOpened && (
                  <Flex
                    ref={menuRef}
                    justifyContent="space-around"
                    transition="opacity 500ms"
                    backgroundColor="common-white"
                    border="1px solid"
                    borderColor="gray-200"
                    borderRadius="16px"
                    boxShadow="lg"
                    position="absolute"
                    top={`${navbarHeight + 2}px`}
                    left="24px"
                    height="auto"
                    onMouseLeave={() => hoverOpenMenu(null)}
                  >
                    {retrieveSubMenuData?.map((subMenu: Builder_SubMenuData) => (
                      <DesktopSubMenu key={`${subMenu.label}-${index}`} subMenu={subMenu} />
                    ))}
                  </Flex>
                )}
              </Flex>
            );
          }),
        [[menuItems]],
      )}
    </Flex>
  );
};

export default MenuItems;
