import Icon from 'atoms/Icons/Icon';
import color from 'atoms/colors/colors';
import gradient from 'atoms/colors/gradients';

import Flex from 'quarks/Flex';
import Text from 'quarks/Text';
import type { BasicProps } from 'quarks/interpolations/basic';

import Rating from 'molecules/Rating/Rating';

import type { FC } from 'react';

interface InlineQuoteProps extends BasicProps {
  background?: 'Primary-600' | 'Gradient-Light';
  alignment?: 'left' | 'center';
  size?: 'small' | 'large';
  rating?: number;
  quote?: string;
  author?: string;
  company?: string;
  role?: string;
}

const InlineQuote: FC<InlineQuoteProps> = ({
  background,
  alignment,
  size,
  quote,
  rating,
  author,
  role,
  company,
  ...props
}) => {
  const backgroundIsDark = background === 'Primary-600';
  const iconColor = backgroundIsDark ? 'primary-100' : 'primary-600';

  const ratingColor = backgroundIsDark ? color.common.white : color.primary[600];
  const ratingToDisplay = rating && rating > 0 ? rating : false;

  const isCentered = alignment === 'center';
  const isSmall = size === 'small';

  return (
    <Flex
      position="relative"
      flexDirection="column"
      md={{
        flexDirection: 'row',
      }}
      textColor="common-white"
      borderRadius="24px"
      boxShadow="lg"
      {...props}
      css={`
        background: ${backgroundIsDark ? color.primary[600] : gradient.comboGradient.lightMode};
      `}
    >
      {!ratingToDisplay && (
        <Flex position="absolute" top="-20px" left="-8px">
          <Icon id="open-quote" size={56} lgSize={48} iconColor={iconColor} />
        </Flex>
      )}
      <Flex
        flexDirection="column"
        gap={isSmall ? '24px' : '32px'}
        alignItems={isCentered ? 'center' : 'flex-start'}
        paddingAll={32}
        lg={{
          paddingX: 48,
          paddingY: isSmall ? 32 : 40,
        }}
      >
        {ratingToDisplay && rating && (
          <Rating
            readOnly
            value={rating < 5 ? ratingToDisplay : 5}
            css={`
              .MuiRating-icon {
                color: ${ratingColor} !important;
              }
            `}
          />
        )}
        {quote && (
          <Text
            textAlign={isCentered ? 'center' : 'left'}
            textStyle="md"
            textColor={backgroundIsDark ? 'common-white' : 'gray-800'}
            lg={{ textStyle: 'xl' }}
          >
            {quote}
          </Text>
        )}

        {author && (
          <Flex flexDirection="column">
            <Text
              textStyle="lg"
              textAlign={isCentered ? 'center' : 'left'}
              textColor={backgroundIsDark ? 'common-white' : 'gray-800'}
            >
              {`— ${author}`}
            </Text>
            {role && company && (
              <Text
                textStyle="md"
                textColor={backgroundIsDark ? 'common-white' : 'gray-900'}
                textAlign={ratingToDisplay ? 'center' : 'left'}
              >{`${role} of ${company}`}</Text>
            )}
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default InlineQuote;
