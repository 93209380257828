import Flex from 'quarks/Flex';
import Paragraph from 'quarks/Paragraph';

import ComponentButton from 'components/ComponentButton/ComponentButton';

import RichTextParser from 'utils/richTextParser';

import type { Builder_ComponentButton } from 'graphqlTypes';
import type { FC } from 'react';

export interface SideBarConversionPanelProps {
  heading?: string;
  subheading?: string;
  buttons: { button: { value: Builder_ComponentButton; id: string } }[];
}

const SideBarConversionPanel: FC<SideBarConversionPanelProps> = ({ heading, subheading, buttons }) => (
  <Flex
    display="none"
    flexDirection="column"
    gap="24px"
    paddingY={32}
    paddingX={24}
    backgroundColor="primary-600"
    borderRadius="20px"
    lg={{ display: 'flex' }}
  >
    {heading && (
      <Paragraph
        fontFamily="displayFont"
        fontSize="displayXs"
        fontWeight="bold"
        lineHeight="displayXs"
        textColor="common-white"
      >
        {heading}
      </Paragraph>
    )}
    {subheading && (
      <Paragraph textStyle="md" textColor="common-white">
        {RichTextParser(subheading)}
      </Paragraph>
    )}
    {buttons && (
      <Flex flexDirection="column" gap="6px">
        {buttons.map(({ button }, ind) => (
          <ComponentButton
            key={button?.id}
            button={{
              value: {
                data: {
                  size: 'xl',
                  hierarchy: ind === 0 ? 'secondaryColor' : 'primaryButton',
                  link: button?.value?.data?.link,
                  label: button?.value?.data?.label,
                },
              },
            }}
            width="100%"
          />
        ))}
      </Flex>
    )}
  </Flex>
);

export default SideBarConversionPanel;

export const SideBarConversionPanelConfig = {
  name: 'sideBarConversionPanel',
  type: 'object',
  subFields: [
    { name: 'heading', type: 'text', defaultValue: 'Fewer surprises. Greater restaurant efficiency.' },
    { name: 'subheading', type: 'richText', defaultValue: '<p>Fewer surprises. Greater restaurant efficiency.</p>' },
    {
      name: 'buttons',
      type: 'list',
      subFields: [{ name: 'button', type: 'reference', model: 'component-button' }],
      helperText: 'Maximum Two buttons. Styles are predefined.',
    },
  ],
};
