import { colorListArray } from 'atoms/colors/colors';

import type { BasicProps } from 'quarks/interpolations/basic';

import type { DoodleKey } from 'utils/doodleGenerator';
import { doodleField } from 'utils/doodleGenerator';

export interface HistoryCardProps extends BasicProps {
  /**
   * Used as an ID, must be unique and is required
   */
  internalName?: string;
  /**
   * Determines the text for the date
   */
  date?: string;
  /**
   * Determines what background gets rendered.
   */
  background?: string;
  /**
   * Determines the text for the heading
   */
  heading?: string;
  /**
   * Determines the text for the subheading
   */
  subheading?: string;
  /**
   * Determines the doodle that gets displayed inside the card.
   */
  doodle?: DoodleKey;
}

export const historyCardBuilderDataField = [
  {
    name: 'historyCards',
    type: 'list',
    subFields: [
      { name: 'internalName', type: 'text', helperText: 'Used as an ID, must be unique.', required: true },
      { name: 'heading', type: 'text' },
      { name: 'subheading', type: 'text' },
      { name: 'background', type: 'text', enum: colorListArray },
      { name: 'date', type: 'text' },
      ...doodleField,
    ],
    showIf: (options: { get: (arg0: string) => string }) => options.get('cardContentType') === 'History Cards',
  },
];
