import Icon from 'atoms/Icons/Icon';
import type { iconIds } from 'atoms/Icons/Icon.types';

import Flex from 'quarks/Flex';
import Text from 'quarks/Text';
import type { BasicProps } from 'quarks/interpolations/basic';

import type { FC } from 'react';

export interface RoiCalcFeatureProps extends BasicProps {
  copy?: string;
  icon?: iconIds;
}

const RoiCalcFeature: FC<RoiCalcFeatureProps> = ({ icon, copy }) => (
  <Flex gap="16px" borderRadius="12px" alignItems="center">
    {icon && (
      <Flex height="fit-content">
        <Icon
          size={20}
          id={icon}
          hasGradientBorder
          wrapped
          paddingAll={10}
          md={{
            paddingAll: 10,
          }}
        />
      </Flex>
    )}
    <Flex width="100%" flexDirection="column" gap="8px" md={{ gap: '12px' }}>
      {copy && (
        <Text
          textStyle="md"
          textColor="gray-900"
          overflowY="hidden"
          md={{ textStyle: 'lg' }}
          transition="max-height 0.150s ease"
        >
          {copy}
        </Text>
      )}
    </Flex>
  </Flex>
);

export default RoiCalcFeature;
