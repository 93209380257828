export const SectionBuilderConfig = {
  name: 'Section',
  inputs: [
    {
      name: 'sectionId',
      type: 'text',
      helperText:
        'The section ID is used for creating anchor links. It must be unique and may only contain alphanumeric (a-z, 1-9) and hyphen (-) characters.',
      regex: {
        pattern: '^[a-zA-Z0-9_-]*$',
        options: 'g',
        message: 'Your section ID may only contain alphanumeric (a-z, 1-9) and hyphen (-) characters.',
      },
      advanced: true,
    },
  ],
};
export default SectionBuilderConfig;
