import { BuilderComponent } from '@builder.io/react';
import { graphql } from 'gatsby';
import { Suspense } from 'react';
import customComponentsRegistration from 'templates/componentGenerator';

import ModalContainer from 'molecules/Modal/Modal';

import Footer from 'components/Footer/Footer';
import GeneralHead from 'components/GeneralHead';
import Header from 'components/Header/Header';
import Main from 'components/Main';
import SEO from 'components/seo';

import type { Query } from 'graphqlTypes';
import type { FC } from 'react';

export interface IHead {
  data: Query;
  location: Location;
}

const PageTemplate = (query: { data: Query }) => {
  const {
    data: {
      allBuilderModels: {
        onePage,
        oneGlobalBanner,
        oneComponentHeader: header,
        oneComponentFooter: footer,
        blogPostCategory,
        resourcesCategory,
        hiddenFormFields,
      },
    },
  } = query;

  const { isDarkHeader, isDarkFooter, banner, isLandingPage = false } = { ...onePage?.data };
  const content = onePage?.content;
  const globalBanner = oneGlobalBanner?.data;

  const isCustomBanner = banner?.customBanner === true;
  const isGlobalBanner = globalBanner?.showBanner === true;
  const isHideBanner = banner?.hideBanner === true;

  customComponentsRegistration();

  return (
    <Suspense fallback>
      {header?.content && (
        <Header
          {...header.content.data}
          banner={(isCustomBanner && !isHideBanner && banner) || (isGlobalBanner && !isHideBanner && globalBanner)}
          isLandingPage={isLandingPage}
          isDarkHeader={isDarkHeader}
        />
      )}
      <Main>
        <BuilderComponent
          content={content}
          model="page"
          options={{ includeRefs: true, cachebust: true }}
          data={{
            template: 'page',
            entryCategories: JSON.stringify({ blogPostCategory, resourcesCategory }),
            hiddenFields: JSON.stringify({ hiddenFormFields }),
          }}
        />
      </Main>
      {footer?.content && (
        <Footer isDark={isDarkFooter} content={footer.content.data} isLandingPage={isLandingPage || false} />
      )}
      <ModalContainer />
    </Suspense>
  );
};

export const Head: FC<IHead> = ({
  data: {
    allBuilderModels: { onePage },
  },
  location,
}) => {
  const { schema } = { ...onePage?.data };

  return (
    <>
      {onePage && (
        <GeneralHead>
          <SEO
            title={onePage?.data?.title}
            description={onePage?.data?.description}
            image={onePage?.data?.ogImage}
            location={location}
            indexable={!onePage?.data?.noIndex}
            schema={schema}
          />
        </GeneralHead>
      )}
    </>
  );
};

export default PageTemplate;

export const pageQuery = graphql`
  query buildPage($path: String!) {
    allBuilderModels {
      onePage(target: { urlPath: $path }, options: { includeRefs: true, cachebust: true }) {
        content
        data {
          isLandingPage
          title
          description
          ogImage
          noIndex
          isDarkHeader
          isDarkFooter
          url
          banner
          schema
        }
      }
      oneGlobalBanner(
        options: { includeRefs: true, cachebust: true }
        target: { id: "b241c59d043b4cc7b0f608356bea42ef" }
      ) {
        data {
          showBanner
          body
          button
          heading
          includeIcon
        }
      }
      oneComponentHeader(
        options: { includeRefs: true, cachebust: true }
        target: { id: "8fe1129198a9478c9cc7346a40a63805" }
      ) {
        content
      }
      oneComponentFooter(
        options: { includeRefs: true, cachebust: true }
        target: { id: "a7c491a16336403c94432e7b3ebb419b" }
      ) {
        content
      }
      blogPostCategory(options: { includeRefs: true, cachebust: true }) {
        content
      }
      resourcesCategory(options: { includeRefs: true, cachebust: true }) {
        content
      }
      hiddenFormFields {
        data {
          hiddenFormField
        }
      }
    }
  }
`;
