import type { iconIds } from 'atoms/Icons/Icon.types';

import type { FlexProps } from 'quarks/interpolations/flex';

import SectionBuilderConfig from 'molecules/Section/Section.config';

import type { Builder_SupportCategoryData, Builder_SupportData } from 'graphqlTypes';

export interface SupportListingProps extends FlexProps {
  sectionId?: string;
}

export interface SupportPost {
  data?: Builder_SupportData;
  id?: string;
}

export interface SupportCategory {
  data?: Builder_SupportCategoryData;
  id?: string;
}

export interface SupportGroup {
  name?: string;
  icon?: iconIds;
  posts?: SupportPost[];
}

export const SupportListingConfig = {
  name: 'SupportListing',
  inputs: [...SectionBuilderConfig.inputs],
};

export default SupportListingConfig;
