import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Grid from 'quarks/Grid';
import Heading from 'quarks/Heading';
import Image from 'quarks/Image';
import Text from 'quarks/Text';

import Section from 'molecules/Section/Section';

import type { ComponentRoiCalculatorProps } from 'components/RoiCalculator/RoiCalcDataAndTypes';
import RoiCalcFeature from 'components/RoiCalculator/RoiCalcFeature';
import RoiCalculator from 'components/RoiCalculator/RoiCalculator';

import colorizeText from 'utils/colorizeText';
import doodleGenerator from 'utils/doodleGenerator';
import type { DoodleKey } from 'utils/doodleGenerator';
import RichTextParser from 'utils/richTextParser';

import type { FC } from 'react';

const ComponentRoiCalculator: FC<ComponentRoiCalculatorProps> = ({
  eyebrow,
  heading,
  headingType,
  subheading,
  downloadSubheading,
  features,
  calculatorTitle,
  calculatorDescription,
  averageMonthlySales,
  percentTowardLabor,
  improvedPercentTowardLabor,
  numberOfLocations,
  instructionText,
  planToggle,
  sectionId,
  aboutLineup,
}) => {
  const doodleProps = {
    position: 'absolute',
    alt: 'doodle',
    zIndex: '-1',
  } as const;

  const downloadFeatureText = features?.map(feature => feature?.copy as string);

  return (
    <Section __typename="Component ROI Calculator" id={sectionId}>
      <Grid
        display="block"
        xl={{
          display: 'grid',
          gap: '60px',
          gridTemplateColumns: '1fr 570px',
        }}
      >
        <Flex flexDirection="column" gap="20px" md={{ gap: '32px' }} position="relative">
          <Image
            {...doodleProps}
            display="none"
            height="500px"
            width="438px"
            bottom="-140px"
            left="-304px"
            image={doodleGenerator('skillet') as DoodleKey}
            transform="rotate(13deg)"
            xl={{
              display: 'block',
            }}
          />
          <Flex flexDirection="column" gap="18px">
            {eyebrow && <Text textStyle="eyebrow">{colorizeText(eyebrow, false)}</Text>}
            {heading && (
              <Heading
                as={headingType || 'h2'}
                textStyle="sm"
                fontWeight="semiBold"
                md={{ textStyle: 'md', fontWeight: 'semiBold' }}
                xl={{ textStyle: 'md', fontWeight: 'semiBold' }}
              >
                {heading}
              </Heading>
            )}
            {subheading && (
              <Container textColor="gray-700" fontSize="textLg" md={{ fontSize: 'textXl' }}>
                {RichTextParser(subheading)}
              </Container>
            )}
          </Flex>
          {features && (
            <Flex flexDirection="column" gap="12px">
              {features.map((feature, index) => (
                <RoiCalcFeature
                  icon={feature?.icon}
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  copy={feature?.copy}
                />
              ))}
            </Flex>
          )}
        </Flex>
        <Container
          position="relative"
          marginTop={24}
          md={{
            marginTop: 48,
          }}
          xl={{
            marginTop: 0,
          }}
        >
          <Image
            {...doodleProps}
            height="68px"
            width="320px"
            top="-68px"
            right="-170px"
            image={doodleGenerator('tripleknotswirl') as DoodleKey}
            transform="rotate(345deg)"
            md={{
              height: '100px',
              width: '478px',
              top: '-110px',
              right: '-230px',
            }}
            xl={{
              top: '-44px',
              right: '-385px',
            }}
          />
          <RoiCalculator
            title={calculatorTitle}
            description={calculatorDescription}
            instructionText={instructionText}
            avgMonthlySalesField={averageMonthlySales}
            percentLaborField={percentTowardLabor}
            improvedPercentLaborField={improvedPercentTowardLabor}
            numberOfLocationsField={numberOfLocations}
            aboutLineup={aboutLineup}
            downloadSubheading={downloadSubheading}
            downloadFeatureText={downloadFeatureText}
            planToggle={planToggle}
          />
        </Container>
      </Grid>
    </Section>
  );
};

export default ComponentRoiCalculator;
