import SectionBuilderConfig from 'molecules/Section/Section.config';

export const trustbarBackgrounds = ['Concave', 'Primary-25', 'Primary-800', 'Gray-900', 'White'];

const TrustbarBuilderConfig = {
  name: 'Trustbar',
  inputs: [
    { name: 'background', type: 'text', enum: trustbarBackgrounds },
    { name: 'heading', type: 'text' },
    {
      name: 'companies',
      type: 'list',
      subFields: [{ name: 'company', type: 'reference', model: 'entity-company' }],
    },
    { name: 'collapseTopPadding', type: 'boolean', defaultValue: false },
    ...SectionBuilderConfig.inputs,
  ],
};

export default TrustbarBuilderConfig;
