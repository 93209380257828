import { useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';

import { media } from 'atoms/breakpoints/breakpoints';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Grid from 'quarks/Grid';
import Text from 'quarks/Text';

import Button, { handleButtonTracking } from 'molecules/Button/Button';
import NumberedPagination from 'molecules/Pagination/NumberedPagination';
import Section from 'molecules/Section/Section';
import Select from 'molecules/Select/Select';

import ExploreAuthors from 'components/AuthorDetail/ExploreAuthors';
import type { BlogListingProps, BlogPost } from 'components/Blog/BlogListing.config';
import type { CardProps } from 'components/Cards/EntryCard/EntryCard';
import EntryCard from 'components/Cards/EntryCard/EntryCard';
import Form from 'components/HsForm/HsForm';

import useBlogPosts from 'utils/hooks/useBlogPosts';

import type { FC } from 'react';

const allCategoriesText = 'View All';

interface AuthorDetailProps extends BlogListingProps {
  authorName: string;
}

const AuthorDetail: FC<AuthorDetailProps> = ({ postsPerPage, blogCardsHeadingType, sectionId, authorName }) => {
  const [blogCategories, setBlogCategories] = useState([
    {
      data: {
        title: allCategoriesText,
        slug: '/',
      },
      id: '0',
    },
  ]);

  const posts: BlogPost[] = useBlogPosts()
    .filter((post: BlogPost) => authorName === post.data.postAuthor.value.name)
    .sort((a: BlogPost, b: BlogPost) => {
      if (!a.data.publishDate || !b.data.publishDate) {
        return 0;
      }

      return new Date(b.data.publishDate).valueOf() - new Date(a.data.publishDate).valueOf();
    });

  const isDesktop = useMediaQuery(media.lg);
  const [activeCategory, setActiveCategory] = useState(allCategoriesText);
  const [activePage, setActivePage] = useState(1);
  const [postOffset, setOffset] = useState(0);
  const [postsToDisplay, setPostsToDisplay] = useState(posts);

  useEffect(() => {
    setOffset(postsPerPage * (activePage - 1));
  }, [activePage, postsPerPage]);

  useEffect(() => {
    const uniqueCategories = [...new Map(posts.map(post => [post?.data?.category?.value?.data?.title, post])).values()];

    setBlogCategories(viewAll => [
      ...viewAll,
      ...uniqueCategories.map((post, i) => ({
        data: {
          title: post?.data?.category?.value?.data?.title,
          slug: post?.data?.category?.value?.data?.slug,
        },
        id: `${i + 1}`,
      })),
    ]);
  }, []);

  const typename = 'AuthorDetail';

  const handleCategoryClick = (id: string) => {
    setActivePage(1);
    setActiveCategory(id);
    id === allCategoriesText
      ? setPostsToDisplay(posts)
      : setPostsToDisplay(posts.filter(post => post?.data?.category?.value?.data?.title === id));

    const trackingScheme = {
      label:
        posts.filter(post => post?.data?.category?.value?.data?.title === id)[0]?.data?.category.value.data.title ||
        'View all',
      location: typename,
    };

    handleButtonTracking(trackingScheme);
  };

  return (
    <>
      <Section __typename={typename} id={sectionId} background="gray-100">
        <Container>
          <Grid gridTemplateColumns="1fr" lg={{ gridTemplateColumns: '1fr 2fr' }}>
            <Flex flexDirection="column" gap="20px" paddingBottom={32} lg={{ paddingRight: 96, paddingBottom: 0 }}>
              <Text textStyle="lg" fontWeight="semiBold">
                Blog Categories
              </Text>
              <Flex flexDirection="column" gap="4px">
                {isDesktop ? (
                  blogCategories?.map(
                    ({ data, id }) =>
                      data?.title && (
                        <Button
                          key={id}
                          justifyContent="flex-start"
                          width="100%"
                          paddingY={10}
                          paddingX={14}
                          borderRadius="6px"
                          backgroundColor={activeCategory === data.title ? 'primary-50' : 'common-transparent'}
                          fontSize="textMd"
                          lineHeight="textMd"
                          textColor={activeCategory === data.title ? 'primary-900' : 'gray-700'}
                          fontWeight="medium"
                          boxShadow="none"
                          onClick={() => handleCategoryClick(data.title || allCategoriesText)}
                          hover={{
                            backgroundColor: 'primary-300',
                            textColor: 'common-white',
                          }}
                        >
                          {data.title}
                        </Button>
                      ),
                  )
                ) : (
                  <Select
                    list={blogCategories.map(({ data }) => data?.title)}
                    defaultValue={activeCategory || allCategoriesText}
                    onChange={e => handleCategoryClick(e.target.value)}
                  />
                )}
              </Flex>
              {isDesktop && (
                <Flex flexDirection="column" gap="24px">
                  <Text textStyle="lg" fontWeight="semiBold">
                    Sign up for our newsletter
                  </Text>
                  <Form
                    formId="Subscribe Form | c9325cee-6d27-41b9-9b9d-731d8cf0cf0e"
                    variant="Stacked"
                    submitButtonText="Subscribe"
                    trackingSchema={{
                      location: typename,
                    }}
                  />
                </Flex>
              )}
            </Flex>
            {posts && (
              <Flex flexDirection="column" gap="30px">
                <Text textStyle="lg" fontWeight="semiBold">
                  Latest Posts
                </Text>
                {postsToDisplay.length ? (
                  <>
                    <Grid gap="30px" gridTemplateColumns="1fr" sm={{ gridTemplateColumns: 'repeat(2, 1fr)' }}>
                      {postsToDisplay.slice(postOffset, postOffset + postsPerPage).map(
                        ({ data, id }) =>
                          data && (
                            <EntryCard
                              key={id}
                              headingType={blogCardsHeadingType}
                              {...(data as CardProps)}
                              css={`
                                margin: 0 auto;
                              `}
                            />
                          ),
                      )}
                    </Grid>
                    <NumberedPagination
                      activePage={activePage}
                      pageCount={Math.ceil(postsToDisplay?.length / postsPerPage) || 1}
                      setActivePage={setActivePage}
                    />
                  </>
                ) : (
                  <Text textAlign="center">No posts found under {activeCategory}</Text>
                )}
              </Flex>
            )}
          </Grid>
        </Container>
      </Section>
      <ExploreAuthors currentAuthor={authorName || ''} />
    </>
  );
};

export default AuthorDetail;
