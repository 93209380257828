import { Builder, BuilderComponent, builder } from '@builder.io/react';
import { graphql } from 'gatsby';
import { Suspense } from 'react';
import customComponentsRegistration, { richTextModels } from 'templates/componentGenerator';

import ModalContainer from 'molecules/Modal/Modal';

import Footer from 'components/Footer/Footer';
import Header from 'components/Header/Header';
import Main from 'components/Main';
import type { LegalLink } from 'components/Sidebar/Variations/Legal';
import SEO from 'components/seo';

import type { Builder as BuilderProps } from 'graphqlTypes';
import type { FC } from 'react';
import type { IHead } from 'templates/BuilderPage';

builder.init(process.env.GATSBY_BUILDER_KEY || '');

interface LegalQuery extends Omit<BuilderProps, 'legal'> {
  legal: { content: { data: LegalLink } }[];
}

const PostTemplate = (query: { data: { allBuilderModels: LegalQuery } }) => {
  const {
    data: {
      allBuilderModels: {
        oneLegal,
        oneGlobalBanner,
        legal: allLegalPages,
        oneComponentHeader: header,
        oneComponentFooter: footer,
        hiddenFormFields,
      },
    },
  } = query;

  const legal = oneLegal?.content;
  const legalLinkTree =
    allLegalPages &&
    allLegalPages.map(({ content }) => ({
      id: content?.data?.id,
      title: content?.data?.title,
      url: content?.data?.url,
    }));

  const { banner } = { ...oneLegal?.data };
  const globalBanner = oneGlobalBanner?.data;

  const isCustomBanner = banner?.customBanner === true;
  const isGlobalBanner = globalBanner?.showBanner === true;
  const isHideBanner = banner?.hideBanner === true;

  customComponentsRegistration();

  return (
    <Suspense fallback>
      {header?.content && (
        <Header
          {...header.content.data}
          banner={(isCustomBanner && !isHideBanner && banner) || (isGlobalBanner && !isHideBanner && globalBanner)}
        />
      )}
      <Main>
        <BuilderComponent
          model="legal"
          content={legal}
          data={{
            template: 'legal',
            legalPages: JSON.stringify(legalLinkTree),
            hiddenFields: JSON.stringify({ hiddenFormFields }),
          }}
          options={{ includeRefs: true, cachebust: true }}
        />
      </Main>
      {footer?.content && <Footer isDark={false} content={footer.content.data} />}
      <ModalContainer />
    </Suspense>
  );
};

export default PostTemplate;

export const Head: FC<IHead> = ({
  data: {
    allBuilderModels: { oneLegal },
  },
  location,
}) => (
  <>
    {oneLegal && (
      <SEO
        title={oneLegal?.data?.title}
        description={oneLegal?.data?.description}
        image={oneLegal?.data?.featuredImage}
        location={location}
        indexable={!oneLegal?.data?.noIndex}
      />
    )}
  </>
);

export const postQuery = graphql`
  query getLegalPages($path: String!) {
    allBuilderModels {
      oneLegal(target: { urlPath: $path }, options: { includeRefs: true, cachebust: true }) {
        content
        data {
          title
          description
          featuredImage
          noIndex
          banner
        }
      }
      legal {
        content
      }
      oneGlobalBanner(
        options: { includeRefs: true, cachebust: true }
        target: { id: "b241c59d043b4cc7b0f608356bea42ef" }
      ) {
        data {
          showBanner
          body
          button
          heading
          includeIcon
        }
      }
      oneComponentHeader(
        options: { includeRefs: true, cachebust: true }
        target: { id: "8fe1129198a9478c9cc7346a40a63805" }
      ) {
        content
      }
      oneComponentFooter(
        options: { includeRefs: true, cachebust: true }
        target: { id: "a7c491a16336403c94432e7b3ebb419b" }
      ) {
        content
      }
      hiddenFormFields {
        data {
          hiddenFormField
        }
      }
    }
  }
`;

Builder.register('insertMenu', {
  name: 'Legal Components',
  items: richTextModels.map(model => ({ name: model.config.name })),
});
