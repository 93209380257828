import Icon from 'atoms/Icons/Icon';

import Flex from 'quarks/Flex';
import Image from 'quarks/Image';
import Text from 'quarks/Text';

import type { ReportCardTypes } from 'components/Cards/ReportCard/reportCardConfigTypes';

import { doodleGenerator } from 'utils/doodleGenerator';

import type { FC } from 'react';

const ReportCard: FC<ReportCardTypes> = ({ heading, features, ...props }) => {
  const breadDoodle = doodleGenerator('doodlegroup');

  return (
    <Flex
      position="relative"
      flexDirection="column"
      borderRadius="12px"
      gap="24px"
      paddingAll={24}
      backgroundColor="primary-700"
      overflowY="hidden"
      overflowX="hidden"
      xl={{ maxWidth: '370px' }}
      {...props}
    >
      {heading && (
        <Text textColor="common-white" textStyle="lg" sm={{ textStyle: 'xl' }} fontWeight="bold">
          {heading}
        </Text>
      )}
      {features && (
        <Flex flexDirection="column" gap="16px">
          {features?.map(({ feature }) => (
            <Flex gap="8px" key={feature}>
              <Icon id="check-blue-50" size={20} />
              <Text
                textColor="gray-100"
                textStyle="md"
                fontWeight="medium"
                sm={{ textStyle: 'lg', fontWeight: 'medium' }}
              >
                {feature}
              </Text>
            </Flex>
          ))}
        </Flex>
      )}
      {breadDoodle && (
        <Image
          image={breadDoodle}
          alt="Plate-Doodle"
          position="absolute"
          transform="rotate(190deg)"
          bottom="-130px"
          left="150px"
          opacity="0.3"
          zIndex={10}
          sm={{ left: '350px' }}
          md={{ left: '480px' }}
          xl={{ left: '150px' }}
        />
      )}
    </Flex>
  );
};

export default ReportCard;

ReportCard.defaultProps = {};
