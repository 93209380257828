/* eslint-disable @typescript-eslint/ban-ts-comment */
const formatValueForHubspot = (data: { [x: string]: any }) =>
  Object.keys(data).map(item => ({
    name: item,
    value: Array.isArray(data[item]) ? data[item].join(';') : data[item],
  }));

const submitHubspotForm = async (data: any, portalId: any, guid: any) => {
  const formattedData: { name: string; value: string }[] = formatValueForHubspot(data);

  try {
    const res = await fetch('/api/form-submit', {
      method: 'POST',
      body: JSON.stringify({
        fields: formattedData.filter(item => item.name !== 'captchaToken'),
        params: {
          portalId,
          guid,
          pageName: document.title,
          pageUri: window.location.href,
          captchaToken: formattedData.find(item => item.name === 'captchaToken')?.value,
        },
      }),
      headers: {
        'content-type': 'application/json',
      },
    });

    // @ts-ignore
    if (window && window.woopra) {
      const formData: any = { ...data };
      const { captchaToken: _, ...woopraData } = formData;
      // @ts-ignore
      window.woopra.track('Woopra form submission', { ...woopraData });
    }

    return res;
  } catch (error) {
    console.error(error);
  }
};

export default submitHubspotForm;
