import { iconValues } from 'atoms/Icons/Icon.types';

import type { BasicProps } from 'quarks/interpolations/basic';

import type { Writeable } from 'utils/typeUtils';

export interface FeaturesCardProps extends BasicProps {
  /**
   * Used as an ID, must be unique and is required
   */
  internalName?: string;
  /**
   * Determines the text for the heading
   */
  heading?: string;
  /**
   * Determines the text for the subheading
   */
  subheading?: string;
  /**
   * If selected Determines what Icon gets displayed.
   */
  icon?: string;
  /**
   * Determines color scheme if Background is dark or not.
   */
  backgroundIsDark?: boolean;
  /**
   * Determines to add gradient or not.
   */
  isGradientEnabled?: boolean;
}
type removeReadOnlyModifier = Writeable<typeof iconValues>;

export const featuresCardBuilderDataField = [
  {
    name: 'featuresCards',
    type: 'list',
    subFields: [
      { name: 'internalName', type: 'text', helperText: 'Used as an ID, must be unique.', required: true },
      { name: 'heading', type: 'text' },
      { name: 'subheading', type: 'richText' },
      { name: 'isGradientEnabled', type: 'boolean' },
      { name: 'icon', type: 'text', enum: iconValues as removeReadOnlyModifier },
    ],
    showIf: (options: { get: (arg0: string) => string }) => options.get('cardContentType') === 'Features Cards',
  },
];
