import SectionBuilderConfig from 'molecules/Section/Section.config';

const FAQBuilderConfig = {
  name: 'FAQ',
  inputs: [
    {
      name: 'accordions',
      type: 'list',
      subFields: [
        { name: 'label', type: 'text' },
        { name: 'headingType', type: 'text', enum: ['h2', 'h3', 'h4', 'h5', 'h6'] },
        { name: 'itemHeadingType', type: 'text', enum: ['h2', 'h3', 'h4', 'h5', 'h6'] },
        { name: 'items', type: 'list', subFields: [{ name: 'item', type: 'reference', model: 'accordion-item' }] },
      ],
    },
    ...SectionBuilderConfig.inputs,
  ],
};

export default FAQBuilderConfig;
