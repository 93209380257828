import { useMediaQuery } from '@mui/material';
import { type FC, useState } from 'react';

import { media } from 'atoms/breakpoints/breakpoints';
import container from 'atoms/spacing/containers';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import type { Url } from 'quarks/Image';
import Image from 'quarks/Image';

import LottieAnimation from 'molecules/LottieAnimation/LottieAnimation';
import Section from 'molecules/Section/Section';

import ComponentButton from 'components/ComponentButton/ComponentButton';
import ButtonWrapper from 'components/ComponentButton/buttonWrapper';
import HeadingFrames from 'components/Hero/HeroHomepage/HeadingFrames';
import type { HeroHomepageProps } from 'components/Hero/HeroStandard/heroTypesAndData';

import doodleGenerator from 'utils/doodleGenerator';
import RichTextParser from 'utils/richTextParser';

import type { Builder_ComponentButton } from 'graphqlTypes';

const homepageLottie = {
  file: 'https://cdn.builder.io/o/assets%2F79098c1284e04893b7e8de5c66f75fd4%2Ff7ac3ab7272f47a4a2651ae6d9f268bf?alt=media&token=64388eda-6ec9-4e63-9de7-047e62ce3e32&apiKey=79098c1284e04893b7e8de5c66f75fd4',
  placeholder: '/images/homepage-lottie-placeholder.png',
  mobile: '/images/homepage-lottie-mobile-image.png',
} as const;

const ComponentHero: FC<HeroHomepageProps> = ({
  subheading,
  buttons,
  sectionId,
  animationText,
  collapseBottomPadding,
  staticTextPart1,
  staticTextPart2,
  staticTextPart3,
}) => {
  const [isLottiePlaying, setIsLottiePlaying] = useState(false);

  const isTablet = useMediaQuery(media.sm);
  const isDesktop = useMediaQuery(media.xl);

  const sectionBackground = () => {
    if (isDesktop) {
      return 'darkConcaveHalf';
    }
    if (isTablet) {
      return 'darkConcaveHalfMobile';
    }

    return 'gray-900';
  };

  return (
    <Section
      __typename="ComponentHomepageHero"
      id={sectionId}
      background={sectionBackground()}
      css={`
        ${media.xl} {
          & > div {
            padding-bottom: ${collapseBottomPadding && 24};
          }
        }
      `}
    >
      <Container position="relative" paddingTop={72}>
        <Image
          image={doodleGenerator('doodle') as Url}
          alt="doodle"
          width="37%"
          right="-21%"
          top="36%"
          position="absolute"
          visibility="hidden"
          transform="rotate(348deg)"
          zIndex={-1}
          sm={{
            visibility: 'visible',
          }}
          xl={{
            width: '29%',
            right: '-28%',
            top: '10%',
          }}
        />
        <Image
          image={doodleGenerator('cutlery') as Url}
          alt="doodle"
          width="66%"
          left="-40%"
          bottom="1%"
          position="absolute"
          visibility="hidden"
          zIndex={-1}
          sm={{
            visibility: 'visible',
          }}
          xl={{
            width: '56%',
            left: '-35%',
            top: '14%',
          }}
        />
        <Flex
          flexDirection="column"
          justifyContent="center"
          gap="24px"
          sm={{
            textAlign: 'center',
          }}
          md={{
            gap: '48px',
          }}
          xl={{
            flexDirection: 'column',
            justifyContent: 'space-around',
            gap: '64px',
          }}
        >
          <Container>
            <Flex flexDirection="column" gap="24px" maxWidth={container.lg} marginY={0} marginX="auto">
              <HeadingFrames
                animationText={animationText}
                isLottiePlaying={isLottiePlaying}
                staticTextPart1={staticTextPart1}
                staticTextPart2={staticTextPart2}
                staticTextPart3={staticTextPart3}
              />
              {subheading && (
                <Container
                  textColor="gray-50"
                  fontSize="textLg"
                  fontWeight="medium"
                  maxWidth={container.md}
                  marginX="auto"
                  md={{ fontSize: 'textXl' }}
                >
                  {RichTextParser(subheading)}
                </Container>
              )}
            </Flex>
            {buttons && (
              <Flex justifyContent="center" width="100%" marginTop={32} flexDirection="column">
                <ButtonWrapper>
                  {buttons?.map((button, index) => (
                    <ComponentButton
                      buttonType={button?.buttonType || 'callToAction'}
                      key={button.button?.value?.id || index}
                      {...(button as Builder_ComponentButton)}
                    />
                  ))}
                </ButtonWrapper>
                <Container md={{ display: 'none' }} marginTop={32}>
                  <Image image={homepageLottie.mobile} alt="Lottie" maxWidth="100%" />
                </Container>
                <Container position="relative" display="none" md={{ display: 'block' }} marginTop={32}>
                  <LottieAnimation
                    ariaLabel="Lottie Animation"
                    lottieAnimation={homepageLottie.file}
                    placeholderImage={homepageLottie.placeholder}
                    setIsPlaying={setIsLottiePlaying}
                    isHomePage
                  />
                </Container>
              </Flex>
            )}
          </Container>
        </Flex>
      </Container>
    </Section>
  );
};

export default ComponentHero;
