import Marquee from 'react-fast-marquee';

import CarouselCard from 'components/IntegrationsCarousel/Components/CarouselCard';

import type { Builder_Integration } from 'graphqlTypes';
import type { FC } from 'react';

interface MarqueeRowProps {
  array?: { integration: { value: Builder_Integration } }[];
  direction: 'left' | 'right';
}

const MarqueeRow: FC<MarqueeRowProps> = ({ array, direction }) => (
  <Marquee
    gradient
    gradientWidth={100}
    gradientColor={[245, 245, 255]}
    speed={25}
    direction={direction}
    pauseOnHover
    css={`
      padding: 10px 0;
      overflow-y: hidden;
      .marquee {
        justify-content: space-between;
      }
      .initial-child-container {
        justify-content: space-evenly;
        width: 100%;
      }
    `}
  >
    {array?.map(integration => (
      <CarouselCard key={integration?.integration?.value?.id} integration={integration?.integration} />
    ))}
  </Marquee>
);

export default MarqueeRow;
