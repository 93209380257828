import Marquee from 'react-fast-marquee';

import GhostCard from 'components/IntegrationsCarousel/Components/GhostCard';

import type { FC } from 'react';

interface GhostMarqueeRowProps {
  direction: 'left' | 'right';
  position: 'top' | 'bottom';
}

const GhostMarqueeRow: FC<GhostMarqueeRowProps> = ({ direction, position }) => {
  const n = 8;

  return (
    <Marquee gradient gradientWidth={100} gradientColor={[245, 245, 255]} speed={25} direction={direction}>
      {[...Array(n)].map(() => (
        <GhostCard position={position} key={Math.random()} />
      ))}
    </Marquee>
  );
};

export default GhostMarqueeRow;
