import Flex from 'quarks/Flex';

import type { HeroPricingCardsProps } from 'components/HeroPricing/heroTypesAndData';
import PricingCardDeck from 'components/PricingCardDeck/PricingCardDeck';

import type { FC } from 'react';

const HeroPricingCards: FC<HeroPricingCardsProps> = ({ isAnnual, isCollapsible, plans }) => (
  <Flex
    gap="24px"
    flexDirection="column"
    justifyContent="center"
    alignItems="flex-start"
    alignSelf="center"
    xl={{ flexDirection: 'row', gap: '32px', alignItems: 'stretch' }}
  >
    {plans &&
      plans?.length > 0 &&
      plans.map(({ plan }, index) => (
        <PricingCardDeck
          key={plan?.id || index}
          {...plan?.value?.data}
          isAnnual={isAnnual}
          collapsible={isCollapsible}
        />
      ))}
  </Flex>
);

export default HeroPricingCards;
