import container from 'atoms/spacing/containers';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import type { FlexProps } from 'quarks/interpolations/flex';

import { sectionSizing, tabletMaxWidth } from 'molecules/Section/Section';

import type { FC, ReactNode } from 'react';

interface WrapperProps extends FlexProps {
  children: ReactNode;
  id?: string;
}

const Wrapper: FC<WrapperProps> = ({ id, children, backgroundColor, ...props }) => (
  <Flex
    id={id}
    position="relative"
    contain="content"
    justifyContent="center"
    width="100%"
    backgroundColor={backgroundColor}
    {...props}
  >
    <Container
      {...sectionSizing}
      md={{ maxWidth: tabletMaxWidth }}
      maxWidth={container.xs}
      marginX="auto"
      css={`
        padding-top: 0;
        padding-bottom: 0;
      `}
    >
      {children}
    </Container>
  </Flex>
);
export default Wrapper;
