import { media } from 'atoms/breakpoints/breakpoints';
import gradient from 'atoms/colors/gradients';

import Container from 'quarks/Container';
import Image from 'quarks/Image';

import Section from 'molecules/Section/Section';
import Wrapper from 'molecules/Wrapper/Wrapper';

import type { ReportCardTypes } from 'components/Cards/ReportCard/reportCardConfigTypes';
import type { ForecastCardTypes, RevenueCardTypes } from 'components/Cards/RevenueCard/revenueCardConfigTypes';
import CalculatorCopy from 'components/ForecastCalculator/CalculatorCopy';
import type { ToolTipFields } from 'components/ForecastCalculator/CalculatorDataAndTypes';
import ForecastCalculator from 'components/ForecastCalculator/ForecastCalculator';
import type {
  CalculatorCopyProps,
  CalculatorResultsTypes,
} from 'components/ForecastCalculator/componentCalculatorTypesConfig';
import { heroDoodleSpecs } from 'components/Hero/HeroStandard/heroTypesAndData';

import doodleGenerator from 'utils/doodleGenerator';

import type { FC } from 'react';

interface ComponentCalculatorProps extends CalculatorCopyProps {
  hourlyField?: ToolTipFields;
  targetLaborCostField?: ToolTipFields;
  sectionId?: string;
  resultsCopy?: CalculatorResultsTypes;
  revenueCard?: RevenueCardTypes;
  forecastCard?: ForecastCardTypes;
  reportCard?: ReportCardTypes;
}

const ComponentCalculator: FC<ComponentCalculatorProps> = ({
  eyebrow,
  heading,
  hourlyField,
  resultsCopy,
  revenueCard,
  forecastCard,
  reportCard,
  sectionId,
  subheading,
  targetLaborCostField,
}) => {
  const hat = doodleGenerator('chefshat');
  const doodle = doodleGenerator('doodle');

  return (
    <Section
      __typename="Component Calculator"
      id={sectionId}
      css={`
        & > div {
          padding-left: 0;
          padding-right: 0;
          padding-bottom: 0;
          max-width: unset;
        }
      `}
    >
      <Wrapper backgroundColor="gray-100">
        <CalculatorCopy
          heading={heading}
          eyebrow={eyebrow}
          subheading={subheading}
          marginBottom={24}
          md={{ marginBottom: 32 }}
        />
      </Wrapper>
      <Container
        css={`
          &:after {
            content: '';
            display: block;
            z-index: -2;
            height: 100%;
            width: 100%;
            background: ${gradient.radialGradient.concaveMobile};
            position: absolute;
            top: 0;
            left: 0;
            ${media.xl} {
              height: 51%;
              background: ${gradient.radialGradient.concave};
            }
          }
        `}
      >
        <ForecastCalculator
          reportCard={reportCard}
          resultsCopy={resultsCopy}
          revenueCard={revenueCard}
          forecastCard={forecastCard}
          hourlyField={hourlyField}
          targetLaborCostField={targetLaborCostField}
        />
      </Container>
      {hat && (
        <Image
          position="absolute"
          transform="matrix(-0.95, 0.09, 0.07, 0.95, 0, 0);"
          width={heroDoodleSpecs.standard.centeredDoodle.width}
          height={heroDoodleSpecs.standard.centeredDoodle.height}
          bottom="auto"
          top={heroDoodleSpecs.standard.centeredDoodle.top}
          left={heroDoodleSpecs.standard.centeredDoodle.left}
          image={hat}
          alt="hat-doodle"
          sm={{
            width: heroDoodleSpecs.small.centeredDoodle.width,
            height: heroDoodleSpecs.small.centeredDoodle.height,
            left: '-80px',
          }}
          md={{
            left: '-150px',
            width: heroDoodleSpecs.medium.centeredDoodle.width,
            height: heroDoodleSpecs.medium.centeredDoodle.height,
            bottom: 'auto',
          }}
          xl={{
            width: '600px',
            height: '440px',
            left: '-250px',
            top: '170px',
          }}
        />
      )}
      {doodle && (
        <Image
          position="absolute"
          image={doodle}
          alt="second-doodle"
          display="none"
          lg={{
            display: 'block',
            top: '300px',
            right: '-100px',
            width: '346px',
            height: '146px',
            transform: 'rotate(-21.07deg)',
          }}
          xl={{ width: '400px', height: '225px', right: '0px', top: '300px' }}
        />
      )}
    </Section>
  );
};

export default ComponentCalculator;
