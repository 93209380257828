import { navigate } from 'gatsby';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

import BasicForm from 'components/HsForm/Variants/HsBasicForm';
import SingleInputForm from 'components/HsForm/Variants/HsSingleInputForm';
import StackedForm from 'components/HsForm/Variants/HsStackedForm';
import type { ComponentFormProps, hubForm } from 'components/HsForm/types';
import fetchHubspotForm from 'components/HsForm/utils/fetchHubspotForm';
import fieldGenerator from 'components/HsForm/utils/fieldGenerator';
import submitHubspotForm from 'components/HsForm/utils/submitHubspotForm';

import type { FC } from 'react';
import type ReCAPTCHA from 'react-google-recaptcha';

const CustomHSForm: FC<ComponentFormProps> = ({
  formId,
  variant,
  onClose,
  formHeading: heading,
  formHeadingType: headingType,
  subHeading: subHeading,
  successImage,
  submitButtonText,
  isDark,
  trackingSchema,
}) => {
  const hsFormId = formId?.split('|')?.pop()?.trim();
  const captchaRef = useRef<ReCAPTCHA>(null);

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors, isValid },
    reset,
  } = useForm({
    mode: 'all',
  });

  const [loading, setLoading] = useState<boolean>(true);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [formData, setFormData] = useState<hubForm | null>();

  useEffect(() => {
    const fetchFormData = async () => {
      if (!hsFormId) {
        console.error('[HubForm] You must pass a HubForm ID.');

        return;
      }
      const data: hubForm = await fetchHubspotForm(hsFormId);
      if (!data) {
        console.error('[HubForm] No form data received from API');

        return;
      }
      setFormData(data);
      setLoading(false);
    };

    fetchFormData().catch(error => {
      console.error(`[HubForm] Could not fetch data for form with ID: ${hsFormId}`, error);
      setLoading(false);
    });
  }, [hsFormId]);

  const formFields = formData?.formFieldGroups && fieldGenerator(formData.formFieldGroups, control, errors);

  const formProps = {
    heading,
    headingType,
    subHeading,
    formFields,
    submitButtonText,
    variant,
    loading,
    isDark,
    captchaRef,
    isValid,
  };

  const formSubmitAnalytics = () => {
    window?.analytics?.track(
      'Form Submitted',
      {
        label: `${formProps.heading} | ${hsFormId}` || 'Unnamed Form',
        location: trackingSchema?.location ? trackingSchema.location : 'Standalone Form Component',
        url: window.location.href,
      },
      {
        context: {
          traits: window.analytics.user().traits(),
        },
      },
    );
  };

  const onSubmit = async (data: any) => {
    if (isValid) {
      const captchaToken = captchaRef?.current && (await captchaRef.current.executeAsync());
      const res = await submitHubspotForm(
        {
          ...data,
          captchaToken,
        },
        formData?.portalId,
        formData?.guid,
      );

      const success = res?.status === 200;
      if (success) {
        formSubmitAnalytics();
        reset();
        formData && formData.redirect ? navigate(formData.redirect) : setSubmitted(true);
      } else {
        console.error('[HubForm] Error submitting form');
      }
    } else {
      console.error('[HubForm] form fields are not Valid');
    }
  };

  switch (variant) {
    case 'Basic':
      return (
        <BasicForm
          handleFormSubmit={handleSubmit(onSubmit)}
          onClose={onClose || (() => null)}
          isSubmitSuccessful={submitted}
          isSubmitting={isSubmitting}
          postSubmitMessage={formData?.inlineMessage}
          successImage={successImage}
          {...formProps}
        />
      );
    case 'Single Field':
      return (
        <SingleInputForm
          isSubmitSuccessful={submitted}
          isSubmitting={isSubmitting}
          handleFormSubmit={handleSubmit(onSubmit)}
          postSubmitMessage={formData?.inlineMessage}
          {...formProps}
        />
      );
    case 'Stacked':
      return (
        <StackedForm
          isSubmitSuccessful={submitted}
          isSubmitting={isSubmitting}
          handleFormSubmit={handleSubmit(onSubmit)}
          postSubmitMessage={formData?.inlineMessage}
          {...formProps}
        />
      );
    default:
      return null;
  }
};

export default CustomHSForm;
