import { useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';

import { media } from 'atoms/breakpoints/breakpoints';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Grid from 'quarks/Grid';
import Text from 'quarks/Text';

import Button, { handleButtonTracking } from 'molecules/Button/Button';
import NumberedPagination from 'molecules/Pagination/NumberedPagination';
import Section from 'molecules/Section/Section';
import Select from 'molecules/Select/Select';

import type { BlogCategory, BlogListingProps, BlogPost } from 'components/Blog/BlogListing.config';
import type { CardProps } from 'components/Cards/EntryCard/EntryCard';
import EntryCard from 'components/Cards/EntryCard/EntryCard';
// import Form from 'components/HsForm/HsForm';

import useBlogCategories from 'utils/hooks/useBlogCategories';
import useBlogPosts from 'utils/hooks/useBlogPosts';

import type { FC } from 'react';

const allCategoriesText = 'View All';

const BlogListing: FC<BlogListingProps> = ({
  postsPerPage,
  featureCardHeadingType,
  blogCardsHeadingType,
  sectionId,
}) => {
  const posts: BlogPost[] = useBlogPosts().sort((a: BlogPost, b: BlogPost) => {
    if (!a.data.publishDate || !b.data.publishDate) {
      return 0;
    }

    return new Date(b.data.publishDate).valueOf() - new Date(a.data.publishDate).valueOf();
  });
  const blogCategories: BlogCategory[] = [
    { data: { title: allCategoriesText, slug: '/' }, id: '0' },
    ...useBlogCategories(),
  ];

  const isDesktop = useMediaQuery(media.lg);
  const [activeCategory, setActiveCategory] = useState(allCategoriesText);
  const [activePage, setActivePage] = useState(1);
  const [postOffset, setOffset] = useState(0);
  const [postsToDisplay, setPostsToDisplay] = useState(posts);

  useEffect(() => {
    setOffset(postsPerPage * (activePage - 1));
  }, [activePage, postsPerPage]);

  const typename = 'BlogListing';

  const handleCategoryClick = (id: string) => {
    setActivePage(1);
    setActiveCategory(id);
    id === allCategoriesText
      ? setPostsToDisplay(posts)
      : setPostsToDisplay(posts.filter(post => post?.data?.category?.value?.data?.title === id));

    const trackingScheme = {
      label: posts.filter(post => post?.data?.category?.value?.data?.title === id)[0]?.data?.category.value.data.title,
      location: typename,
    };

    handleButtonTracking(trackingScheme);
  };

  return (
    <Section __typename={typename} background="blogListing" sectionType="listing" id={sectionId}>
      <Container>
        {posts?.[0]?.data && (
          <Flex paddingBottom={96} justifyContent="center">
            <EntryCard variant="featured" headingType={featureCardHeadingType} {...(posts[0].data as CardProps)} />
          </Flex>
        )}
        <Grid gridTemplateColumns="1fr" lg={{ gridTemplateColumns: '1fr 2fr' }}>
          <Flex
            flexDirection="column"
            gap="20px"
            paddingBottom={12}
            marginBottom={20}
            position="sticky"
            top="73px"
            alignSelf="start"
            zIndex={1}
            backgroundColor="common-white"
            css={`
              margin-left: -10px;
              margin-right: -10px;
              padding-left: 10px;
              padding-right: 10px;
              ${media.lg} {
                margin: 0;
                padding-left: 0;
                padding-right: 96px;
                padding-bottom: 0;
              }
            `}
            lg={{ top: '80px' }}
          >
            <Text textStyle="lg" fontWeight="semiBold">
              Blog Categories
            </Text>
            <Flex flexDirection="column" gap="4px">
              {isDesktop ? (
                blogCategories?.map(
                  ({ data, id }) =>
                    data?.title && (
                      <Button
                        key={id}
                        justifyContent="flex-start"
                        width="100%"
                        paddingY={10}
                        paddingX={14}
                        borderRadius="6px"
                        backgroundColor={activeCategory === data.title ? 'primary-50' : 'common-transparent'}
                        fontSize="textMd"
                        lineHeight="textMd"
                        textColor={activeCategory === data.title ? 'primary-900' : 'gray-700'}
                        fontWeight="medium"
                        boxShadow="none"
                        textAlign="left"
                        onClick={() => handleCategoryClick(data.title || allCategoriesText)}
                        hover={{
                          backgroundColor: 'primary-300',
                          textColor: 'common-white',
                        }}
                        focusVisible={{
                          borderColor: 'primary-300',
                          boxShadow: 'focused',
                        }}
                      >
                        {data.title}
                      </Button>
                    ),
                )
              ) : (
                <Select
                  list={blogCategories.map(({ data }) => data?.title)}
                  defaultValue={activeCategory || allCategoriesText}
                  onChange={e => handleCategoryClick(e.target.value)}
                />
              )}
            </Flex>
            {/* {isDesktop && (
              <Flex flexDirection="column" gap="24px">
                <Text textStyle="lg" fontWeight="semiBold">
                  Sign up for our newsletter
                </Text>
                <Form
                  formId="Subscribe Form | c9325cee-6d27-41b9-9b9d-731d8cf0cf0e"
                  variant="Stacked"
                  submitButtonText="Subscribe"
                  trackingSchema={{
                    location: typename,
                  }}
                />
              </Flex>
            )} */}
          </Flex>
          {posts && (
            <Flex flexDirection="column" gap="30px">
              <Text textStyle="lg" fontWeight="semiBold">
                Latest Posts
              </Text>
              {postsToDisplay.length ? (
                <>
                  <Grid gap="30px" gridTemplateColumns="1fr" sm={{ gridTemplateColumns: 'repeat(2, 1fr)' }}>
                    {postsToDisplay.slice(postOffset, postOffset + postsPerPage).map(
                      ({ data, id }) =>
                        data && (
                          <EntryCard
                            key={id}
                            headingType={blogCardsHeadingType}
                            {...(data as CardProps)}
                            css={`
                              margin: 0 auto;
                            `}
                          />
                        ),
                    )}
                  </Grid>{' '}
                  <NumberedPagination
                    activePage={activePage}
                    pageCount={Math.ceil(postsToDisplay?.length / postsPerPage) || 1}
                    setActivePage={setActivePage}
                  />
                </>
              ) : (
                <Text textAlign="center">No posts found under {activeCategory}</Text>
              )}
            </Flex>
          )}
        </Grid>
      </Container>
    </Section>
  );
};

export default BlogListing;
