/* eslint-disable */
const formatDate = function (dateString: string): string {
  if (isNaN(Date.parse(dateString))) {
    console.log('Invalid date string passed to the function');

    return '';
  }
  const date = new Date(dateString);
  const options = { year: 'numeric', month: 'long', day: 'numeric' };

  // @ts-ignore
  return date.toLocaleDateString('en-US', options);
};

export default formatDate;
