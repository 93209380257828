/* eslint-disable react/no-array-index-key */
import { useEffect, useState } from 'react';

import type { iconIds } from 'atoms/Icons/Icon.types';

import Flex from 'quarks/Flex';
import Text from 'quarks/Text';
import type { BasicProps } from 'quarks/interpolations/basic';

import ComponentButton from 'components/ComponentButton/ComponentButton';
import SubMenuItemMedia from 'components/Header/SubMenuItemMedia';
import SubMenuItemNavigation from 'components/Header/SubMenuItemNavigation';

import type { Builder_SubMenu } from 'graphqlTypes';
import type { FC } from 'react';

interface MobileSubMenuProps extends BasicProps {
  menus: Array<{ subMenu: { value: Builder_SubMenu } }>;
  parentLabel: string;
}

interface subMenuItemProps extends BasicProps {
  title?: string;
  description?: string;
  icon?: iconIds;
  image?: string;
  imageCaption?: string;
  link?: string;
}

const MobileNavMenu: FC<MobileSubMenuProps> = ({ menus, parentLabel }) => {
  const [opacity, setOpacity] = useState(0);
  useEffect(() => setOpacity(1), []);

  const lowerCasedParentLabel = parentLabel.toLowerCase();
  const retrieveSubMenuData = menus.map(menu => menu.subMenu.value.data);

  return (
    <Flex flexDirection="column" gap="32px">
      {retrieveSubMenuData.map((subMenu, index) => {
        if (!subMenu) {
          return;
        }
        const isSubMenuMedia = subMenu.subMenuType === 'Media Link';
        const subLabelEqualsParentLabel = lowerCasedParentLabel === subMenu.label?.toLowerCase();

        return (
          <Flex
            marginTop={index === 0 ? 24 : 0}
            gap={subLabelEqualsParentLabel ? '0px' : '30px'}
            flexDirection="column"
            key={`${subMenu.label}-${index}`}
            backgroundColor={isSubMenuMedia ? 'gray-50' : 'common-transparent'}
            paddingTop={isSubMenuMedia ? 12 : 0}
            paddingBottom={isSubMenuMedia ? 24 : 0}
          >
            <Flex
              opacity={opacity}
              transition="opacity 500ms"
              justifyContent={subLabelEqualsParentLabel ? 'center' : 'space-between'}
              paddingX={isSubMenuMedia ? 12 : 16}
              position="relative"
              after={
                subLabelEqualsParentLabel
                  ? { content: '' }
                  : {
                      content: '',
                      position: 'absolute',
                      height: '1px',
                      width: '100%',
                      backgroundColor: 'gray-100',
                      bottom: '-12px',
                    }
              }
            >
              {!subLabelEqualsParentLabel && (
                <Text
                  textStyle="md"
                  textColor="gray-900"
                  fontWeight="semiBold"
                  md={{ textStyle: 'lg', fontWeight: 'bold' }}
                >
                  {subMenu.label}
                </Text>
              )}
              {subMenu.submenuCallToAction && !subMenu.submenuCallToAction.disabled && (
                <ComponentButton
                  buttonType="callToAction"
                  button={{
                    value: {
                      data: {
                        size: 'md',
                        hierarchy: 'Link Color',
                        endIcon: '->',
                        ...subMenu?.submenuCallToAction,
                      },
                    },
                  }}
                  size="md"
                  hierarchy="Link Color"
                  endIcon="->"
                  paddingTop={0}
                  {...subMenu.submenuCallToAction}
                />
              )}
            </Flex>
            <Flex
              as="ul"
              flexWrap="wrap"
              gap="16px"
              paddingX={isSubMenuMedia ? 14 : 16}
              css={`
                list-style-type: none;
                scrollbar-width: none;
                ::-webkit-scrollbar {
                  display: none;
                }
              `}
              md={{ gap: '30px 16px' }}
            >
              {subMenu.subMenuItems.map(({ subMenuItem }: { subMenuItem: subMenuItemProps }) => {
                if (!subMenuItem) {
                  return;
                }

                return !isSubMenuMedia ? (
                  <SubMenuItemNavigation
                    key={`${subMenu.label}-${index}`}
                    {...subMenuItem}
                    opacity={opacity}
                    transition="opacity 500ms"
                    width="360px"
                  />
                ) : (
                  <SubMenuItemMedia
                    key={`${subMenu.label}-${index}`}
                    {...subMenuItem}
                    opacity={opacity}
                    transition="opacity 500ms"
                  />
                );
              })}
            </Flex>
          </Flex>
        );
      })}
    </Flex>
  );
};

export default MobileNavMenu;
