import Icon from 'atoms/Icons/Icon';
import type { iconIds } from 'atoms/Icons/Icon.types';
import color from 'atoms/colors/colors';

import Flex from 'quarks/Flex';
import Image from 'quarks/Image';
import Link from 'quarks/Link';
import Text from 'quarks/Text';
import type { BasicProps } from 'quarks/interpolations/basic';

import doodleGenerator from 'utils/doodleGenerator';

import type { FC } from 'react';

interface CtaCardProps extends BasicProps {
  /**
   * Determines which icon gets displayed.
   */
  icon?: iconIds;
  /**
   * Card CTA link.
   */
  link?: string;
  /**
   * Card title
   */
  title?: string;
  /**
   * Card description
   */
  description?: string;
}

const CtaCard: FC<CtaCardProps> = ({ icon, title, description, link }) => {
  const doodle = doodleGenerator('tripleknotswirl');

  const imageProps = {
    opacity: '.2',
    width: '100%',
    position: 'absolute',
    alt: 'doodle',
    transform: 'rotate(31.531deg)',
  } as const;

  return (
    <Link
      href={link}
      maxWidth="370px"
      paddingAll={16}
      md={{
        paddingAll: 24,
      }}
      backgroundColor="gray-800"
      borderRadius="8px"
      position="relative"
      overflowX="hidden"
      overflowY="hidden"
      textColor="gray-50"
      css={`
        img {
          display: none;
        }
        :hover {
          background-color: ${color.primary[500]};
          img {
            display: block;
          }
        }
      `}
    >
      <Flex flexDirection="column" marginAll="auto" flexWrap="wrap" gap="24px" width="100%" height="100%">
        <Icon id="arrow-up-right" size={62} iconColor="common-white" />
        <Flex flexDirection="column" gap="16px">
          <Flex gap="8px" alignItems="center" lg={{ gap: '12px' }}>
            {icon && <Icon size={36} mdSize={32} id={icon} iconColor="common-white" />}
            {title && (
              <Text
                fontSize="displayXs"
                fontWeight="semiBold"
                lg={{
                  fontSize: 'displaySm',
                }}
              >
                {title}
              </Text>
            )}
          </Flex>
          {description && <Text textStyle="md">{description}</Text>}
        </Flex>
      </Flex>
      {doodle && (
        <>
          <Image image={doodle} {...imageProps} right="-30%" top="5%" />
          <Image image={doodle} {...imageProps} left="-55%" bottom="16%" />
        </>
      )}
    </Link>
  );
};

export default CtaCard;
