import { createContext, useContext, useEffect, useState } from 'react';

import type { Dispatch, FC, ReactNode, SetStateAction } from 'react';

interface ActiveContentProviderProps {
  /**
   * Passes the section content in as children.
   */
  children: ReactNode;
}

export const ActiveContentContext = createContext<
  [string, Dispatch<SetStateAction<string>>, Dispatch<SetStateAction<boolean>>]
>(['', () => null, () => null]);

export const ActiveContentProvider: FC<ActiveContentProviderProps> = ({ children }) => {
  const [id, setId] = useState('');
  const [activeId, setActiveId] = useState('');
  const [pause, setPause] = useState(false);

  useEffect(() => {
    !pause && setActiveId(id);
  }, [pause, id]);

  return <ActiveContentContext.Provider value={[activeId, setId, setPause]}>{children}</ActiveContentContext.Provider>;
};

const useActiveContent = () => useContext(ActiveContentContext);

export default useActiveContent;
