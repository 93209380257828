import SectionBuilderConfig from 'molecules/Section/Section.config';

import ComponentStatsBarBuilderConfig from 'components/Stats/StatsBar.config';

import { doodleField } from 'utils/doodleGenerator';

export const ComponentStatsBuilderConfig = {
  name: 'Stats',
  inputs: [
    { name: 'background', type: 'text', enum: ['White', 'Gray-100', 'Gray-900'], defaultValue: 'Gray-100' },
    { name: 'eyebrow', type: 'text', defaultValue: 'Eyebrow' },
    { name: 'heading', type: 'text', defaultValue: 'Heading' },
    { name: 'headingType', type: 'text', enum: ['h2', 'h3', 'h4', 'h5', 'h6'], defaultValue: 'h2' },
    {
      name: 'subheading',
      type: 'richText',
      defaultValue: '<p>This is a placeholder text. Should be kept relatively short.</p>',
    },
    ...doodleField,
    ...ComponentStatsBarBuilderConfig.inputs,
    ...SectionBuilderConfig.inputs,
  ],
};
export default ComponentStatsBuilderConfig;
