import { media } from 'atoms/breakpoints/breakpoints';
import container from 'atoms/spacing/containers';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Heading from 'quarks/Heading';
import Image from 'quarks/Image';

import Section from 'molecules/Section/Section';

import ComponentButton from 'components/ComponentButton/ComponentButton';
import ButtonWrapper from 'components/ComponentButton/buttonWrapper';
import { heroDoodleSpecs } from 'components/Hero/HeroStandard/heroTypesAndData';
import type { HeroProps } from 'components/Hero/HeroStandard/heroTypesAndData';

import colorizeText from 'utils/colorizeText';
import { doodleGenerator } from 'utils/doodleGenerator';
import RichTextParser from 'utils/richTextParser';

import type { Builder_ComponentButton } from 'graphqlTypes';
import type { FC } from 'react';

const ComponentHero: FC<HeroProps> = ({ background, heading, subheading, buttonGroup }) => {
  const cookHat = doodleGenerator('chefshat');
  const secondDoodle = doodleGenerator('doodle');

  return (
    <Section __typename="404-Hero" background={background}>
      <Container position="relative" paddingTop={64} md={{ paddingTop: 96 }}>
        <Flex flexDirection="column" justifyContent="center" alignItems="center" textAlign="center" gap="32px">
          <Flex flexDirection="column" gap="24px" maxWidth={container.md} marginX="auto" marginY={0}>
            <Container
              css={`
                > span {
                  font-size: 150px;
                  line-height: 130px;
                }
                ${media.md} {
                  > span {
                    font-size: 200px;
                    line-height: 230px;
                  }
                }
                ${media.lg} {
                  > span {
                    font-size: 250px;
                  }
                }
              `}
            >
              {colorizeText('404', false)}
            </Container>
            {heading && (
              <Heading
                as="h1"
                textColor="gray-900"
                textStyle="md"
                fontWeight="semiBold"
                md={{ textStyle: 'lg', fontWeight: 'semiBold' }}
                xl={{ textStyle: 'xl', fontWeight: 'semiBold' }}
              >
                {heading}
              </Heading>
            )}
            {subheading && (
              <Container textColor="gray-700" fontSize="textLg" fontWeight="medium" md={{ fontSize: 'textXl' }}>
                {RichTextParser(subheading)}
              </Container>
            )}
          </Flex>
          {buttonGroup && (
            <Flex justifyContent="center" width="100%">
              <ButtonWrapper>
                {buttonGroup?.map((button, index) => (
                  <ComponentButton
                    buttonType={button?.buttonType || 'callToAction'}
                    key={button.button?.value?.id || index}
                    {...(button as Builder_ComponentButton)}
                  />
                ))}
              </ButtonWrapper>
            </Flex>
          )}
        </Flex>
        {cookHat && (
          <Image
            image={cookHat}
            display="none"
            position="absolute"
            transform="matrix(-0.95, 0.09, 0.07, 0.95, 0, 0);"
            width={heroDoodleSpecs.standard.centeredDoodle.width}
            height={heroDoodleSpecs.standard.centeredDoodle.height}
            zIndex="-10"
            bottom="auto"
            top={heroDoodleSpecs.standard.centeredDoodle.top}
            left={heroDoodleSpecs.standard.centeredDoodle.left}
            alt="image-of-chefs-hat"
            sm={{
              display: 'block',
              width: heroDoodleSpecs.small.centeredDoodle.width,
              height: heroDoodleSpecs.small.centeredDoodle.height,
              left: '-180px',
            }}
            md={{
              left: heroDoodleSpecs.medium.centeredDoodle.left,
              width: heroDoodleSpecs.medium.centeredDoodle.width,
              height: heroDoodleSpecs.medium.centeredDoodle.height,
              bottom: 'auto',
            }}
            xl={{
              width: heroDoodleSpecs.xLarge.centeredDoodle.width,
              height: heroDoodleSpecs.xLarge.centeredDoodle.height,
              left: heroDoodleSpecs.xLarge.centeredDoodle.left,
              bottom: 'auto',
            }}
          />
        )}
        {secondDoodle && (
          <Image
            image={secondDoodle}
            position="absolute"
            zIndex="-10"
            alt="second-doodle"
            display="none"
            md={{
              display: 'block',
              top: '100px',
              right: '-200px',
              width: '346px',
              height: '146px',
              transform: 'rotate(-11.07deg)',
            }}
            xl={{ width: '542px', height: '225px', right: '-350px' }}
          />
        )}
      </Container>
    </Section>
  );
};

ComponentHero.defaultProps = {
  heading: 'Component Hero',
  subheading: '<p> Missing Subheading for Hero Component!</p>',
  background: 'White',
};

export default ComponentHero;
