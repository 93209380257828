import { useMediaQuery } from '@mui/material';
import { useState } from 'react';
import { useMeasure } from 'react-use';

import { media } from 'atoms/breakpoints/breakpoints';
import type { fontWeight } from 'atoms/typography/typography';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Grid from 'quarks/Grid';
import Image from 'quarks/Image';
import type { BasicProps } from 'quarks/interpolations/basic';

import Pagination, { paginationButtonProps } from 'molecules/Pagination/Pagination';
import Section from 'molecules/Section/Section';

import ComponentButton from 'components/ComponentButton/ComponentButton';
import type { ButtonTypes } from 'components/ComponentButton/buttonTypeConfig';
import ComponentHeading from 'components/Heading/Heading';
import NoImageQuote from 'components/QuoteSlider/NoImageQuote';
import StandardQuote from 'components/QuoteSlider/StandardQuote';

import doodleGenerator from 'utils/doodleGenerator';
import { getBackgroundIsDark } from 'utils/functions';

import type { CSSProps } from 'theme/getAppTheme';

import type { Builder_EntityQuote } from 'graphqlTypes';
import type { FC } from 'react';

type HeadingProps = {
  eyebrow: string;
  coloredEyebrow: boolean;
  heading: string;
  subheading?: string;
  subheadingFontWeight?: keyof typeof fontWeight;
  leftAlign?: boolean;
  buttonGroup?: ButtonTypes;
  collapseBottomPadding?: boolean;
};

type QuoteSliderProps = BasicProps & {
  background: string;
  useHeading: boolean;
  headingContent: HeadingProps;
  quoteType: string;
  quotes: Array<{ quote: { value: Builder_EntityQuote; id: string } }>;
  sectionId?: string;
};

const QuoteSlider: FC<QuoteSliderProps> = ({
  useHeading,
  headingContent,
  quoteType,
  background,
  quotes,
  sectionId,
}) => {
  const [activeIndex, setActive] = useState(0);
  const [ref, { width: quoteWidth }] = useMeasure<HTMLDivElement>();
  const isNoImageType = quoteType === 'No Image';

  const isTablet = useMediaQuery(media.md);

  const backgroundIsDark = getBackgroundIsDark(background);

  if (!quotes) {
    return null;
  }

  const gap = isNoImageType ? 30 : 192;
  const length = quotes.length;

  const setActiveDot = (dot: number) => setActive(dot);
  const leftArrowClick = () => activeIndex !== 0 && setActive(activeIndex - 1);
  const rightArrowClick = () => activeIndex !== length - 1 && setActive(activeIndex + 1);

  const pagination = (
    <Pagination
      activeDot={activeIndex}
      variant="dark"
      dotsCount={length}
      onSetActiveDot={setActiveDot}
      onLeftArrowClick={leftArrowClick}
      onRightArrowClick={rightArrowClick}
      isWithArrow={false}
      justifyContent="left"
    />
  );

  const buttonProps = {
    ...paginationButtonProps,
    backgroundColor: backgroundIsDark ? 'gray-900' : 'common-white',
    border: '2px solid',
    borderColor: backgroundIsDark ? 'gray-400' : 'gray-700',
    textColor: backgroundIsDark ? 'common-white' : 'gray-900',
    height: '56px',
    width: '56px',
  } as const;

  const leftDoodle = doodleGenerator('cutlery');
  const rightDoodle = doodleGenerator('tripleknotswirl');

  return (
    <Section __typename="ComponentQuote" background={background} id={sectionId}>
      <Container>
        {isTablet && leftDoodle && (
          <Image
            width="513px"
            height="461px"
            opacity=".7"
            top="50%"
            position="absolute"
            zIndex={-10}
            image={leftDoodle}
            alt="doodle"
            css={`
              transform-origin: left;
            `}
            md={{
              left: '-30%',
            }}
            lg={{
              left: '-15%',
            }}
            xl={{
              top: '46%',
              left: '-17%',
              width: 'auto',
              height: 'auto',
            }}
          />
        )}
        {isTablet && rightDoodle && (
          <Image
            width="428px"
            height="135px"
            opacity=".7"
            position="absolute"
            top="10%"
            right="-10%"
            transform="matrix(0.97, -0.24, -0.24, -0.97, 0, 0)"
            zIndex={-10}
            image={rightDoodle}
            alt="doodle"
            css={`
              transform-origin: left;
            `}
            md={{
              top: '15%',
              right: '-15%',
            }}
            xl={{ width: 'auto', height: 'auto' }}
          />
        )}
        {useHeading && <ComponentHeading background={background} {...headingContent} />}
        <Flex
          overflowX={isNoImageType ? 'visible' : 'hidden'}
          width="100%"
          marginBottom={32}
          marginTop={useHeading ? 24 : 0}
          lg={{ marginTop: 0 }}
        >
          <Grid
            gap={`${gap}px`}
            alignItems="flex-start"
            transform={`translateX(${-activeIndex * (quoteWidth + gap)}px)`}
            transition="transform 0.5s ease"
            minWidth={`calc(100% * ${length} + (${gap}px * ${length - 1}))`}
            gridTemplateColumns={`repeat(${length}, 1fr)`}
            sm={{ minWidth: `calc(${isNoImageType ? '370px' : '100%'} * ${length} + (${gap}px * ${length - 1}))` }}
          >
            {quotes.map(
              ({ quote }, index) =>
                quote?.value?.data && (
                  <Flex
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${quote.id}-${index}`}
                    ref={ref}
                    justifyContent="center"
                    flexDirection="column"
                    width="100%"
                  >
                    {isNoImageType ? (
                      <NoImageQuote backgroundIsDark={backgroundIsDark} {...quote?.value?.data} />
                    ) : (
                      <StandardQuote
                        textColor="common-white"
                        backgroundColor={backgroundIsDark ? 'gray-800' : 'gray-900'}
                        width="100%"
                        height="100%"
                        {...quote?.value?.data}
                        pagination={quotes?.length > 1 && pagination}
                      />
                    )}
                  </Flex>
                ),
            )}
          </Grid>
        </Flex>
        {isNoImageType
          ? quotes?.length > 1 && (
              <Flex width="100%" justifyContent="center">
                {pagination}
              </Flex>
            )
          : quotes?.length > 1 && (
              <Flex gap="24px">
                <ComponentButton
                  button={{
                    value: { data: { disabled: length ? activeIndex === 0 : false, size: 'xlarge' } },
                  }}
                  labelIcon="<"
                  onClick={leftArrowClick}
                  {...buttonProps}
                  css={`
                    &&&.Mui-disabled {
                      background-color: ${backgroundIsDark
                        ? (muiProps: CSSProps) => muiProps.theme.palette.gray[800]
                        : (muiProps: CSSProps) => muiProps.theme.palette.gray[200]};
                    }

                    svg {
                      padding-right: 2px;
                    }
                  `}
                />
                <ComponentButton
                  button={{
                    value: {
                      data: { disabled: length ? activeIndex === length - 1 : false, size: 'xlarge' },
                    },
                  }}
                  labelIcon=">"
                  onClick={rightArrowClick}
                  {...buttonProps}
                  css={`
                    &&&.Mui-disabled {
                      background-color: ${backgroundIsDark
                        ? (muiProps: CSSProps) => muiProps.theme.palette.gray[800]
                        : (muiProps: CSSProps) => muiProps.theme.palette.gray[200]};
                    }

                    svg {
                      padding-left: 2px;
                    }
                  `}
                />
              </Flex>
            )}
      </Container>
    </Section>
  );
};

export default QuoteSlider;
