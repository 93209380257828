import { media } from 'atoms/breakpoints/breakpoints';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Grid from 'quarks/Grid';
import Heading from 'quarks/Heading';
import Text from 'quarks/Text';

import ReportCard from 'components/Cards/ReportCard/ReportCard';
import RevenueCard from 'components/Cards/RevenueCard/RevenueCard';
import type { CalculatorResultsTypes } from 'components/ForecastCalculator/componentCalculatorTypesConfig';

import colorizeText from 'utils/colorizeText';
import RichTextParser from 'utils/richTextParser';

import type { FC } from 'react';

const CalculatorResults: FC<CalculatorResultsTypes> = ({
  resultsCopy,
  revenueCard,
  forecastCard,
  reportCard,
  weeks,
  forecastData,
  calculatePressed,
  calculatorFormWithData,
}) => {
  const futureForeCastData = [
    { heading: 'Next Week’s sales Forecast', value: forecastData?.sales, isHours: false },
    { heading: 'Forecasted Labor Costs', value: forecastData?.laborCost, isHours: false },
    { heading: 'Labor Hour Allowance', value: forecastData?.laborHours, isHours: true },
  ];

  const revenueTotals = weeks?.map(week => {
    const weeklyData = {
      heading: week,
      value: forecastData?.weeklyTotals[week],
    };

    return weeklyData;
  });

  return (
    <Flex flexDirection="column" gap="24px" sm={{ gap: '32px' }} xl={{ gap: '48px' }}>
      <Flex flexDirection="column" gap="24px" sm={{ gap: '32px' }} md={{ gap: '48px' }}>
        <Flex flexDirection="column" textAlign="left" gap="8px" sm={{ gap: '16px' }} md={{ gap: '24px' }}>
          <Flex flexDirection="column" gap="24px">
            {resultsCopy?.eyebrow && <Text textStyle="eyebrow">{colorizeText(resultsCopy.eyebrow, false)}</Text>}
            {resultsCopy?.heading && (
              <Heading
                as={resultsCopy?.headingType || 'h2'}
                textStyle="sm"
                textColor="gray-900"
                md={{ textStyle: 'md' }}
                xl={{ textStyle: 'lg' }}
                fontWeight="semiBold"
              >
                {resultsCopy.heading}
              </Heading>
            )}
            {resultsCopy?.subheading && (
              <Container
                textColor="gray-700"
                fontSize="textMd"
                fontWeight="regular"
                md={{ fontSize: 'textLg', fontWeight: 'regular' }}
                lg={{ fontSize: 'textXl', fontWeight: 'regular' }}
              >
                {RichTextParser(resultsCopy.subheading)}
              </Container>
            )}
          </Flex>
        </Flex>
      </Flex>
      <Grid
        paddingBottom={32}
        gridTemplateColumns="1fr"
        gap="24px"
        sm={{ gap: '30px', gridTemplateColumns: 'repeat(2, 1fr)', paddingBottom: 48 }}
        xl={{ gridTemplateColumns: 'repeat(3, 1fr)', paddingBottom: 72 }}
      >
        <RevenueCard
          heading={revenueCard?.heading}
          headingBackgroundColor="primary-50"
          headingColor="primary-900"
          results={revenueTotals}
          message={revenueCard?.message}
        />
        <RevenueCard
          heading={forecastCard?.heading}
          headingBackgroundColor="primary-700"
          headingColor="common-white"
          resultsHeadingColor="common-black"
          results={futureForeCastData}
          message={forecastCard?.message}
          buttonText={forecastCard?.buttonText}
          form={calculatorFormWithData}
          calculatePressed={calculatePressed}
        />
        {reportCard && (
          <ReportCard
            {...reportCard}
            css={`
              ${media.sm} {
                grid-column: span 2;
              }
              ${media.xl} {
                grid-column: span 1;
              }
            `}
          />
        )}
      </Grid>
    </Flex>
  );
};

export default CalculatorResults;
