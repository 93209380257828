import Icon from 'atoms/Icons/Icon';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Heading from 'quarks/Heading';
import Image from 'quarks/Image';
import Link from 'quarks/Link';
import Text from 'quarks/Text';

import type { TeamCardProps } from 'components/Cards/TeamCard/teamCardConfigTypes';

import { doodleGenerator } from 'utils/doodleGenerator';
import { optimizeBuilderImage } from 'utils/functions';

import type { FC } from 'react';

const TeamCard: FC<TeamCardProps> = ({ backgroundIsDark, quote, person }) => {
  const breadDoodle = doodleGenerator('breadloaf');
  const swirlDoodle = doodleGenerator('doubleknotthirdinverted');
  const retrievePersonData = person?.value?.data;

  const optimizedHeadshotImage = retrievePersonData?.headshot && optimizeBuilderImage(retrievePersonData?.headshot);

  return (
    <Flex flexDirection="column" borderRadius="12px" maxWidth="370px" gap="24px" marginY={0} marginX="auto">
      <Link href={retrievePersonData?.linkedinProfile || 'https://www.linkedin.com/company/lineupai/'}>
        <Flex
          flexDirection="column"
          justifyContent="center"
          textAlign="center"
          borderRadius="12px"
          position="relative"
          height="400px"
          gap="24px"
          backgroundColor="primary-500"
          overflowX="hidden"
          overflowY="hidden"
        >
          <Flex flexDirection="column" gap="24px" hover={{ zIndex: 2 }}>
            <Flex flexDirection="column" gap="12px" paddingX={24}>
              <Text textColor="common-white" textStyle="xl" fontWeight="bold">
                {quote?.question}
              </Text>
              <Text textColor="common-white" textStyle="xl">
                {quote?.answer}
              </Text>
            </Flex>
            <Container alignSelf="center">
              <Icon size={24} id="linkedin" isBackgroundDark iconColor="common-white" />
            </Container>
          </Flex>
          {optimizedHeadshotImage && (
            <Image
              image={optimizedHeadshotImage}
              backgroundColor="primary-100"
              position="absolute"
              borderRadius="12px"
              objectFit="cover"
              opacity="1"
              width="100%"
              height="100%"
              top="0px"
              bottom="0px"
              left="0px"
              right="0px"
              alt={retrievePersonData?.fullName ? `${retrievePersonData?.fullName} Headshot` : 'Lineup Staff Headshot'}
              transition="opacity .300s ease"
              zIndex={1}
              hover={{ opacity: '0' }}
            />
          )}
          {swirlDoodle && (
            <Image
              width="447px"
              height="72px"
              image={swirlDoodle}
              transform="rotate(-44deg)"
              position="absolute"
              top="150px"
              left="-80px"
              alt="swirl doodle"
              filter="invert(0%) sepia(100%) saturate(21%) hue-rotate(27deg) brightness(106%) contrast(104%)"
              css={`
                transform-origin: left;
              `}
            />
          )}
          {breadDoodle && (
            <Image
              width="212px"
              height="159px"
              image={breadDoodle}
              position="absolute"
              bottom="-28px"
              right="-20px"
              alt="breadloaf doodle"
              filter="invert(0%) sepia(100%) saturate(21%) hue-rotate(27deg) brightness(106%) contrast(104%)"
            />
          )}
        </Flex>
      </Link>
      <Flex flexDirection="column" textAlign="center" gap="8px">
        <Heading
          as="h2"
          textStyle="xs"
          fontWeight="semiBold"
          textColor={backgroundIsDark ? 'common-white' : 'gray-900'}
        >
          {retrievePersonData?.fullName}
        </Heading>
        <Text textColor={backgroundIsDark ? 'gray-25' : 'gray-700'} textStyle="xl">
          {retrievePersonData?.role}
        </Text>
      </Flex>
    </Flex>
  );
};

export default TeamCard;

TeamCard.defaultProps = {};
