import { iconValues } from 'atoms/Icons/Icon.types';

import SectionBuilderConfig from 'molecules/Section/Section.config';

import { SideBarConversionPanelConfig } from 'components/Sidebar/SideBarConversionPanel';

const ContentEditorBuilderConfig = {
  name: 'ContentEditor',
  inputs: [
    {
      name: 'postFeatures',
      type: 'list',
      helperText: 'Adds up to three key features at the top of the sidebar',
      subFields: [
        { name: 'icon', type: 'text', enum: iconValues, defaultValue: 'rocket-01', required: true },
        { name: 'copy', text: 'text', defaultValue: 'Saved 1.1-1.3 million dollars in labor costs' },
        { name: 'id', type: 'text', required: true, advanced: true },
      ],
      defaultValue: [
        {
          icon: 'rocket-01',
          copy: 'This is placeholder text. Should be kept relatively short.',
          id: 'post-feature-0',
        },
        {
          icon: 'rocket-01',
          copy: 'This is placeholder text. Should be kept relatively short.',
          id: 'post-feature-1',
        },
      ],
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onChange: options => {
        if (options.get('postFeatures').length > 1) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          options.get('postFeatures').forEach(({ postFeature }, index: number) => {
            postFeature.set('id', `post-feature-${index}`);
          });
        }
        if (options.get('postFeatures').length > 3) {
          options.set('postFeatures', options.get('postFeatures').slice(0, 3));
          alert('Maximum of three features are allowed.');
        }
      },
    },
    { ...SideBarConversionPanelConfig },
    ...SectionBuilderConfig.inputs,
  ],
};

export default ContentEditorBuilderConfig;
