import type { BasicProps } from 'quarks/interpolations/basic';
import type { HeadingTypes } from 'quarks/styleProps/heading';

import Section from 'molecules/Section/Section';
import Switchback from 'molecules/Switchback/Switchback';
import SwitchbackForm from 'molecules/Switchback/SwitchbackForm';

import type { ButtonTypes } from 'components/ComponentButton/buttonTypeConfig';
import type { FeatureProps } from 'components/ComponentSwitchback/Feature';
import type { ComponentFormProps } from 'components/HsForm/types';

import type { DoodleKey } from 'utils/doodleGenerator';

import type { FC } from 'react';

export interface SwitchbackProps extends BasicProps {
  assetType: 'Image' | 'Animation';
  eyebrow?: string;
  buttons?: ButtonTypes;
  coloredEyebrow?: boolean;
  heading?: string;
  headingType?: HeadingTypes;
  featureType?: string;
  doodle?: DoodleKey;
  subheading?: string;
  background?: string;
  switchbackImage?: string;
  featureListHeading?: string;
  features?: FeatureProps[];
  hsForm?: ComponentFormProps;
  isFormSwitchback?: boolean;
  imageCaption?: string;
  stats?: { stat: string; caption: string }[];
  assetSide?: string;
  lottieFile?: string;
  ariaLabel?: string;
  sectionId?: string;
}

const ComponentSwitchback: FC<SwitchbackProps> = ({
  assetType = 'Image',
  background = 'gray-100',
  buttons,
  featureType,
  coloredEyebrow,
  eyebrow,
  hsForm,
  featureListHeading,
  heading,
  headingType,
  isFormSwitchback,
  imageCaption,
  subheading,
  doodle,
  switchbackImage,
  assetSide,
  features,
  stats,
  lottieFile,
  ariaLabel,
  sectionId,
}) => {
  const sharedSwitchbackProps = {
    background: background && background,
    eyebrow: eyebrow && eyebrow,
    heading: heading && heading,
    coloredEyebrow: coloredEyebrow && coloredEyebrow,
    subheading: subheading && subheading,
    features: features && features,
  };

  return (
    <Section __typename="ComponentSwitchback" background={background} id={sectionId}>
      {isFormSwitchback ? (
        <SwitchbackForm
          featureListHeading={featureListHeading}
          hsForm={hsForm}
          rightDoodle={doodle}
          {...sharedSwitchbackProps}
        />
      ) : (
        <Switchback
          assetType={assetType}
          buttons={buttons}
          featureType={featureType}
          headingType={headingType}
          imageCaption={imageCaption}
          doodle={doodle}
          switchbackImage={switchbackImage}
          assetSide={assetSide}
          stats={stats}
          lottieFile={lottieFile}
          ariaLabel={ariaLabel}
          {...sharedSwitchbackProps}
        />
      )}
    </Section>
  );
};

ComponentSwitchback.defaultProps = {
  coloredEyebrow: false,
  eyebrow: 'eyebrow',
  background: 'Gray-100',
  heading: 'Switchback Image',
  subheading: '<p>Insert Subheading Here</p>',
};

export default ComponentSwitchback;
