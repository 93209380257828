import type { hubForm } from 'components/HsForm/types';

export const fetchHubspotForm = async (formId: string): Promise<hubForm> => {
  try {
    const data: Response = await fetch('/api/get-forms', {
      method: 'POST',
      body: JSON.stringify({ id: formId }),
      headers: {
        'content-type': 'application/json',
      },
    });

    return await data.json();
  } catch (error) {
    throw new Error(`Failed to fetch Hubspot form: ${formId}`);
  }
};

export default fetchHubspotForm;
