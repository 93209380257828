import SectionBuilderConfig from 'molecules/Section/Section.config';

const ProgressBarSwitcherConfig = {
  name: 'Progress Bar Switcher',
  inputs: [
    { name: 'background', type: 'text', enum: ['White', 'Gray-900'], defaultValue: 'White' },
    {
      name: 'slides',
      type: 'list',
      helperText: 'The design calls for a minimum of 3 slides. There is a maximum limit of 6.',
      subFields: [
        {
          name: 'eyebrow',
          type: 'text',
        },
        {
          name: 'heading',
          type: 'text',
        },
        {
          name: 'subheading',
          type: 'longText',
        },
        {
          name: 'assetType',
          type: 'text',
          enum: ['Image', 'Lottie'],
          defaultValue: 'Image',
          helperText: 'If Image is selected, the Lottie fields below should be left blank.',
        },
        {
          name: 'slideImage',
          required: true,
          type: 'file',
          helperText:
            'If the asset type indicated is a Lottie, this image will serve as the thumbnail for when the slide is not active.',
        },
        {
          name: 'lottieFile',
          type: 'file',
          allowedFileTypes: ['lottie', 'json'],
          helperText: 'Only accepts .json files',
        },
        {
          name: 'animationLength',
          type: 'number',
          helperText: 'Please indicate the length of the Lottie animation in seconds.',
        },
      ],
      onChange: (options: { get: (arg0: string) => string; set: (arg0: string, arg1: string) => void }) => {
        if (options.get('slides').length > 6) {
          options.set('slides', options.get('slides').slice(0, 6));
          alert('Maximum of 6 allowed.');
        }
      },
    },
    ...SectionBuilderConfig.inputs,
  ],
};

export default ProgressBarSwitcherConfig;
