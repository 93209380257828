import Flex from 'quarks/Flex';
import Heading from 'quarks/Heading';
import Image from 'quarks/Image';
import Paragraph from 'quarks/Paragraph';
import Text from 'quarks/Text';

import type { HistoryCardProps } from 'components/Cards/HistoryCard/historyCardConfigTypes';

import { doodleGenerator } from 'utils/doodleGenerator';
import { getBackground, getBackgroundIsDark } from 'utils/functions';

import type { FC } from 'react';

const HistoryCard: FC<HistoryCardProps> = ({ date, heading, subheading, background, doodle }) => {
  const { backgroundColor } = getBackground('HistoryCard', background, 0);
  const backgroundIsDark = getBackgroundIsDark(background);

  const dateAndHeadingColorScheme = backgroundIsDark ? 'common-white' : 'gray-900';
  const subheadingColorScheme = backgroundIsDark ? 'common-white' : 'gray-700';

  const doodleImage = doodle && doodleGenerator(doodle);

  return (
    <Flex
      borderRadius="12px"
      backgroundColor={backgroundColor}
      maxWidth="327px"
      position="relative"
      overflowX="hidden"
      overflowY="hidden"
      sm={{ maxWidth: '370px' }}
    >
      <Flex flexDirection="column" gap="16px" paddingY={58} paddingX={32}>
        {date && (
          <Text textColor={dateAndHeadingColorScheme} textStyle="eyebrow">
            {date}
          </Text>
        )}
        {heading && (
          <Heading textColor={dateAndHeadingColorScheme} as="h6" textStyle="xs" fontWeight="bold">
            {heading}
          </Heading>
        )}
        {subheading && (
          <Paragraph textColor={subheadingColorScheme} textStyle="lg" fontWeight="regular">
            {subheading}
          </Paragraph>
        )}
      </Flex>
      {doodleImage && (
        <Image
          position="absolute"
          alt="doodle-image"
          image={doodleImage}
          width="320px"
          height="160px"
          transform="rotate(-5deg)"
          bottom="-25px"
          right="-220px"
          filter={
            backgroundIsDark
              ? 'invert(0%) sepia(100%) saturate(21%) hue-rotate(27deg) brightness(106%) contrast(104%)'
              : 'invert(76%) sepia(93%) saturate(4153%) hue-rotate(233deg) brightness(101%) contrast(102%)'
          }
          zIndex={1}
        />
      )}
    </Flex>
  );
};

export default HistoryCard;

HistoryCard.defaultProps = {};
