import type { GetColorList, GetColorOptions } from 'atoms/colors/colors';

import type { valueof } from 'utils/typeUtils';
import { keyExistsOnObject, objectEntries } from 'utils/typeUtils';

import type { Properties } from 'csstype';
import type { DefaultTheme } from 'styled-components';

export const gradient = {
  lineupGradient: {
    lightMode: 'linear-gradient(90deg, #00A8B5 8.85%, #8A3BFF 37.5%, #BB59E0 64.06%, #FF4F41 92.19%);',
    darkMode: 'linear-gradient(90deg, #6EDCDF 8.85%, #A6ACFF 37.5%, #D08BE9 64.06%, #FC9A94 92.19%);',
    textOne: 'linear-gradient(52.54deg, #C243F2 25.28%, #6A79FF 47.59%, #07CCD4 73.41%)',
    textTwo: 'linear-gradient(52.54deg, #07CCD4 25.28%, #6A79FF 47.59%, #C243F2 73.41%)',
    whiteTransparent: 'linear-gradient(270deg, #fff 44.12%, rgba(255, 255, 255, 0) 100%)',
    darkTransparent: 'linear-gradient(270deg, #252533 44.12%, rgba(37, 37, 51, 0) 100%)',
  },
  radialGradient: {
    convex: 'radial-gradient(100% 100% at 50% 100%, #F5F5FF 0%, #F5F5FF 99.99%, #FFFFFF 100%);',
    concave: 'radial-gradient(65.62% 65.62% at 50% 34.37%, #F5F5FF 0%, #F5F5FF 99.99%, #FFFFFF 100%)',
    concaveMobile: 'radial-gradient(129.88% 71.5% at 49.26% -31.32%, #F5F5FF 0%, #F5F5FF 99.99%, #FFFFFF 100%)',
  },
  comboGradient: {
    lightMode: `
    linear-gradient(169.75deg, rgba(234, 235, 255, 0) 59.29%, #EAEBFF 99.43%),
    radial-gradient(97.67% 108.37% at -6.56% 108.37%, rgba(59, 78, 255, 0.3) 4.24%, rgba(59, 78, 255, 0) 94.66%),
    radial-gradient(62.61% 133.35% at 100% 27.18%, rgba(59, 78, 255, 0.3) 4.24%, rgba(59, 78, 255, 0) 94.66%),
    radial-gradient(33.34% 136.03% at 37.69% 77.73%, rgba(255, 255, 255, 0.8) 4.24%, rgba(255, 255, 255, 0) 94.66%),
    linear-gradient(0deg, #EAEBFF, #EAEBFF),
    linear-gradient(180deg, #F6F6FF 71.35%, #CBCCFF 100%),
    linear-gradient(0deg, #FFFFFF, #FFFFFF);
    `,
  },
} as const;

export type GradientObject = typeof gradient;
export type GradientOptions = GetColorOptions<GradientObject>;

export type GradientList = GetColorList<GradientObject> | undefined;
export type GradientDefinition = keyof GradientObject;
export const gradientListArray = objectEntries(gradient)
  .map(([gradientName, gradientValues]) => Object.keys(gradientValues).map(value => `${gradientName}-${value}`))
  .flat() as GradientList[];

export interface LightAndDark {
  light: GradientList;
  dark: GradientList;
}

type GradientKeys = valueof<GradientOptions>;
type GradientArray = [GradientDefinition, GradientKeys];

export type GetGradientDefinition = GradientList | LightAndDark;

export const getGradient = (palette: DefaultTheme['palette'], gradients: GetGradientDefinition) => {
  if (!gradients) {
    return;
  }

  return typeof gradients === 'object' ? gradients[palette.mode] : gradients;
};

/**
 * @param value any string
 * @returns if string is valid gradient atom, returns `gradient`. if not, `url(inputString)`
 */
export const parseBackgroundGradient = (
  palette: DefaultTheme['palette'],
  value: GetGradientDefinition | Properties['backgroundImage'],
) => {
  const parsedValue = getGradient(palette, value as GetGradientDefinition);
  const [gradientName, gradientValue] = parsedValue?.split('-') as GradientArray;

  if (!keyExistsOnObject(gradient, gradientName) || !keyExistsOnObject(gradient[gradientName], gradientValue)) {
    return `url(${value})`;
  }

  return gradient[gradientName][gradientValue];
};

export default gradient;
