const HorizontalRuleConfig = {
  name: 'horizontalRule',
  image: 'https://tabler-icons.io/static/tabler-icons/icons-png/arrows-horizontal.png',
  requiresParent: {
    message: 'Block must be placed inside the Content Editor',
    component: 'ContentEditor',
  },
};

export default HorizontalRuleConfig;
