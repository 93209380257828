/* eslint-disable react/no-array-index-key */

import type { iconIds } from 'atoms/Icons/Icon.types';

import Flex from 'quarks/Flex';
import Text from 'quarks/Text';
import type { BasicProps } from 'quarks/interpolations/basic';

import ComponentButton from 'components/ComponentButton/ComponentButton';
import SubMenuItemMedia from 'components/Header/SubMenuItemMedia';
import SubMenuItemNavigation from 'components/Header/SubMenuItemNavigation';

import type { Builder_SubMenuData } from 'graphqlTypes';
import type { FC } from 'react';

interface DesktopSubMenuProps extends BasicProps {
  subMenu: Builder_SubMenuData;
}

interface subMenuItemProps extends BasicProps {
  title?: string;
  description?: string;
  icon?: iconIds;
  image?: string;
  link?: string;
}

const DesktopSubMenu: FC<DesktopSubMenuProps> = ({ subMenu, ...props }) => {
  const isSubMenuMedia = subMenu.subMenuType === 'Media Link';

  return (
    <Flex
      borderRadius="16px"
      backgroundColor={isSubMenuMedia ? 'gray-50' : 'common-white'}
      flexDirection="column"
      paddingAll={32}
      gap="30px"
      width={isSubMenuMedia ? '420px' : '385px'}
      {...props}
    >
      <Flex justifyContent={subMenu.submenuCallToAction ? 'space-between' : 'start'}>
        <Text
          textStyle="lg"
          fontWeight="bold"
          textColor="gray-900"
          position="relative"
          after={{
            content: '',
            position: 'absolute',
            height: '1px',
            width: '335px',
            backgroundColor: 'gray-100',
            bottom: '-12px',
            left: '-12px',
          }}
        >
          {subMenu?.label}
        </Text>
        {subMenu.submenuCallToAction && !subMenu.submenuCallToAction.disabled && (
          <ComponentButton
            button={{
              value: {
                data: {
                  ...subMenu?.submenuCallToAction,
                },
              },
            }}
            paddingTop={0}
          />
        )}
      </Flex>
      <Flex
        as="ul"
        flexDirection="column"
        gap="24px"
        paddingLeft={0}
        css={`
          list-style-type: none;
          scrollbar-width: none;
          ::-webkit-scrollbar {
            display: none;
          }
        `}
      >
        {subMenu.subMenuItems.map(({ subMenuItem }: { subMenuItem: subMenuItemProps }, index: number) => {
          if (!subMenuItem) {
            return;
          }

          return !isSubMenuMedia ? (
            <SubMenuItemNavigation key={`${subMenu.label}-${index}`} {...subMenuItem} />
          ) : (
            <SubMenuItemMedia key={`${subMenu.label}-${index}`} {...subMenuItem} />
          );
        })}
      </Flex>
    </Flex>
  );
};

export default DesktopSubMenu;
