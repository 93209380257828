import { useMediaQuery } from '@mui/material';
import { useState } from 'react';

import { breakpoints, media } from 'atoms/breakpoints/breakpoints';
// import color from 'atoms/colors/colors';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Grid from 'quarks/Grid';
import Text from 'quarks/Text';

import Button, { handleButtonTracking } from 'molecules/Button/Button';
import Section from 'molecules/Section/Section';
import Select from 'molecules/Select/Select';

import type { CardProps } from 'components/Cards/EntryCard/EntryCard';
import EntryCard from 'components/Cards/EntryCard/EntryCard';
// import Form from 'components/HsForm/HsForm';
import ResourceCategoryGrid from 'components/ResourceListing/ResourceCategoryGrid';
import type {
  ResourceListingProps,
  ResourcePost,
  ResourcesCategory,
} from 'components/ResourceListing/ResourceListing.config';

import useGatedResourcePosts from 'utils/hooks/useGatedResourcePosts';
import useResourcesCategories from 'utils/hooks/useResourceCategories';

import type { FC } from 'react';

const allCategoriesText = 'View All';

const ResourceListing: FC<ResourceListingProps> = ({ featureCardHeadingType, sectionId }) => {
  const posts: ResourcePost[] = useGatedResourcePosts().sort((a: ResourcePost, b: ResourcePost) => {
    if (!a?.data?.publishDate || !b?.data?.publishDate) {
      return 0;
    }

    return new Date(b.data.publishDate).valueOf() - new Date(a.data.publishDate).valueOf();
  });

  const filteredCategories: ResourcesCategory[] = [...useResourcesCategories()];

  const resourcesCategories: ResourcesCategory[] = [
    { data: { title: allCategoriesText, slug: '/' }, id: 0 },
    ...useResourcesCategories(),
  ];

  const isDesktop = useMediaQuery(media.lg);
  const [activeCategory, setActiveCategory] = useState(allCategoriesText);
  const [postsToDisplay, setPostsToDisplay] = useState(posts);

  const location = 'ResourceListing';

  const handleCategoryClick = (id: string) => {
    setActiveCategory(id);
    id === allCategoriesText
      ? setPostsToDisplay(posts)
      : setPostsToDisplay(posts.filter(post => post?.data?.category?.value?.data?.title === id));

    const trackingScheme = {
      label: posts.filter(post => post?.data?.category?.value?.data?.title === id)[0]?.data?.category.value.data.title,
      location,
    };

    handleButtonTracking(trackingScheme);
  };

  return (
    <>
      <Section __typename="ResourceListingFeatured" background="resourceListing" id={sectionId}>
        <Container>
          {posts?.[0]?.data && (
            <Flex justifyContent="center">
              <EntryCard
                css={`
                  width: 100%;
                `}
                variant="featured"
                headingType={featureCardHeadingType}
                {...(posts[0].data as CardProps)}
              />
            </Flex>
          )}
        </Container>
      </Section>
      <Section
        __typename="ResourceListing"
        css={`
          margin-top: -2.5rem;
          @media screen and (min-width: ${breakpoints.lg}) {
            margin-top: -4.5rem;
          }
          @media screen and (min-width: ${breakpoints.xl}) {
            margin-top: -6rem;
          }
        `}
      >
        <Container>
          <Grid gap="48px" gridTemplateColumns="1fr" lg={{ gridTemplateColumns: '1fr 2fr', gap: '100px' }}>
            <Flex
              flexDirection="column"
              gap="20px"
              paddingBottom={12}
              marginBottom={20}
              position="sticky"
              top="73px"
              alignSelf="start"
              zIndex={1}
              backgroundColor="common-white"
              css={`
                margin-left: -10px;
                margin-right: -10px;
                padding-left: 10px;
                padding-right: 10px;
                ${media.lg} {
                  margin: 0;
                  padding-left: 0;
                  /* padding-right: 96px; */
                  padding-bottom: 0;
                }
              `}
              lg={{ top: '80px' }}
            >
              <Text textStyle="lg" fontWeight="semiBold">
                Categories
              </Text>
              <Flex flexDirection="column" gap="4px">
                {isDesktop ? (
                  resourcesCategories?.map(
                    ({ data, id }) =>
                      data?.title && (
                        <Button
                          key={id}
                          justifyContent="flex-start"
                          width="100%"
                          paddingY={10}
                          paddingX={14}
                          borderRadius="6px"
                          backgroundColor={activeCategory === data?.title ? 'primary-50' : 'common-transparent'}
                          fontSize="textMd"
                          lineHeight="textMd"
                          textColor={activeCategory === data?.title ? 'primary-900' : 'gray-700'}
                          fontWeight="medium"
                          boxShadow="none"
                          onClick={() => handleCategoryClick(data?.title || allCategoriesText)}
                          hover={{
                            backgroundColor: 'primary-300',
                            textColor: 'common-white',
                          }}
                          focusVisible={{
                            borderColor: 'primary-300',
                            boxShadow: 'focused',
                          }}
                        >
                          {data?.title}
                        </Button>
                      ),
                  )
                ) : (
                  <Select
                    list={resourcesCategories.map(({ data }) => data?.title)}
                    defaultValue={activeCategory}
                    onChange={e => handleCategoryClick(e.target.value)}
                  />
                )}
              </Flex>
              {/* {isDesktop && (
                <Flex
                  flexDirection="column"
                  gap="24px"
                  marginTop={6}
                  paddingTop={32}
                  borderTop={`1px solid ${color.gray[200]}`}
                >
                  <Text textStyle="lg" fontWeight="semiBold">
                    Sign up for our newsletter
                  </Text>
                  <Form
                    formId="Subscribe Form | c9325cee-6d27-41b9-9b9d-731d8cf0cf0e"
                    variant="Stacked"
                    trackingSchema={{
                      location,
                    }}
                  />
                </Flex>
              )} */}
            </Flex>
            {posts && (
              <Flex flexDirection="column" gap="30px">
                {activeCategory === allCategoriesText ? (
                  filteredCategories?.map(({ data, id }) => {
                    const catPosts = posts.filter(post => post?.data?.category?.value?.id === id).slice(0, 2);
                    if (catPosts?.length && data?.title) {
                      return (
                        <ResourceCategoryGrid
                          key={id}
                          heading={data.title}
                          posts={catPosts}
                          handleCategoryClick={handleCategoryClick}
                          categorySelect
                        />
                      );
                    }

                    return null;
                  })
                ) : (
                  <ResourceCategoryGrid heading={activeCategory} posts={postsToDisplay} />
                )}
              </Flex>
            )}
          </Grid>
        </Container>
      </Section>
    </>
  );
};

export default ResourceListing;
