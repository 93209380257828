import { FormControl, RadioGroup as MuiRadioGroup } from '@mui/material';
import styled from 'styled-components';

import Text from 'quarks/Text';
import type { BasicProps } from 'quarks/interpolations/basic';
import { flex } from 'quarks/interpolations/flex';

import Radio from 'molecules/Radio/Radio';

import type { radioOptions } from 'components/HsForm/types';

import type { FC } from 'react';

const StyledFormControl = styled(FormControl)`
  && {
    ${flex}
  }
`;

interface RadioGroupProps extends BasicProps {
  /**
   * The id of the `Radio Group` element. Use this prop to make `label` and `helperText` accessible for screen readers.
   */
  id?: string;
  /**
   * Name attribute of the `TextField` element.
   */
  name?: string;
  /**
   * Text label of the Radio button
   */
  label?: string;
  /**
   * List of Radio Options
   */
  options: radioOptions[];
  /**
   * If `true`, the component is required.
   */
  required?: boolean;
  /**
   * displays text under the label to assist the user
   */
  helperText?: string;
  /**
   * If `true`, the label is displayed in an error state.
   */
  error?: boolean;
}

const RadioGroup: FC<RadioGroupProps> = ({ id, name, label, options, required, helperText, error, ...props }) => (
  <StyledFormControl required={required} {...props} width="100%">
    {label && (
      <Text as="label" htmlFor={id || name} textStyle="sm" fontWeight="regular" textColor="gray-700">
        {label}
      </Text>
    )}
    <MuiRadioGroup>
      {options.map(
        radio => radio?.value && <Radio key={radio.value} label={radio.label || radio.value} value={radio.value} />,
      )}
    </MuiRadioGroup>
    {helperText && (
      <Text textStyle="sm" fontWeight="semiBold" textColor={error ? 'error-500' : 'gray-500'}>
        {helperText}
      </Text>
    )}
  </StyledFormControl>
);

export default RadioGroup;
