import Icon from 'atoms/Icons/Icon';
import color from 'atoms/colors/colors';
import container from 'atoms/spacing/containers';

import Container from 'quarks/Container';
import Grid from 'quarks/Grid';
import Heading from 'quarks/Heading';
import Link from 'quarks/Link';

import Section from 'molecules/Section/Section';

import type { PopularArticle } from 'components/Hero/HeroStandard/heroTypesAndData';

import type { FC } from 'react';

interface PopularGridProps {
  popularArticles: PopularArticle[];
}

const PopularArticlesGrid: FC<PopularGridProps> = ({ popularArticles }) => (
  <Section __typename="popularArticles" background="darkConvexGrey">
    <Container textColor="common-white" maxWidth={container.sm} marginX="auto">
      <Heading as="h2" marginBottom={32} fontSize="displayXs" lineHeight="displayXs" textAlign="center">
        Popular articles:
      </Heading>
      <Grid
        columnGap="30px"
        gridTemplateColumns="1fr"
        rowGap="16px"
        sm={{
          rowGap: '42px',
          gridTemplateColumns: 'repeat(2, 1fr)',
        }}
      >
        {popularArticles.map(article => (
          <Link
            key={article?.label}
            href={article?.link}
            fontFamily="textFont"
            fontWeight="semiBold"
            display="flex"
            textColor="gray-50"
            fontSize="textMd"
            sm={{ fontSize: 'textLg' }}
            css={`
              gap: 24px;
              align-items: center;
              svg {
                fill: ${color.common.white};
                stroke: ${color.common.white};
              }
              :hover {
                text-decoration: underline;
                color: ${color.primary[100]};
                > div {
                  background-color: ${color.common.white};
                }
                svg {
                  fill: ${color.primary[500]};
                  stroke: ${color.primary[500]};
                }
              }
            `}
          >
            {article?.icon && (
              <Icon
                id={article?.icon}
                wrapped
                isSquare
                backgroundColor="gray-800"
                iconColor="common-white"
                size={24}
                smSize={32}
              />
            )}
            {article?.label}
          </Link>
        ))}
      </Grid>
    </Container>
  </Section>
);

export default PopularArticlesGrid;
