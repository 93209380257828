import { useEffect, useState } from 'react';

import Container from 'quarks/Container';

import GradientLinearProgress from 'molecules/LinearProgress/GradientLinearProgress';

import onKeyDown from 'utils/onKeyDown';

import type { Dispatch, FC, SetStateAction } from 'react';

interface ProgressBarButtonProps {
  eyebrow?: string;
  assetType?: 'Image' | 'Lottie';
  animationLength?: number;
  index: number;
  activeSlide: number;
  setActiveSlide: Dispatch<SetStateAction<number>>;
  isPlaying: boolean;
  setIsPlaying: Dispatch<SetStateAction<boolean>>;
  setIsFinished: Dispatch<SetStateAction<boolean>>;
  length: number;
}

const ProgressBarButton: FC<ProgressBarButtonProps> = ({
  assetType,
  animationLength = 5,
  eyebrow,
  index,
  activeSlide,
  setActiveSlide,
  isPlaying,
  setIsPlaying,
  setIsFinished,
  length,
}) => {
  const timer = assetType === 'Lottie' ? animationLength : 5;

  const [counter, setCounter] = useState(0);
  const [percentProgress, setPercentProgress] = useState(0);

  const calculateGradientWidth = (currIndex: number) => {
    if (currIndex < activeSlide) {
      return 100;
    } else if (currIndex === activeSlide) {
      return Math.floor((counter / timer) * 100);
    } else {
      return 0;
    }
  };

  const handleSlideItem = (slideIndex: number) => {
    setActiveSlide(slideIndex);
    setCounter(0);
    setIsPlaying(true);
    setIsFinished(false);
  };

  useEffect(() => {
    const currentWidth = calculateGradientWidth(index);
    setPercentProgress(currentWidth);
  }, [counter, activeSlide]);

  useEffect(() => {
    let currentTimer = undefined as NodeJS.Timeout | undefined;
    let nextTimer = undefined as NodeJS.Timeout | undefined;
    const isActive = activeSlide === index;

    if (!isPlaying) {
      return;
    }

    if (isActive && isPlaying && counter < timer) {
      currentTimer = setInterval(() => setCounter(counter + 0.25), 250);
    } else if (isActive && isPlaying && counter === timer) {
      if (activeSlide < length - 1) {
        nextTimer = setTimeout(() => {
          setActiveSlide(activeSlide + 1);
          setCounter(0);
        }, 250);
      } else {
        setIsPlaying(false);
        clearInterval(currentTimer);
        setIsFinished(true);
      }
    }

    return () => {
      clearInterval(currentTimer);
      clearTimeout(nextTimer);
    };
  }, [activeSlide, counter, isPlaying]);

  return (
    <Container
      as="button"
      tabIndex={0}
      minHeight="40px"
      backgroundColor="common-transparent"
      onClick={() => handleSlideItem(index)}
      onKeyDown={e => onKeyDown(e, () => handleSlideItem(index))}
      aria-label={eyebrow}
      aria-current={index === activeSlide ? true : false}
      paddingAll={0}
      border="none"
      borderRadius="3px"
      cursor="pointer"
      focusVisible={{
        boxShadow: 'focused',
        outline: 'none',
      }}
      position="relative"
    >
      <GradientLinearProgress
        value={percentProgress}
        label={false}
        variant="buffer"
        labelPosition="end"
        position="absolute"
        top="10px"
        width="100%"
      />
    </Container>
  );
};

export default ProgressBarButton;
