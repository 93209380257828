import Flex from 'quarks/Flex';
import Image from 'quarks/Image';
import Paragraph from 'quarks/Paragraph';
import Text from 'quarks/Text';

import { optimizeBuilderImage } from 'utils/functions';

import type { Builder_EntityPerson } from 'graphqlTypes';
import type { FC } from 'react';

interface CopyProps {
  quote?: string;
  author?: { value: Builder_EntityPerson };
}

const BlockQuote: FC<CopyProps> = ({ quote, author }) => {
  const person = author?.value?.data;
  const optimizedImage = person?.headshot && optimizeBuilderImage(person?.headshot);

  return (
    <Flex
      flexDirection="column"
      gap="32px"
      paddingY={8}
      paddingLeft={20}
      borderLeft="2px solid"
      borderLeftColor="primary-700"
    >
      <Paragraph textStyle="xl" textColor="gray-900" fontWeight="medium">
        {quote || '...add quote'}
      </Paragraph>
      {person && (
        <Flex gap="12px">
          {optimizedImage && (
            <Flex width="48px" height="48px" borderRadius="50%" overflowX="hidden" overflowY="hidden">
              <Image image={optimizedImage} alt={person?.fullName || `${person?.fullName}'s Headshot`} />
            </Flex>
          )}
          {(person?.fullName || person?.role) && (
            <Flex flexDirection="column">
              {person?.fullName && (
                <Text textStyle="md" fontWeight="semiBold" textColor="gray-900">
                  {person?.fullName}
                </Text>
              )}
              {person?.role && (
                <Text textStyle="md" fontWeight="regular" textColor="gray-700">
                  {person?.role}
                </Text>
              )}
            </Flex>
          )}
        </Flex>
      )}
    </Flex>
  );
};

export default BlockQuote;
