import Image from 'quarks/Image';

import LottieAnimation from 'molecules/LottieAnimation/LottieAnimation';

const renderLottieAsset = (
  assetType?: 'Image' | 'Animation' | 'featureAssets',
  optimizedImage?: string,
  imageCaption?: string,
  lottieFile?: string,
  ariaLabel?: string,
) => {
  if (!assetType) {
    return null;
  }

  switch (assetType) {
    case 'Animation':
      if (!lottieFile) {
        return null;
      }

      return <LottieAnimation ariaLabel={ariaLabel || 'Lottie Animation'} lottieAnimation={lottieFile} loop />;
    case 'Image':
      if (!optimizedImage) {
        return null;
      }

      return (
        <Image
          alignSelf="center"
          objectFit="contain"
          width="100%"
          borderRadius="8px"
          image={optimizedImage}
          alt={imageCaption || ''}
          transition="align-self 0.150s ease"
        />
      );
  }
};

export default renderLottieAsset;
