const themeHierarchy = {
  primaryButton: {
    bg: 'primary-600',
    text: 'common-white',
    border: 'primary-600',
    hoverBg: 'primary-700',
    hoverText: 'common-white',
    focusBorder: 'gray-300',
    disabledBg: 'primary-200',
    disabledText: 'common-white',
    disabledBorder: 'primary-200',
    variant: 'contained',
  },
  secondaryGray: {
    bg: 'common-white',
    text: 'gray-700',
    border: 'gray-300',
    hoverBg: 'gray-50',
    hoverText: 'gray-800',
    focusBorder: 'gray-300',
    disabledBg: 'common-white',
    disabledText: 'gray-500',
    disabledBorder: 'gray-400',
    variant: 'outlined',
  },
  secondaryColor: {
    bg: 'primary-50',
    text: 'primary-700',
    border: 'primary-50',
    hoverBg: 'primary-100',
    hoverText: 'primary-800',
    focusBorder: 'primary-50',
    disabledBg: 'primary-25',
    disabledText: 'primary-300',
    disabledBorder: 'primary-25',
    variant: 'contained',
  },
  tertiaryGray: {
    bg: 'common-transparent',
    text: 'gray-700',
    border: 'common-white',
    hoverBg: 'gray-50',
    hoverText: 'gray-700',
    focusBorder: 'primary-50',
    disabledBg: 'common-white',
    disabledText: 'gray-500',
    disabledBorder: 'common-white',
    variant: 'text',
  },
  tertiaryColor: {
    bg: 'primary-25',
    text: 'primary-700',
    border: 'primary-25',
    hoverBg: 'primary-50',
    hoverText: 'primary-800',
    focusBorder: 'primary-50',
    disabledBg: 'primary-25',
    disabledText: 'gray-500',
    disabledBorder: 'primary-25',
    variant: 'text',
  },
  tertiaryWhite: {
    bg: 'common-transparent',
    text: 'primary-25',
    border: 'primary-25',
    hoverBg: 'primary-25',
    hoverText: 'gray-700',
    focusBorder: 'primary-50',
    disabledBg: 'common-transparent',
    disabledText: 'primary-200',
    disabledBorder: 'primary-200',
    variant: 'outlined',
  },
  linkGray: {
    bg: 'common-transparent',
    text: 'gray-900',
    border: 'common-white',
    hoverBg: 'common-transparent',
    hoverText: 'primary-700',
    focusBorder: 'common-white',
    disabledBg: 'common-transparent',
    disabledText: 'gray-500',
    disabledBorder: 'common-white',
    variant: 'text',
  },
  linkColor: {
    bg: 'common-transparent',
    text: 'primary-700',
    border: 'common-white',
    hoverBg: 'common-transparent',
    hoverText: 'primary-900',
    focusBorder: 'common-white',
    disabledBg: 'common-transparent',
    disabledText: 'gray-500',
    disabledBorder: 'common-white',
    variant: 'text',
  },
  linkWhite: {
    bg: 'common-transparent',
    text: 'primary-25',
    border: 'common-transparent',
    hoverBg: 'common-transparent',
    hoverText: 'primary-100',
    focusBorder: 'common-white',
    disabledBg: 'common-transparent',
    disabledText: 'primary-200',
    disabledBorder: 'common-transparent',
    variant: 'text',
  },
} as const;

export type HierarchyDefinition = keyof typeof themeHierarchy;

export default themeHierarchy;
