import FocusTrap from '@mui/base/FocusTrap';
import { Dialog } from '@mui/material';
import { useContext, useEffect, useMemo } from 'react';
import styled from 'styled-components';

import Flex from 'quarks/Flex';

import Form from 'components/HsForm/HsForm';

import { ModalContext } from 'contexts/ModalProvider';
import type { ModalReferenceProps } from 'contexts/ModalProvider';

import type { FC, KeyboardEvent } from 'react';

export const directionHandler = {
  top: {
    initial: '-150%',
    exit: '-150%',
  },
  bottom: {
    initial: '150%',
    exit: '150%',
  },
} as const;

export const getPosition = {
  top: 'flex-start',
  bottom: 'flex-end',
} as const;

const StyledModal = styled(Dialog)`
  .MuiBackdrop-root {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
    backdrop-filter: blur(5px);
  }
`;

const ModalContainer: FC<ModalReferenceProps> = () => {
  const { modalState, modalDispatch } = useContext(ModalContext);

  const handleClose = () => {
    modalDispatch({ type: 'CLOSE_MODAL' });
  };

  const onKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Escape') {
      handleClose();
    }
  };

  useEffect(() => {
    if (!document) {
      return;
    }

    if (!modalState.open) {
      document.body.style.overflow = 'visible';

      return;
    } else {
      document.body.style.overflow = 'hidden';
    }
  }, [modalState]);

  const modalReference = useMemo(
    () =>
      ({ __typename, data }: ModalReferenceProps) => {
        switch (__typename) {
          case 'form':
            return (
              <Form
                key="form"
                onClose={handleClose}
                {...data}
                position={undefined}
                trackingSchema={{
                  location: 'Modal',
                }}
              />
            );
          default: {
            return null;
          }
        }
      },
    [],
  );

  return (
    <>
      {modalState.open && (
        <StyledModal open={modalState.open} scroll="body" onKeyDown={e => onKeyDown(e)}>
          {modalState.reference && (
            <FocusTrap open>
              <Flex alignItems="center" justifyContent="center" width="100%" height="100%">
                {modalReference(modalState.reference)}
              </Flex>
            </FocusTrap>
          )}
        </StyledModal>
      )}
    </>
  );
};

export default ModalContainer;
