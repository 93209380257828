import { useWindowSize } from 'react-use';

import container from 'atoms/spacing/containers';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Heading from 'quarks/Heading';
import Image from 'quarks/Image';
import Text from 'quarks/Text';
import type { BasicProps } from 'quarks/interpolations/basic';

import Section from 'molecules/Section/Section';

import ComponentButton from 'components/ComponentButton/ComponentButton';
import type { ButtonTypes } from 'components/ComponentButton/buttonTypeConfig';
import ButtonWrapper from 'components/ComponentButton/buttonWrapper';

import colorizeText from 'utils/colorizeText';
import { doodleGenerator } from 'utils/doodleGenerator';
import { getBackground, getBackgroundIsDark, getButtonHierarchy } from 'utils/functions';
import RichTextParser from 'utils/richTextParser';

import type { Builder_ComponentButton } from 'graphqlTypes';
import type { FC } from 'react';

interface ConversionPanelProps extends BasicProps {
  eyebrow: string;
  coloredEyebrow: boolean;
  enableDoodle: boolean;
  heading: string;
  subheading: string;
  background: string;
  contained: boolean;
  offset: boolean;
  containedBackground: string;
  offsetContainedBackground: string;
  buttonGroup: ButtonTypes;
  sectionId?: string;
}

interface ConversionPanelButtonProps {
  offset?: boolean;
  buttonGroup: ButtonTypes;
  typename: 'Conversion Panel';
}

const ConversionPanelButtons: FC<ConversionPanelButtonProps> = ({ offset, buttonGroup, typename }) => {
  const buttonSection = buttonGroup?.map((button, index) => (
    <ComponentButton
      buttonType={button?.buttonType || 'callToAction'}
      key={button.button?.value?.id || index}
      {...(button as Builder_ComponentButton)}
      trackingSchema={{
        hierarchy: getButtonHierarchy(buttonGroup, button),
        location: typename,
      }}
    />
  ));

  return offset ? (
    <Container
      width="100%"
      sm={{ maxWidth: 'fit-content' }}
      md={{
        width: 'fit-content',
        maxWidth: 'unset',
      }}
    >
      <ButtonWrapper
        sm={{ flexDirection: 'column' }}
        md={{
          flexDirection: 'row',
        }}
        offsetConvPanelOverride
      >
        {buttonSection}
      </ButtonWrapper>
    </Container>
  ) : (
    <ButtonWrapper>{buttonSection}</ButtonWrapper>
  );
};

const ComponentConversionPanel: FC<ConversionPanelProps> = ({
  background,
  contained,
  offset,
  containedBackground,
  offsetContainedBackground,
  coloredEyebrow,
  enableDoodle,
  eyebrow,
  heading,
  subheading,
  buttonGroup,
  sectionId,
}) => {
  const { width } = useWindowSize();
  const isOffset = contained && offset;
  const containedBackgroundColor = offset ? offsetContainedBackground : containedBackground;

  const backgroundIsDark = contained ? getBackgroundIsDark(containedBackgroundColor) : getBackgroundIsDark(background);
  const textColor = backgroundIsDark ? 'gray-50' : 'gray-900';
  const styledEyebrow = coloredEyebrow ? colorizeText(eyebrow, backgroundIsDark) : eyebrow;
  const assetString = contained && offset ? ' ContainedOffset' : ' Contained';
  const addString = enableDoodle && contained ? containedBackgroundColor + assetString : containedBackgroundColor;
  const { backgroundColor, backgroundImage } = getBackground('ConversionPanel', addString, width);
  const doodleImage = enableDoodle && doodleGenerator('doodlegroup');
  const typename = 'Conversion Panel';
  const paddingType = isOffset ? 'offset' : 'standard';

  const padding = {
    containedMobile: {
      x: {
        standard: 8,
        offset: 24,
      },
      y: {
        standard: 48,
        offset: 40,
      },
    },
    containedTablet: {
      x: {
        standard: 8,
        offset: 48,
      },
      y: {
        standard: 72,
        offset: 40,
      },
    },
    containedDesktop: {
      x: {
        standard: 8,
        offset: 72,
      },
      y: {
        standard: 80,
        offset: 56,
      },
    },
  } as const;

  return (
    <Section __typename={typename} background={background} contained={contained} id={sectionId}>
      <Flex
        backgroundColor={contained ? backgroundColor : undefined}
        position={contained ? 'relative' : 'inherit'}
        borderRadius={contained ? '12px' : '0px'}
        paddingY={contained ? padding.containedMobile.y[paddingType] : 0}
        paddingX={contained ? padding.containedMobile.x[paddingType] : 0}
        md={{
          borderRadius: contained ? '24px' : '0px',
          paddingY: contained ? padding.containedTablet.y[paddingType] : 0,
          paddingX: contained ? padding.containedTablet.x[paddingType] : 0,
        }}
        xl={{
          paddingY: contained ? padding.containedDesktop.y[paddingType] : 0,
          paddingX: contained ? padding.containedDesktop.x[paddingType] : 0,
        }}
      >
        {contained && enableDoodle && backgroundImage && (
          <Image
            image={backgroundImage}
            position="absolute"
            borderRadius="12px"
            objectFit="cover"
            width="100%"
            height="100%"
            zIndex={-1}
            top="0px"
            bottom="0px"
            left="0px"
            right="0px"
            alt="contained-background-image"
            sm={{ borderRadius: '24px' }}
          />
        )}
        {doodleImage && enableDoodle && !contained && (
          <>
            <Image
              transform="rotate(90deg)"
              opacity=".2"
              top="-124%"
              left="-30px"
              position="absolute"
              zIndex={-10}
              image={doodleImage}
              alt="doodle"
              css={`
                transform-origin: left;
              `}
              md={{
                left: '0px',
              }}
              lg={{
                left: '100px',
              }}
              xl={{
                top: '-110%',
              }}
            />
            <Image
              transform="rotate(-90deg)"
              opacity=".2"
              top="-55%"
              right="-30px"
              position="absolute"
              zIndex={10}
              image={doodleImage}
              alt="doodle-two"
              css={`
                transform-origin: right;
              `}
              md={{
                right: '0px',
              }}
              lg={{
                right: '100px',
              }}
            />
          </>
        )}
        <Flex
          flexDirection="column"
          textAlign="center"
          alignItems="center"
          gap="32px"
          textColor={textColor}
          maxWidth={!isOffset && container.md}
          width="100%"
          justifyContent="space-between"
          sm={{
            flexDirection: isOffset ? 'row' : 'column',
            textAlign: isOffset ? 'left' : 'center',
            marginX: 'auto',
          }}
        >
          <Flex flexDirection="column" gap="24px" width={isOffset && '100%'}>
            {eyebrow && <Text textStyle="eyebrow">{styledEyebrow}</Text>}
            {heading && (
              <Heading as="h2" textStyle="xs" md={{ textStyle: 'md' }} xl={{ textStyle: 'lg' }} fontWeight="semiBold">
                {heading}
              </Heading>
            )}
            {subheading && (
              <Container
                textColor={backgroundIsDark ? 'common-white' : 'gray-700'}
                fontSize="textLg"
                fontWeight="medium"
                md={{ fontSize: 'textXl' }}
              >
                {RichTextParser(subheading)}
              </Container>
            )}
          </Flex>
          {buttonGroup && <ConversionPanelButtons offset={isOffset} typename={typename} buttonGroup={buttonGroup} />}
        </Flex>
      </Flex>
    </Section>
  );
};

ComponentConversionPanel.defaultProps = {
  coloredEyebrow: false,
  eyebrow: 'eyebrow',
  heading: 'Component Conversion Panel',
  subheading: '<p>Insert a Subheading</p>',
  contained: false,
};

export default ComponentConversionPanel;
