import { useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';

import Icon from 'atoms/Icons/Icon';
import { media } from 'atoms/breakpoints/breakpoints';

import Flex from 'quarks/Flex';
import Grid from 'quarks/Grid';
import Text from 'quarks/Text';

import Button from 'molecules/Button/Button';
import NumberedPagination from 'molecules/Pagination/NumberedPagination';
import Section from 'molecules/Section/Section';
import TextField from 'molecules/TextField/TextField';

import PartnerCard from 'components/Cards/PartnerCard';
import FilterMenu from 'components/PartnerList/FilterMenu';
import type { PartnerListProps } from 'components/PartnerList/partnerListTypesAndData';

import { generatePartnerCardsForList } from 'utils/dataConversion';
import { filterFunc } from 'utils/filterUtils';
import onKeyDown from 'utils/onKeyDown';

import type { FC, KeyboardEvent } from 'react';

const PartnerList: FC<PartnerListProps> = ({ sectionId, integrationPartnerCards, specialOfferPartnerCards }) => {
  const list = generatePartnerCardsForList(integrationPartnerCards, specialOfferPartnerCards);
  const isTablet = useMediaQuery(media.md);
  const isDesktop = useMediaQuery(media.lg);
  const deckSize = isDesktop ? 9 : 8;

  const [cardsToDisplay, setCardsToDisplay] = useState(list);
  const [searching, setSearching] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useState<string>('');
  const [activePage, setActivePage] = useState<number>(1);
  const [cardOffset, setOffset] = useState<number>(0);
  const [activePartnership, setActivePartnership] = useState<string[]>([]);
  const [activeServiceType, setActiveServiceType] = useState<string[]>([]);
  const [overlayOpen, setOverlayOpen] = useState<boolean>(false);

  const handleClearFilters = () => {
    setActivePage(1);
    setActivePartnership([]);
    setActiveServiceType([]);
  };

  const handleClearSearch = (e?: KeyboardEvent<HTMLElement | SVGSVGElement>) => {
    if (e && e.stopPropagation) {
      e.stopPropagation();
    }
    setActivePage(1);
    setSearchParams('');
    setSearching(false);
    setCardsToDisplay(list);
  };

  const handleFilters = () => {
    setActivePage(1);
    setSearchParams('');
    searching && handleClearSearch();

    const filteredCards = list.filter(card => {
      const serviceFilter =
        activeServiceType.length > 0
          ? activeServiceType.some(service => (card.serviceType as readonly string[])?.includes(service))
          : true;

      const partnershipFilter =
        activePartnership.length > 0 ? activePartnership.some(partner => card.offerType === partner) : true;

      return serviceFilter && partnershipFilter;
    });

    setCardsToDisplay(filteredCards);
  };

  const handleSearch = () => {
    const tmpArr = searchParams.split(' ').filter(item => item !== '');
    const filtered = list.filter(offer => filterFunc(offer.name, tmpArr));

    handleClearFilters();
    setCardsToDisplay(filtered);
    setSearching(true);
  };

  const handleButtonClick = () => {
    handleFilters();
    setOverlayOpen(!overlayOpen);
  };

  useEffect(() => {
    setOffset(deckSize * (activePage - 1));
  }, [activePage]);

  return (
    <>
      <Section __typename="PartnerList" id={sectionId} sectionType="listing">
        <Flex flexDirection="column" gap="65px" position="relative">
          <Flex
            flexDirection="column"
            md={{ flexDirection: 'row' }}
            justifyContent="space-between"
            paddingTop={56}
            borderTop="1px solid"
            borderTopColor="primary-50"
            gap="32px"
          >
            <Flex flexDirection="column" md={{ maxWidth: '470px', flexDirection: 'row' }} gap="16px" width="100%">
              <TextField
                width="100%"
                type="text"
                startIcon={<Icon id="search-lg" iconColor="gray-500" />}
                placeholder="Search"
                required={true}
                endIcon={
                  searchParams !== '' ? (
                    <Icon
                      id="x-close"
                      iconColor="gray-500"
                      tabIndex={0}
                      onClick={() => handleClearSearch()}
                      onKeyDown={e => onKeyDown(e, () => handleClearSearch(e))}
                      cursor="pointer"
                    />
                  ) : null
                }
                onChange={e => setSearchParams(e.target.value)}
                onKeyDown={e => onKeyDown(e, () => handleSearch(), 'Enter')}
                value={searchParams}
              />
              <Button
                variant="contained"
                onClick={() => handleSearch()}
                disabled={searchParams === ''}
                focusVisible={{ boxShadow: 'focused' }}
                width="100%"
                md={{ width: 'unset' }}
              >
                Search
              </Button>
            </Flex>
            <Button
              variant="outlined"
              textColor="gray-700"
              focusVisible={{
                boxShadow: 'focused',
              }}
              startIcon={<Icon id={overlayOpen ? 'x-close' : 'filter-lines'} iconColor="gray-700" />}
              onClick={() => handleButtonClick()}
              width="100%"
              md={{ width: 'unset' }}
            >
              {overlayOpen ? 'Close' : 'Filter'}
            </Button>
            {isTablet && overlayOpen && (
              <FilterMenu
                handleButtonClick={handleButtonClick}
                handleClearFilters={handleClearFilters}
                activePartnership={activePartnership}
                setActivePartnership={setActivePartnership}
                activeServiceType={activeServiceType}
                setActiveServiceType={setActiveServiceType}
              />
            )}
          </Flex>
          <Grid
            columnGap="30px"
            rowGap="32px"
            gridTemplateColumns="1fr"
            md={{
              gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
            }}
            lg={{
              gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
            }}
          >
            {cardsToDisplay?.length > 0 ? (
              cardsToDisplay
                .slice(cardOffset, cardOffset + deckSize)
                .map(card => (
                  <PartnerCard
                    key={card.name}
                    name={card.name}
                    brandLogo={card?.brandLogo}
                    description={card?.description}
                    isPopular={card?.isPopular}
                    serviceType={card?.serviceType}
                    link={card?.link}
                  />
                ))
            ) : (
              <Text textStyle="xxl" textColor="common-black" fontWeight="semiBold">
                No results.
              </Text>
            )}
          </Grid>
          <NumberedPagination
            activePage={activePage}
            pageCount={Math.ceil(cardsToDisplay?.length / 9) || 1}
            setActivePage={setActivePage}
          />
        </Flex>
      </Section>
      {!isTablet && overlayOpen && (
        <FilterMenu
          handleButtonClick={handleButtonClick}
          handleClearFilters={handleClearFilters}
          activePartnership={activePartnership}
          setActivePartnership={setActivePartnership}
          activeServiceType={activeServiceType}
          setActiveServiceType={setActiveServiceType}
        />
      )}
    </>
  );
};

export default PartnerList;
