import type { GetColorDefinition } from 'atoms/colors/colors';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Heading from 'quarks/Heading';
import Text from 'quarks/Text';

import type { ComponentStatProps } from 'components/Stats/ComponentStats';

import type { FC } from 'react';

interface StatBarProps extends ComponentStatProps {
  backgroundIsDark: boolean;
}

const StatBar: FC<StatBarProps> = ({ stats, backgroundIsDark, ...props }) => {
  const symbolLightModeColorList = ['irisBlue-500', 'purpleHeart-400', 'bitterSweet-300'];
  const symbolDarkModeColorList = ['irisBlue-300', 'purpleHeart-200', 'bitterSweet-200'];
  const backgroundFallback = backgroundIsDark ? 'gray-900' : 'common-white';
  const backgroundColor = backgroundIsDark === undefined ? 'gray-100' : backgroundFallback;
  const gradientColor = backgroundIsDark ? 'lineupGradient-darkMode' : 'lineupGradient-lightMode';

  return (
    <Flex
      backgroundImage={gradientColor}
      backgroundColor={backgroundColor}
      borderRadius="8px"
      width="100%"
      css={`
        padding: 2px;
      `}
      {...props}
    >
      <Flex
        flexDirection="column"
        width="inherit"
        backgroundColor={backgroundColor}
        borderRadius="6px"
        gap="8px"
        justifyContent="space-around"
        textAlign="center"
        sm={{ flexDirection: 'row' }}
      >
        {stats?.map((stat, index) => {
          const statWithoutSymbol = stat?.statItem?.statistic?.slice(0, stat.statItem.statistic.length - 1);
          const symbolCharacter = stat?.statItem?.statistic?.charAt(stat.statItem.statistic.length - 1);

          return (
            <Flex
              paddingY={16}
              paddingX={24}
              flexDirection="column"
              gap="8px"
              // eslint-disable-next-line react/no-array-index-key
              key={`${stat?.statItem?.id}-${index}`}
              width="100%"
            >
              <Heading
                as="h3"
                textStyle="md"
                fontWeight="bold"
                textColor={backgroundIsDark ? 'common-white' : 'gray-900'}
                md={{ textStyle: 'lg' }}
              >
                {statWithoutSymbol}
                <Container
                  as="text"
                  textColor={
                    backgroundIsDark
                      ? (symbolLightModeColorList[index] as GetColorDefinition)
                      : (symbolDarkModeColorList[index] as GetColorDefinition)
                  }
                >
                  {symbolCharacter}
                </Container>
              </Heading>
              <Text textStyle="md" textColor={backgroundIsDark ? 'gray-50' : 'gray-700'} fontWeight="bold">
                {stat?.statItem?.caption}
              </Text>
            </Flex>
          );
        })}
      </Flex>
    </Flex>
  );
};

export default StatBar;
