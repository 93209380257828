import { forwardRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import rotate from 'atoms/animations/rotate';

import Flex from 'quarks/Flex';
import Text from 'quarks/Text';

import ComponentButton from 'components/ComponentButton/ComponentButton';
import type { FormProps } from 'components/HsForm/types';

import type { FC } from 'react';

const StackedForm: FC<FormProps> = forwardRef(
  ({
    heading,
    headingType,
    subHeading,
    formFields,
    postSubmitMessage,
    isSubmitting,
    isSubmitSuccessful,
    handleFormSubmit,
    isDark,
    submitButtonText,
    loading,
    captchaRef,
    isValid,
    ...props
  }) => {
    const textColor = isDark ? 'gray-200' : 'gray-800';
    const labelProp = submitButtonText
      ? {
          label: isSubmitting ? '...Submitting' : submitButtonText || undefined,
        }
      : ({ labelIcon: isSubmitting ? 'refresh-cw-01' : 'send-01' } as const);

    return (
      <Flex as="form" flexDirection="column" gap="6px" onSubmit={handleFormSubmit} {...props}>
        {heading && (
          <Text as={headingType || 'p'} textStyle="lg" fontWeight="bold" textColor={textColor}>
            {heading}
          </Text>
        )}
        {subHeading && (
          <Text textStyle="sm" textColor={textColor}>
            {subHeading}
          </Text>
        )}
        {loading ? (
          <Flex>
            <Text textStyle="eyebrow" textColor="primary-600">
              Loading...please wait
            </Text>
          </Flex>
        ) : (
          <>
            {formFields && (
              <Flex flexDirection="column" gap="10px">
                {formFields}
                <ReCAPTCHA ref={captchaRef} size="invisible" sitekey={`${process.env.GATSBY_RECAPTCHA_SITE_KEY}`} />
                <ComponentButton
                  type="submit"
                  button={{
                    value: { data: { hierarchy: 'Primary Button', size: 'medium', disabled: !isValid, ...labelProp } },
                  }}
                  width="100%"
                  sm={{ width: 'auto' }}
                  css={rotate}
                  focusVisible={{
                    boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba(255, 255, 255, 0.3)',
                    outline: 0,
                  }}
                  trackingSchema={{
                    hierarchy: '1 of 1',
                    location: `Form: ${heading}`,
                  }}
                />
              </Flex>
            )}
          </>
        )}
        {isSubmitSuccessful && (
          <Text textStyle="xs" textColor={isDark ? 'gray-200' : 'primary-700'}>
            {postSubmitMessage ? postSubmitMessage : 'Success! Check your email.'}
          </Text>
        )}
      </Flex>
    );
  },
);

export default StackedForm;
