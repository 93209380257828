const HeadingsBuilderConfig = {
  name: 'Headings',
  image: 'https://tabler-icons.io/static/tabler-icons/icons-png/h-1.png',
  requiresParent: {
    message: 'Block must be placed inside the Content Editor',
    component: 'ContentEditor',
  },
  inputs: [
    { name: 'heading', type: 'text' },
    { name: 'headingType', type: 'text', enum: ['h2', 'h3', 'h4', 'h5', 'h6'], defaultValue: 'h2' },
  ],
};

export default HeadingsBuilderConfig;
