import type { LegalSidebarProps } from 'components/Sidebar/Variations/Legal';
import LegalSideBar from 'components/Sidebar/Variations/Legal';
import type { PostSideBarProps } from 'components/Sidebar/Variations/Post';
import PostSideBar from 'components/Sidebar/Variations/Post';
import type { ResourceSidebarProps } from 'components/Sidebar/Variations/Resource';
import ResourceSidebar from 'components/Sidebar/Variations/Resource';
import type { SupportSideBarProps } from 'components/Sidebar/Variations/Support';
import SupportSideBar from 'components/Sidebar/Variations/Support';

import type { FC } from 'react';

export type postTypes = 'legal' | 'blog' | 'resource' | 'support';

interface SideBarProps extends PostSideBarProps, LegalSidebarProps, ResourceSidebarProps, SupportSideBarProps {
  variation: postTypes;
}

const SideBar: FC<SideBarProps> = ({ variation, ...props }) => {
  if (!variation) {
    return null;
  }

  switch (variation) {
    case 'legal':
      return <LegalSideBar {...props} />;
    case 'blog':
      return <PostSideBar {...props} />;
    case 'resource':
      return <ResourceSidebar {...props} />;
    case 'support':
      return <SupportSideBar {...props} />;
    default:
      return <PostSideBar {...props} />;
  }
};

export default SideBar;
