import styled from 'styled-components';

import type { iconIds } from 'atoms/Icons/Icon.types';
import IconWrapper from 'atoms/Icons/IconWrapper';
import type { IconWrapperProps } from 'atoms/Icons/IconWrapper';
import { media } from 'atoms/breakpoints/breakpoints';
import color, { getColor } from 'atoms/colors/colors';
import type { ColorList } from 'atoms/colors/colors';

import Flex from 'quarks/Flex';
import type { FlexProps } from 'quarks/interpolations/flex';

import type { FC } from 'react';
import type { DefaultTheme } from 'styled-components';

interface WrapperProps extends IconWrapperProps {
  wrapped?: boolean;
}

interface IconProps extends Omit<IconWrapperProps, 'children' | 'backgroundColor'>, FlexProps {
  id: iconIds;
  iconColor?: ColorList;
  size?: number;
  smSize?: number;
  mdSize?: number;
  lgSize?: number;
  wrapped?: boolean;
  isSquare?: boolean;
  tabIndex?: number;
  onClick?: () => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLElement>) => void;
}

interface StyledSVGProps {
  size: number | undefined;
  smSize: number | undefined;
  mdSize: number | undefined;
  lgSize: number | undefined;
}

const SVG = styled.svg<StyledSVGProps>`
  ${media.sm} {
    height: ${props => props.smSize && props.smSize}px;
    width: ${props => props.smSize && props.smSize}px;
  }
  ${media.md} {
    height: ${props => props.mdSize && props.mdSize}px;
    width: ${props => props.mdSize && props.mdSize}px;
  }
  ${media.lg} {
    height: ${props => props.lgSize && props.lgSize}px;
    width: ${props => props.lgSize && props.lgSize}px;
  }
`;

const Wrapper: FC<WrapperProps> = ({
  children,
  wrapped,
  isSquare,
  backgroundColor,
  hasGradientBorder,
  isBackgroundDark,
  ...props
}) =>
  wrapped ? (
    <IconWrapper
      backgroundColor={backgroundColor}
      hasGradientBorder={hasGradientBorder}
      isBackgroundDark={isBackgroundDark}
      isSquare={isSquare}
      {...props}
    >
      {children}
    </IconWrapper>
  ) : (
    <Flex {...props}>{children}</Flex>
  );

export const Icon: FC<IconProps> = ({
  backgroundColor,
  id,
  iconColor,
  hasGradientBorder,
  isBackgroundDark,
  size = 16,
  smSize = size,
  mdSize = smSize || size,
  lgSize = mdSize || smSize || size,
  wrapped,
  ...props
}) => {
  const colorScheme = isBackgroundDark ? 'common-white' : 'gray-900';
  const backgroundDefault = isBackgroundDark ? 'gray-900' : 'gray-25';

  return (
    <Wrapper
      wrapped={wrapped}
      backgroundColor={backgroundColor || backgroundDefault}
      hasGradientBorder={hasGradientBorder}
      isBackgroundDark={isBackgroundDark}
      {...props}
    >
      <SVG
        width={size}
        height={size}
        size={size}
        smSize={smSize}
        mdSize={mdSize}
        lgSize={lgSize}
        fill={getColor(color as DefaultTheme['palette'], iconColor || colorScheme)}
        stroke={getColor(color as DefaultTheme['palette'], iconColor || colorScheme)}
      >
        <use href={`/icons/sprites.svg#${id}`} />
      </SVG>
    </Wrapper>
  );
};

export default Icon;
