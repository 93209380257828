import type { FlexProps } from 'quarks/interpolations/flex';
import type { HeadingTypes } from 'quarks/styleProps/heading';

import SectionBuilderConfig from 'molecules/Section/Section.config';

import type { Builder_CaseStudyCategoryData, Builder_CaseStudyData } from 'graphqlTypes';

export interface CaseStudyListingProps extends FlexProps {
  postsPerPage: number;
  featureCardHeadingType: HeadingTypes;
  caseStudyHeadingType: HeadingTypes;
  sectionId?: string;
}

export interface CaseStudyCategory {
  data: Builder_CaseStudyCategoryData;
  id: string;
}

export interface CaseStudy {
  data?: Builder_CaseStudyData;
  id?: string;
}

const CaseStudyListingBuilderConfig = {
  name: 'CaseStudyListing',
  inputs: [
    { name: 'featureCardHeadingType', type: 'text', enum: ['h2', 'h3', 'h4', 'h5', 'h6'], defaultValue: 'h2' },
    { name: 'caseCardsHeadingType', type: 'text', enum: ['h2', 'h3', 'h4', 'h5', 'h6'], defaultValue: 'h2' },
    {
      name: 'postsPerPage',
      type: 'number',
      defaultValue: 8,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onChange: options => {
        if (!options.get('postsPerPage')) {
          return 8;
        }

        options.set('postsPerPage', Math.floor(options.get('postsPerPage')));
      },
    },
    ...SectionBuilderConfig.inputs,
  ],
};

export default CaseStudyListingBuilderConfig;
