import type { iconIds } from 'atoms/Icons/Icon.types';
import container from 'atoms/spacing/containers';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Heading from 'quarks/Heading';
import Image from 'quarks/Image';
import Text from 'quarks/Text';
import type { BasicProps } from 'quarks/interpolations/basic';
import type { HeadingTypes } from 'quarks/styleProps/heading';

import Section from 'molecules/Section/Section';

import StatBar from 'components/Stats/StatBar';

import colorizeText from 'utils/colorizeText';
import type { DoodleKey } from 'utils/doodleGenerator';
import { doodleGenerator } from 'utils/doodleGenerator';
import { getBackgroundIsDark } from 'utils/functions';
import RichTextParser from 'utils/richTextParser';

import type { FC } from 'react';

interface StatProps {
  statItem?: {
    /**
     * Provides stat icon.
     */
    icon?: iconIds;
    /**
     * Provides stat text.
     */
    statistic?: string;
    /**
     * Provides caption text.
     */
    caption?: string;
    /**
     * Provides an id used for mapping.
     */
    id?: string;
  };
}

export interface ComponentStatProps extends BasicProps {
  /**
   * Provides heading hierarchy type.
   */
  headingType?: HeadingTypes;
  /**
   * Determines Background selected, if none selected, will render white background.
   */
  background?: string;
  /**
   * Provides eyebrow text.
   */
  eyebrow?: string;
  /**
   * Provides heading text.
   */
  heading?: string;
  /**
   * Provides subheading text.
   */
  subheading?: string;
  /**
   * Determines what doodle gets selected and displayed
   */
  doodle?: DoodleKey;
  /**
   * An Array of Stat objects.
   */
  stats?: StatProps[];
  /**
   * Sets section id for anchor link.
   */
  sectionId?: string;
}

const Stats: FC<ComponentStatProps> = ({
  background,
  doodle,
  eyebrow,
  heading,
  headingType,
  subheading,
  stats,
  sectionId,
  ...props
}) => {
  const backgroundIsDark = getBackgroundIsDark(background);
  const doodleImage = doodle && doodleGenerator(doodle);

  return (
    <Section __typename="ComponentStats" background={background} id={sectionId}>
      <Flex
        flexDirection="column"
        textAlign="center"
        gap="24px"
        maxWidth={container.md}
        marginX="auto"
        marginY={0}
        position="relative"
        md={{ gap: '56px' }}
        {...props}
      >
        <Flex flexDirection="column" gap="24px">
          {eyebrow && <Text textStyle="eyebrow">{colorizeText(eyebrow, backgroundIsDark)}</Text>}
          {heading && (
            <Heading
              as={headingType || 'h2'}
              textStyle="xs"
              md={{ textStyle: 'md' }}
              xl={{ textStyle: 'lg' }}
              fontWeight="semiBold"
              textColor={backgroundIsDark ? 'common-white' : 'gray-900'}
            >
              {heading}
            </Heading>
          )}
          {subheading && (
            <Container
              textColor={backgroundIsDark ? 'gray-50' : 'gray-700'}
              fontSize="textLg"
              fontWeight="regular"
              md={{ fontSize: 'textXl' }}
            >
              {RichTextParser(subheading)}
            </Container>
          )}
        </Flex>
        <StatBar stats={stats} backgroundIsDark={backgroundIsDark} />
        {doodleImage && (
          <Image
            image={doodleImage}
            alt={`doodle of ${doodle}`}
            position="absolute"
            bottom="60px"
            right="-220px"
            width="504px"
            display="none"
            transform="rotate(-36.13deg)"
            md={{
              display: 'block',
            }}
            xl={{
              right: '-400px',
            }}
            zIndex="-1"
          />
        )}
      </Flex>
    </Section>
  );
};

export default Stats;
