import Icon from 'atoms/Icons/Icon';
import type { iconIds } from 'atoms/Icons/Icon.types';

import Flex from 'quarks/Flex';
import Text from 'quarks/Text';
import type { BasicProps } from 'quarks/interpolations/basic';

import type { FC } from 'react';

interface BenefitsGridCardProps extends BasicProps {
  /**
   * If selected Determines what Icon gets displayed.
   */
  icon?: iconIds;
  /**
   * Determines the text for the title
   */
  title?: string;
  /**
   * Determines the text for the description
   */
  description?: string;
  /**
   * Determines color scheme if Background is dark or not.
   */
  backgroundIsDark?: boolean;
}

const BenefitsGridCard: FC<BenefitsGridCardProps> = ({ icon, title, description, backgroundIsDark }) => (
  <Flex gap="16px" width="100%">
    {icon && <Icon size={40} id={icon} isBackgroundDark={backgroundIsDark} iconColor="primary-600" />}
    <Flex flexDirection="column" gap="4px" height="100%" justifyContent="center">
      {title && (
        <Text
          textColor={backgroundIsDark ? 'common-white' : 'gray-900'}
          textStyle="lg"
          fontWeight="semiBold"
          md={{ textStyle: 'md', fontWeight: 'semiBold' }}
        >
          {title}
        </Text>
      )}
      {description && (
        <Text
          display="none"
          textColor={backgroundIsDark ? 'gray-25' : 'gray-700'}
          textStyle="md"
          xl={{ display: 'block' }}
        >
          {description}
        </Text>
      )}
    </Flex>
  </Flex>
);

export default BenefitsGridCard;
