import { useMediaQuery } from '@mui/material';

import { media } from 'atoms/breakpoints/breakpoints';

import Flex from 'quarks/Flex';
import Paragraph from 'quarks/Paragraph';

import Button, { handleButtonTracking } from 'molecules/Button/Button';
import Select from 'molecules/Select/Select';

import type { CategoryProps } from 'components/IntegrationsList/IntegrationsList';

import type { FC } from 'react';

export interface IntegrationsSidebarProps {
  categories: CategoryProps[];
  activeCategory?: string;
  onClick: (value: string, type: 'slug' | 'title') => void;
}

const IntegrationsSidebar: FC<IntegrationsSidebarProps> = ({ categories, activeCategory, onClick }) => {
  const isDesktop = useMediaQuery(media.lg);

  const handleClick = (slug: string, title?: string | null) => {
    onClick(slug, 'slug');
    const trackingScheme = {
      label: title,
      location: 'Integrations Sidebar',
    };

    handleButtonTracking(trackingScheme);
  };
  const handleChange = (event: string) => {
    onClick(event, 'title');
  };

  return (
    <Flex
      position="static"
      flexDirection="column"
      gap="20px"
      height="fit-content"
      flex="0 0 25%"
      lg={{ position: 'sticky', top: '110px' }}
    >
      {isDesktop ? (
        <>
          <Paragraph textStyle="lg" fontWeight="semiBold" textColor="gray-900">
            Integration Categories
          </Paragraph>
          <Flex flexDirection="column" gap="6px">
            {categories.map((category: CategoryProps) => (
              <Button
                key={category.id}
                justifyContent="flex-start"
                width="100%"
                paddingY={10}
                paddingX={14}
                borderRadius="6px"
                backgroundColor={category.slug === activeCategory ? 'primary-50' : 'common-transparent'}
                fontSize="textMd"
                lineHeight="textMd"
                textColor={category.slug === activeCategory ? 'primary-900' : 'gray-700'}
                fontWeight="medium"
                boxShadow="none"
                hover={{
                  backgroundColor: 'primary-300',
                  textColor: 'common-white',
                }}
                onClick={() => handleClick(category?.slug || 'all', category?.title)}
              >
                {category.title}
              </Button>
            ))}
          </Flex>
        </>
      ) : (
        <Select
          list={categories.map(category => category?.title)}
          defaultValue="View All"
          onChange={e => handleChange(e.target.value)}
        />
      )}
    </Flex>
  );
};

export default IntegrationsSidebar;
