import type { HeadingTypes } from 'quarks/styleProps/heading';

import SectionBuilderConfig from 'molecules/Section/Section.config';

import type { Builder_GatedResourceData, Builder_ResourcesCategoryData } from 'graphqlTypes';
import type { ReactElement } from 'react';

export interface ResourceListingProps {
  content: ReactElement[];
  featureCardHeadingType: HeadingTypes;
  sectionId?: string;
}

export interface ResourcePost {
  data?: Builder_GatedResourceData;
  id?: string;
}

export interface ResourcesCategory {
  data?: Builder_ResourcesCategoryData;
  id?: string;
}

const ResourceListingBuilderConfig = {
  name: 'Resource Listing',
  inputs: [{ name: 'enableDoodle', type: 'boolean' }],
  ...SectionBuilderConfig.inputs,
};

export default ResourceListingBuilderConfig;
