import SectionBuilderConfig from 'molecules/Section/Section.config';

import { ButtonBuilderConfig } from 'components/ComponentButton/buttonTypeConfig';

const AnimationFrameConfig = {
  type: 'object',
  subFields: [
    {
      name: 'text1',
      type: 'text',
    },
    {
      name: 'text2',
      type: 'text',
    },
  ],
};

const HeroBuilderConfig = {
  name: 'ComponentHeroHomepage',
  inputs: [
    {
      name: 'staticTextPart1',
      type: 'text',
      helperText: 'This text will render before the first text animation.',
      defaultValue: 'Simplifying complex ',
    },
    {
      name: 'staticTextPart2',
      type: 'text',
      helperText: 'This text will render after the first text animation.',
      defaultValue: 'challenges for restaurants using ',
    },
    {
      name: 'staticTextPart3',
      type: 'text',
      helperText: 'This text will render before the second text animation. It will not display on the last frame. ',
      defaultValue: 'AI-powered ',
    },
    {
      name: 'animationText',
      helperText: 'Set gradient text for each animation frame.',
      type: 'object',
      subFields: [
        {
          name: 'frame1',
          ...AnimationFrameConfig,
        },
        {
          name: 'frame2',
          ...AnimationFrameConfig,
        },
        {
          name: 'frame3',
          ...AnimationFrameConfig,
        },
      ],
    },

    { name: 'subheading', type: 'richText' },
    {
      name: 'buttons',
      type: 'list',
      subFields: ButtonBuilderConfig.inputs,
      helperText: 'For the Hero Component, the Figma Design System has both buttons at xLarge. ',
    },
    { name: 'collapseBottomPadding', type: 'boolean', defaultValue: false },
    ...SectionBuilderConfig.inputs,
  ],
};

export default HeroBuilderConfig;
