declare const window: Window &
  typeof globalThis & {
    Calendly: any;
  };

export const generateCalendlyScript = (formId: string | undefined, calendlyRoutingId: string | undefined) => {
  if (!formId || !calendlyRoutingId) {
    return;
  }

  const calendlyStylesheet = document.createElement('link');
  calendlyStylesheet.rel = 'stylesheet';
  calendlyStylesheet.type = 'text/css';
  calendlyStylesheet.setAttribute('href', 'https://assets.calendly.com/assets/external/widget.css');

  const calendlyScript = document.createElement('script');
  calendlyScript.type = 'text/javascript';
  calendlyScript.setAttribute('src', 'https://assets.calendly.com/assets/external/forms.js');
  calendlyScript.async = true;

  document.body.appendChild(calendlyStylesheet);
  document.body.appendChild(calendlyScript);
  if (window) {
    window.addEventListener('load', () => {
      if (window?.Calendly) {
        window.Calendly?.initHubspotForm({
          id: formId,
          url: `https://calendly.com/api/form_builder/forms/${calendlyRoutingId}/submissions`,
        });
      }
    });
  }
};

export default generateCalendlyScript;
