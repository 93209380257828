import { media } from 'atoms/breakpoints/breakpoints';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Text from 'quarks/Text';

import ComponentButton from 'components/ComponentButton/ComponentButton';

import RichTextParser from 'utils/richTextParser';

import type { Builder_ComponentButton } from 'graphqlTypes';
import type { FC, ReactNode } from 'react';

interface VideoContentProps {
  title?: string;
  description?: string;
  ctaButton?: Builder_ComponentButton;
  children: ReactNode;
}

const VideoContent: FC<VideoContentProps> = ({ title, description, ctaButton, children }) => (
  <Container width="100%">
    <Container borderRadius="8px" md={{ borderRadius: '20px' }} overflowX="hidden" overflowY="hidden">
      {children}
    </Container>
    <Flex
      marginTop={16}
      flexDirection="column"
      gap="16px"
      width="100%"
      md={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between', paddingX: 32 }}
      xl={{ paddingX: 58 }}
    >
      <Flex flexDirection="column" gap="16px" maxWidth="470px" xl={{ gap: '24px', maxWidth: '670px' }}>
        {title && (
          <Text
            textColor="common-white"
            textStyle="xl"
            fontSize="textXl"
            fontWeight="bold"
            md={{ fontFamily: 'displayFont', fontSize: 'displayXs', fontWeight: 'bold' }}
          >
            {title}
          </Text>
        )}
        {description && (
          <Text
            textColor="common-white"
            textStyle="md"
            fontSize="textMd"
            fontWeight="regular"
            md={{ textStyle: 'lg', fontSize: 'textLg' }}
          >
            {RichTextParser(description)}
          </Text>
        )}
      </Flex>
      {ctaButton && (
        <Flex
          alignItems="flex-start"
          css={`
            &&& > a,
            &&& > button {
              width: 100%;
            }
            ${media.sm} {
              &&& > a,
              &&& > button {
                width: fit-content;
              }
            }
          `}
        >
          <ComponentButton
            buttonType="callToAction"
            button={{
              value: {
                data: {
                  hierarchy: ctaButton.data?.hierarchy,
                  label: ctaButton.data?.label,
                  size: ctaButton.data?.size,
                  link: ctaButton.data?.link,
                },
              },
            }}
          />
        </Flex>
      )}
    </Flex>
  </Container>
);

export default VideoContent;
