import SectionBuilderConfig from 'molecules/Section/Section.config';

import { ButtonBuilderConfig } from 'components/ComponentButton/buttonTypeConfig';

export const ConversionPanelBuilderConfig = {
  name: 'ComponentConversionPanel',
  inputs: [
    {
      name: 'background',
      type: 'text',
      enum: [
        'Primary-600',
        'Gray-100',
        'Gray-900',
        'Dark Split',
        'Gray Dark Split',
        'Convex',
        'Concave',
        'Dark Concave',
        'Dark Convex',
        'White',
      ],
    },
    { name: 'contained', type: 'boolean', helperText: 'Setting this to true will make the copy be inside a container' },
    {
      name: 'offset',
      type: 'boolean',
      helperText: 'Setting this to true will offset the content as opposed to having it stacked.',
      showIf: (options: { get: (arg0: string) => boolean }) => options.get('contained') === true,
    },
    {
      name: 'containedBackground',
      type: 'text',
      enum: ['Gray-100', 'Primary-600', 'Gray-900'],
      showIf: "(options.get('contained') === true) && (options.get('offset') === false)",
    },
    {
      name: 'offsetContainedBackground',
      type: 'text',
      enum: ['Gray-100', 'Gray-900'],
      defaultValue: 'Gray-100',
      showIf: "(options.get('offset') === true) && (options.get('contained') === true)",
    },
    { name: 'coloredEyebrow', type: 'boolean', helperText: 'Set to true if you want your eyebrow colorized' },
    { name: 'eyebrow', type: 'text' },
    { name: 'heading', type: 'text' },
    { name: 'subheading', type: 'richText' },
    { name: 'enableDoodle', type: 'boolean' },
    {
      name: 'buttonGroup',
      type: 'list',
      subFields: ButtonBuilderConfig.inputs,
    },
    ...SectionBuilderConfig.inputs,
  ],
};
export default ConversionPanelBuilderConfig;
