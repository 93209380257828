import { ButtonBuilderConfig } from 'components/ComponentButton/buttonTypeConfig';

const HeadingBuilderConfig = {
  name: 'ComponentHeading',
  inputs: [
    { name: 'internalName', type: 'text' },
    { name: 'coloredEyebrow', type: 'boolean', helperText: 'Set to true if you want your heading colorized' },
    { name: 'eyebrow', type: 'text' },
    { name: 'heading', type: 'text' },
    { name: 'subheading', type: 'richText' },
    { name: 'subheadingFontWeight', type: 'text', enum: ['regular', 'medium'], defaultValue: 'medium' },
    { name: 'leftAlign', type: 'boolean', helperText: 'Left aligns heading content' },
    { name: 'collapseBottomPadding', type: 'boolean', defaultValue: false },
    { name: 'background', type: 'text', enum: ['Primary-600', 'Gray-900', 'White', 'Concave', 'Convex'] },
    {
      name: 'buttonGroup',
      type: 'list',
      subFields: ButtonBuilderConfig.inputs,
    },
  ],
};

export default HeadingBuilderConfig;
