import { ArrowRight } from 'react-feather';

import container from 'atoms/spacing/containers';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Heading from 'quarks/Heading';
import Paragraph from 'quarks/Paragraph';
import Text from 'quarks/Text';

import Badge from 'molecules/Badge/Badge';
import BadgeGroup from 'molecules/BadgeGroup/BadgeGroup';

import ComponentButton from 'components/ComponentButton/ComponentButton';
import ButtonWrapper from 'components/ComponentButton/buttonWrapper';
import type { HeroCopyProps } from 'components/Hero/HeroStandard/heroTypesAndData';

import colorizeText from 'utils/colorizeText';
import RichTextParser from 'utils/richTextParser';

import type { Builder_ComponentButton } from 'graphqlTypes';
import type { FC } from 'react';

const HeroCopy: FC<HeroCopyProps> = ({
  heading,
  enableCenteredCopy,
  backgroundIsDark,
  subheading,
  badgeGroup,
  eyebrow,
  buttons,
}) => (
  <Container>
    <Flex
      flexDirection="column"
      gap="24px"
      maxWidth={container.md}
      marginY={enableCenteredCopy && 0}
      marginX={enableCenteredCopy && 'auto'}
    >
      {(badgeGroup?.badgeTitle || badgeGroup?.badgeDescription) && (
        <BadgeGroup
          backgroundColor={backgroundIsDark ? 'gray-800' : 'primary-50'}
          textColor={backgroundIsDark ? 'gray-100' : 'primary-700'}
          title={badgeGroup?.badgeDescription || 'Insert Description'}
          badgePosition="start"
          icon={<ArrowRight />}
        >
          <Badge
            size="sm"
            fontWeight="medium"
            textColor={backgroundIsDark ? 'primary-900' : 'common-white'}
            backgroundColor={backgroundIsDark ? 'primary-50' : 'primary-600'}
          >
            <Paragraph>{badgeGroup?.badgeTitle}</Paragraph>
          </Badge>
        </BadgeGroup>
      )}
      {eyebrow && <Text textStyle="eyebrow">{colorizeText(eyebrow, backgroundIsDark)}</Text>}
      {heading && (
        <Heading
          as="h1"
          textColor={backgroundIsDark ? 'common-white' : 'gray-900'}
          textStyle="md"
          fontWeight="semiBold"
          md={{ textStyle: 'lg', fontWeight: 'semiBold' }}
          xl={{ textStyle: 'xl', fontWeight: 'semiBold' }}
        >
          {heading}
        </Heading>
      )}
      {subheading && (
        <Container
          textColor={backgroundIsDark ? 'gray-50' : 'gray-700'}
          fontSize="textLg"
          fontWeight="medium"
          md={{ fontSize: 'textXl' }}
        >
          {RichTextParser(subheading)}
        </Container>
      )}
    </Flex>
    {buttons && (
      <Flex justifyContent={enableCenteredCopy ? 'center' : 'start'} width="100%" marginTop={32}>
        <ButtonWrapper>
          {buttons?.map((button, index) => (
            <ComponentButton
              buttonType={button?.buttonType || 'callToAction'}
              key={button.button?.value?.id || index}
              {...(button as Builder_ComponentButton)}
            />
          ))}
        </ButtonWrapper>
      </Flex>
    )}
  </Container>
);

export default HeroCopy;
