import type { valueof } from 'utils/typeUtils';
import { objectEntries } from 'utils/typeUtils';

import type { DefaultTheme } from 'styled-components';

const color = {
  common: { white: '#fff', black: '#000', transparent: 'transparent' },
  primary: {
    25: '#F6F6FF',
    50: '#EAEBFF',
    100: '#CBCCFF',
    200: '#A6ACFF',
    300: '#7E8AFF',
    400: '#5D6DFF',
    500: '#3B4EFF',
    600: '#3645F3',
    700: '#2B39E6',
    800: '#202BDB',
    900: '#0005CB',
  },
  gray: {
    25: '#FDFDFF',
    50: '#FAFAFF',
    100: '#F5F5FF',
    200: '#F0F0FF',
    300: '#E5E5F7',
    400: '#C2C2D4',
    500: '#A4A4B5',
    600: '#7A7A8B',
    700: '#666676',
    800: '#464756',
    900: '#252533',
  },
  softWhite: {
    25: '#FCFCFC',
    50: '#F9F9F9',
    100: '#F2F2F2',
    200: '#E9E9E9',
    300: '#D6D6D6',
    400: '#B5B5B5',
    500: '#969696',
    600: '#6D6D6D',
    700: '#5A5A5A',
    800: '#3B3B3B',
    900: '#1B1B1B',
  },
  irisBlue: {
    25: '#EAF9F9',
    50: '#DDF7F7',
    100: '#AAEAEB',
    200: '#6EDCDF',
    300: '#07CCD4',
    400: '#00C3CD',
    500: '#00B8C7',
    600: '#00A8B5',
    700: '#00939B',
    800: '#007F84',
    900: '#005C59',
  },
  purpleHeart: {
    25: '#F9EFFC',
    50: '#F4E3F9',
    100: '#E2BAF1',
    200: '#D08BE9',
    300: '#BB59E0',
    400: '#AD2BD9',
    500: '#9B00CD',
    600: '#8B00C7',
    700: '#7300C0',
    800: '#5D00B9',
    900: '#2A00AC',
  },
  bitterSweet: {
    25: '#FFF4F5',
    50: '#FFEBED',
    100: '#FFCDD0',
    200: '#FC9A94',
    300: '#F6726A',
    400: '#FF4F41',
    500: '#FF4021',
    600: '#F53523',
    700: '#E3291E',
    800: '#D62116',
    900: '#C61306',
  },
  warning: {
    25: '#FFFCF5',
    50: '##FFFAEB',
    100: '#FEF0C7',
    200: '#FEDF89',
    300: '#FEDF89',
    400: '#FDB022',
    500: '#F79009',
    600: '#DC6803',
    700: '#B54708',
    800: '#93370D',
    900: '#7A2E0E',
  },
  success: {
    25: '#F6FEF9',
    50: '#ECFDF3',
    100: '#D1FADF',
    200: '#A6F4C5',
    300: '#6CE9A6',
    400: '#32D583',
    500: '#12B76A',
    600: '#039855',
    700: '#027A48',
    800: '#05603A',
    900: '#054F31',
  },
  error: {
    25: '#FFFBFA',
    50: '#FEF3F2',
    100: '#FEE4E2',
    200: '#FECDCA',
    300: '#FDA29B',
    400: '#F97066',
    500: '#F04438',
    600: '#D92D20',
    700: '#B42318',
    800: '#912018',
    900: '#7A271A',
  },
} as const;

export type GetColorOptions<T> = {
  readonly [P in keyof T]: keyof T[P];
};

export type GetColorList<T> = {
  readonly [P in keyof T]: `${P extends number | string ? P : never}-${keyof T[P] extends string | number
    ? keyof T[P]
    : never}`;
}[keyof T];

export const globalValues = ['inherit', 'initial', 'revert', 'revert-layer', 'unset'] as const;
export type GlobalValues = typeof globalValues[number];

export type ColorObject = typeof color;
export type ColorOptions = GetColorOptions<ColorObject>;

export type ColorList = GetColorList<ColorObject> | GlobalValues;
export type ColorDefinition = keyof ColorObject;
export const colorListArray = objectEntries(color)
  .map(([colorName, colorValues]) => Object.keys(colorValues).map(value => `${colorName}-${value}`))
  .flat() as ColorList[];

export interface LightAndDark {
  light: ColorList;
  dark: ColorList;
}

type Common = ColorOptions['common'];
type Others = valueof<Omit<ColorOptions, 'common'>>;
type ColorArray = [ColorDefinition, Common | Others];

export type GetColorDefinition = ColorList | LightAndDark;

export const getColor = (palette: DefaultTheme['palette'], colors: GetColorDefinition) => {
  if (!colors) {
    return;
  }

  if (typeof colors === 'string' && globalValues.some(value => value === colors)) {
    return colors;
  }
  const [colorKey, colorValue] =
    typeof colors === 'string' ? (colors.split('-') as ColorArray) : (colors[palette.mode].split('-') as ColorArray);
  const subPalette = palette[colorKey];

  return 'white' in subPalette ? subPalette[colorValue as Common] : subPalette[colorValue as Others];
};

export default color;
