/* eslint-disable @typescript-eslint/no-explicit-any */
import { Document, Link, Page, StyleSheet, Text, View } from '@react-pdf/renderer';

import color from 'atoms/colors/colors';

import {
  AboutDoodle,
  AboutWhisk,
  BlueCircleCheck,
  CircleCheck,
  HeaderLeft,
  HeaderRight,
  Logo,
} from 'components/RoiCalculator/Logo';

import type { FC } from 'react';

interface RoiResultsDocumentProps {
  finalAvgMonthlySales: string | number;
  finalPercentLabor: string | number;
  finalPercentImprovedLabor: string | number;
  finalNumberLocations: string | number;
  finalPlanChosen: string | number;
  finalRoi: string | number;
  downloadSubheading?: string;
  downloadFeatureText?: string[];
  aboutLineup?: { heading?: string; subheading?: string; bulletOne?: string; bulletTwo?: string; bulletThree?: string };
  planToggle?: boolean;
}

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    paddingTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 0,
    fontFamily: 'Helvetica',
  },
  padding: {
    padding: 16,
  },
  paddingBottom: {
    paddingBottom: 8,
  },
  paddingY: {
    paddingVertical: 16,
  },
  paddingX: {
    paddingHorizontal: 16,
  },
  marginX: {
    marginHorizontal: 12,
  },
  marginY: {
    marginVertical: 8,
  },
  lightGray: {
    color: `${color.gray[700]}`,
  },
  darkGray: {
    color: `${color.gray[900]}`,
  },
  blue: {
    color: `${color.primary[600]}`,
  },
  headerRight: {
    position: 'absolute',
    right: -60,
  },
  right: {
    position: 'absolute',
    right: 0,
  },
  left: {
    position: 'absolute',
    left: 0,
  },
  logo: {
    position: 'absolute',
    left: 20,
  },
  background: {
    height: 70,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    backgroundColor: `${color.primary[25]}`,
  },
  eyebrow: {
    textTransform: 'uppercase',
    fontSize: 8,
    lineHeight: 1.2,
    fontFamily: 'Helvetica-Bold',
    paddingLeft: 16,
    paddingBottom: 16,
    marginTop: 64,
  },
  title: {
    fontSize: 16,
    lineHeight: 1.2,
    fontWeight: 900,
    paddingLeft: 16,
    fontFamily: 'Helvetica-Bold',
  },
  text: {
    fontSize: 10,
    lineHeight: 1.3,
    flexGrow: 0,
  },
  bold: {
    fontSize: 14,
    lineHeight: 1.3,
    fontWeight: 900,
    fontFamily: 'Helvetica-Bold',
  },
  halfText: {
    width: 350,
  },
  bullet: {
    display: 'flex',
    flexDirection: 'row',
    gap: 4,
    marginVertical: 4,
  },
  border: {
    border: `0.5px solid ${color.gray[300]}`,
    borderRadius: 8,
  },
  row: {
    flexDirection: 'row',
    columnGap: 8,
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 16,
    paddingVertical: 12,
    borderBottom: `0.5px solid ${color.gray[300]}`,
  },
  bottomSection: {
    backgroundColor: `${color.primary[25]}`,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    rowGap: 8,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  about: {
    border: `0.5px solid ${color.primary[500]}`,
    borderRadius: 8,
    backgroundColor: `${color.primary[500]}`,
    color: `${color.common.white}`,
    marginTop: 24,
    paddingRight: 16,
    position: 'relative',
  },
  footer: {
    fontSize: 8,
    position: 'absolute',
    bottom: 12,
    paddingHorizontal: 16,
  },
});

const ResultsDocument: FC<RoiResultsDocumentProps> = ({
  finalAvgMonthlySales,
  finalPercentLabor,
  finalPercentImprovedLabor,
  finalNumberLocations,
  finalPlanChosen,
  finalRoi,
  downloadSubheading,
  downloadFeatureText,
  aboutLineup,
  planToggle,
}) => {
  const MyDocument: any = Document;
  const MyPage: any = Page;
  const MyView: any = View;
  const MyText: any = Text;
  const MyLink: any = Link;

  return (
    <MyDocument>
      <MyPage size="letter" style={styles.page}>
        <MyView style={styles.background}>
          <MyView style={styles.left}>
            <HeaderLeft />
          </MyView>
          <MyView style={styles.headerRight}>
            <HeaderRight />
          </MyView>
        </MyView>
        {/* eslint-disable-next-line no-inline-styles/no-inline-styles */}
        <MyView style={{ width: '100%' }}>
          <MyView style={styles.logo}>
            <Logo />
          </MyView>
          <MyText style={[styles.blue, styles.eyebrow, styles.text]}>ROI Calculation Results</MyText>
          <MyText style={styles.title}>
            Annual projected savings with <MyLink src="https://lineup.ai/">Lineup.ai</MyLink>
          </MyText>
          <MyView style={[styles.padding, styles.halfText]}>
            <MyText style={[styles.text, styles.lightGray, styles.paddingBottom]}>{downloadSubheading}</MyText>
            {downloadFeatureText &&
              downloadFeatureText.map(feature => (
                <MyView key={Math.floor(Math.random() * 100)} style={styles.bullet}>
                  <BlueCircleCheck />
                  <MyText style={[styles.text, styles.darkGray]}>{feature}</MyText>
                </MyView>
              ))}
          </MyView>
          <MyText style={[styles.title, styles.paddingBottom]}>Results</MyText>
          <MyView style={[styles.border, styles.marginX]}>
            <MyView style={styles.row}>
              <MyText style={[styles.darkGray, styles.text]}>Avg. monthy sales</MyText>
              <MyText style={[styles.blue, styles.text]}>{finalAvgMonthlySales}</MyText>
            </MyView>
            <MyView style={styles.row}>
              <MyText style={[styles.darkGray, styles.text]}>% Toward labor</MyText>
              <MyText style={[styles.blue, styles.text]}>{finalPercentLabor}%</MyText>
            </MyView>
            <MyView style={styles.row}>
              <MyText style={[styles.darkGray, styles.text]}>Improved % toward labor</MyText>
              <MyText style={[styles.blue, styles.text]}>{finalPercentImprovedLabor}%</MyText>
            </MyView>
            <MyView style={styles.row}>
              <MyText style={[styles.darkGray, styles.text]}>Number of locations</MyText>
              <MyText style={[styles.blue, styles.text]}>{finalNumberLocations}</MyText>
            </MyView>
            {planToggle && (
              <MyView style={styles.row}>
                <MyText style={[styles.darkGray, styles.text]}>Plan chosen</MyText>
                <MyText style={[styles.blue, styles.text]}>{finalPlanChosen}</MyText>
              </MyView>
            )}
            <MyView style={[styles.bottomSection, styles.padding]}>
              <MyText style={[styles.blue, styles.bold]}>Annual ROI</MyText>
              <MyText style={[styles.blue, styles.bold]}>{finalRoi}</MyText>
            </MyView>
          </MyView>
          <MyView style={[styles.about, styles.marginX, styles.paddingY]}>
            <MyView style={styles.left}>
              <AboutDoodle />
            </MyView>
            <MyView style={styles.right}>
              <AboutWhisk />
            </MyView>
            <MyText style={[styles.title]}>{aboutLineup?.heading ?? 'About Lineup.ai'}</MyText>
            {aboutLineup?.subheading && (
              <MyText style={[styles.text, styles.paddingX, styles.marginY]}>{aboutLineup.subheading}</MyText>
            )}
            {aboutLineup?.bulletOne && (
              <MyView style={[styles.bullet, styles.paddingX]}>
                <CircleCheck />
                <MyText style={styles.text}>{aboutLineup.bulletOne}</MyText>
              </MyView>
            )}
            {aboutLineup?.bulletTwo && (
              <MyView style={[styles.bullet, styles.paddingX]}>
                <CircleCheck />
                <MyText style={styles.text}>{aboutLineup.bulletTwo}</MyText>
              </MyView>
            )}
            {aboutLineup?.bulletThree && (
              <MyView style={[styles.bullet, styles.paddingX]}>
                <CircleCheck />
                <MyText style={styles.text}>{aboutLineup.bulletThree}</MyText>
              </MyView>
            )}
          </MyView>
          <MyText style={[styles.footer, styles.lightGray]}>Page 1 of 1</MyText>
          <MyText style={[styles.footer, styles.right, styles.blue]}>
            <MyLink src="https://lineup.ai/">Lineup.ai</MyLink>
          </MyText>
        </MyView>
      </MyPage>
    </MyDocument>
  );
};

export default ResultsDocument;
