import type { BasicProps } from 'quarks/interpolations/basic';

export interface ReportCardTypes extends BasicProps {
  heading?: string;
  features?: { feature: string }[];
}

export const reportCardsBuilderDataField = [
  {
    name: 'reportCard',
    type: 'object',
    subFields: [
      {
        name: 'heading',
        type: 'string',
      },
      {
        name: 'features',
        type: 'list',
        subFields: [
          {
            name: 'feature',
            type: 'string',
          },
        ],
      },
    ],
  },
];
