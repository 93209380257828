import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import MUISelect from '@mui/material/Select';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import Icon from 'atoms/Icons/Icon';
import { fontFamily } from 'atoms/typography/typography';

import Flex from 'quarks/Flex';
import Text from 'quarks/Text';
import type { BasicProps } from 'quarks/interpolations/basic';
import { basic } from 'quarks/interpolations/basic';
import { allCSSWithPseudos } from 'quarks/styleProps/all';

import { menuProps } from 'molecules/Select/Select.theme';

import type { SelectChangeEvent } from '@mui/material/Select';
import type { ChangeEvent, ChangeEventHandler, FC, ReactNode } from 'react';

const StyledFormControl = styled(FormControl).withConfig({
  shouldForwardProp: prop => !Object.keys(allCSSWithPseudos).includes(prop),
})`
  && {
    ${basic}
  }
`;

const StyledSelect = styled(MUISelect).withConfig({
  shouldForwardProp: prop => !Object.keys(allCSSWithPseudos).includes(prop),
})`
  && {
    ${basic}
  }
`;

export interface SelectProps extends BasicProps {
  /**
   * The id of the `Select field` element. Use this prop to make `label` and `helperText` accessible for screen readers.
   */
  id?: string;
  /**
   * Items set inside `list` as an array will appear inside the `Select` dropdown.
   */
  list: (string | undefined | null)[] | { label: string | number; value: string | number }[];
  /**
   * Name attribute of the `Select field` element.
   */
  name?: string;
  /**
   * displays error message under the label (replaces helper text)
   */
  errorMessage?: string;
  /**
   * If `true`, the component is places in the error state
   */
  error?: boolean;
  /**
   * This sets the `placeholderText` that appears inside `Select` as placeholder text.
   */
  placeholderText?: string;
  /**
   * The name that appears above `Select`.
   */
  label?: string;
  /**
   * The default value. Use when the component is not controlled.
   */
  defaultValue?: string;
  /**
   * The `icon` that appears to the left of ALL menu items in the dropdown and `select` window.
   * To set a custom icon for each item, pass a mapped array of JSX element to `children`.
   */
  itemIcon?: ReactNode;
  /**
   * Sets the size of the `itemIcon`. Can be set with a `px` or `rem` value.
   */
  iconSize?: string;
  /**
   * The `icon` that appears in the `select` window. Follows the rules set in the `Icon` atom.
   */
  startIcon?: ReactNode;
  /**
   * displays text under the label to assist the user.
   */
  helperText?: string;
  /**
   * Callback fired when a menu item is selected.
   */
  onChange?: ChangeEventHandler<HTMLInputElement>;
  /**
   * If `true`, the label is displayed as required and the `TextField` element is required.
   */
  required?: boolean;
  /**
   * if user clicks the submit button, it sets true
   */
  submitValidation?: boolean;
}

const Select: FC<SelectProps> = ({
  id,
  list,
  name,
  placeholderText,
  defaultValue,
  label,
  fontSize,
  fontWeight,
  itemIcon,
  iconSize,
  startIcon,
  onChange,
  error,
  helperText,
  errorMessage,
  required,
  ...props
}) => {
  const [value, setValue] = useState(defaultValue || '');

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const targetValue = event.target.value;
    setValue(targetValue as string);
    onChange && onChange(event as ChangeEvent<HTMLInputElement>);
  };

  return (
    <StyledFormControl error={error} {...props}>
      <Text
        as="label"
        htmlFor={id || name}
        fontSize={fontSize}
        fontWeight={fontWeight}
        fontFamily="textFont"
        textColor="gray-700"
      >
        {label}
      </Text>
      <StyledSelect
        id={id || name}
        name={name}
        value={value}
        position="relative"
        displayEmpty={true}
        MenuProps={menuProps}
        borderRadius="8px"
        marginY={4}
        css={`
          svg {
            position: absolute;
            top: 33%;
            right: 14px;
          }
          [aria-expanded='true'] ~ svg {
            transform: rotate(180deg);
          }
        `}
        IconComponent={() => <Icon id="chevron-down" iconColor="gray-500" size={20} paddingRight={10} />}
        startAdornment={startIcon}
        onChange={handleChange}
        required={required}
        error={error}
        border="solid 1px"
        borderColor="gray-400"
        hover={{
          backgroundColor: 'primary-25',
        }}
        sx={{
          '.MuiInputBase-input': {
            fontSize: '16px',
            fontFamily: fontFamily.textFont,
            borderRadius: '8px',
            padding: '10px 14px',
          },
          '.MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
        }}
      >
        {placeholderText && (
          <MenuItem value="">
            <Flex textColor={{ light: 'gray-500', dark: 'gray-400' }}>{placeholderText}</Flex>
          </MenuItem>
        )}
        {list.map(item => {
          if (!item) {
            return null;
          }

          const itemValue = typeof item === 'string' ? item : item.value;
          const itemLabel = typeof item === 'string' ? item : item.label;

          return (
            <MenuItem key={itemValue} value={itemValue}>
              <Flex alignItems="center" fontSize="textMd" fontFamily="textFont">
                {itemIcon && (
                  <Flex marginRight={8} width={iconSize}>
                    {itemIcon}
                  </Flex>
                )}
                {itemLabel}
              </Flex>
            </MenuItem>
          );
        })}
      </StyledSelect>
      <Text textStyle="sm" fontWeight="semiBold" textColor={error ? 'error-400' : 'gray-600'}>
        {error ? errorMessage : helperText}
      </Text>
    </StyledFormControl>
  );
};

export default Select;

Select.defaultProps = {
  minWidth: '280px',
  fontSize: 'textSm',
  fontWeight: 'semiBold',
  onChange: () => null,
};
