import { useMediaQuery } from '@mui/material';
import { memo, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useWindowScroll } from 'react-use';

import { media } from 'atoms/breakpoints/breakpoints';

import type { BasicProps } from 'quarks/interpolations/basic';

import type { BannerProps } from 'components/Banner/Banner';
import Banner from 'components/Banner/Banner';
import FullHeader from 'components/Header/FullHeader';
import SimpleHeader from 'components/Header/SimpleHeader';

import type { ContextState as ModalContextState } from 'contexts/ModalProvider';
import { ModalContext } from 'contexts/ModalProvider';

import type { Builder_ComponentHeaderData } from 'graphqlTypes';
import type { FC } from 'react';

export interface GeneralHeaderProps extends BasicProps {
  pageHasScrolled: boolean;
  borderBottomValue: number;
  mobileHeaderHeight: number;
  desktopHeaderHeight: number;
  modalState: ModalContextState;
  isDarkHeader?: boolean;
}
interface HeaderProps extends Builder_ComponentHeaderData, BasicProps {
  banner?: BannerProps;
  isLandingPage?: boolean;
  isDarkHeader?: boolean;
}

const Header: FC<HeaderProps> = ({
  isLandingPage,
  isDarkHeader,
  banner,
  navLoginButton,
  navRegisterButton,
  menuItems,
}) => {
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const [openMenu, setOpenMenu] = useState<number | null>(null);
  const [pageHasScrolled, setPageHasScrolled] = useState(false);

  const mobileNavMenuRef = useRef(null);

  const isDesktop = useMediaQuery(media.xl);

  const { modalState } = useContext(ModalContext);

  const handleMenuClick = (menuIndex: number) => {
    if (openMenu === menuIndex) {
      return setOpenMenu(null);
    }
    setOpenMenu(menuIndex);
  };

  const { y: scrollTopPxCount } = useWindowScroll();

  const borderBottomValue = 1;
  const mobileHeaderHeight = parseInt(`${isLandingPage ? 70 : 71}`) + borderBottomValue;
  const desktopHeaderHeight = parseInt(`${isLandingPage ? 93 : 71}`) + borderBottomValue;

  useEffect(() => {
    if (pageHasScrolled === false && scrollTopPxCount > 0) {
      setPageHasScrolled(true);
    } else if (pageHasScrolled === true && scrollTopPxCount === 0) {
      setPageHasScrolled(false);
    } else if (pageHasScrolled === true && scrollTopPxCount > desktopHeaderHeight && isDesktop) {
      setOpenMenu(null);
    }
  }, [scrollTopPxCount]);

  const HeaderContent = useMemo(() => {
    if (isLandingPage) {
      return (
        <SimpleHeader
          pageHasScrolled={pageHasScrolled}
          borderBottomValue={borderBottomValue}
          mobileHeaderHeight={mobileHeaderHeight}
          desktopHeaderHeight={desktopHeaderHeight}
          modalState={modalState}
        />
      );
    } else {
      return (
        <FullHeader
          navLoginButton={navLoginButton}
          navRegisterButton={navRegisterButton}
          menuItems={menuItems}
          pageHasScrolled={pageHasScrolled}
          isMobileNavOpen={isMobileNavOpen}
          openMenu={openMenu}
          borderBottomValue={borderBottomValue}
          mobileHeaderHeight={mobileHeaderHeight}
          desktopHeaderHeight={desktopHeaderHeight}
          handleMenuClick={handleMenuClick}
          setOpenMenu={setOpenMenu}
          mobileNavMenuRef={mobileNavMenuRef}
          setIsMobileNavOpen={setIsMobileNavOpen}
          modalState={modalState}
          isDarkHeader={isDarkHeader && !pageHasScrolled && !isMobileNavOpen}
        />
      );
    }
  }, [isLandingPage, pageHasScrolled, isMobileNavOpen, openMenu, modalState]);

  return (
    <>
      {banner && (
        <Banner
          includeIcon={banner?.includeIcon}
          body={banner?.body}
          heading={banner?.heading}
          button={banner?.button}
          hideBanner={pageHasScrolled}
        />
      )}
      {HeaderContent}
    </>
  );
};

export default memo(Header);
