import Icon from 'atoms/Icons/Icon';
import type { iconIds } from 'atoms/Icons/Icon.types';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Text from 'quarks/Text';

import type { FeaturesCardProps } from 'components/Cards/FeaturesCard/featuresCardConfigAndTypes';

import RichTextParser from 'utils/richTextParser';

import type { FC } from 'react';

const FeaturesCard: FC<FeaturesCardProps> = ({ heading, subheading, icon, backgroundIsDark, isGradientEnabled }) => {
  const backgroundColor = backgroundIsDark ? 'gray-800' : 'gray-25';
  const gradientColor = backgroundIsDark ? 'lineupGradient-darkMode' : 'lineupGradient-lightMode';

  const iconColor = backgroundIsDark ? 'gray-900' : 'primary-600';
  const customBackground = backgroundIsDark ? 'gray-100' : 'primary-100';

  return (
    <Flex
      flexDirection="column"
      maxWidth="328px"
      height="100%"
      backgroundImage={isGradientEnabled ? gradientColor : 'primary-100'}
      backgroundColor={backgroundColor}
      border="1px solid"
      borderColor="primary-100"
      borderRadius="16px"
      boxShadow="lg"
      css={`
        padding: 1px;
      `}
      md={{ maxWidth: '237px' }}
      xl={{ maxWidth: '370px' }}
    >
      <Flex
        marginX="auto"
        backgroundColor={backgroundColor}
        borderRadius="14px"
        paddingX={24}
        paddingY={32}
        alignItems="center"
        flexDirection="column"
        gap="16px"
        textAlign="center"
        xl={{ paddingY: 40, paddingX: 32 }}
      >
        {icon && (
          <Icon
            size={24}
            id={icon as iconIds}
            isBackgroundDark={backgroundIsDark}
            backgroundColor={customBackground}
            iconColor={iconColor}
            wrapped
          />
        )}
        {heading && (
          <Text
            textColor={backgroundIsDark ? 'common-white' : 'gray-900'}
            textStyle="xl"
            fontWeight="semiBold"
            md={{ textStyle: 'lg', fontWeight: 'bold' }}
            xl={{ textStyle: 'xxl', fontWeight: 'bold' }}
          >
            {heading}
          </Text>
        )}
        {subheading && (
          <Container
            textColor={backgroundIsDark ? 'gray-50' : 'gray-700'}
            fontFamily="textFont"
            fontSize="textMd"
            xl={{ fontSize: 'textLg' }}
          >
            {RichTextParser(subheading)}
          </Container>
        )}
      </Flex>
    </Flex>
  );
};

export default FeaturesCard;

FeaturesCard.defaultProps = {};
