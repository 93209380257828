/* eslint-disable no-useless-escape */
import Flex from 'quarks/Flex';
import Paragraph from 'quarks/Paragraph';
import type { FlexProps } from 'quarks/interpolations/flex';

import type { FC, ReactNode } from 'react';

interface BadgeGroupProps extends FlexProps {
  /**
   * `<Badge>` component to be used within the `BadgeGroup`
   */
  children: ReactNode;
  /**
   * Determines if the badge will be at the `start` or `end` of the badgeGroup
   */
  badgePosition: 'start' | 'end';
  /**
   * Text or Paragraph Component or string that will appear next to the badge.
   */
  title: ReactNode | string;
  /**
   * Optional icon to go after/before the text. the icon will always be on the opposite side of the badge.
   */
  icon?: ReactNode;
}

const BadgeGroup: FC<BadgeGroupProps> = ({ children, badgePosition, icon, title, ...props }) => {
  const isBadgeStart = badgePosition === 'start';

  return (
    <Flex
      gap="8px"
      paddingY={4}
      lineHeight="textLg"
      width="fit-content"
      backgroundColor="irisBlue-50"
      borderRadius="16px"
      fontSize="textXs"
      fontWeight="medium"
      alignItems="center"
      flexDirection={isBadgeStart ? 'row' : 'row-reverse'}
      xs={{ paddingLeft: children ? 4 : 12, paddingRight: 12 }}
      sm={{ fontSize: 'textSm' }}
      css={`
        > svg {
          height: 18px;
          width: 18px;
        }
      `}
      {...props}
    >
      {children}
      {typeof title === 'string' ? <Paragraph>{title}</Paragraph> : title}
      {icon && icon}
    </Flex>
  );
};

export default BadgeGroup;

BadgeGroup.defaultProps = {
  textColor: { light: 'primary-700', dark: 'gray-700' },
  backgroundColor: { light: 'primary-50', dark: 'gray-50' },
  badgePosition: 'start',
};
