import type { valueof } from 'utils/typeUtils';

const container = {
  xs: '328px',
  sm: '570px',
  md: '770px',
  lg: '1170px',
  xl: '1280px',
} as const;

export type ContainerDefinition = keyof typeof container;
export type ContainerValues = valueof<typeof container>;
export default container;
