const CopyBuilderConfig = {
  name: 'Copy',
  override: true,
  image: 'https://tabler-icons.io/static/tabler-icons/icons-png/pilcrow.png',
  requiresParent: {
    message: 'Block must be placed inside the Content Editor',
    component: 'ContentEditor',
  },
  inputs: [{ name: 'copy', type: 'richText' }],
};
export default CopyBuilderConfig;
