import BlockQuote from 'builder/BlockQuote';
import { useState } from 'react';

import color from 'atoms/colors/colors';
import container from 'atoms/spacing/containers';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Heading from 'quarks/Heading';
import Image from 'quarks/Image';
import Text from 'quarks/Text';
import type { BasicProps } from 'quarks/interpolations/basic';
import type { HeadingTypes } from 'quarks/styleProps/heading';

import Section from 'molecules/Section/Section';
import Switchback from 'molecules/Switchback/Switchback';

import type { ButtonTypes } from 'components/ComponentButton/buttonTypeConfig';

import colorizeText from 'utils/colorizeText';
import type { DoodleKey } from 'utils/doodleGenerator';
import { getBackgroundIsDark, optimizeBuilderImage } from 'utils/functions';
import onKeyDown from 'utils/onKeyDown';

import type { Builder_EntityPersonData } from 'graphqlTypes';
import type { FC } from 'react';

interface SwitcherProps extends BasicProps {
  background?: string;
  eyebrow?: string;
  heading?: string;
  tabs: {
    title?: string;
    tabIcon?: string;
    switchbacks?: {
      eyebrow?: string;
      buttons?: ButtonTypes;
      coloredEyebrow?: boolean;
      heading?: string;
      headingType?: HeadingTypes;
      doodle?: DoodleKey;
      subheading?: string;
      switchbackImage?: string;
      imageCaption?: string;
      assetSide?: string;
      sectionId?: string;
    }[];
    quoteAuthor?: { value: { data: Builder_EntityPersonData; id: string } };
    quote?: string;
  }[];
  sectionId?: string;
}

const Switcher: FC<SwitcherProps> = ({ background, eyebrow, heading, tabs, sectionId }) => {
  const firstTabTitle = tabs?.[0]?.title;
  const backgroundIsDark = getBackgroundIsDark(background);

  const [active, setActive] = useState(`${firstTabTitle}-0`);

  return (
    <>
      <Section __typename="ComponentSwitcherHeading" id={sectionId} background="White" sectionType="noBottomPadding">
        <Flex
          textAlign="center"
          flexDirection="column"
          gap="24px"
          marginX="auto"
          marginBottom={48}
          max-width={container.md}
        >
          {eyebrow && <Text textStyle="eyebrow">{colorizeText(eyebrow, backgroundIsDark)}</Text>}
          {heading && (
            <Heading
              as="h2"
              textColor={backgroundIsDark ? 'common-white' : 'gray-900'}
              textStyle="xs"
              md={{ textStyle: 'md' }}
              xl={{ textStyle: 'lg' }}
              fontWeight="semiBold"
            >
              {heading}
            </Heading>
          )}
        </Flex>
      </Section>
      {!!tabs?.length && (
        <>
          <Section __typename="ComponentSwitcherTabs" background="White" sectionType="noPadding">
            <Flex justifyContent="center" minWidth="fit-content" width="100%">
              {tabs?.map((tab, index) => (
                <Flex
                  key={tab?.title || index}
                  role="button"
                  onClick={() => setActive(`${tab?.title}-${index}`)}
                  onKeyDown={e => onKeyDown(e, () => setActive(`${tab?.title}-${index}`), 'Enter')}
                  tabIndex={0}
                  flexDirection="column"
                  gap="16px"
                  paddingBottom={16}
                  paddingX={16}
                  alignItems="center"
                  fontSize="textXl"
                  fontWeight="semiBold"
                  cursor="pointer"
                  whiteSpace="nowrap"
                  textAlign="center"
                  aria-label={tab?.title}
                  aria-current={`${tab?.title}-${index}` === active ? true : false}
                  xl={{
                    fontSize: 'displayXs',
                  }}
                  textColor={`${tab?.title}-${index}` === active ? 'primary-700' : 'common-black'}
                  css={`
                    border-bottom: ${`${tab?.title}-${index}` === active
                      ? `4px solid ${color.primary[500]}`
                      : '4px solid transparent'};
                    width: calc(100% / ${tabs.length});
                    min-width: fit-content;
                  `}
                >
                  {tab?.tabIcon && (
                    <Image
                      image={optimizeBuilderImage(tab.tabIcon)}
                      alt={tab?.title ?? ''}
                      maxWidth="48px"
                      maxHeight="48px"
                      css={`
                        opacity: ${`${tab?.title}-${index}` === active ? 1 : 0.5};
                      `}
                    />
                  )}
                  {tab?.title}
                </Flex>
              ))}
            </Flex>
          </Section>
          <Section __typename="ComponentSwitcher" background={background}>
            <Flex
              flexDirection="column"
              gap="40px"
              lg={{
                gap: '72px',
              }}
              xl={{
                gap: '96px',
              }}
            >
              {tabs?.map((tab, index) => {
                if (`${tab?.title}-${index}` === active) {
                  const hasQuote = tab?.quoteAuthor && tab?.quote;

                  return (
                    <>
                      {tab?.switchbacks?.map(switchback => (
                        <Container key={switchback?.sectionId}>
                          <Switchback
                            background={background}
                            assetType="Image"
                            buttons={switchback?.buttons}
                            coloredEyebrow={switchback?.coloredEyebrow}
                            eyebrow={switchback?.eyebrow}
                            heading={switchback?.heading}
                            headingType={switchback?.headingType}
                            imageCaption={switchback?.imageCaption}
                            subheading={switchback?.subheading}
                            doodle={switchback?.doodle}
                            switchbackImage={switchback?.switchbackImage}
                            assetSide={switchback?.assetSide}
                            sectionId={switchback?.sectionId}
                          />
                        </Container>
                      ))}
                      {hasQuote && <BlockQuote quote={tab?.quote} author={tab?.quoteAuthor} />}
                    </>
                  );
                } else {
                  return null;
                }
              })}
            </Flex>
          </Section>
        </>
      )}
    </>
  );
};

export default Switcher;
