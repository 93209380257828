import container from 'atoms/spacing/containers';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Heading from 'quarks/Heading';
import Image from 'quarks/Image';
import Text from 'quarks/Text';
import type { BasicProps } from 'quarks/interpolations/basic';

import Section from 'molecules/Section/Section';
import VideoPlayer from 'molecules/VideoPlayer/VideoPlayer';
import type { VideoPlayerProps } from 'molecules/VideoPlayer/VideoPlayer';

import colorizeText from 'utils/colorizeText';
import type { DoodleKey } from 'utils/doodleGenerator';
import doodleGenerator from 'utils/doodleGenerator';
import { getBackgroundIsDark } from 'utils/functions';
import RichTextParser from 'utils/richTextParser';

import type { FC } from 'react';

interface VideoSectionProps extends BasicProps, VideoPlayerProps {
  eyebrow?: string;
  coloredEyebrow?: boolean;
  heading?: string;
  subheading?: string;
  doodle?: DoodleKey;
  background?: string;
  sectionId?: string;
}

const VideoSection: FC<VideoSectionProps> = ({
  eyebrow,
  coloredEyebrow,
  heading,
  subheading,
  doodle,
  background,
  videoUrl,
  thumbnailImage,
  darkVideoPlayButton,
  title,
  description,
  ctaButton,
  sectionId,
}) => {
  const backgroundIsDark = getBackgroundIsDark(background);

  const textColor = backgroundIsDark ? 'common-white' : 'gray-900';
  const styledEyebrow = coloredEyebrow && eyebrow ? colorizeText(eyebrow, backgroundIsDark) : eyebrow;

  const doodleImage = doodle && doodleGenerator(doodle);

  return (
    <Section __typename="ComponentVideoSection" background={background} id={sectionId}>
      <Flex gap="32px" md={{ gap: '56px' }} flexDirection="column">
        <Flex
          flexDirection="column"
          gap="24px"
          alignItems="center"
          textAlign="center"
          textColor={textColor}
          marginX="auto"
          md={{ maxWidth: container.md }}
        >
          {eyebrow && <Text textStyle="eyebrow">{styledEyebrow}</Text>}
          {heading && (
            <Heading as="h2" textStyle="xs" fontWeight="semiBold" md={{ textStyle: 'md' }} xl={{ textStyle: 'lg' }}>
              {heading}
            </Heading>
          )}
          {subheading && (
            <Container
              textColor={backgroundIsDark ? 'gray-50' : 'gray-700'}
              fontSize="textLg"
              md={{ fontSize: 'textXl' }}
            >
              {RichTextParser(subheading)}
            </Container>
          )}
        </Flex>
        {videoUrl && (
          <Container position="relative" marginX="auto" md={{ maxWidth: container.md }}>
            <VideoPlayer
              thumbnailImage={thumbnailImage}
              videoUrl={videoUrl}
              title={title}
              description={description}
              ctaButton={ctaButton}
              darkVideoPlayButton={darkVideoPlayButton}
            />
            {doodleImage && (
              <Image
                width="259px"
                left="90%"
                bottom="-24%"
                position="absolute"
                css={{ pointerEvents: 'none' }}
                zIndex={-10}
                image={doodleImage}
                alt={doodle}
                xl={{ width: '390px', bottom: '-34%' }}
              />
            )}
          </Container>
        )}
      </Flex>
    </Section>
  );
};

export default VideoSection;
