import { builder } from '@builder.io/react';
import { LazyMotion } from 'framer-motion';

import { ModalProvider } from 'contexts/ModalProvider';
import { ThemeProvider } from 'contexts/ThemeProvider';

import type { GatsbyBrowser } from 'gatsby';

const features = () => import('./src/utils/motionFeatures').then(res => res.default);

builder.init(process.env.GATSBY_BUILDER_KEY || '');

// eslint-disable-next-line import/prefer-default-export
export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element }) => (
  <ThemeProvider>
    <LazyMotion features={features} strict>
      <ModalProvider>{element}</ModalProvider>
    </LazyMotion>
  </ThemeProvider>
);
