import Flex from 'quarks/Flex';
import Image from 'quarks/Image';
import Text from 'quarks/Text';

import ComponentButton from 'components/ComponentButton/ComponentButton';

import { optimizeBuilderImage } from 'utils/functions';

import type { FC } from 'react';

export interface IntegrationCardProps {
  integration: {
    /**
     * Text for an Image Caption
     */
    imageAlt?: string;
    /**
     * Gets Converted from absolute URL into a Gatsby Image.
     */
    brandLogo?: string;
    /**
     * Determines the text for the heading
     */
    heading?: string;
    /**
     * Determines the text for the subheading
     */
    subheading?: string;
    /**
     * The URL to link to when the button is clicked. If defined, an `a` element will be used as the root node.
     */
    href?: string;
  };
}

const IntegrationCard: FC<IntegrationCardProps> = ({ integration, ...props }) => {
  const { heading, subheading, href, brandLogo = false, imageAlt = '' } = integration;
  const optimizedImage = brandLogo && optimizeBuilderImage(brandLogo);

  return (
    <Flex
      flexDirection="column"
      width="100%"
      backgroundColor="common-white"
      borderRadius="16px"
      overflowY="hidden"
      paddingBottom={24}
      boxShadow="lg"
      hover={{
        boxShadow: 'xxl',
      }}
      sm={{ width: '47%', maxWidth: '370px' }}
      {...props}
    >
      {optimizedImage && (
        <Image
          objectFit="contain"
          borderTop="16px"
          image={optimizedImage}
          alt={imageAlt || ''}
          height="240px"
          marginBottom={24}
        />
      )}
      <Flex paddingX={24} flexDirection="column" gap="4px" md={{ gap: '12px' }}>
        <Flex gap="8px" flexDirection="column">
          {heading && (
            <Text textStyle="xl" fontWeight="semiBold" md={{ textStyle: 'xxl', fontWeight: 'semiBold' }}>
              {heading}
            </Text>
          )}
          {subheading && (
            <Text
              textOverflow="ellipsis"
              textStyle="sm"
              textColor="gray-700"
              css={`
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
              `}
              md={{ textStyle: 'md' }}
            >
              {subheading}
            </Text>
          )}
        </Flex>
        {href && (
          <ComponentButton
            buttonType="callToAction"
            button={{
              value: {
                data: {
                  hierarchy: 'Link Gray',
                  size: 'xLarge',
                  label: 'Learn More',
                  link: href,
                  endIcon: '>',
                },
              },
            }}
            trackingSchema={{
              hierarchy: '1 of 1',
              location: 'IntegrationCard',
            }}
          />
        )}
      </Flex>
    </Flex>
  );
};

export default IntegrationCard;

IntegrationCard.defaultProps = {};
