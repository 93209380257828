import SectionBuilderConfig from 'molecules/Section/Section.config';

import { cardContentTypeList, cardDeckTypeList } from 'components/CardDeck/cardDeckPropsConfig';
import cardsBuilderDataField from 'components/Cards/EntryCard/entryCardConfig';
import { featuresCardBuilderDataField } from 'components/Cards/FeaturesCard/featuresCardConfigAndTypes';
import { historyCardBuilderDataField } from 'components/Cards/HistoryCard/historyCardConfigTypes';
import { teamCardsBuilderDataField } from 'components/Cards/TeamCard/teamCardConfigTypes';
import { ButtonBuilderConfig } from 'components/ComponentButton/buttonTypeConfig';

import { doodleField } from 'utils/doodleGenerator';

const CardDeckBuilderConfig = {
  name: 'ComponentCardDeck',
  inputs: [
    {
      name: 'background',
      type: 'text',
      enum: ['White', 'Concave', 'Convex', 'Dark Concave', 'Dark Convex', 'Gray-100', 'Gray-900'],
      defaultValue: 'White',
    },
    { name: 'eyebrow', type: 'text', defaultValue: 'Eyebrow' },
    { name: 'heading', type: 'text', defaultValue: 'Card Deck' },
    { name: 'subheading', type: 'richText' },
    {
      name: 'buttonGroup',
      type: 'list',
      subFields: ButtonBuilderConfig.inputs,
    },
    ...doodleField,
    { name: 'cardContentType', type: 'text', enum: cardContentTypeList, defaultValue: 'Entry Cards' },
    { name: 'cardDeckType', type: 'text', enum: cardDeckTypeList, defaultValue: 'Standard' },
    ...cardsBuilderDataField,
    ...featuresCardBuilderDataField,
    ...historyCardBuilderDataField,
    ...teamCardsBuilderDataField,
    ...SectionBuilderConfig.inputs,
  ],
};

export default CardDeckBuilderConfig;
