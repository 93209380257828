import FormControlLabel from '@mui/material/FormControlLabel';
import MuiSwitch from '@mui/material/Switch';
import styled from 'styled-components';

import type { GetColorDefinition } from 'atoms/colors/colors';
import type { FontSizeDefinition, FontWeightDefinition } from 'atoms/typography/typography';

import Text from 'quarks/Text';
import type { BasicProps } from 'quarks/interpolations/basic';
import { basic } from 'quarks/interpolations/basic';
import { allCSSWithPseudos } from 'quarks/styleProps/all';
import type { TextStyles } from 'quarks/styleProps/text';

import onKeyDown from 'utils/onKeyDown';

import type { FC, SyntheticEvent } from 'react';

const StyledFormControl = styled(FormControlLabel).withConfig({
  shouldForwardProp: prop => !Object.keys(allCSSWithPseudos).includes(prop),
})`
  && {
    ${basic}
  }
`;

interface SwitchProps extends BasicProps {
  /**
   * Text label of the Switch button
   */
  label: string;
  /**
   * Change the label color
   */
  labelColor?: GetColorDefinition;
  /**
   * If `true`, the component is disabled.
   */
  disabled?: boolean;
  /**
   * Change the checked track color
   */
  checkedOffBackgroundColor?: string;
  /**
   * Change the track color
   */
  offBackgroundColor?: string;
  /**
   * displays error message under the label (replaces helper text)
   */
  errorMessage?: string;
  /**
   * If `true`, the component is places in the error state
   */
  error?: boolean;
  /**
   * Determines the size of the `Switch` and `label`
   */
  size?: 'small' | 'large' | 'xlarge';
  /**
   * If `true`, the component is required.
   */
  required?: boolean;
  /**
   * Displays text under the label to assist the user
   */
  helperText?: string;
  /**
   * Callback fired when the state is changed.
   * **Signature:**
   * `function(event: React.ChangeEvent<HTMLInputElement>) => void`
   * event: The event source of the callback. You can pull out the new value by accessing `event.target.value` (string). You can pull out the new checked state by accessing `event.target.checked` (boolean).
   */
  onChange?: (event: SyntheticEvent<Element, Event>, checked: boolean) => void;
  /**
   * Callback fired when the state is changed.
   */
  keyDown?: () => void;
  /**
   * The id of the `input` element.
   */
  id?: string;
  /**
   * If `true`, the component is checked.
   */
  checked?: boolean;
  /**
   * The value of the component. The DOM API casts this to a string.
   */
  value?: string | boolean;
}

const sizeMap = {
  small: {
    label: {
      textSize: 'textSm',
      fontWeight: 'semiBold',
    },
    helper: {
      textSize: 'sm',
    },
  },
  large: {
    label: {
      textSize: 'textMd',
      fontWeight: 'semiBold',
    },
    helper: {
      textSize: 'md',
    },
  },
  xlarge: {
    label: {
      textSize: 'text2xl',
      fontWeight: 'medium',
    },
    helper: {
      textSize: 'lg',
    },
  },
};

const Switch: FC<SwitchProps> = ({
  label,
  labelColor,
  size,
  checkedOffBackgroundColor,
  offBackgroundColor,
  checked,
  required,
  value,
  helperText,
  id,
  error,
  errorMessage,
  keyDown,
  ...props
}) => (
  <StyledFormControl
    control={<MuiSwitch size={size === 'xlarge' ? 'large' : size} required={required} />}
    value={value}
    checked={checked}
    onKeyDown={keyDown && (e => onKeyDown(e, () => keyDown()))}
    id={id}
    label={
      <>
        <Text
          textStyle="md"
          fontSize={size === 'small' ? 'textSm' : 'textMd'}
          textColor={labelColor ? labelColor : 'gray-600'}
          fontWeight={sizeMap[size || 'large']?.label.fontWeight as FontWeightDefinition}
          xs={{ fontSize: sizeMap[size || 'large']?.label.textSize as FontSizeDefinition }}
          lineHeight="textLg"
          alignSelf="flex-start"
        >
          {label}
        </Text>
        <Text
          display="block"
          textStyle={size === 'small' ? 'sm' : 'md'}
          textColor={error ? 'bitterSweet-300' : { light: 'gray-600', dark: 'gray-300' }}
          xs={{ textStyle: sizeMap[size || 'large']?.helper.textSize as TextStyles }}
        >
          {error ? errorMessage : helperText}
        </Text>
      </>
    }
    css={`
      &&& {
        & .MuiSwitch-track,
        & :not(.Mui-checked).MuiSwitch-switchBase:hover + .MuiSwitch-track {
          background-color: ${offBackgroundColor};
        }

        & .Mui-checked + .MuiSwitch-track {
          background-color: ${checkedOffBackgroundColor};
        }
      }
    `}
    {...props}
  />
);

Switch.defaultProps = {
  label: '',
  size: 'large',
};

export default Switch;
