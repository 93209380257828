const createBreadCrumbDataModel = (subDirectory?: string) => {
  const breadcrumbData = [
    {
      id: 'ab3b7c9d-baf097d380',
      label: 'Home',
      link: '/',
    },
  ];

  const convertLocationToListOfStrings = subDirectory?.split('/');
  let domain = '/';

  convertLocationToListOfStrings?.forEach((url: string, index: number) => {
    const authorToBlog = url.replace('authors', 'blog');

    const stripSlashFromUrl = authorToBlog.replace('/', '');
    if (stripSlashFromUrl === '') {
      return;
    }

    const addBranding = stripSlashFromUrl.replace('lineup ai', 'lineup.ai');

    const convertToLabel = `${addBranding.charAt(0).toUpperCase()}${addBranding.slice(1)}`;

    domain = `${domain}${authorToBlog}`;
    const data = {
      id: `${url}${index}`,
      label: convertToLabel,
      link: domain,
    };

    breadcrumbData.push(data);
  });

  return breadcrumbData;
};

export default createBreadCrumbDataModel;
